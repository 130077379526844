import { useReducer, useCallback, useMemo } from 'react';

import html2canvas from 'html2canvas';

import {
  getBatch,
  getRefDashboardItem,
  // kpi
  getRefKpiItemList,
  getRefKpiLayout,
  getRefKpiItem,
  getRefPublicKpiList,
  getRefPublicDashboard,
  getRefPublicLayout,
  // layout
  layoutAll2Firestore,
  layoutInsert2Firestore,
  layoutUpdate2Firestore,
  // Comments  - Mural
  getRefCommentsList,
  getRefComment,
  // template
  getRefTemplateList,
  getRefTemplateDoc,
  getRefTemplateLayout,
  getRefTemplateKpis,
  getRefSharedItem,
  getTransaction,
} from '../utils/firestore';
import genPublicKpisFunc from '../utils/functions/genPublicKpis';

import differenceSet from '../utils/functions/differenceSet';
import getRequestMeta from '../utils/functions/generateMeta';
import { ip } from '../utils/functions/urls';
import firebase from '../utils/firebase';
import firestoreErrors from '../utils/firestoreErrors';
import { error as errorLabels } from '../label';
import notificationTriggers from './notifications';
import createLog from './createLog';
import uuidv4 from '../juristec-ui/utils/functions/randomUUID';

const defaultLayout = {
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: [],
};

const commentsLimitPerLoad = 30;

const initialState = {
  isLoading: false,
  collection: 'kpis', // qual snap q tá aberto.
  snapshots: [], // fica dentro do dashboard
  kpiItemList: [],
  dashboardDoc: null,
  kpisLayout: defaultLayout,

  comments: [],
  commentsCursor: null,
  commentsLoading: false,
  commentsLoadingMore: false,
  commentsLoadingError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadCards':
      return {
        ...state,
        kpisLayout: action.kpisLayout,
        kpiItemList: action.kpis,
        dashboardDoc: action.dashboardDoc,
        collection: action.collection,
        isLoading: false,
      };

    case 'loadLayoutAndDash':
      return {
        ...state,
        kpisLayout: action.kpisLayout || defaultLayout,
        dashboardDoc: action.dashboardDoc,
        isLoading: false,
      };
      
    /// create KPI Coment
    case 'createKpiComment':
      return {
        ...state,
        kpiItemList: [state.kpiItemLIst, action.comment],
      };

    case 'removeCard':
      return {
        ...state,
        kpisLayout: action.kpisLayout,
        dashboardDoc: action.dashdoc,
        kpiItemList: state.kpiItemList.filter((k) => k.id !== action.cardId),
        isLoading: false,
      };

    case 'cloneCard':
      return {
        ...state,
        isLoading: false,
        kpisLayout: action.kpisLayout,
        kpiItemList: [...state.kpiItemList, action.clonedCard],
      };

    case 'setLayout':
      return { ...state, kpisLayout: action.kpisLayout, isLoading: false };

    case 'setDashboard':
      return {
        ...state,
        isLoading: false,
        dashboardDoc: action.dashboardDoc,
      };

    case 'loadComments':
      return {
        ...state,
        comments: action.comments,
        commentsCursor: action.cursor,
        commentsLoading: false,
        commentsLoadingError: false,
      };

    case 'loadMoreComments':
      return {
        ...state,
        comments: [...action.comments, ...state.comments],
        commentsCursor: action.cursor,
        commentsLoadingMore: false,
      };

    case 'addComment':
      return {
        ...state,
        comments: [...state.comments, action.comment],
        // commentsLoading: false,
      };

    case 'editComment':
      return {
        ...state,
        comments: state.comments.map((c) => {
          if (c.id === action.commentId) {
            return {
              ...c,
              ...action.editInfos,
            };
          }
          return c;
        }),
      };

    case 'archiveComment':
      return {
        ...state,
        comments: state.comments.filter((c) => c.id !== action.commentId),
      };

    case 'commentsLoadingError':
      return { ...state, commentsLoadingError: action.error, commentsLoading: false };

    case 'setDescription':
      return {
        ...state,
        dashboardDoc: { ...state.dashboardDoc, description: action.description },
      };

    case 'setLoadMoreLoading':
      return { ...state, commentsLoadingMore: action.l };

    case 'setCommentsLoading':
      return { ...state, commentsLoading: action.l };
    case 'setLoading':
      return { ...state, isLoading: action.isLoading };

    case 'setCardStyles':
      return {
        ...state,
        isLoading: false,
        dashboardDoc: { ...state.dashboardDoc, cardStyles: action.cardStyles },
      };

    case 'setGlobalFilterSelectors':
      return {
        ...state,
        isLoading: false,
        dashboardDoc: { ...state.dashboardDoc, globalFilters: action.globalFilters },
      };

    case 'attDashDocPublic':
      return {
        ...state,
        dashboardDoc: {
          ...state.dashboardDoc,
          publicUrl: action.publicUrl,
          publicTimestamp: action.publicTimestamp,
        },
        isLoading: false,
      };
    default:
      return state;
  }
};

const getToken = async (u) => u.getIdToken();
const parseKpi = (kpi) => {
  const parsedKpi = {};
  Object.keys(kpi).forEach((propertie) => {
    try {
      parsedKpi[propertie] = propertie === 'name' ? kpi[propertie] : JSON.parse(kpi[propertie]);
    } catch (_) {
      parsedKpi[propertie] = kpi[propertie];
    }
  });
  return parsedKpi;
};

const isString = (s) => typeof s === 'string' || s instanceof String;
const isFile = (f) => f instanceof Blob || f instanceof File;

// getRefKpiLayout, getRefKpiItemList
function useGridKPI(ownerId, currentDashId, currentUser, userDoc) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setLoading = (l = true) => dispatch({ type: 'setLoading', isLoading: l });
  // const setCommentsLoading = (l = true) => dispatch({ type: 'setCommentsLoading', l });

  // mexe no doc do dash
  const setViewAt = useCallback(async () => {
    try {
      // console.log(ownerId, currentUser.uid, `curretnDAshid: ${currentDashId}`, `is owner: ${ownerId === currentUser.uid}`);
      const dashRef = ownerId === currentUser.uid
        ? getRefDashboardItem(ownerId, currentDashId)
        : getRefSharedItem(currentUser.uid, `${ownerId}_${currentDashId}`);
      dashRef.set({ viewAt: firebase.serverTimestamp() }, { merge: true });
      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code), raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [ownerId, currentUser, currentDashId]);

  const setLastUpdateUserOnShared = useCallback(async (dashdoc) => {
    try {
      if (dashdoc.sharedWith) {
        const batch = getBatch();
        Object.keys(dashdoc.sharedWith).forEach((id) => {
          // const { sharedAt, value } = dashdoc.sharedWith[id];
          // console.log('lastUpdate no shared: ', id, `${currentUser.uid}_${currentDashId}`);
          const sharedRef = getRefSharedItem(id, `${currentUser.uid}_${currentDashId}`);
          batch.set(sharedRef, { lastUpdateUser: firebase.serverTimestamp() }, { merge: true });
        });
        return batch.commit();
      }
      return null;
    } catch (er) {
      console.log(er);
      return null;
    }
  }, [currentDashId, currentUser]);

  const getKpi = useCallback(async (userId, dashId, kpiId, snap) => {
    const kpiRaw = await getRefKpiItem(userId, dashId, kpiId, snap || 'kpis').get();
    return { ...parseKpi(kpiRaw.data()), id: kpiRaw.id };
  }, []);

  const getKpisLayout = useCallback(async (userId, dashId, snap) => {
    const lytRaw = await getRefKpiLayout(userId, dashId, snap || 'kpis').get();
    return lytRaw.data();
  }, []);

  const getKpisList = useCallback(async (userId, dashId, snap) => {
    const kpisListRaw = await getRefKpiItemList(userId, dashId, snap || 'kpis').get();
    return kpisListRaw.docs.map((k) => ({ ...parseKpi(k.data()), id: k.id }));
  }, []);

  const getDashboard = useCallback(async (userId, dashId) => {
    const dashRaw = await getRefDashboardItem(userId, dashId).get();
    return { ...dashRaw.data(), id: dashRaw.id };
  }, []);

  const getAllInfos = useCallback(async (userId, dashboardId, collection) => {
    try {
      const [kpis, kpisLayout, dashboardDoc] = await Promise.all([getKpisList(userId, dashboardId, collection),
        getKpisLayout(userId, dashboardId, collection), getDashboard(userId, dashboardId)]);
      if (dashboardDoc && dashboardDoc.status && !dashboardDoc.isDeleted) {
        await setViewAt();
      }
      return {
        error: false, msg: '', kpis, kpisLayout, dashboardDoc, raw: '',
      };
    } catch (er) {
      return { error: true, msg: firestoreErrors(er.code) || errorLabels.getAllInfos, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [getDashboard, setViewAt, getKpisList, getKpisLayout]);

  /// ///////////// SAVELAYOUT ////////////////////
  const saveLayout = useCallback(async (userId, dashId, toSaveLayout, snap) => {
    try {
      const lytRef = getRefKpiLayout(userId, dashId, snap || 'kpis');
      await lytRef.set(layoutAll2Firestore(toSaveLayout), { merge: true });
      return { error: false, msg: '' };
    } catch (er) {
      return { error: true, msg: firestoreErrors(er.code) || errorLabels.saveLayout, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, []);

  /// //////////// SAVE INSIGHT CARD //////////
  const saveInsightCard = useCallback(async (
    userId, dashId, companyId, kpiLayout, labelKey, LabelData, image,
  ) => {
    try {
      let batch = getBatch();
      const refDashboardLayout = getRefKpiLayout(userId, dashId, 'kpis');
      const LabelObject = { ...LabelData };

      let imgUrl = isString(image) ? image : '';
      if (isFile(image)) {
        imgUrl = await firebase.uploadImage(`companies/${companyId}/images/${userId}/`, image);
      }

      LabelObject.style.image = imgUrl;

      if (labelKey !== 'new') {
        const labelRef = getRefKpiItem(userId, dashId, labelKey);
        batch.update(labelRef, LabelObject);

        batch = createLog('insight', 'edit', {
          userDoc, labelId: labelRef.id, dashId,
        }, batch);
      } else {
        // const dashboardNewDocument = refDashboardItemList.doc();
        const newKpiDoc = getRefKpiItemList(userId, dashId).doc();
        batch.set(newKpiDoc, LabelObject);

        batch = createLog('insight', 'add', {
          userDoc, labelId: newKpiDoc.id, dashId,
        }, batch);

        // UPDATE dashboardlayout
        const ndoc = {
          i: newKpiDoc.id,
          w: 12,
          h: 10,
          x: 0,
          y: 1000,
        };
        const newStateDashLayout2Firestore = layoutInsert2Firestore(kpiLayout, ndoc);
        batch.set(refDashboardLayout, newStateDashLayout2Firestore, { merge: true });
      }

      // UPDATE DASHBOARDLASTUPDATE
      const lastUpdateInfo = { lastUpdateUser: firebase.serverTimestamp() };
      const refDashboard = getRefDashboardItem(userId, dashId);
      batch.update(refDashboard, lastUpdateInfo);

      // update LAST UPDATE USER ON SHAREDS.
      await getTransaction((transaction) => transaction.get(refDashboard).then(async (dashdocRaw) => {
        const dashdoc = dashdocRaw.data();
        if (dashdoc.sharedWith && Object.keys(dashdoc.sharedWith).length) {
          await setLastUpdateUserOnShared(dashdoc);
        }
      }));

      // Commit the batch
      await batch.commit();
      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.createInsight,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  /// SAVE KPI COMMENT (CREATE AND UPDATE) ///
  const saveKpiComment = useCallback(
    async (kpiKey, comment) => {
      try {
        const refKpiToUpdate = getRefKpiItem(ownerId, currentDashId, kpiKey);
        await refKpiToUpdate.set({ comment }, { merge: true });
        return { error: false, msg: '' };
      } catch (error) {
        console.log(error);
        return {
          error: true,
          msg: firestoreErrors(error.code) || errorLabels.useGridKpi.createKpiComment,
          raw: `Erro do sistema: ${error.toString()}`,
        };
      }
    }, [],
  );

  /// SAVE KPI VALUE FILTERS (CREATE AND UPDATE) ///
  const saveKpiValueFilters = useCallback(
    async (kpiKey, metaGen) => {
      try {
        const refKpiToUpdate = getRefKpiItem(ownerId, currentDashId, kpiKey);
        await refKpiToUpdate.set({ meta: JSON.stringify(metaGen) }, { merge: true });
        return { error: false, msg: '' };
      } catch (error) {
        console.log(error);
        return {
          error: true,
          msg: firestoreErrors(error.code) || errorLabels.useGridKpi.createKpiValueFilters,
          raw: `Erro do sistema: ${error.toString()}`,
        };
      }
    }, [],
  );

  // Internal use
  const changeFilePermission = useCallback(async (fileId, selected) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          users: [
            ...selected.map((uid) => ({
              uid,
              read: true,
            })),
          ],
        }),
      };
      const resFetch = await fetch(`${ip}/db/file/${fileId}/permissions/edit`, opt);
      const json = await resFetch.json();

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.useGridKpi.createCard,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.createCard,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  /// //////////////// SAVE CARD (CREATE AND UPDATE) ///////////////////
  const saveKpiCard = useCallback(
    async (userId, dashId, kpiName, kpiKey, cardType, dashLayout, metaGen, metaGridInfo, pivotTable, styleConfig) => {
      try {
        const refDashboardLayout = getRefKpiLayout(userId, dashId, 'kpis');

        const KpiObject = {
          name: kpiName,
          status: 'active',
          updatedAt: firebase.serverTimestamp(),
          type: cardType,
          meta: JSON.stringify(metaGen),
          data: JSON.stringify(pivotTable),
          style: JSON.stringify(styleConfig),
          database: metaGen.database,
        };

        const refDashboard = getRefDashboardItem(userId, dashId);

        await getTransaction((transaction) => transaction.get(refDashboard)
          .then(async (dash) => {
            if (!dash.exists) {
              throw new Error('dashboad does not exist!');
            }
            let ndoc = {};
            // UPDATE INSERT
            if (kpiKey !== 'new') {
              const toUpdateKpie = getRefKpiItem(userId, dashId, kpiKey);
              // const dashboardNewDocument = refDashboardItemList.doc(kpiKey);
              transaction.update(toUpdateKpie, KpiObject);
              ndoc = {
                i: toUpdateKpie.id,
                w: metaGridInfo?.w || 12,
                h: metaGridInfo?.h || 10,
                x: metaGridInfo?.x || 0,
                y: metaGridInfo?.y || 1000,
              };

              const newStateDashLayout2Firestore = layoutUpdate2Firestore(dashLayout, ndoc);
              transaction.update(refDashboardLayout, newStateDashLayout2Firestore);
              transaction = createLog('kpi', 'edit', {
                KpiObject, dashData: dash.data(), userDoc, kpiKey, kpiId: ndoc.i, dashId,
              }, transaction);
            /// ////////////////
            } else {
              const newKpiDoc = getRefKpiItemList(userId, dashId).doc();
              const KpiObjectDated = {
                ...KpiObject,
                createdAt: firebase.serverTimestamp(),
              };
              transaction.set(newKpiDoc, KpiObjectDated);

              // UPDATE dashboardlayout
              ndoc = {
                i: newKpiDoc.id,
                w: metaGridInfo?.w || 12,
                h: metaGridInfo?.h || 10,
                x: 0,
                y: 1000,
              };
              const layoutRaw = await refDashboardLayout.get();
              const layoutData = layoutRaw.data() || defaultLayout;
              const newStateDashLayout2Firestore = layoutInsert2Firestore(layoutData, ndoc);
              transaction.set(refDashboardLayout, newStateDashLayout2Firestore, { merge: true });
              transaction = createLog('kpi', 'add', {
                KpiObject, dashData: dash.data(), userDoc, kpiKey, kpiId: ndoc.i, dashId,
              }, transaction);
            }
            // UPDATE DASHBOARD_DOC
            const oldDatabases = dash.get('databases') || [];
            const databases = !oldDatabases.includes(metaGen.database)
              ? [...oldDatabases, metaGen.database] : oldDatabases;
            transaction.update(refDashboard,
              { databases, lastUpdateUser: firebase.serverTimestamp() });

            // UPDATING FILE PERMISSIONS FOR ALREADY SHARED USERS
            // AND LASTUPDATUSER
            const alreadyShared = dash.get('sharedWith') || {};
            if (Object.keys(alreadyShared).length) {
              await setLastUpdateUserOnShared(dash.data());
              transaction = notificationTriggers('create_update', {
                KpiObject, dashData: dash.data(), userDoc, kpiKey, kpiId: ndoc.i, dashId,
              }, transaction);
            }
            if (!oldDatabases.includes(metaGen.database)
            && Object.keys(alreadyShared).length > 0
            && metaGen.fileOwner === userId) {
              const fileRes = await changeFilePermission(
                metaGen.database, Object.keys(alreadyShared),
              );
              if (fileRes.error) {
                return fileRes.error;
              }

              // const idsToMetadata = {};
              // Object.keys(alreadyShared).forEach((id) => {
              //   idsToMetadata[`shared_${id}`] = true;
              // });
              // await firebase.updateCustomFileMetadataUsingPath(`/users/${userId}/databases/${metaGen.database}`, idsToMetadata);
            }

            // notification
          }));
        return { error: false, msg: '' };
      } catch (er) {
        console.log(er);
        return {
          error: true,
          msg: firestoreErrors(er.code) || errorLabels.useGridKpi.createCard,
          raw: `Erro do sistema: ${er.toString()}`,
        };
      }
    }, [userDoc],
  );

  /// ////////////// REMOVE CARD ////////////////////
  const removeCard = useCallback(async (userId, dashId, cardId) => {
    try {
      const refCardsRef = getRefKpiItemList(userId, dashId);
      const dashboardRef = getRefDashboardItem(userId, dashId);
      const card = refCardsRef.doc(`${cardId}`);
      let dashdocUpdated = {};
      const cardData = await card.get().then((doc) => doc.data());
      await card.delete();

      await getTransaction((transaction) => transaction.get(dashboardRef).then(async (dashdocRaw) => {
        const dashdoc = dashdocRaw.data();
        transaction = createLog((cardData.type === 'label' ? 'insight' : 'kpi'), 'remove', {
          KpiObject: cardData, userDoc, kpiId: cardId, dashId,
        }, transaction);
        // update a lista de docs do dashboard
        const cards = await refCardsRef.where('status', '==', 'active').get();
        const databaseArray = [
          ...new Set(cards.docs.map((d) => d?.data().database).filter((d) => d)),
        ];
        const changes = { lastUpdateUser: firebase.serverTimestamp() };
        if (databaseArray && dashdoc?.databases && differenceSet(databaseArray, dashdoc.databases)) {
          changes.databases = databaseArray;
        }

        if (changes.databases && dashdoc.globalFilters?.length > 0) {
          changes.globalFilters = dashdoc.globalFilters.filter((gf) => changes.databases.includes(gf.database));
        }
        // change lastUpdateUser to shareds
        if (dashdoc.sharedWith) {
          await setLastUpdateUserOnShared(dashdoc);

          transaction = notificationTriggers('kpi_delete', {
            dashdoc, cardData, cardId, userDoc, dashId,
          }, transaction);
        }

        dashdocUpdated = {
          ...dashdoc,
          ...changes,
        }

        transaction.update(dashboardRef, changes);
      }));

      return { error: false, msg: '', dashdoc: dashdocUpdated };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.removeCard,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  /// ////////////////// CLONE CARD ////////////////////
  const cloneCard = useCallback(async (userId, dashId, toCloneCard, layout) => {
    try {
      const batch = getBatch();
      const refKpiItemList = getRefKpiItemList(userId, dashId);
      const kpiNewDocument = refKpiItemList.doc();
      const getCardData = () => {
        const objData = {
          ...toCloneCard,
          name: `clone de ${toCloneCard.name}`,
          clonned_at: firebase.serverTimestamp(),
        };
        if (toCloneCard.meta) objData.meta = JSON.stringify(toCloneCard.meta);
        if (toCloneCard.data) objData.data = JSON.stringify(toCloneCard.data);
        if (toCloneCard.style) objData.style = JSON.stringify(toCloneCard.style);
        return objData;
      };

      batch.set(kpiNewDocument, getCardData());
      const stateDashboardLayout2Firestore = layoutAll2Firestore(layout);
      const originalItemLayout = [...stateDashboardLayout2Firestore.lg].filter(
        (v) => v.i === toCloneCard.id,
      );

      const refDashboardLayout = getRefKpiLayout(userId, dashId);
      const ndoc = {
        i: kpiNewDocument.id,
        w: originalItemLayout?.[0]?.w || 0,
        h: originalItemLayout?.[0]?.h || 0,
        x: originalItemLayout?.[0]?.x || 0,
        y: 1000,
      };
      const layouts2 = layoutInsert2Firestore(layout, ndoc);
      batch.update(refDashboardLayout, layouts2);

      // update LAST UPDATE USER ON SHAREDS.
      const refDashboard = getRefDashboardItem(userId, dashId);
      await getTransaction((transaction) => transaction.get(refDashboard).then(async (dashdocRaw) => {
        const dashdoc = dashdocRaw.data();
        if (dashdoc.sharedWith && Object.keys(dashdoc.sharedWith).length) {
          await setLastUpdateUserOnShared(dashdoc);
        }
      }));

      await batch.commit();
      return { error: false, msg: '', clonedCardId: kpiNewDocument.id };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.cloneCard,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  /** ****************** SNAPSHOTS  ******************* */
  /// /////////////// add SNAP //////////////////
  async function deleteSnapCollection(userId, dashId, snapName, batch = getBatch()) {
    const ref = getRefKpiItemList(userId, dashId, snapName);
    const getting = await ref.get();

    getting.docs.forEach((doc) => batch.delete(doc));

    return batch;
  }

  function compare(a, b) {
    const toCompare = b.snapId || b;
    const aux = a.snapId || a;
    return toCompare.localeCompare(aux, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  const saveSnapshot = useCallback(
    async (userId, dashId, snapName, oldSnaps, cardList, cardsLayout, deleteOlder) => {
      try {
        const orderedOldSnaps = oldSnaps.sort(compare);
        const snapId = new Date().toISOString();
        const refSnap = getRefKpiItemList(userId, dashId, snapId);
        const dashboardRef = getRefDashboardItem(userId, dashId);
        let batch = getBatch();

        const refSnapLayout = getRefKpiLayout(userId, dashId, snapId);
        batch.set(refSnapLayout, layoutAll2Firestore(cardsLayout));

        // copy kpis
        cardList.forEach((card) => {
          const newKpi = refSnap.doc(card.id);
          batch.set(newKpi, {
            ...card,
            meta: card.meta ? JSON.stringify(card.meta) : null,
            data: card.data ? JSON.stringify(card.data) : null,
            style: card.style ? JSON.stringify(card.style) : null,
          });
        });

        if (deleteOlder) {
          batch = await deleteSnapCollection(userId, dashId, orderedOldSnaps.shift(), batch);
        }

        const toSaveSnaps = [{ name: snapName, snapId }, ...orderedOldSnaps];

        batch.update(dashboardRef, { snapshots: toSaveSnaps });

        batch = createLog('story', 'add', {
          dashId, userDoc, snapId, snapName,
        }, batch);

        await batch.commit();

        // update LAST UPDATE USER ON SHAREDS.

        await getTransaction((transaction) => transaction.get(dashboardRef).then(async (dashdocRaw) => {
          const dashdoc = dashdocRaw.data();
          if (dashdoc.sharedWith && Object.keys(dashdoc.sharedWith).length) {
            await setLastUpdateUserOnShared(dashdoc);
          }
        }));

        return { error: false, msg: '' };
      } catch (er) {
        return {
          error: true,
          msg: firestoreErrors(er.code) || errorLabels.useGridKpi.newSnap,
          raw: `Erro do sistema: ${er.toString()}`,
        };
      }
    }, [],
  );
  /// ////////////////// END ADD SNAP //////////////////////

  /// ///////////// EDIT SNAP /////////////// (edit snap name)
  const editSnapshot = useCallback(async (userId, dashId, editSnap, oldSnapsFromDoc) => {
    try {
      const dashboardRef = getRefDashboardItem(userId, dashId);
      const oldSnaps = oldSnapsFromDoc || [];
      const attSnaps = oldSnaps.map((old) => {
        if (old.snapId === editSnap.snapId) return editSnap;
        return old;
      });

      await dashboardRef.update({ snapshots: attSnaps });

      // update LAST UPDATE USER ON SHAREDS.

      await getTransaction((transaction) => transaction.get(dashboardRef).then(async (dashdocRaw) => {
        const dashdoc = dashdocRaw.data();
        transaction = createLog('story', 'edit', {
          dashId, userDoc, name: editSnap.name,
        }, transaction);
        if (dashdoc.sharedWith && Object.keys(dashdoc.sharedWith).length) {
          await setLastUpdateUserOnShared(dashdoc);
        }
      }));

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.editSnap,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  /// ///////// REMOVE SNAP ///////////////////
  const removeSnap = useCallback(async (userId, dashId, toDeleteSnap, oldSnaps) => {
    try {
      const dashboardRef = getRefDashboardItem(userId, dashId);
      const attSnaps = oldSnaps.filter((old) => old.snapId !== toDeleteSnap.snapId);

      let batch = getBatch();

      batch.update(dashboardRef, { snapshots: attSnaps });
      batch = await deleteSnapCollection(userId, dashId, toDeleteSnap.snapId, batch);

      batch = createLog('story', 'remove', {
        dashId, userDoc, snapId: toDeleteSnap.snapId, snapName: toDeleteSnap.name,
      }, batch);

      // update LAST UPDATE USER ON SHAREDS.

      await getTransaction((transaction) => transaction.get(dashboardRef).then(async (dashdocRaw) => {
        const dashdoc = dashdocRaw.data();
        if (dashdoc.sharedWith && Object.keys(dashdoc.sharedWith).length) {
          await setLastUpdateUserOnShared(dashdoc);
        }
      }));

      await batch.commit();
      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.removeSnap,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  /// ///////////// DONWLOAD RELATORIO /////////////
  const downloadReportFromCard = useCallback(async (dashId, cardId, fileName) => {
    try {
      const token = await getToken(currentUser);
      const opt = { ...await getRequestMeta(token), responseType: 'blob' };
      const resFetch = await fetch(`${ip}/kpis/report/${dashId}/${cardId}`, opt);

      if (resFetch.status !== 200) {
        const json = await resFetch.json();
        return { error: true, msg: json.msg || errorLabels.useGridKpi.getReport, raw: json };
      }

      const blob = await resFetch.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // meta?.database?.replace('.metrics', '') || 'report.xlsx';
      a.click();
      return { error: false, msg: '' };
    } catch (er) {
      return { error: true, msg: errorLabels.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  /// ///////////////// DONWLOAD IMAGE //////////////
  const donwloadImageCard = useCallback(async (cardRef, name) => {
    if (!cardRef) return;
    const canvas = await html2canvas(cardRef, { logging: false });
    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const a = document.createElement('a');
    a.href = image;
    a.download = `${name || 'card'}.png`;
    a.click();
  }, []);

  /// /////////// getColumnDependecies ///////////////
  const getColumnDependecies = useCallback((kpis, globalFilters) => {
    const metas = kpis.map((card) => ('meta' in card ? card.meta : null)).filter((kpi) => kpi !== null);
    const columnsRequired = {};
    metas.forEach((kpi) => {
      if (kpi.columns?.length > 0) {
        columnsRequired[kpi.columns[0].column] = { type: kpi.columns[0].type || 'category', description: '' };
      }
      if (kpi.control?.length > 0) {
        columnsRequired[kpi.control[0].column] = { type: kpi.control[0].type || 'category', description: '' };
      }
      if (kpi.filters?.length > 0) {
        kpi.filters.forEach((filter) => {
          if (filter?.column !== kpi.columns[0]?.column) {
            columnsRequired[filter.column] = { type: filter.type || 'category', description: '' };
          }
        });
      }
      // if (globalFilters?.length > 0) {
      //   globalFilters.forEach((gFilter) => {
      //     if (gFilter?.column !== kpi.columns[0]?.column) {
      //       columnsRequired[gFilter.column] = { type: gFilter.type || 'category', description: '' };
      //     }
      //   });
      // }
      columnsRequired[kpi.lines[0].column] = { type: kpi.lines[0].type || 'category', description: '' };
      columnsRequired[kpi.values[0].column] = { type: kpi.values[0].type || 'category', description: '' };
    });

    return columnsRequired;
  }, []);

  /// /////////////// COMMENTS FUNCS /////////////////

  const loadComments = useCallback(async () => {
    try {
      const commentsRaw = await getRefCommentsList(ownerId, currentDashId)
        .orderBy('serverTimestamp', 'asc')
        .where('archived', '==', false)
        .limitToLast(commentsLimitPerLoad)
        .get();

      const comments = commentsRaw.docs.map((c) => ({ ...c.data(), id: c.id }));

      const cursor = comments.length >= commentsLimitPerLoad ? commentsRaw.docs[0] : null;

      return {
        error: false,
        msg: '',
        comments,
        cursor,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.loadComments,
        raw: `Erro do sistema: ${er.toString()}`,
        comments: [],
        cursor: null,
      };
    }
  }, [ownerId, currentDashId]);

  const loadMoreComments = useCallback(async (cursor) => {
    if (!cursor) {
      return {
        error: false, comments: [], cursor: null, msg: '',
      };
    }
    try {
      const dataCursor = cursor.data();
      const commentsRaw = await getRefCommentsList(ownerId, currentDashId)
        .orderBy('serverTimestamp', 'desc')
        .startAfter(cursor)
        .where('archived', '==', false)
        .where('serverTimestamp', '<', dataCursor.serverTimestamp)
        .limit(commentsLimitPerLoad)
        .get();

      const comments = commentsRaw.docs.map((c) => ({ ...c.data(), id: c.id })).reverse();
      const newCursor = comments.length >= commentsLimitPerLoad ? commentsRaw.docs[0] : null;
      return {
        error: false, msg: '', comments, cursor: newCursor,
      };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.loadMoreComments,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [ownerId, currentDashId]);

  const addComment = useCallback(async (text, mentions) => {
    const comment = {
      text,
      timestamp: new Date().getTime(),
      authorId: currentUser.uid,
      author: currentUser.email,
      mentions,
      archived: false,
      image: currentUser.photoURL,
    };

    try {
      const CommentsListRef = getRefCommentsList(ownerId, currentDashId);
      const dashDoc = await getDashboard(ownerId, currentDashId);
      const doc = CommentsListRef.doc();
      await doc.set({
        ...comment,
        id: doc.id,
        serverTimestamp: firebase.serverTimestamp(),
      });
      if (comment.mentions.users.length > 0) {
        let batch = getBatch();
        batch = notificationTriggers('comment', { comment, userDoc, dashDoc }, batch);
        await batch.commit();
      }

      return { error: false, msg: '', comment: { ...comment, id: doc.id } };
    } catch (er) {
      console.log(er);
      const localErroId = `${uuidv4()}_error`;
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.addComment,
        raw: `Erro do sistema: ${er.toString()}`,
        comment: { ...comment, error: true, id: localErroId },
      };
    }
  }, [ownerId, currentDashId, currentUser]);

  const editComment = useCallback(async (text, mentions, commentId) => {
    const editInfos = {
      text,
      edited: true,
      editedTimestamp: new Date().getTime(),
      mentions,
    };
    try {
      const CommentsDocRef = await getRefComment(ownerId, currentDashId, commentId);
      await CommentsDocRef.set({
        ...editInfos,
        serverEditedTimestamp: firebase.serverTimestamp(),
      }, { merge: true });

      return { error: false, msg: '', editInfos };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.editComment,
        raw: `Erro do sistema: ${er.toString()}`,
        editInfos: { ...editInfos, error: true },
      };
    }
  }, [ownerId, currentDashId]);

  const archiveComment = useCallback(async (commentId) => {
    try {
      const CommentsRaw = await getRefComment(ownerId, currentDashId, commentId);
      await CommentsRaw.set({
        archived: true,
        archivedTimestamp: firebase.serverTimestamp(),
      }, { merge: true });

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.archiveComment,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [ownerId, currentDashId]);

  // /////////////// Descrição /////////////////// //
  const setDescription = useCallback(async (description) => {
    try {
      const ref = getRefDashboardItem(ownerId, currentDashId);
      await ref.set({ description }, { merge: true });

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.setDescription,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [ownerId, currentDashId]);

  const setCardStyles = useCallback(async (cardStyles) => {
    try {
      const ref = getRefDashboardItem(ownerId, currentDashId);
      await ref.set({ cardStyles }, { merge: true });

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.setCardStyles,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [ownerId, currentDashId]);

  // ////////////// create Template ////////////// //
  /**
   * templateObj = {
   *  category: '',
   *  columnsRequired: {
   *    ['nome da variável']: {
   *        type: ' ',
   *        description: '',
   *     }
   *    }
   *  image: '',
   *  keywords: ['', ''...],
   *  name: '',
   *  tutorialLink: 'www....',
   *
   * }
   */
  const createTemplate = useCallback(async (
    templateObj,
    kpiItemList,
    stateKpisLayout,
  ) => {
    try {
      const templateList = getRefTemplateList();
      const newTemplate = templateList.doc();

      const batch = getBatch();

      const refLayoutDoc = getRefTemplateLayout(newTemplate.id);
      batch.set(refLayoutDoc,
        layoutAll2Firestore(stateKpisLayout),
        { merge: true });

      const templateKpis = getRefTemplateKpis(newTemplate.id);
      kpiItemList.forEach((card) => {
        const newKpi = templateKpis.doc(card.id);
        const cardObj = { ...card, style: JSON.stringify(card.style) };
        if (card.type !== 'label') {
          cardObj.data = JSON.stringify(card.data);
          cardObj.meta = JSON.stringify(card.meta);
        }
        batch.set(newKpi, cardObj);
      });

      let imageUrl = isString(templateObj.imageFile) ? templateObj.imageFile : '';
      if (isFile(templateObj.imageFile)) {
        imageUrl = templateObj.imageFile
          ? await firebase.uploadImage('system/templates', templateObj.imageFile)
          : await firebase.uploadImage('system/templates', { name: 'defaultimg.png' });
      }

      const newTemplateObj = {
        image: imageUrl,
        columnsRequired: templateObj.variables,
        ...templateObj,
      };

      delete newTemplateObj.variables;
      delete newTemplateObj.imageFile;

      batch.set(newTemplate, {
        ...newTemplateObj,
        created_at: firebase.serverTimestamp(),
      });

      await batch.commit();
      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.createTemplate,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [userDoc]);

  const editTemplate = useCallback(async (
    templateId,
    templateObj,
  ) => {
    try {
      const oldTemplate = getRefTemplateDoc(templateId);
      const batch = getBatch();

      let imageUrl = isString(templateObj.imageFile) ? templateObj.imageFile : '';
      if (isFile(templateObj.imageFile)) {
        imageUrl = templateObj.imageFile
          ? await firebase.uploadImage('system/templates', templateObj.imageFile)
          : await firebase.uploadImage('system/templates', { name: 'defaultimg.png' });
      }

      const editedTemplateObj = {
        image: imageUrl,
        columnsRequired: templateObj.variables,
        ...templateObj,
      };

      delete editedTemplateObj.variables;
      delete editedTemplateObj.imageFile;

      batch.update(oldTemplate, {
        ...editedTemplateObj,
        last_update: firebase.serverTimestamp(),
      });

      await batch.commit();
      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.editTemplate,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [userDoc]);

  const deleteTemplate = useCallback(async (templateId) => {
    try {
      const template = getRefTemplateDoc(templateId);
      template.delete();
      /* const batch = getBatch();

      let imageUrl = isString(templateObj.imageFile) ? templateObj.imageFile : '';
      if (isFile(templateObj.imageFile)) {
        imageUrl = templateObj.imageFile
          ? await firebase.uploadImage('system/templates', templateObj.imageFile)
          : await firebase.uploadImage('system/templates', { name: 'defaultimg.png' });
      }

      const editedTemplateObj = {
        image: imageUrl,
        columnsRequired: templateObj.variables,
        ...templateObj,
      };

      delete editedTemplateObj.variables;
      delete editedTemplateObj.imageFile;

      batch.update(oldTemplate, {
        ...editedTemplateObj,
        last_update: firebase.serverTimestamp(),
      });

      await batch.commit(); */
      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.deleteTemplate,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [userDoc]);

  // Gen public data
  const genPublicKpis = useCallback(async (dashDoc, snapId = 'kpis', revokeLink) => {
    try {
      const { publicUrl, localTimestamp } = await genPublicKpisFunc(userDoc, dashDoc, snapId, revokeLink);
      return {
        error: false, msg: '', publicUrl, localTimestamp,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code),
        raw: `Erro do sistema: ${er.toString()}`,
        link: '',
      };
    }
  }, [userDoc]);

  const getPublicKpis = useCallback(async (userId, dashId) => {
    try {
      const layoutRef = getRefPublicLayout(userId, dashId);
      const dashRef = getRefPublicDashboard(userId, dashId);
      const kpisRef = getRefPublicKpiList(userId, dashId);

      const [layoutRaw, dashRaw, kpisRaw] = await Promise.all([layoutRef.get(), dashRef.get(), kpisRef.get()]);

      const layout = layoutRaw.data();
      const dashDoc = dashRaw.data();
      const kpis = kpisRaw.docs.map((d) => ({ ...parseKpi(d.data()), id: d.id }));

      return {
        error: false,
        msg: '',
        layout,
        dashDoc: dashDoc?.publicUrl ? dashDoc : null,
        kpis,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code),
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  const setGlobalFilterSelectors = useCallback(async (globalFilters) => {
    try {
      const ref = getRefDashboardItem(ownerId, currentDashId);
      await ref.set({ globalFilters }, { merge: true });

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.useGridKpi.setGlobalFilterSelectors,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [ownerId, currentDashId]);

  const middleware = useCallback(async (action) => {
    // console.log('[users] middleware: ', action);
    switch (action.type) {
      case 'loadCards': {
        setLoading(true);
        const getRes = await getAllInfos(ownerId, currentDashId, action.collection || 'kpis');
        if (getRes.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'loadCards',
            kpis: getRes.kpis,
            kpisLayout: getRes.kpisLayout,
            dashboardDoc: getRes.dashboardDoc,
            collection: action.collection || 'kpis',
          });
        }
        return getRes;
      }

      case 'loadLayoutAndDash': {
        setLoading(true);
        const [dashboardDoc, kpisLayout] = await Promise
          .all([getDashboard(ownerId, currentDashId), getKpisLayout(ownerId, currentDashId)]);
        dispatch({ type: 'loadLayoutAndDash', dashboardDoc, kpisLayout });
        return { dashboardDoc, kpisLayout };
      }

      case 'loadLayout': {
        setLoading(true);
        const result = await getKpisLayout(ownerId, currentDashId);
        dispatch({ type: 'setLayout', kpisLayout: result });
        return result;
      }

      case 'saveLayout': {
        setLoading(true);
        const saveLytRes = await saveLayout(ownerId, currentDashId, action.kpisLayout);
        if (saveLytRes.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setLayout', kpisLayout: action.kpisLayout });
        }
        return saveLytRes;
      }

      // salvar comentário do KPI
      case 'saveKpiComment': { 
        const { kpiKey, comment } = action;
        const { error, msg, raw } = await saveKpiComment(kpiKey, comment);
        dispatch({ type: 'saveKpiComment', comment });
        return { error, msg, raw };
      }

      case 'saveKpiValueFilters': {
        const { kpiKey, metaGen } = action;
        const { error, msg, raw } = await saveKpiValueFilters(kpiKey, metaGen);
        dispatch({ type: 'saveKpiValueFilters', metaGen });
        return { error, msg, raw };
      }

      case 'removeCard': {
        setLoading(true);
        const rmvCardRes = await removeCard(ownerId, currentDashId, action.cardId);
        if (rmvCardRes.error) {
          setLoading(false);
        } else {
          const lyt = await getKpisLayout(ownerId, currentDashId);
          dispatch({ type: 'removeCard', cardId: action.cardId, kpisLayout: lyt, dashdoc: rmvCardRes.dashdoc });
        }
        return rmvCardRes;
      }

      case 'cloneCard': {
        setLoading(true);
        const { toClone, cardslayout } = action;
        const res = await cloneCard(ownerId, currentDashId, toClone, cardslayout);
        if (res.error) {
          setLoading(false);
        } else {
          const [clonedCard, kpisLayout] = await Promise.all([
            getKpi(ownerId, currentDashId, res.clonedCardId),
            getKpisLayout(ownerId, currentDashId),
          ]);
          dispatch({ type: 'cloneCard', clonedCard, kpisLayout });
        }
        return res;
      }

      case 'saveKpiCard': {
        setLoading(true);
        const {
          kpiName, kpiKey, cardType, dashLayout, metaGen, metaGridInfo, pivotTable, styleConfig,
        } = action;
        const result = await saveKpiCard(ownerId, currentDashId, kpiName, kpiKey, cardType, dashLayout, metaGen, metaGridInfo, pivotTable, styleConfig);
        setLoading(false);
        return result;
      }

      case 'saveInsightCard': {
        setLoading(true);
        const {
          kpiLayout, labelKey, LabelData, image,
        } = action;
        const result = await saveInsightCard(
          ownerId, currentDashId, userDoc.company, kpiLayout, labelKey, LabelData, image,
        );
        setLoading(false);
        return result;
      }

      case 'getKpiInfo': {
        const { kpiId, snap } = action;
        setLoading(true);
        const res = await getKpi(ownerId, currentDashId, kpiId, snap);
        setLoading(false);
        return res;
      }

      /// ////////// SNAPSHOTS /////////////
      case 'saveSnapshot': {
        setLoading(true);
        const newSnapRes = await saveSnapshot(
          ownerId,
          currentDashId,
          action.snapName,
          action.oldSnaps,
          action.cardList,
          action.cardsLayout,
          action.deleteOlder || false,
        );
        if (newSnapRes.error) setLoading(false);
        else {
          const dashDoc = await getDashboard(ownerId, currentDashId);

          dispatch({ type: 'setDashboard', dashboardDoc: dashDoc });
        }
        return newSnapRes;
      }

      case 'editSnapshot': {
        setLoading(true);
        const { editSnap, oldSnaps } = action;
        const editSnapRes = await editSnapshot(ownerId, currentDashId, editSnap, oldSnaps);
        if (editSnapRes.error) {
          setLoading(false);
        } else {
          const dashDoc = await getDashboard(ownerId, currentDashId);
          dispatch({ type: 'setDashboard', dashboardDoc: dashDoc });
        }
        return editSnapRes;
      }

      case 'removeSnapshot': {
        setLoading(true);
        const { toDeleteSnap, oldSnaps } = action;
        const rmvSnap = await removeSnap(ownerId, currentDashId, toDeleteSnap, oldSnaps);
        if (rmvSnap.error) {
          setLoading(false);
        } else {
          const dashDoc = await getDashboard(ownerId, currentDashId);
          dispatch({ type: 'setDashboard', dashboardDoc: dashDoc });
        }
        return rmvSnap;
      }

      // OTHERS
      case 'downloadReportFromCard': {
        setLoading(true);
        const { cardId, fileName } = action;
        const result = await downloadReportFromCard(currentDashId, cardId, fileName);
        setLoading(false);
        return result;
      }

      case 'getDashInfo': {
        setLoading(true);
        const res = await getDashboard(ownerId, currentDashId);
        setLoading(false);
        return res;
      }

      case 'setDashInfo': {
        const { dashboardDoc } = action;
        dispatch({
          type: 'setDashboard',
          dashboardDoc,
        });
        return {};
      }

      case 'downloadImgCard': {
        setLoading(true);
        const { cardRef, name } = action;
        await donwloadImageCard(cardRef, name);
        setLoading(false);
        return {};
      }

      case 'getColumnDependecies': {
        return getColumnDependecies(action.kpiList, action.globalFilters);
      }

      case 'loadComments': {
        dispatch({ type: 'setCommentsLoading', l: true });
        const {
          error, comments, msg, raw, cursor,
        } = await loadComments();

        if (error) {
          dispatch({ type: 'commentsLoadingError', error: true });
        } else {
          dispatch({ type: 'loadComments', comments, cursor });
        }
        return { error, msg, raw };
      }

      case 'addComment': {
        const {
          error, msg, raw, comment,
        } = await addComment(action.text, action.mentions);
        dispatch({ type: 'addComment', comment });
        // esquema para saber qnd deu erro. (salvar só no front, flag com addError)
        return { error, msg, raw };
      }

      case 'editComment': {
        const { commentId, text, mentions } = action;
        // action.mentions
        const {
          error, msg, raw, editInfos,
        } = await editComment(text, mentions, commentId);
        if (!error) {
          dispatch({ type: 'editComment', commentId, editInfos });
        } else {
          dispatch({ type: 'setCommentsLoading', l: false });
          // esquema para saber qnd deu erro. (salvar só no front, flag com editError)
        }
        return { error, msg, raw };
      }

      case 'archiveComment': {
        const { error, msg, raw } = await archiveComment(action.commentId);
        if (!error) {
          dispatch({ type: 'archiveComment', commentId: action.commentId });
        }

        return { error, msg, raw };
      }

      case 'setDescription': {
        const { description } = action;
        const { error, msg, raw } = await setDescription(description);
        dispatch({ type: 'setDescription', description });
        return { error, msg, raw };
      }

      case 'loadMoreComments': {
        dispatch({ type: 'setLoadMoreLoading', l: true });
        const {
          msg, error, raw, comments, cursor,
        } = await loadMoreComments(action.cursor);
        if (!error) {
          dispatch({ type: 'loadMoreComments', comments, cursor });
        } else {
          dispatch({ type: 'setLoadMoreLoading', l: false });
        }

        return { error, msg, raw };
      }

      case 'setCardStyles': {
        setLoading(true);
        const result = await setCardStyles(action.cardStyles);
        if (!result.error) {
          dispatch({ type: 'setCardStyles', cardStyles: action.cardStyles });
        } else {
          setLoading(false);
        }

        return result;
      }

      case 'setGlobalFilterSelectors': {
        setLoading(true);
        const result = await setGlobalFilterSelectors(action.globalFilters);
        if (!result.error) {
          dispatch({ type: 'setGlobalFilterSelectors', globalFilters: action.globalFilters });
        } else {
          setLoading(false);
        }

        return result;
      }

      case 'createTemplate': {
        setLoading(true);
        const { template, kpiItemList, kpisLayout } = action;
        const result = await createTemplate(template, kpiItemList, kpisLayout);
        setLoading(false);
        return result;
      }

      case 'editTemplate': {
        setLoading(true);
        const { templateId, template } = action;
        const result = await editTemplate(templateId, template);
        setLoading(false);
        return result;
      }

      case 'deleteTemplate': {
        setLoading(true);
        const { templateId } = action;
        const result = await deleteTemplate(templateId);
        setLoading(false);
        return result;
      }

      case 'genPublicKpis': {
        setLoading(true);
        const res = await genPublicKpis(action.dashId, action.snapId, action.revokeLink);
        if (!res.error) {
          dispatch({
            type: 'attDashDocPublic',
            publicUrl: res.publicUrl,
            publicTimestamp: res.localTimestamp,
          });
        } else {
          setLoading(false);
        }
        return res;
      }

      case 'getPublicKpis': {
        setLoading(true);
        const {
          error,
          msg,
          raw,
          kpis, layout, dashDoc,
        } = await getPublicKpis(action.userId, action.dashId);
        if (!error) {
          dispatch({
            type: 'loadCards',
            kpis,
            kpisLayout: layout,
            dashboardDoc: dashDoc,
            collection: 'kpis',
          });
        }
        setLoading(false);
        return { error, msg, raw };
      }

      default:
        return dispatch(action);
    }
  }, [
    ownerId,
    currentDashId,
    getAllInfos,
    genPublicKpis,
    cloneCard,
    getKpi,
    getKpisLayout,
    saveLayout,
    removeCard,
    getDashboard,
    saveSnapshot,
    editSnapshot,
    removeSnap,
    downloadReportFromCard,
    donwloadImageCard,
    saveKpiCard,
    saveInsightCard,
    getColumnDependecies,
    // comments
    loadComments,
    addComment,
    editComment,
    archiveComment,
    loadMoreComments,
    setDescription,
    // template
    createTemplate,
    editTemplate,
    deleteTemplate,
  ]);

  const kpiAPI = useMemo(() => ({
    loadCardsFromCollection: async (collection) => middleware({ type: 'loadCards', collection }),
    saveLayout: async (kpisLayout) => middleware({ type: 'saveLayout', kpisLayout }),
    loadLayout: async () => middleware({ type: 'loadLayout' }),
    // cards
    saveInsightCard: async (kpiLayout, labelKey, LabelData, image) => middleware({
      type: 'saveInsightCard', kpiLayout, labelKey, LabelData, image,
    }),
    saveKpiComment: async (kpiKey, comment) => middleware({ type: 'saveKpiComment', kpiKey, comment }),
    saveKpiValueFilters: async (kpiKey, metaGen) => middleware({ type: 'saveKpiValueFilters', kpiKey, metaGen }),
    saveKpiCard: async (kpiName, kpiKey, cardType, dashLayout, metaGen, metaGridInfo, pivotTable, styleConfig) => middleware({
      type: 'saveKpiCard', kpiName, kpiKey, cardType, dashLayout, metaGen, metaGridInfo, pivotTable, styleConfig,
    }),
    removeCard: async (cardId) => middleware({ type: 'removeCard', cardId }),
    cloneCard: async (toClone, cardslayout) => middleware({ type: 'cloneCard', toClone, cardslayout }),
    getKpiInfo: async (kpiId, snap) => middleware({ type: 'getKpiInfo', kpiId, snap }),
    // snaps
    changeToSnap: async (snapId) => middleware({ type: 'changeToSnap', snapId }),
    saveSnapshot: async (snapName, oldSnaps, cardList, cardsLayout, deleteOlder) => middleware({
      type: 'saveSnapshot', snapName, oldSnaps, cardList, cardsLayout, deleteOlder,
    }),
    editSnapshot: async (editSnap, oldSnaps) => middleware({ type: 'editSnapshot', editSnap, oldSnaps }),
    removeSnapshot: async (toDeleteSnap, oldSnaps) => middleware({ type: 'removeSnapshot', toDeleteSnap, oldSnaps }),
    // others
    downloadImgCard: async (cardRef, name) => middleware({ type: 'downloadImgCard', cardRef, name }),
    downloadReportFromCard: async (cardId, fileName) => middleware({ type: 'downloadReportFromCard', cardId, fileName }),
    getDashInfo: async () => middleware({ type: 'getDashInfo' }),
    setDashInfo: async (dashboardDoc) => middleware({ type: 'setDashInfo', dashboardDoc }),
    setIsLoading: async (l) => middleware({ type: 'setLoading', isLoading: l }),
    loadLayoutAndDash: async () => middleware({ type: 'loadLayoutAndDash' }),
    getColumnDependecies: async (kpiList, globalFilters) => middleware({ type: 'getColumnDependecies', kpiList, globalFilters }),
    // comments
    loadComments: async () => middleware({ type: 'loadComments' }),
    addComment: async (text, mentions) => middleware({ type: 'addComment', text, mentions }),
    editComment: async (text, mentions, commentId) => middleware({
      type: 'editComment', commentId, text, mentions,
    }),
    archiveComment: async (commentId) => middleware({ type: 'archiveComment', commentId }),
    loadMoreComments: async (cursor) => middleware({ type: 'loadMoreComments', cursor }),
    /// description
    setDescription: async (description) => middleware({ type: 'setDescription', description }),
    setCardStyles: async (cardStyles) => middleware({ type: 'setCardStyles', cardStyles }),
    setGlobalFilterSelectors: async (globalFilters) => middleware({ type: 'setGlobalFilterSelectors', globalFilters }),
    // template
    createTemplate: async (template, kpiItemList, kpisLayout) => middleware({
      type: 'createTemplate', template, kpiItemList, kpisLayout,
    }),
    editTemplate: async (templateId, template) => middleware({
      type: 'editTemplate', templateId, template,
    }),
    deleteTemplate: async (templateId) => middleware({
      type: 'deleteTemplate', templateId,
    }),
    genPublicKpis: async (dashId, snapId, revokeLink) => middleware({
      type: 'genPublicKpis', dashId, snapId, revokeLink,
    }),
    getPublicKpis: async (userId, dashId) => middleware({
      type: 'getPublicKpis', userId, dashId,
    }),
  }), [middleware]);

  return [state, kpiAPI];
}

export default useGridKPI;
