import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  RangeController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup
      title="Estilos"
      id="styles"
      extend={extend}
      setExtend={setExtend}
    >
      <SimpleColorPickerController
        label="Cor do dia vazio"
        handle={handle}
        kWord="EmptyColorControl"
        configValue={config?.EmptyColorControl}
        orientation="row"
        fullWidth
      />
      <RangeController
        label="Tamanho da borda dos meses"
        handle={handle}
        kWord="MonthWidthControl"
        configValue={config?.MonthWidthControl}
        min={0}
        max={20}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor da borda dos meses"
        handle={handle}
        kWord="MonthColorControl"
        configValue={config?.MonthColorControl}
        orientation="row"
        fullWidth
        disabled={!config?.MonthWidthControl || config?.MonthWidthControl?.toString() === '0'}
      />
      <RangeController
        label="Tamanho da borda dos dias"
        handle={handle}
        kWord="DayWidthControl"
        configValue={config?.DayWidthControl}
        min={0}
        max={20}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor da borda dos dias"
        handle={handle}
        kWord="DayColorControl"
        configValue={config?.DayColorControl}
        orientation="row"
        fullWidth
        disabled={!config?.DayWidthControl || config?.DayWidthControl?.toString() === '0'}
      />
      <RangeController
        min={2}
        max={Math.max(data.data.length, 5)}
        label="Quantidade de cores do degradê"
        handle={handle}
        kWord="QtdColorsControl"
        configValue={config?.QtdColorsControl}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor inicial"
        handle={handle}
        kWord="GradientFirstColor"
        configValue={config?.GradientPickerControl?.color1 || '#4711B2'}
        orientation="row"
      />
      <SimpleColorPickerController
        label="Cor final"
        handle={handle}
        kWord="GradientSecondColor"
        configValue={config?.GradientPickerControl?.color2 || '#FF9626'}
        orientation="row"
      />
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default StyleControllers;
