/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../juristec-ui/core/Checkbox';
import InputRadio from '../../../juristec-ui/core/InputRadio';
import useOnScreen from '../../../juristec-ui/hooks/useOnScreen';

import { OrderRow, OptLabel } from './styled/ControlFilter.styled';

const ControlFilterItem = ({
  option, handler, selected, isRadio,
}) => {
  const itemRef = useRef();
  const isVisible = useOnScreen(itemRef);

  return (
    <OrderRow ref={itemRef} className="hover-bgcolor">
      {isVisible ? (
        <>
          {isRadio ? (
            <InputRadio
              value={option.value}
              text={(
                <OptLabel>
                  {option.icon}
                  <span>{option.label}</span>
                </OptLabel>
              )}
              color="primary"
              variant="outlined"
              selected={selected?.[0]}
              onChange={handler}
              style={{ margin: '0' }}
            />
          ) : (
            <Checkbox
              text={(
                <OptLabel>
                  {option.icon}
                  <span>{option.label}</span>
                </OptLabel>
              )}
              variant="outlined"
              style={{ width: '100%', justifyContent: 'flex-start' }}
              checked={selected?.includes(option.value)}
              handleCheckboxChange={(check) => handler(check, option.value)}
            />
          )}
        </>
      ) : (<></>)}
    </OrderRow>
  );
};

ControlFilterItem.propTypes = {
  option: PropTypes.objectOf(PropTypes.any).isRequired,
  handler: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.any),
  isRadio: PropTypes.bool,
};

ControlFilterItem.defaultProps = {
  handler: () => {},
  selected: [],
  isRadio: false,
};

export default ControlFilterItem;
