import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../juristec-ui/core/IconButton';
import Popover from '../../../juristec-ui/core/Popover';
import ControlFilter from '../../Popovers/ControlFilter';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import compare from '../../../utils/functions/sorting';
import { expDateOrder } from '../../../juristec-ui/utils/formatCharts/orderDatetime';
import { dateOptions } from '../../../options';

import { Edit, ExpandMore, ArrowLeft } from '../../../juristec-ui/icons';

import {
  MainContainer,
  ContentRow,
  CenteredItens,
  FilterBadge,
  IconFlipWrapper,
  InfoTooltip,
} from './styled/GlobalFiltersToolbar.styled';

const getSelectorLabel = (selector) => (
  dateOptions.find((d) => d.value === selector).label || ''
);

const GlobalFiltersToolbar = ({
  toggleToolbar,
  globalFilters,
  globalFilterSelectors,
  setGlobalFilterSelectors,
  getColumnValues,
  filterColumnsModal,
  isOwner,
}) => {
  const closeGlobalFilters = () => {
    toggleToolbar(false);
  };

  const globalFilterUpdate = (fId, data = {}) => {
    setGlobalFilterSelectors((gfilters) => ({
      ...gfilters,
      [fId]: {
        ...gfilters[fId],
        ...data,
      },
    }));
  };

  const closeGlobalFilter = (fId) => globalFilterUpdate(fId, { isOpen: false });

  const loadColumnValues = async (fId, gFilter) => {
    globalFilterUpdate(fId, { isOpen: true });
    if (!gFilter.options && gFilter.column && gFilter.database) {
      const res = await getColumnValues(
        gFilter.database, gFilter.column, gFilter.selector,
      );
      if (res.fileError) {
        globalFilterUpdate(fId, { fileError: [...res.fileError] });
      } else {
          res.opts.sort((a, b) => (compare(
          expDateOrder(a.label), expDateOrder(b.label),
        )));
        globalFilterUpdate(fId, { options: [...res.opts] });
      }
    }
  };

  const toggleGlobalFilter = (fId) => {
    const gFilter = globalFilterSelectors[fId];
    if (!gFilter.isOpen) loadColumnValues(fId, gFilter);
    else closeGlobalFilter(fId);
  };

  const handleGlobalFilter = (fId, selected) => {
    globalFilterUpdate(fId, { selected });
    const oldRef = globalFilters.current || {};
    let filterInfo = null;
    const filterData = globalFilterSelectors[fId];
    if (selected?.length !== filterData.options?.length) {
      filterInfo = {
        values: selected,
        selector: filterData.selector,
        column: filterData.column,
      };
    }
    globalFilters.current = {
      ...oldRef,
      [filterData.database]: {
        ...oldRef[filterData.database],
        [`${filterData.column}${filterData.selector}`]: filterInfo,
      },
    };
  };

  const filterColumnsModalHandle = () => {
    filterColumnsModal(toggleToolbar);
  };

  const checkIfActive = (filterData) => (
    !!globalFilters?.current[filterData.database]?.[`${filterData.column}${filterData.selector}`]
  );

  return (
    <MainContainer>
      <ContentRow>
        <Tooltip text="Voltar para toolbar">
          <IconButton
            shape="rounded"
            color="secondary"
            variant="contained"
            onClick={closeGlobalFilters}
          >
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <CenteredItens>
          {Object.keys(globalFilterSelectors).map((gKey) => (
            <Popover
              key={gKey}
              open={globalFilterSelectors[gKey].isOpen || false}
              closePopover={() => closeGlobalFilter(gKey)}
            >
              <Popover.Action>
                <Tooltip
                  text={(
                    <InfoTooltip>
                      <span>{`Arquivo: ${globalFilterSelectors[gKey].filename}`}</span>
                      {globalFilterSelectors[gKey].selector && (
                        <span>{`Formato: ${getSelectorLabel(globalFilterSelectors[gKey].selector)}`}</span>
                      )}
                    </InfoTooltip>
                  )}
                  direction="top"
                >
                  <FilterBadge
                    onClick={() => toggleGlobalFilter(gKey)}
                    isActive={
                      checkIfActive(globalFilterSelectors[gKey])
                    }
                  >
                    <span>{globalFilterSelectors[gKey].column}</span>
                    <IconFlipWrapper flip={globalFilterSelectors[gKey].isOpen || false}>
                      <ExpandMore />
                    </IconFlipWrapper>
                  </FilterBadge>
                </Tooltip>
              </Popover.Action>
              <Popover.Content stayOpened offset={[-20, 2]}>
                <ControlFilter
                  close={() => closeGlobalFilter(gKey)}
                  controlOptions={globalFilterSelectors[gKey].options}
                  controlFilterValues={globalFilterSelectors[gKey].selected || []}
                  handleFilter={(selected) => handleGlobalFilter(gKey, selected)}
                  controlVar={globalFilterSelectors[gKey].column || ''}
                  errorMsg={globalFilterSelectors[gKey].fileError}
                />
              </Popover.Content>
            </Popover>
          ))}
        </CenteredItens>
        {isOwner && (
          <Tooltip text="Gerenciar variáveis">
            <IconButton
              shape="rounded"
              color="secondary"
              variant="contained"
              onClick={filterColumnsModalHandle}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </ContentRow>
    </MainContainer>
  );
};

GlobalFiltersToolbar.propTypes = {
  toggleToolbar: PropTypes.func.isRequired,
  globalFilters: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  globalFilterSelectors: PropTypes.objectOf(PropTypes.any),
  setGlobalFilterSelectors: PropTypes.func.isRequired,
  getColumnValues: PropTypes.func.isRequired,
  filterColumnsModal: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

GlobalFiltersToolbar.defaultProps = {
  globalFilterSelectors: {},
};


export default GlobalFiltersToolbar;
