/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import DataTable from '../../juristec-ui/core/DataTable/DataTable';
import IconButton from '../../juristec-ui/core/IconButton';
import Tooltip from '../../juristec-ui/core/Tooltip';
import TableCellPop from '../../juristec-ui/core/TableCellPop';
import TablePagination from '../../juristec-ui/core/TablePagination';
import TableFilters from '../Popovers/TableFilters';

import originIcon, { getOriginOptions, getConnectorOptions } from '../../juristec-ui/utils/originMap';

import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import compare from '../../utils/functions/sorting';

import {
  Trash, UpdateError, UpdateSuccess, UpdateWarning,
} from '../../juristec-ui/icons';

import {
  MainContainer, TableOverFlow, IconWrapper, OwnerLabel,
} from './styled/ReportsTable.styled';

const ReportsTable = ({
  reports,
  instances,
  //
  getReports,
  deleteReport,
  openReportStatus,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    origin: { isOpen: false, selected: [], isDirty: false },
    status: { isOpen: false, selected: [], isDirty: false },
    connector: { isOpen: false, selected: [], isDirty: false },
    companyId: { isOpen: false, selected: [], isDirty: false },
  });

  useEffect(() => {
    const fetchList = async () => {
      await getReports(page, filters, false);
    };
    if (!reports.list || !reports.list[page]) { //  || reports.list[page].length === 0
      fetchList();
    }
  }, [page, filters]);

  useEffect(() => {
    setPage(0);
    const fetchList = async () => {
      await getReports(0, filters, true);
    };
    if (filters.origin.isDirty
      || filters.status.isDirty
      || filters.connector.isDirty
      || filters.companyId.isDirty
    ) {
      fetchList();
    }
  }, [
    filters.origin.selected,
    filters.status.selected,
    filters.connector.selected,
    filters.companyId.selected,
  ]);

  /**
   * Sets status icon with related status
   * @param {object} report report info
   * @returns An icon with the corresponding status
   */
  const getStatusBadge = (report) => {
    const fetchList = async () => {
      setPage(0);
      await getReports(0, filters, true);
    };
    const openInfo = () => openReportStatus(report, fetchList);

    switch (report.status) {
      case 'ERROR':
        return (
          <IconButton color="error" onClick={openInfo}>
            <UpdateError />
          </IconButton>
        );
      case 'WARNING':
        return (
          <IconButton color="warning" onClick={openInfo}>
            <UpdateWarning />
          </IconButton>
        );
      default:
        return (
          <IconButton color="success" onClick={openInfo}>
            <UpdateSuccess />
          </IconButton>
        );
    }
  };

  /**
   * Find company name by company id
   * @param {string} companyId Company unique identifier
   * @returns {string} Company name
   */
  const getCompanyName = (companyId) => {
    const company = instances.find((ins) => ins.id === companyId);
    return company?.name || companyId;
  };

  const getCompaniesOpt = () => {
    const temp = instances.map((ins) => ({
      id: ins.id, label: ins.name, value: ins.id,
    }));
    return temp.sort((a, b) => (compare(a.label, b.label)));
  };

  const toggleFilter = (field, state) => {
    setFilters((f) => ({
      ...f,
      [field]: {
        ...f[field],
        isOpen: state !== undefined ? state : !f[field].isOpen,
      },
    }));
  };

  const handleFilter = (field, selected) => {
    setFilters((f) => ({
      ...f,
      [field]: {
        ...f[field],
        selected,
        isDirty: true,
      },
    }));
  };

  /** Arrangement of table columns */
  const columns = useMemo(() => [
    {
      field: 'origin',
      label: 'Origem',
      valueGetter: (param) => <IconWrapper>{originIcon(param.origin)}</IconWrapper>,
      tools: (
        <TableFilters
          field="origin"
          isOpen={filters?.origin?.isOpen || false}
          toggle={toggleFilter}
          options={getOriginOptions || []}
          selected={filters?.origin?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'connector',
      label: 'Conector',
      valueGetter: (param) => param.connector,
      tools: (
        <TableFilters
          field="connector"
          isOpen={filters?.connector?.isOpen || false}
          toggle={toggleFilter}
          options={getConnectorOptions || []}
          selected={filters?.connector?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'companyId',
      label: 'Empresa',
      valueGetter: (param) => <TableCellPop text={getCompanyName(param.companyId)} />,
      tools: (
        <TableFilters
          field="companyId"
          isOpen={filters?.companyId?.isOpen || false}
          toggle={toggleFilter}
          options={getCompaniesOpt() || []}
          selected={filters?.companyId?.selected || []}
          handleFilter={handleFilter}
          popDirection="bottom-start"
          isRadio
        />
      ),
    },
    {
      field: 'filename',
      label: 'Arquivo',
      valueGetter: (param) => (
        <>
          <TableCellPop text={param.filename} />
          <OwnerLabel>{param.ownerEmail || 'Usuário não encontrado'}</OwnerLabel>
        </>
      ),
    },
    {
      field: 'updatedAt',
      label: 'Última atualização',
      valueGetter: (param) => formatDateTime(param.updatedAt, { time: 'half' }),
    },
    {
      field: 'status',
      label: 'Status',
      valueGetter: (param) => getStatusBadge(param),
      tools: (
        <TableFilters
          field="status"
          isOpen={filters?.status?.isOpen || false}
          toggle={toggleFilter}
          options={[
            { id: 'status_success', label: 'Sucesso', value: 'SUCCESS' },
            { id: 'status_warning', label: 'Atenção', value: 'WARNING' },
            { id: 'status_error', label: 'Erro', value: 'ERROR' },
          ]}
          selected={filters?.status?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'delete',
      label: '',
      valueGetter: (param) => (
        <Tooltip text="Apagar relatório">
          <IconButton onClick={() => deleteReport(param)}>
            <Trash />
          </IconButton>
        </Tooltip>
      ),
    },
  ], [reports, filters]);

  return (
    <MainContainer>
      <TableOverFlow>
        <DataTable
          columns={columns}
          rowData={reports.list[page] || []}
          defaultSortField="updatedAt"
          defaultSortOrder="descending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: theme.background,
          }}
        />
      </TableOverFlow>
      <TablePagination
        page={page}
        setPage={setPage}
        totalPages={reports.pageTotal - 1}
      />
    </MainContainer>
  );
};

ReportsTable.propTypes = {
  /** Array of reports info */
  reports: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    pageTotal: PropTypes.number,
  }),
  /** Array of instances */
  instances: PropTypes.arrayOf(PropTypes.shape({})),
  /** Requests all the reports */
  getReports: PropTypes.func,
  /** Handles the removal of a report */
  deleteReport: PropTypes.func,
  /** Handles the modal with report info */
  openReportStatus: PropTypes.func,
};

ReportsTable.defaultProps = {
  reports: null,
  instances: [],
  getReports: () => {},
  deleteReport: () => {},
  openReportStatus: () => {},
};

export default ReportsTable;
