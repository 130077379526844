import styled from 'styled-components';
import { gap } from '../../../juristec-ui/styles/theme';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const RowContainer = styled.div`
  display: flex;
  ${gap('5px', 'row')}
  margin-top: 5px;
  flex-direction: row;
`;

export const ScrollContainer = styled(RowContainer)`
  overflow: hidden auto;
  max-height: 100px;
`;

export const BadgeContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 4px;
  grid-template-columns: 32.5% 32.5% 32.5%;
  @media ${devices.tablet} {
    grid-template-columns: 50% 50%;
  }
`;

export const FilterBadge = styled.div`
  color: ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.secondary}20;
  border: 2px solid ${({ theme }) => theme.secondary};
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  min-height: 14px;
  overflow: hidden;
  padding: 2px;
  margin-right: 3px;
  & > span {
    margin-left: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const BadgeClose = styled.button`
  background-color: ${({ theme }) => theme.secondary};
  border: none;
  cursor: pointer;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin-left: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.secondaryHover};
  }
`;
