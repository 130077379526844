/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import InputTextLine from '../InputTextLine';

import { DatePickerContainer, IconWrapper } from './styled/DatePicker.styled';

import 'react-datepicker/dist/react-datepicker.css';
import { Date } from '../../icons';

const DatePicker = ({
  value, label, startDate, endDate, minDate, maxDate, onChange,
}) => {
  const TextInputDate = forwardRef(
    ({
      value, label, onClick, onChange,
    }, ref) => (
      <div style={{ position: 'relative', width: '100%' }}>
        <InputTextLine
          value={value}
          onClick={onClick}
          ref={ref}
          label={label}
          onChange={onChange}
          readOnly
          fullWidth
          style={{ paddingRight: '35px', cursor: 'pointer' }}
        />
        <IconWrapper>
          <Date />
        </IconWrapper>
      </div>
    ),
  );

  return (
    <DatePickerContainer>
      <ReactDatePicker
        selected={value}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date) => onChange(date)}
        customInput={<TextInputDate label={label} onChange={onChange} />}
        locale={ptBR}
        shouldCloseOnSelect={false}
        dateFormat="yyyy-MM-dd"
        wrapperClassName="datepicker-style"
        popperClassName="container-style"
        dayClassName={() => 'day-style'}
        monthClassName={() => 'month-style'}
      />
    </DatePickerContainer>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  value: new Date(),
  label: '',
  startDate: null,
  endDate: null,
  minDate: null,
  maxDate: null,
  onChange: () => {},
};

export default DatePicker;
