import styled, { css } from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';

export const Container = styled.div`
    /* padding: 5px; */
    min-height: 50px;
    max-width: 700px;
    margin: 10px 0px;
`;

export const Infos = styled.div`
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  ${gap('1.5rem', 'row')};
`;

export const StyledFont = styled.span`
  max-width: max-content;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.secondary};
`;

export const KpisCount = styled.div`
    display: flex;
    ${gap('3px', 'row')};
    font-size: 16px;
    max-height: 30px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  /* width: 30%; */
`;

export const FilesList = styled.div`
  border-top: 0.5px solid #C4C4C4;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  & > ::-webkit-scrollbar-track { 
    background: white;
  }
`;

export const EmptyList = styled.div`
  background-color: ${({ theme }) => theme.containerOdd};
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  color: ${({ theme }) => theme.color};
  font-size: 14px;
`;

export const UpdateDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
`;

export const DashboardsTableOverFlow = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 141px);
  box-sizing: border-box;
  padding-bottom: 65px;
`;

export const UpdatedByContainer = styled.span`
  font-size: 9pt;
  font-weight: bold;
`;

export const DashboardNameContainer = styled.span`
  /* ${(props) => css`
    color: ${({ theme }) => (props.isClickable ? theme.secondary : theme.color)};
  `}; */
  font-weight: ${(props) => (props.isClickable ? 'bold' : 'normal')};
  text-decoration: ${(props) => (props.isClickable ? 'underline' : 'none')};
  cursor:  ${(props) => (props.isClickable ? 'pointer' : 'normal')};
`;
