export default {
  TitleControl: '',
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  ShowTitleControl: { checked: true },
  PaddingAngleControl: { value: 0 },
  InnerRadiusControl: { value: 0 },
  CornerRadiusControl: { value: 0 },
  BorderRadiusControl: { value: 0 },
  BorderWidthControl: { value: 0 },
  DataFormat: { type: 'absolute', target: 'global' },

  Margin: {
    top: 50,
    right: 110,
    bottom: 50,
    left: 60,
  },
  MarginTop: 50,
  MarginRight: 110,
  MarginBottom: 50,
  MarginLeft: 60,
  MinValueControl: { value: 0, checked: true, pai: true },
  MaxValueControl: { value: 0, checked: true, pai: true },
  PaddingControl: { value: 0.1 },
  InnerPaddingControl: { value: 0 },
  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  LegendControl: { checked: true },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendPlacementControl: {
    value: {
      // PARA USAR NO SELECT DE POSICAO...
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      // anchor: 'bottom',
      translateX: 110,
      translateY: 0,
      // translateX: -92,
      // translateY: 45,
    },
  },
  SliceTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  Decimals: 'auto',
  // TOOLTIP / INTERACTIVE
  // InteractivityControl: { checked: true },
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  InteractiveDecimals: 'auto',
  Animate: false,
  MotionConfig: 'default',
  PartExtension: 0,

  // LABEL
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  LabelGroupingControl: '',
  LabelCurrencyControl: 'Nenhum',
  LabelRotationControl: 0,
  LabelsSeparadorNumerico: false,
  EnableLabelControl: { checked: true },
  RadialLabelControl: { checked: true },
  LabelSkipWidthControl: { value: 0 },
  LabelSkipHeightControl: { value: 0 },
  LabelsFontSizeControl: { value: 15 },
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },

  CurrencyControl: 'Nenhum',
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,

  // colors
  ColorTheme: { scheme: 'nivo' },
  Colors: {},
  PalletsControl: 'default', // || 'custom' // variável para poder selecionar palletas do nivo ou poder setar cada cor pessoalmente...
  ShowZeroValuesControl: { checked: false },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  legendClick: [],
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },
};
