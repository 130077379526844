import styled, { css } from 'styled-components';
import { gap } from '../../../styles/theme';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Column)`
  padding: 0.5rem 0;
  max-height: 300px;
  overflow-y: auto;
  width: max-content;
  & * {
    font-size: 14px;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 0.5rem;
  ${gap('0.5rem', 'row')}
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};
  padding: .2rem .6rem;
  & svg {
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.grey};
  }
  & .inputTxt {
    padding: 5px 0;
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const IconFlipWrapper = styled(IconWrapper)`
  transition: all 0.5s;
  ${(props) => props.flip && css`
    transform: rotate(180deg);
  `}
`;

export const SeparatorLine = styled.hr`
  margin: 0.2rem auto 0.3rem;
  width: 80%;
  border: 0.8px solid #ddd;
`;

export const CategoryContainer = styled(Column)`
  text-align: center;

  & > span {
    color: ${({ theme }) => theme.grey};
  }
`;

export const GroupContainer = styled(Column)`
  & > div {
    justify-content: start;
    font-size: 16px;
    padding: 0.4rem 1rem;
    margin: 0;
    letter-spacing: -0.5px;
    &:hover {
      background-color: ${({ theme }) => theme.containerHover};
    }
  }
`;
