/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import compare from '../../../utils/functions/sorting';
import { expDateOrder } from '../../utils/formatCharts/orderDatetime';
import { SortArrow } from '../../icons';

import { StyledTable, ColumnHeader, IconWrapper } from './styled/DataTable.styled';

const sortOrderPicker = {
  ascending: 1,
  descending: -1,
};
/**
 * A component for displaying tabulated data sets
 */
const DataTable = ({
  columns,
  rowData,
  rowTotals,
  defaultSortField,
  defaultSortOrder,
  headerColor,
  rowColor,
  strippedRowsColor,
  theadStyle,
  tbodyStyle,
  indexAsHeader,
  expandRow,
  usePagination,
  page,
  itemsPerPage,
  useNumSorting,
  ...props
}) => {
  const [dataList, setDataList] = useState(rowData);
  const [sortBy, setSortBy] = useState({
    order: sortOrderPicker[defaultSortOrder],
    field: defaultSortField,
  });

  const sortColumn = (field) => {
    setSortBy({
      order: field === sortBy.field ? -1 * sortBy.order : 1,
      field,
    });
  };

  useEffect(() => {
    setSortBy({
      order: sortOrderPicker[defaultSortOrder],
      field: defaultSortField,
    });
  }, [defaultSortField, defaultSortOrder]);

  useEffect(() => {
    const numSorting = useNumSorting && (columns?.find((c) => c.field === sortBy.field))?.isNumeric;
    const aux = [...rowData].sort(
      (a, b) => sortBy.order * (compare(
        expDateOrder(a[sortBy.field]), expDateOrder(b[sortBy.field]), numSorting,
      )),
    );
    setDataList(
      usePagination ? aux.slice(page * itemsPerPage, (page + 1) * itemsPerPage) : aux,
    );
  }, [sortBy, rowData, page, itemsPerPage]);

  return (
    <StyledTable
      rowColor={rowColor}
      strippedRowsColor={strippedRowsColor}
      indexAsHeader={indexAsHeader}
      headerColor={headerColor}
      expand={expandRow}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <thead style={theadStyle}>
        {columns && (
          <tr className="columnHeader">
            {columns.map((column, key) => (
              <th key={key} style={headerColor ? { backgroundColor: headerColor } : {}}>
                <ColumnHeader
                  {...(column.sortable && {
                    onClick: () => sortColumn(column.field),
                    sortable: true,
                  })}
                >
                  {column.label}
                  {column.sortable && (
                    <IconWrapper toggle={sortBy} field={column.field}>
                      <SortArrow />
                    </IconWrapper>
                  )}
                  {column.tools}
                </ColumnHeader>
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody style={tbodyStyle}>
        {dataList
          && dataList.map((data, rowKey) => (
            data && (
            <Fragment key={rowKey}>
              <tr
                className={expandRow && data?.showMoreContent ? 'show-content' : ''}
                onClick={() => (expandRow && data?.toggleMoreContent ? data.toggleMoreContent(!data?.showMoreContent) : {})}
              >
                {columns.map((column, columnKey) => (column.field === 'index' ? (
                  <th key={columnKey} style={headerColor && indexAsHeader ? { backgroundColor: headerColor } : {}}>
                    {column.valueGetter ? column.valueGetter(data) : data[column.field]}
                  </th>
                ) : (
                  <td key={columnKey} style={column.dataColor && { backgroundColor: column.dataColor }}>
                    {column.valueGetter ? column.valueGetter(data) : data[column.field]}
                  </td>
                )))}
              </tr>
              {expandRow && (
                <tr className={`extra-row ${data?.showMoreContent ? 'show-row' : 'hide-row'}`}>
                  <th><div /></th>
                  <td colSpan={columns.length}>
                    {data?.moreContent || <></>}
                  </td>
                </tr>
              )}
            </Fragment>
            )
          ))}
        {rowTotals && (
          <tr key="totais-row">
            {columns.map((column, columnKey) => (column.field === 'index' ? (
              <th key={`totais-row-index-${columnKey}`} style={headerColor && indexAsHeader ? { backgroundColor: headerColor } : {}}>
                {column.valueGetter ? column.valueGetter(rowTotals) : rowTotals[column.field]}
              </th>
            ) : (
              <td key={`totais-row-vals-${columnKey}`} style={column.dataColor && { backgroundColor: column.dataColor }}>
                {column.valueGetter ? column.valueGetter(rowTotals) : rowTotals[column.field]}
              </td>
            )))}
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
};

DataTable.propTypes = {
  /**
   * A array of objects with the parameters of each column
   * ex.: [{field: string, label: string, valueGetter: func, sortable: boolean},...]
   */
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * A array of objects with the values of each row
   */
  rowData: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * An object with the totals of the colums in a row
   */
  rowTotals: PropTypes.objectOf(PropTypes.any),
  /**
   * A string that represents which field will be sorted by default
   */
  defaultSortField: PropTypes.string,
  /**
   * A string that represents which order will be sorted by default
   */
  defaultSortOrder: PropTypes.oneOf(['ascending', 'descending'], PropTypes.string),
  /**
   * The background color of the header row. Can be passed theme key names
   */
  headerColor: PropTypes.string,
  /**
   * The background color of each row. Can be passed theme key names
   */
  rowColor: PropTypes.string,
  /**
   * The background color of each odd row. Can be passed theme key names
   */
  strippedRowsColor: PropTypes.string,
  /**
   * Inline style of the table header
   */
  theadStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Inline style of the table body
   */
  tbodyStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Indicates whether the index of the rows is part of the header
   */
  indexAsHeader: PropTypes.bool,
  /**
   * Indicates whether the even row is the expansion of the odd row
   */
  expandRow: PropTypes.bool,
  /**
   * Indicates whether the table needs to be paginated
   */
  usePagination: PropTypes.bool,
  /**
   * Indicates the actual page
   */
  page: PropTypes.number,
  /**
   * Indicates how many items (rows) to show in a page
   */
  itemsPerPage: PropTypes.number,
  /**
   * Indicates whether is to use number only sorting.
   */
  useNumSorting: PropTypes.bool,
};

DataTable.defaultProps = {
  defaultSortField: '',
  defaultSortOrder: 'ascending',
  headerColor: undefined,
  rowColor: 'white',
  rowTotals: undefined,
  strippedRowsColor: undefined,
  theadStyle: undefined,
  tbodyStyle: undefined,
  indexAsHeader: false,
  expandRow: false,
  usePagination: false,
  page: 0,
  itemsPerPage: Infinity,
  useNumSorting: false,
};

export default DataTable;
