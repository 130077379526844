import firebase from '../firebase';
import secureFileName from '../secureFileNames';
import { error } from '../../label';

export const toMb = (size) => parseFloat((size / (1024 * 1024)).toFixed(2));

export const getFormatFromMetricsFile = (t) => {
  const rgx = new RegExp(/\.([a-z 0-9]+)(?=.metrics)/i);
  const ax = rgx.exec(t);
  return ax?.[ax?.length - 1] || 'xlsx';
};

export const verifyCompanyFiles = async (companyId, filesNames) => {
  try {
    if (!filesNames) return { databases: [] };
    const cmpyPromises = filesNames.map((fName) => firebase.getFileMetadataByPath(`/company/${companyId}/databases/${secureFileName(fName)}`));
    const cmpyMeta = await Promise.all(cmpyPromises);
    const cmpyFiles = cmpyMeta.map((f) => f?.fullPath).filter((g) => g);
    const databases = filesNames.filter((v) => !cmpyFiles.some((k) => k.includes(v)));

    return { databases };
  } catch (er) {
    console.log(er);
    return { databases: filesNames };
  }
};

export const verifySpace = async (owner, companyId, filesNames, curFSize, maxFSize) => {
  try {
    const promises = [];

    filesNames.forEach((fName) => promises.push(
      firebase.getFileMetadataByPath(`/companies/${companyId}/databases/${owner}_${secureFileName(fName)}.metrics`),
    ));

    const metadatas = await Promise.all(promises);

    const combinedSize = metadatas.reduce((acc, cur) => {
      if (cur) return acc + cur.size;
      return acc;
    }, 0);

    if (toMb(combinedSize) + curFSize > maxFSize) return { canCopy: false, msg: 'Espaço insuficiente no disco' };
    return { canCopy: true, msg: null };
  } catch (er) {
    console.log(er);
    return { canCopy: false, msg: error.generic };
  }
};

export const verifyFilesWithSameName = (userId, databases) => firebase.verifyFilesWithSameName(`/users/${userId}/databases`, databases);
