import styled from 'styled-components';
import { devices } from '../../../../core/breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;

  // input para a baixo
  @media ${devices.tablet} {
    padding: 10px 20px;
  } ;
`;

export const LeftArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-size: 24px;

  @media ${devices.tablet} {
    font-size: 18px;
    span {
      font-size: 18px;
    }
  }

  @media ${devices.phoneS} {
    font-size: 18px;
    span {
      font-size: 18px;
    }
  } ;
`;

export const TitleArea = styled.div`
  color: rgb(130, 130, 130);
  font-weight: bold;
  display: flex;
  align-items: baseline;
  color: ${({ theme }) => theme.toolbarFont};

  @media ${devices.tablet} {
    font-size: 18px;
  }

  @media ${devices.phoneS} {
    font-size: 18px;
  }
`;
