import styled from 'styled-components';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 0.5rem;
  width: 100%;

  & .label {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.inputLabel};
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }

  & .errorMessage {
    position: absolute;
  }
`;

export const FirstPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 600px;
  gap: 15px;
  & input, textarea {
    font-size: 14px !important;
  }
`;

export const ImageSquare = styled.div`
  background-color: ${({ theme }) => theme.lightgrey};
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid ${({ theme }) => theme.grey};
  border-radius: 10px;
  width: 100%;
`;

export const ImageOverlay = styled.div`
  align-items: center;
  position: absolute;
  display: flex;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: #00000080;
`;

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 10px;
  display: flex;
  height: 150px;
  width: 100%;
  align-self: center;
  max-width: 500px;
`;

export const VarTip = styled.span`
  color: ${({ theme }) => theme.lightgrey};
  font-size: 14px;
`;

export const KeywordsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  max-height: 70px;
  gap: 5px;
  flex-wrap: wrap;
  & button {
    font-size: 10px;
    height: 22px;
  }
  & .btnClose {
    cursor: pointer;
    margin-left: 5px;
    background-color: ${({ theme }) => theme.secondary};
    border-radius: 50%;
    height: 10px;
    width: 10px;
    padding: 2px;
  }
`;

export const KeywordInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
`;
