import React from 'react';
import PropTypes from 'prop-types';

import Container from './styled/MiniLoading';

const MiniLoading = ({ fill }) => (
  <Container className="loading-ring" fill={fill}>
    <div />
    <div />
    <div />
    <div />
  </Container>
);

MiniLoading.propTypes = {
  /**
   * Fill Color to the Loading component
   */
  fill: PropTypes.string,
};

MiniLoading.defaultProps = {
  fill: 'grey',
};

export default MiniLoading;
