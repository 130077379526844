import styled from 'styled-components';
import { devices } from '../../breakpoints';

export const Tabs = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-bottom: 2px;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 2px;
  border-bottom: 4px solid ${({ active, id, theme }) => (active === id ? theme.secondary : 'transparent')};
  color: ${({ active, id, theme }) => (active === id ? theme.secondary : theme.grey)};
  text-shadow: ${({ active, id, theme }) => (
    active === id ? `${theme.secondary} 1px` : 'transparent 0px')} 0px 0px;
  height: 2em;

  &:hover { 
    color: ${({ theme }) => theme.secondaryHover};
  }

  &:disabled {
    color: ${({ theme }) => theme.disabled};
    opacity: 1;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
  }
  font-size: 14px;

  @media ${devices.phoneS} {
    font-size: 14px;
  }
  @media ${devices.ultrawide} {
    font-size: 16px;
  }
`;

export const Content = styled.div`
  ${({ active }) => (active ? '' : 'display:none')};
  overflow: hidden;
`;
