/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useRef, useEffect, useState, Children,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { usePopper } from 'react-popper';

import { StyledContentPopover, StyledContainerPopover } from './styled/Popover.styled';

const Content = ({
  children,
  stayOpened,
  closePopover,
  open,
  containerRef,
  direction,
  offset,
  style,
  containerStyle,
  ...props
}) => {
  const [popper, setPopper] = useState();
  const { styles, attributes } = usePopper(containerRef?.current, popper, {
    placement: direction || 'bottom-end',
    modifiers: [
      { name: 'offset', options: { offset: offset || [0, 10] } },
    ],
  });

  const handleClick = ({ target }) => {
    if (!popper) return;
    if (popper.contains(target)) {
      return;
    }
    if (containerRef?.current.contains(target)) {
      return;
    }
    closePopover();
  };

  useEffect(() => {
    if (open && popper) {
      // add when mounted
      document.addEventListener('mousedown', handleClick);
      window.addEventListener('resize', closePopover);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleClick);
        window.removeEventListener('resize', closePopover);
      };
    }
    return () => {};
  }, [open, popper]);

  return open && ReactDOM.createPortal(
    <StyledContentPopover
      {...props}
      data-testid="popover-content"
      onClick={stayOpened ? null : closePopover}
      ref={setPopper}
      style={{ ...styles.popper, ...style }}
      {...attributes.popper}
    >
      {children}
    </StyledContentPopover>,
    document.body,
  );
};

const Action = ({
  children,
}) => (
  children
);

const Popover = ({
  children,
  open,
  containerStyle,
  ...props
}) => {
  const containerRef = useRef();
  let ActionComponent = null;
  let ContentComponent = null;

  Children.forEach(children, (child) => {
    if (child.type === Action) {
      ActionComponent = child;
      return;
    }
    if (child.type === Content) {
      ContentComponent = child;
    }
  });

  return (
    <StyledContainerPopover ref={containerRef} style={containerStyle}>
      {React.cloneElement(ActionComponent, { ...props, open, containerRef })}
      {React.cloneElement(ContentComponent, { ...props, open, containerRef })}
    </StyledContainerPopover>
  );
};

Popover.Content = Content;
Popover.Action = Action;

Popover.propTypes = {
  /**
    * Popover content
    */
  children: PropTypes.node.isRequired,
  /**
    * state that indicates whether it is open or closed
    */
  open: PropTypes.bool.isRequired,
};
Content.propTypes = {
  /**
    * Popover.content content
    */
  children: PropTypes.node.isRequired,
  /**
    * function that closes the popover
    */
  closePopover: PropTypes.func,
  /**
    * state that indicates whether it is open or closed
    */
  open: PropTypes.bool,
  /**
    * prop that control if popover stay open after click
    */
  stayOpened: PropTypes.bool,
  /**
     * prop to manually change the direction.
     */
  direction: PropTypes.oneOf([
    'left', 'left-start', 'left-end',
    'right', 'right-start', 'right-end',
    'bottom', 'bottom-start', 'bottom-end',
    'top', 'top-start', 'top-end',
  ]),
  /**
   * Array with indicates a distance offset ([horizontal, vertical])
   */
  offset: PropTypes.arrayOf(PropTypes.number),
  /**
   * Object with inline style
   */
  style: PropTypes.objectOf(PropTypes.any),
  /**
   * Object with inline style for the container
   */
  containerStyle: PropTypes.objectOf(PropTypes.any),
};

Content.defaultProps = {
  stayOpened: false,
  open: false,
  closePopover: () => { },
  direction: 'bottom-end',
  offset: [0, 10],
  style: {},
  containerStyle: {},
};

export default Popover;
