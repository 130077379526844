/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useContext, useRef, useLayoutEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import Avatar from '../../juristec-ui/core/Avatar';
import IconButton from '../../juristec-ui/core/IconButton';
import {
  Bell,
  Question,
  Dashboard,
  ArchiveBox,
  UploadImage,
} from '../../juristec-ui/icons';
import Burger from '../../juristec-ui/core/Burger';
import Backdrop from '../../juristec-ui/core/Backdrop';
import Drawer from '../../juristec-ui/core/Drawer';
import Tooltip from '../../juristec-ui/core/Tooltip';
import ProfileConfig from '../../juristec-ui/core/ProfileConfig';
import Popover from '../../juristec-ui/core/Popover';
import Notifications from '../Popovers/Notifications';
import TutorialGuide from './TutorialGuide';
import ProfileEdit from '../ProfileEdit';

import useDeviceType from '../../juristec-ui/hooks/useDeviceType';
import { ModalContext } from '../../context/ModalProvider';
import { TourContext } from '../../context/TourProvider';
import { NotificationsContext } from '../../context/NotificationProvider';

import {
  HeaderContainer,
  Brand,
  DrawerNavLink,
  NavLink,
  LeftElementHeader,
  RightElementHeader,
  HeaderAvatar,
  NotificationElement,
  CompanyLogoOverlay,
  SeparatorLine,
} from './styled/Header.styled';

/**
 * The Header component is the Navigation bar of the project.
 * That is where the user can find easy access to other routes and main features of the application.
 */
const support = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      window.open('https://suporte.legalone-analytics.com.br/hc/pt-br', '_blank');
      break;

    case 'benner-metrics':
      window.open('https://suporte.bennermetrics.com.br/hc/pt-br', '_blank');
      break;

    default:
      window.open('https://suporte.legalmetrics.com.br/hc/pt-br', '_blank');
      break;
  }
};

const Header = ({
  user,
  userInfo,
  userMultifactors,
  userIsAdmin,
  clientLogo,
  handleProfileChanges,
  handleAvatarChange,
  nightMode,
  setNightMode,
  handleLogout,
  handleLogoChange,
  checkImage,
  newMfaNumber,
  delMfaNumber,
}) => {
  const device = useDeviceType();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showProfileConfig, setShowProfileConfig] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showGuideDrawer, setShowGuideDrawer] = useState(false);

  const theme = useTheme();

  const { state: notificationsState, notificationsAPI } = useContext(NotificationsContext);
  const { setModalConfig, toggleModal } = useContext(ModalContext);

  const inputFile = useRef(null);
  const isMounted = useRef(false);

  const { tourOpen, nextStep /* refreshTour */ } = useContext(TourContext);

  const toggleState = (stateSetter) => {
    stateSetter((prev) => !prev);
  };

  const openProfileEdit = () => {
    setShowProfileConfig(false);
    setModalConfig({
      title: 'Perfil de usuário',
      children: (
        <ProfileEdit
          user={{
            name: user.displayName,
            src: user.photoURL || '',
            email: user.email,
            permission: userInfo.role || 'guest',
            mfas: userMultifactors() || [],
          }}
          addMfaNumber={newMfaNumber}
          removeMfaNumber={delMfaNumber}
          closeModal={toggleModal}
          submitFormData={handleProfileChanges}
          submitAvatarImg={handleAvatarChange}
          checkImage={checkImage}
        />
      ),
      disableFocus: true,
      // nodeTarget: 'profile-edit-modal',
    });
  };

  const moreNotifications = async () => {
    const offSet = notificationsState.lastElement;
    const { error, msg, raw } = await notificationsAPI.getNotifications(offSet);
    if (error) {
      console.log(error, msg, raw);
    }
  };

  const setViewedNotifications = useCallback(async () => {
    const {
      error, msg, raw,
    } = await notificationsAPI.setViewedAtAllVisible(notificationsState.notifications);
    if (error) {
      console.log({ error, msg, raw });
    }
    // n corrigir esse warning por enquanto. pode causar loop infinito
  }, [notificationsAPI]);

  useLayoutEffect(() => {
    if (!showNotifications && isMounted.current) {
      setViewedNotifications();
    }
  }, [showNotifications, isMounted, setViewedNotifications]);

  useEffect(() => {
    (async () => {
      await notificationsAPI.init();
    })();
  }, [notificationsAPI]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = (() => {
      switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
        case 'legalone-analytics':
          return 'https://static.zdassets.com/ekr/snippet.js?key=f5e10f81-26e6-484c-a8b8-07479806e908';

        case 'benner-metrics':
          return 'https://static.zdassets.com/ekr/snippet.js?key=2b669cc5-f213-44bc-b2a9-03633a522925';

        case 'legalmetrics':
          return 'https://static.zdassets.com/ekr/snippet.js?key=2245de47-cba2-42b2-ab5e-9eef62aa14c6';

        default:
          return 'https://static.zdassets.com/ekr/snippet.js?key=2245de47-cba2-42b2-ab5e-9eef62aa14c6';
      }
    })();
    script.id = 'ze-snippet';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Jira widget
  // <script
  //   data-jsd-embedded
  //   data-key="2b12ab78-83da-4c1b-a338-829251b0eb1e"
  //   data-base-url="https://jsd-widget.atlassian.com"
  //   src="https://jsd-widget.atlassian.com/assets/embed.js">
  // </script>
  useEffect(() => {
    const jiraScript = document.createElement('script');
    jiraScript.dataset.key = '2b12ab78-83da-4c1b-a338-829251b0eb1e';
    jiraScript.dataset.baseUrl = 'https://jsd-widget.atlassian.com';
    jiraScript.dataset.jsdEmbedded = true;
    jiraScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    jiraScript.id = 'jira-widget';

    document.body.appendChild(jiraScript);

    return () => {
      document.body.removeChild(jiraScript);
    };
  }, []);

  return (
    <>
      {showSideMenu && <Backdrop onClick={() => setShowSideMenu(false)} />}
      <Drawer
        open={showSideMenu}
        direction="left"
        handleCloseDrawer={() => setShowSideMenu(false)}
      >
        <img
          src={clientLogo}
          alt="logo"
          width="150px"
          style={{ alignSelf: 'center', padding: '1rem 0 0 0' }}
        />
        <SeparatorLine />
        <DrawerNavLink to="/home" onClick={() => setShowSideMenu(false)} activeClassName="header-active-link">
          <Dashboard />
          Dashboards
        </DrawerNavLink>
        {userInfo?.role !== 'guest' && (
          <DrawerNavLink to="/arquivos" onClick={() => setShowSideMenu(false)} activeClassName="header-active-link">
            <ArchiveBox />
            Arquivos
          </DrawerNavLink>
        )}
      </Drawer>
      <HeaderContainer>
        <LeftElementHeader>
          <Burger
            open={showSideMenu}
            color="primary"
            onClick={() => toggleState(setShowSideMenu)}
          />
          <NavLink to="/home">
            <Brand>{theme.logo}</Brand>
          </NavLink>
          <NavLink to="/home" activeClassName="header-active-link">
            Dashboards
          </NavLink>
          {userInfo?.role !== 'guest' && (
            <NavLink to="/arquivos" activeClassName="header-active-link">
              Arquivos
            </NavLink>
          )}
        </LeftElementHeader>
        <RightElementHeader>
          {device === 'phone' ? null : (
            <Tooltip text="Tutorial" direction="left">
              <IconButton onClick={() => toggleState(setShowGuideDrawer)}>
                <Question />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ position: 'relative' }}>
            <Popover
              style={{
                borderRadius: '20px',
                backgroundColor: theme.popoverBackground,
                overflow: 'hidden',
              }}
              open={showNotifications}
              stayOpened
              closePopover={() => {
                setShowNotifications(false);
              }}
            >
              <Popover.Action>
                <Tooltip text="Notificações" direction="left">
                  <IconButton onClick={() => toggleState(setShowNotifications)}>
                    <Bell />
                  </IconButton>
                </Tooltip>
              </Popover.Action>
              <Popover.Content direction="bottom-end">
                <Notifications
                  closePopover={() => setShowNotifications(false)}
                  userInfo={userInfo}
                  moreNotifications={moreNotifications}
                  notificationsLoading={notificationsState.isLoading}
                  notificationList={notificationsState.notifications}
                  haveMore={!!notificationsState.lastElement}
                />
              </Popover.Content>
            </Popover>
            {notificationsState.notificationsCount > 0 && (
              <NotificationElement newMessages={notificationsState.notificationsCount}>
                {notificationsState.notificationsCount}
              </NotificationElement>
            )}
          </div>
          <Popover
            style={{
              borderRadius: '20px',
              backgroundColor: theme.popoverBackground,
              overflow: 'hidden',
            }}
            open={showProfileConfig}
            stayOpened
            closePopover={() => setShowProfileConfig(false)}
          >
            <Popover.Action>
              <HeaderAvatar>
                <img src={clientLogo} alt="client_logo" />
                <Tooltip text="Configurações" direction="left">
                  <Avatar
                    name={user.displayName}
                    src={user.photoURL}
                    size="medium"
                    style={{
                      borderColor: `${theme.grey}`,
                      color: `${theme.primary}`,
                    }}
                    onClick={() => { toggleState(setShowProfileConfig); if (tourOpen) nextStep(); }}
                    className="profile_popover"
                  />
                </Tooltip>
                {userInfo?.role === 'super' && (
                  <CompanyLogoOverlay>
                    <Tooltip text="Alterar logo" direction="left" atModal>
                      <IconButton
                        color="#fff"
                        size={device === 'phone' ? 'small' : 'medium'}
                        onClick={() => inputFile.current.click()}
                      >
                        <UploadImage />
                      </IconButton>
                    </Tooltip>
                    <input
                      type="file"
                      ref={inputFile}
                      accept="image/*"
                      onChange={handleLogoChange}
                      style={{ display: 'none' }}
                    />
                  </CompanyLogoOverlay>
                )}
              </HeaderAvatar>
            </Popover.Action>
            <Popover.Content direction="bottom-end">
              <ProfileConfig
                openProfileEdit={openProfileEdit}
                closeProfileConfig={() => setShowProfileConfig(false)}
                user={{
                  name: user.displayName,
                  src: user.photoURL,
                  isAdmin: userIsAdmin,
                  role: userInfo?.role,
                }}
                nightMode={nightMode}
                handleNightMode={setNightMode}
                handleLogout={handleLogout}
                tourContext={{ tourOpen, nextStep }}
              />
            </Popover.Content>
          </Popover>
        </RightElementHeader>
      </HeaderContainer>
      <Drawer
        open={showGuideDrawer}
        direction="right"
        handleCloseDrawer={() => setShowGuideDrawer(false)}
      >
        <TutorialGuide
          support={support}
          close={() => setShowGuideDrawer(false)}
        />
      </Drawer>
    </>
  );
};

Header.propTypes = {
  /**
   * A string to the path of the client logo
   */
  clientLogo: PropTypes.string,
  checkImage: PropTypes.func,
};

Header.defaultProps = {
  clientLogo: '',
  checkImage: () => {},
};

export default Header;
