import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstPageContainer = styled(Column)`
  max-width: 600px;
  max-height: calc(100vh - 335px);
  gap: 5px;
`;

export const HeaderTips = styled(Column)`
  gap: 0.8rem;
  font-size: 14px;
  margin-bottom: 5px;
  & span {
    margin: 0;
    &.danger {
      font-weight: bold;
      color: ${({ theme }) => theme.error};
    }
  }
`;

export const VarContainer = styled.div`
  overflow: hidden auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  background-color: ${({ theme }) => theme.containerOdd};
  border-radius: 10px;
  padding: 1rem;
`;

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SelectContainerFile = styled(SelectContainer)`
  margin: 0 0 .5rem;
`;
