/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { usePopper } from 'react-popper';

import { WrapperTooltip, ContentTooltip } from './styled/Tooltip.styled';

/**
* Display additional information on floating labels when users hover elements on the screen.
*/
function Tooltip({
  children, text, atModal, noEvent, direction, className,
}) {
  const node = useRef();
  const [popper, setPopper] = useState();
  const [arrow, setArrow] = useState(null);
  const [hover, setHover] = useState(false);
  const { styles, attributes } = usePopper(node?.current, popper, {
    placement: direction || 'left',
    modifiers: [
      { name: 'arrow', options: { element: arrow } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  const onMouseOverHandler = () => {
    setHover(true);
  };

  const onMouseOutHandler = () => {
    setHover(false);
  };

  const onFocusHandler = () => {
    setHover(false);
  };

  const onClickHandler = () => {
    setHover(false);
  };

  const onBlurHandler = () => {
    setHover(false);
  };

  return (
    <>
      <WrapperTooltip
        data-testid="tooltip"
        ref={node}
        noEvent={noEvent}
        className={className}
        onMouseEnter={onMouseOverHandler}
        onMouseLeave={onMouseOutHandler}
        onFocus={onFocusHandler}
        onClick={onClickHandler}
        onBlur={onBlurHandler}
      >
        {children}
      </WrapperTooltip>
      {hover && ReactDOM.createPortal(
        <ContentTooltip
          ref={setPopper}
          style={styles.popper}
          {...attributes.popper}
          atModal={atModal}
        >
          {text}
          <div ref={setArrow} style={styles.arrow} className="arrow-tooltip" />
        </ContentTooltip>,
        document.body,
      )}
    </>
  );
}

Tooltip.propTypes = {
  /**
    * Tooltip content
    */
  children: PropTypes.node.isRequired,
  /**
    * Tooltip text
    */
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number,
  ]).isRequired,
  /**
    * Boolean required when the tooltip is used in a modal, that changes zIndex
    */
  atModal: PropTypes.bool,
  /**
    *If it is necessary to remove the events from the tooltip and leave it only to children
    */
  noEvent: PropTypes.bool,
  /**
    * property responsible for tooltip direction
    */
  direction: PropTypes.oneOf([
    'left', 'left-start', 'left-end',
    'right', 'right-start', 'right-start',
    'bottom', 'bottom-start', 'bottom-end',
    'top', 'top-start', 'top-end',
  ]),
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  atModal: false,
  noEvent: false,
  direction: 'left',
  className: '',
};

export default Tooltip;
