import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import IconButton from '../../../../juristec-ui/core/IconButton';
import Tooltip from '../../../../juristec-ui/core/Tooltip';
import Toggle from '../../../../juristec-ui/core/Toggle';

import { Add, Close } from '../../../../juristec-ui/icons';

import { compactString } from '../../../../juristec-ui/utils/functions/formatString';

import {
  verifyEmail,
} from '../../../../juristec-ui/utils/validators/inputTextValidators';

import {
  MainContainer,
  ColumnContainer,
  VerticalSeparator,
  InputWrapper,
  EmailContainer,
  EmailList,
} from './styled/NewInstance.styled';

const SecondStep = ({
  scientistsEmailsState,
  adminEmailsState,
  sendEmailState,
}) => {
  // Estados que controlam os inputs
  const [adminInput, setAdminInput] = useState({ value: '', error: false, errorMsg: '' });
  const [scientistInput, setScientstInput] = useState({ value: '', error: false, errorMsg: '' });

  // Estados que contem o que sera enviado (declarados no componente pai)
  const [adminEmails, setAdminEmails] = adminEmailsState;
  const [scientistsEmails, setScientistsEmails] = scientistsEmailsState;
  const [sendEmail, setSendEmail] = sendEmailState;

  /**
   * Lida com o input de email, verificando a validade e atualiza o estado informado
   * @param {string} value String que representa um email
   * @param {function} state Estado ao qual deve ser atualizado
   */
  const handleEmailInput = (value, state) => {
    const val = compactString(value);
    const msg = verifyEmail(val);
    state({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  /**
   * Lida com o input de email de admin
   * @param {event} e Evento de alteracao do input
   */
  const handleAdminInput = (e) => {
    handleEmailInput(e.target.value, setAdminInput);
  };

  /**
   * Lida com o input de email de cientista
   * @param {event} e Evento de alteracao do input
   */
  const handleScientistInput = (e) => {
    handleEmailInput(e.target.value, setScientstInput);
  };

  /**
   * Adiciona um email na lista de emails de admins
   */
  const addAdminEmail = () => {
    if (adminInput.error || !adminInput.value) return;
    const auxList = new Set(adminEmails);
    auxList.add(adminInput.value);
    setAdminEmails([...auxList]);
    handleEmailInput('', setAdminInput);
  };

  /**
   * Adiciona um email na lista de emails de cientistas
   */
  const addScientistEmail = () => {
    if (scientistInput.error || !scientistInput.value) return;
    const auxList = new Set(scientistsEmails);
    auxList.add(scientistInput.value);
    setScientistsEmails([...auxList]);
    handleEmailInput('', setScientstInput);
  };

  /**
   * Adiciona um email de admin ao apertar a tecla ENTER
   * @param {event} e Evento da tecla ENTER
   */
  const handleEnterKeyAdmin = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') addAdminEmail();
  };

  /**
   * Adiciona um email de cientista ao apertar a tecla ENTER
   * @param {object} e Evento da tecla ENTER
   */
  const handleEnterKeyScientist = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') addScientistEmail();
  };

  /**
   * Remove um email da lista de emails dos admins
   * @param {string} email Email a ser removido
   */
  const removeAdminEmail = (email) => {
    setAdminEmails((old) => old.filter((em) => em !== email));
  };

  /**
   * Remove um email da lista de emails dos cientistas
   * @param {string} email Email a ser removido
   */
  const removeScientistEmail = (email) => {
    setScientistsEmails((old) => old.filter((em) => em !== email));
  };

  /**
   * Ativa ou desativa a permissao de enviar emails
   */
  const toggleSendEmail = () => { setSendEmail((p) => !p); };

  return (
    <MainContainer>
      {/* Email dos admins */}
      <ColumnContainer>
        <InputWrapper>
          <InputTextLine
            label="E-mail dos proprietários"
            onChange={handleAdminInput}
            onKeyDown={handleEnterKeyAdmin}
            value={adminInput.value}
            error={adminInput.errorMsg.length > 0}
            errorMessage={adminInput.errorMsg}
            wrapperStyle={{ marginRight: '10px' }}
          />
          <Tooltip text="Adicionar" atModal direction="right">
            <IconButton
              onClick={addAdminEmail}
              color="secondary"
              shape="rounded"
              variant="contained"
              disabled={adminInput.error || !adminInput.value}
              className="email-container-button"
            >
              <Add />
            </IconButton>
          </Tooltip>
        </InputWrapper>
        <EmailContainer>
          <EmailList>
            {adminEmails.map((email) => (
              <li key={email}>
                <div>
                  <span>{email}</span>
                  <Tooltip text="Remover" atModal direction="right">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      onClick={() => removeAdminEmail(email)}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </div>
              </li>
            ))}
          </EmailList>
        </EmailContainer>
      </ColumnContainer>

      <VerticalSeparator />

      {/* Email dos cientistas */}
      <ColumnContainer>
        <InputWrapper>
          <InputTextLine
            label="E-mail dos cientistas"
            onChange={handleScientistInput}
            onKeyDown={handleEnterKeyScientist}
            value={scientistInput.value}
            error={scientistInput.errorMsg.length > 0}
            errorMessage={scientistInput.errorMsg}
            wrapperStyle={{ marginRight: '10px' }}
          />
          <Tooltip text="Adicionar" atModal direction="right">
            <IconButton
              onClick={addScientistEmail}
              color="secondary"
              shape="rounded"
              variant="contained"
              disabled={scientistInput.error || !scientistInput.value}
              className="email-container-button"
            >
              <Add />
            </IconButton>
          </Tooltip>
        </InputWrapper>
        <EmailContainer>
          <EmailList>
            {scientistsEmails.map((email) => (
              <li key={email}>
                <div>
                  <span>{email}</span>
                  <Tooltip text="Remover" atModal direction="right">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      onClick={() => removeScientistEmail(email)}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </div>
              </li>
            ))}
          </EmailList>
        </EmailContainer>
      </ColumnContainer>
      <InputWrapper className="bottom">
        <span className="custom-label">Enviar e-mail aos usuários</span>
        <Toggle
          checked={sendEmail}
          handleChange={toggleSendEmail}
          firstColor="primary"
          size="small"
          optionPosition="both"
        />
      </InputWrapper>
    </MainContainer>
  );
};

SecondStep.propTypes = {
  scientistsEmailsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminEmailsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  sendEmailState: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SecondStep;
