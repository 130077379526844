import styled from 'styled-components';
import { devices } from '../../breakpoints';

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ alert }) => (alert ? '1050' : '1040')};
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.fadedBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled.div`
  z-index: ${({ alert }) => (alert ? '110' : '100')};
  background: ${({ transparent, theme }) => (transparent ? 'transparent' : theme.modal)};
  position: relative;
  border-radius: 20px;
  height: auto;
  max-height: 90vh;
  outline: none;
  padding: ${({ alert }) => (alert ? '15px' : '0px')};
  transition: all 500ms;

  max-width: 85vw;
  min-width: ${({ minWidth }) => (minWidth ? 'auto' : '30vw')};
  width: auto;
  @media ${devices.tablet} {
    max-width: 90vw;
    min-width: ${({ minWidth }) => (minWidth ? 'auto' : '40vw')};
    width: auto;
  }
  @media ${devices.phoneS} {
    max-width: 95vw;
    min-width: ${({ minWidth }) => (minWidth ? 'auto' : '40vw')};
    width: 95vw;
  }
  @media ${devices.ultrawide} {
    max-width: 80vw;
    min-width: ${({ minWidth }) => (minWidth ? 'auto' : '25vw')};
    width: auto;
  }
`;

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: ${({ title }) => (title !== '' ? '50px' : '20px')};
  align-items: center;
  position: relative;
  span {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: ${({ theme }) => theme.primary};
    cursor: default;
    margin: 0.25rem;
    padding: 0px 30px;
    text-align: center;
  }
  button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

const StyledModalContent = styled.div`
  word-break: break-word;
  max-height: ${({ notLabel, yesLabel }) => (notLabel === '' && yesLabel === '' ? 'calc(90vh - 85px)' : 'calc(90vh - 121px)')};
  overflow: auto;
  box-sizing: border-box;
  padding: ${({ noPadding }) => (noPadding ? '0px' : '5px 10px')};
`;

export {
  StyledModalOverlay,
  StyledModal,
  StyledModalHeader,
  StyledModalContent,
};
