import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  CheckboxContainer, HiddenCheckbox, CheckboxText, StyledCheckbox,
} from './styled/Checkbox.styled';
import { Check } from '../../icons';

/**
* The primary goal of checkboxes is to provide a way for the user to make a range of selections. The user can select from zero, one, or multiple options. In other words, each checkbox is independent of all other checkboxes in the list, and checking one box doesn't uncheck the others.
*/
const Checkbox = ({
  text, color, variant, checked, handleCheckboxChange, disabled, fontSize, ...props
}) => {
  const [_checked, _setChecked] = useState(false);

  const change = () => {
    if (!disabled) {
      handleCheckboxChange(!checked);
      _setChecked((p) => !p);
    }
  };

  useLayoutEffect(() => {
    _setChecked(checked);
  }, [checked]);

  return (
    <CheckboxContainer
      checked={_checked}
      onClick={change}
      {...props}
    >
      <HiddenCheckbox
        checked={_checked}
        onChange={change}
      />
      <StyledCheckbox color={color} variant={variant} checked={_checked} disabled={disabled} className="box">
        <Check />
      </StyledCheckbox>
      <CheckboxText disabled={disabled} checked={_checked} fontSize={fontSize}>
        {text}
      </CheckboxText>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  /**
    * The text associated with that checkbox
    */
  text: PropTypes.string,
  /**
    * Choose between two options: contained or outlined checkboxes
    */
  variant: PropTypes.oneOf(['contained', 'outlined']),
  /**
    * Choose among seven different colors or pass a hexadecimal color
    */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info', 'dark', PropTypes.string]),
  /**
    * A boolean state that indicates wheter the checkbox is selected or not
    */
  checked: PropTypes.bool,
  /**
   * Size of the text
   */
  fontSize: PropTypes.string,
  /**
    * function to handle with the checkbox selection
    */
  handleCheckboxChange: PropTypes.func.isRequired,
  /**
    * A boolean variable that indicates wheter the checkbox is disabld or not
    */
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  text: '',
  checked: false,
  fontSize: '14px',
  variant: 'contained',
  color: 'primary',
  disabled: false,
};

export default Checkbox;
