import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// components
import FileViewData from '../../components/FileViewData';
import FileViewDataToolbar from '../../components/Toolbars/FileViewDataToolbar';
import ApplyTemplate from '../../components/ProgressiveModals/ApplyTemplate';
import Loader from '../../juristec-ui/core/Loader';

// contexts
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import { TemplateContext } from '../../context/TemplateProvider';
import { TourContext } from '../../context/TourProvider';
import { UsersContext } from '../../context/UsersProvider';

// others
import urlHash from '../../utils/functions/urlHash';
import { csvDateFormatOptions, xlsxDateFormatOptions } from '../../options'; // '../../../../options';

const legacyTypes = {
  float64: 'float',
  int64: 'float',
  'datetime64[ns]': 'date',
  object: 'abc',
  category: 'abc',
  get(k) {
    return this[k] || k;
  },
};

const decimalOptions = [
  { value: '.', label: 'Ponto' },
  { value: ',', label: 'Vírgula' },
];

function FilesViewerPage() {
  const { hash } = useParams();
  const history = useHistory();
  //
  const { state, filesAPI } = useContext(FilesContext);
  const { user } = useContext(AuthContext);
  const { state: templateState, templateAPI } = useContext(TemplateContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { tourOpen, nextStep } = useContext(TourContext);
  const { state: usersState } = useContext(UsersContext);

  const [file, setFile] = useState({
    origin: 'Desktop',
    filename: '',
    updatedAt: '',
    size: '',
  });
  const [page, setPage] = useState(0);
  const [fileData, setFileData] = useState({ columns: [] });
  const [fileLength, setFileLength] = useState(0);
  const [columnTypes, setColumnTypes] = useState({});
  //
  const [originalTypes, setOriginalTypes] = useState({});
  const [changedKeys, setChangedKeys] = useState({});
  const [changed, setChanged] = useState(false);
  //
  const [dateFormat, setDateFormat] = useState({ value: '', label: '' });
  const [decimalFormat, setDecimalFormat] = useState(decimalOptions[0]);
  //
  const [isCsv, setIsCsv] = useState(false);
  // check if the user is owner or can write in the file
  const [canManipulate, setCanManipulate] = useState(false);

  const getFileInfo = async (fileId) => {
    const { error, msg, res } = await filesAPI.getFile(fileId);
    if (error) {
      setAlertConfig({
        type: 'error',
        text: msg,
      });
      return;
    }
    const userOwner = usersState.users.filter((u) => u.uid === res?.owner);
    const ownerName = userOwner[0]?.name || userOwner[0]?.email.split('@')[0] || '';
    setFile({ ...res, ownerName });
    setCanManipulate(user?.uid === res?.owner || res?.shared_write.includes(user?.uid));
    const isFileCsv = res.filename.split('.').pop().includes('csv');
    if (isFileCsv) {
      setDateFormat(csvDateFormatOptions[0]);
    } else setDateFormat(xlsxDateFormatOptions[0]);
    setIsCsv(isFileCsv);
  };

  const fetchPage = async () => {
    const {
      error,
      msg,
      res,
    } = await filesAPI.getFileSample(file.file_id, false, 20, page + 1);
    if (error) {
      setAlertConfig({
        type: 'error',
        text: msg,
      });
      return;
    }

    const fileLen = (res.total_pages - 1) * 20;
    const types = Object.keys(res.types).reduce((acc, cur) => {
      acc[cur] = legacyTypes.get(res.types[cur]);
      return acc;
    }, {});

    setFileData(res);
    setFileLength(fileLen);
    setColumnTypes(types);
    setOriginalTypes(types);
  };

  // useEffect(() => {
  //   console.log(columnTypes);
  // }, [columnTypes]);

  const handleOptimizer = async () => {
    const submitF = async () => {
      // const floatColumns = [];
      // const dateColumns = [];
      // const textColumns = [];
      // Object.keys(columnTypes).forEach((column) => {
      //   if (columnTypes[column] === 'number') floatColumns.push(column);
      //   else if (columnTypes[column] === 'date') dateColumns.push(column);
      //   else textColumns.push(column);
      // });

      // const aux = {
      //   filename: file.filename.split('.metrics')[0],
      //   uploaded_file: file.name || file.filename,
      //   date_columns: dateColumns,
      //   float_columns: floatColumns,
      //   int_columns: [],
      //   decimal: decimalFormat.value || ',',
      //   date_format: dateFormat.value || '',
      //   header: fileData.header || 0,
      //   overwrite: true,
      // };

      const {
        error: editError,
        msg: editMsg,
        raw,
      } = await filesAPI.editColumns(file.file_id, columnTypes);
      if (editError) {
        setAlertConfig({
          type: 'error',
          text: editMsg,
          children: raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: (
          <span>
            Arquivo
            {' '}
            <strong style={{ fontStyle: 'italic' }}>
              {file.filename}
            </strong>
            {' '}
            validado com sucesso!
          </span>
        ),
      });
      setOriginalTypes((p) => ({ ...p, ...changedKeys }));
      setChanged(false);
      setChangedKeys({});
    };

    setAlertConfig({
      text: 'Deseja Revalidar o arquivo?',
      type: 'warning',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: submitF,
    });
  };

  const handleApplyTemplate = async () => {
    let templates = [];
    if (!templateState.templates.length) {
      const result = await templateAPI.getTemplates();
      if (result.error) {
        setAlertConfig({
          type: 'error',
          msg: result.msg,
        });
        return;
      }
      templates = result.templates;
    } else templates = templateState.templates;

    const { error: er, msg, res } = await filesAPI.getFileSample(file.file_id, false);
    if (er) {
      setAlertConfig({
        type: 'error',
        text: msg,
      });
      return;
    }

    const fileColumns = res.columns
      .filter((val) => val !== 'index')
      .map((val) => (
        {
          label: val,
          value: val,
          type: res.types[val],
        }
      ));

    const handleColumnChange = async (column, format) => {
      // Recebendo valores unicos
      const { error: columnError, msg: columnMsg, res: columnRes } = await filesAPI.getUnique(
        file.file_id,
        column,
        format || '',
      );
      if (columnError) {
        setAlertConfig({ type: 'error', text: columnMsg });
      }
      return columnRes.map((val) => ({ label: val, value: val }));
    };

    const submitNewTemplate = async ({
      template, name, bindings, filters,
    }) => {
      if (tourOpen) nextStep();
      const { error: createError, msg: createMsg } = await templateAPI.applyTemplate(
        template.id,
        name,
        file.file_id,
        bindings,
        filters,
        {},
      );

      if (createError) {
        setAlertConfig({
          type: 'error',
          text: createMsg,
        });
        closeModal();
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Template aplicado com sucesso',
        okFunction: () => {
          closeModal();
          history.push('/home');
        },
      });
    };

    setModalConfig({
      title: 'Aplicar Template',
      children: (
        <ApplyTemplate
          hide={closeModal}
          templates={templates}
          fileColumns={fileColumns}
          // columnData={[]}
          onSelectFilter={handleColumnChange}
          submitData={submitNewTemplate}
          tourContext={{ tourOpen, nextStep }}
        />
      ),
    });
  };

  const goBack = () => {
    history.goBack();
    // history.push('/arquivos');
  };

  const columnTypeChange = (field, type) => {
    setChangedKeys((p) => {
      const aux = { ...p };
      if (aux[field] === type || aux[field] === originalTypes[field]) {
        delete aux[field];
      } else {
        aux[field] = type;
      }
      return aux;
    });
    setColumnTypes((p) => ({ ...p, [field]: type }));
  };

  useEffect(() => {
    let stillHaveChanges = false;

    const keys = Object.keys(changedKeys);
    const len = keys.length;

    // for() para q se force um fim de laço na primeira diferença.
    for (let i = 0; i < len; i += 1) {
      const field = keys[i];

      if (changedKeys[field] !== originalTypes[field]) {
        stillHaveChanges = true;
        break;
      }
    }
    setChanged(stillHaveChanges);
  }, [changedKeys]);

  useEffect(() => {
    if (file.filename) fetchPage();
  }, [page, file]);

  useEffect(() => {
    const getParams = () => {
      const str = urlHash(hash, true).split('/');
      return str[0];
    };

    const fileId = getParams();
    getFileInfo(fileId);
    // const isFileCsv = urlParams.filename.split('.').pop().includes('csv');
    // // console.log(filename, isFileCsv, filename.split('.').pop());
    // if (isFileCsv) {
    //   setDateFormat(csvDateFormatOptions[0]);
    // } else setDateFormat(xlsxDateFormatOptions[0]);
    // setIsCsv(isFileCsv);
    // setFile({
    //   ...urlParams,
    //   filename: `${urlParams.filename}.metrics`,
    // });
  }, []);

  return (
    <>
      {(state.isLoading || templateState.isLoading) && <Loader />}
      <FileViewDataToolbar
        changed={changed}
        ownerName={file?.ownerName}
        fileName={file?.filename}
        fileSize={file?.size}
        lastUpdate={file?.updated_at}
        applyTemplate={handleApplyTemplate}
        handleOptimizer={handleOptimizer}
        goBack={goBack}
      />
      <FileViewData
        fileData={fileData}
        fileLength={fileLength}
        canManipulate={canManipulate}
        columnTypes={columnTypes}
        columnTypeChange={columnTypeChange}
        fetchPage={fetchPage}
        page={page}
        origin={file.origin || 'Desktop'}
        setPage={setPage}
        //
        dateFormat={dateFormat}
        setDateFormat={setDateFormat}
        decimalFormat={decimalFormat}
        setDecimalFormat={setDecimalFormat}
        isCsv={isCsv}
      />
    </>
  );
}

export default FilesViewerPage;
