import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import Loader from '../../juristec-ui/core/Loader';

import { AuthContext } from '../../context/AuthProvider';

const logoutRedirect = () => window.location.replace(`${window.location.protocol}//${window.location.host}/login`);

const PrivateRoute = ({
  component: RouteComponent, onlyAdmin, onlyScientist, onlySuper, ...rest
}) => {
  const { currentUser, user, claimsUser } = useContext(AuthContext);
  // const { user } = useContext(UserContext);

  const filterIsScientist = (routeProps) => {
    if (!user) return <Loader />;
    return ((user?.isScientist || user?.role === 'scientist' || user?.role === 'super') ? <RouteComponent {...routeProps} /> : <Redirect to="/home" />);
  };

  const filterIsSuper = (routeProps) => {
    if (!user) return <Loader />;
    return ((user?.isScientist || user?.role === 'super') ? <RouteComponent {...routeProps} /> : <Redirect to="/home" />);
  };

  const filterIsAdmin = (routeProps) => {
    if (!user) return <Loader />;
    return ((claimsUser?.is_admin) ? <RouteComponent {...routeProps} /> : <Redirect to="/home" />);
  };

  if (onlyAdmin) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (currentUser ? (
          filterIsAdmin(routeProps)
        ) : (
          logoutRedirect()
        ))}
      />
    );
  }

  if (onlyScientist) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (currentUser ? (
          filterIsScientist(routeProps)
        ) : (
          logoutRedirect()
        ))}
      />
    );
  }

  if (onlySuper) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (currentUser ? (
          filterIsSuper(routeProps)
        ) : (
          logoutRedirect()
        ))}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (currentUser ? (
        <RouteComponent {...routeProps} />
      ) : (
        logoutRedirect()
      ))}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  onlyAdmin: PropTypes.bool,
  onlyScientist: PropTypes.bool,
  onlySuper: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  onlyAdmin: false,
  onlyScientist: false,
  onlySuper: false,
};

export default PrivateRoute;
