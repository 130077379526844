import React, {
  useEffect, useRef, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import IconButton from '../../../juristec-ui/core/IconButton';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/Select';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import TextArea from '../../../juristec-ui/core/TextArea';
import Badge from '../../../juristec-ui/core/Badge';
import UnsplashPicker from '../../../juristec-ui/core/UnsplashPicker';

import {
  Add, Close, UploadImage, Finder,
} from '../../../juristec-ui/icons';
import { ModalContext } from '../../../context/ModalProvider';

import {
  verifyInput,
  verifyLink,
} from '../../../juristec-ui/utils/validators/inputTextValidators';

import { trimString, compactString } from '../../../juristec-ui/utils/functions/formatString';

import {
  FirstPageContainer,
  StepContainer,
  ImageContainer,
  ImageOverlay,
  ImageSquare,
  VarTip,
  KeywordsContainer,
  KeywordInputContainer,
} from './styled/CreateTemplate.styled';

// easy, medium, advance, expert
const difficultyOpts = [
  { id: 'easy', label: 'Iniciante', value: 'easy' },
  { id: 'medium', label: 'Intermediário', value: 'medium' },
  { id: 'advanced', label: 'Avançado', value: 'advanced' },
  { id: 'expert', label: 'Expert', value: 'expert' },
];

const CreateTemplate = ({
  submitData, hide, templateVars, editTemplateData, checkImage,
}) => {
  const [showImgOpts, setShowImgOpts] = useState(true);
  const [templateImg, setTemplateImg] = useState('');

  const [fileImage, setFileImage] = useState(null);
  const [templateName, setTemplateName] = useState({ value: '', error: true, errorMsg: '' });
  const [description, setDescription] = useState({ value: '', error: true, errorMsg: '' });
  const [tutorialLink, setTutorialLink] = useState({ value: '', error: false, errorMsg: '' });
  // const [category, setCategory] = useState(categories[0]);
  const [category, setCategory] = useState({ value: '', error: true, errorMsg: '' });
  const [difficulty, setDifficulty] = useState(difficultyOpts[0]);
  // const [keywords, setKeywords] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [inputKeyword, setInputKeyword] = useState({ value: '', error: true, errorMsg: '' });

  const [varsData, setVarsData] = useState(templateVars);

  const wrapperRef = useRef(null);
  const inputFile = useRef(null);
  const templateNameRef = useRef(null);

  const { setModalConfig, toggleModal } = useContext(ModalContext);

  useEffect(() => {
    const closeImgOpts = (e) => {
      if (templateImg.length > 0 && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setShowImgOpts(false);
      }
    };
    document.addEventListener('click', closeImgOpts);
    document.addEventListener('mouseover', closeImgOpts);
    return () => {
      document.removeEventListener('click', closeImgOpts);
      document.removeEventListener('mouseover', closeImgOpts);
    };
  }, [templateImg, wrapperRef]);

  useEffect(() => {
    if (fileImage) {
      setTemplateImg(typeof (fileImage) === 'string' ? fileImage : URL.createObjectURL(fileImage));
    } else {
      setTemplateImg('');
    }
  }, [fileImage]);

  const templateNameHandle = (val) => {
    const msg = verifyInput(val, true, 2);
    setTemplateName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const descriptionHandle = (val) => {
    const msg = verifyInput(val, true, 2, 300);
    setDescription({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const tutorialLinkHandle = (val) => {
    const msg = verifyLink(val);
    setTutorialLink({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const inputKeyworkHandle = (val) => {
    const msg = verifyInput(val, true, 2);
    setInputKeyword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const categoryHandle = (val) => {
    const msg = verifyInput(val, true, 2);
    setCategory({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleFormTxt = (e) => {
    switch (e.target.name) {
      case 'templateName':
        templateNameHandle(e.target.value);
        break;
      case 'description':
        descriptionHandle(e.target.value);
        break;
      case 'tutorialLink':
        tutorialLinkHandle(e.target.value);
        break;
      case 'keywords':
        inputKeyworkHandle(e.target.value);
        break;
      case 'category':
        categoryHandle(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleFormSelects = (select, val) => {
    switch (select) {
      case 'difficulty':
        setDifficulty(val);
        break;
      // case 'categories':
      //   if (typeof (val) === 'object') {
      //     setCategory(val);
      //   }
      //   break;
      case 'keywords':
        if (typeof (val) === 'object') {
          setKeywords(val);
        }
        break;
      default:
        break;
    }
  };

  const addKeyword = () => {
    if (inputKeyword.error) return;
    if (keywords.filter((k) => k.value === inputKeyword.value).length === 0) {
      setKeywords((old) => [
        ...old,
        {
          id: inputKeyword.value,
          label: inputKeyword.value,
          value: inputKeyword.value,
        },
      ]);
    }
    setInputKeyword({
      value: '',
      error: true,
      errorMsg: '',
    });
  };

  const removeKeyword = (val) => {
    setKeywords((old) => (old.reduce((aux, k) => {
      if (k.value !== val) {
        aux.push(k);
      }
      return aux;
    }, [])));
  };

  // const handleCreateCategory = (val) => {
  //   const newOpt = { id: val, label: val, value: val };
  //   categories.push(newOpt);
  //   setCategory(newOpt);
  // };

  const handleVarDesc = (val, key) => {
    const msg = verifyInput(val, false, 0, 50);
    setVarsData((old) => ({
      ...old,
      [key]: {
        ...old[key],
        description: val,
        error: msg.length !== 0,
        errorMsg: msg,
      },
    }));
  };

  const validateFirstPage = () => {
    if (templateName.error || description.error || tutorialLink.error || category.error || !difficulty) {
      templateNameHandle(templateName.value);
      descriptionHandle(description.value);
      tutorialLinkHandle(tutorialLink.value);
      categoryHandle(category.value);
      return false;
    }
    return true;
  };

  const validateSecondPage = () => {
    if (Object.values(varsData).filter((variable) => variable.error).length > 0) {
      Object.keys(varsData).forEach((variable) => {
        handleVarDesc(varsData[variable].description || '', variable);
      });
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    submitData({
      name: templateName.value,
      description: description.value,
      tutorialLink: tutorialLink.value,
      category: category.value,
      difficulty: difficulty.value,
      keywords: keywords?.map((k) => k.value),
      imageFile: fileImage,
      variables: Object.keys(varsData).reduce((vars, variable) => {
        const { error, errorMsg, ...data } = varsData[variable];
        vars[variable] = data;
        return vars;
      }, {}),
    });
  };

  const openUnsplashPicker = () => {
    setModalConfig({
      title: 'Selecionar Fotos',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={setFileImage}
          defaultSearch="Templates"
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  useEffect(() => {
    if (editTemplateData) {
      // setTemplateName({ value: editTemplateData.name, error: false, errorMsg: '' });
      templateNameHandle(editTemplateData.name || '');
      // setCategory({ value: editTemplateData.category, error: false, errorMsg: '' });
      categoryHandle(editTemplateData.category || '');
      setDifficulty(difficultyOpts.find((opt) => opt.value === editTemplateData.difficulty));
      // setDescription({ value: editTemplateData.description, error: false, errorMsg: '' });
      descriptionHandle(editTemplateData.description || '');
      setTutorialLink({ value: editTemplateData.tutorialLink || '', error: false, errorMsg: '' });
      setKeywords(editTemplateData.keywords?.map((k) => ({ id: k, value: k, label: k })) || []);
      setFileImage(editTemplateData.image || null);
    }
  }, []);

  const handleDeviceImage = (e) => {
    console.log(e.target.files[0]);
    URL.revokeObjectURL(templateImg);
    const deviceImage = e.target.files[0];
    console.log('Entrou');
    if (deviceImage && checkImage(deviceImage)) {
      setFileImage(deviceImage);
      setTemplateImg(URL.createObjectURL(deviceImage));
    }
  };

  const firstStep = ({
    label: 'Geral',
    validation: validateFirstPage,
    content: (
      <FirstPageContainer>
        <StepContainer>
          <InputTextLine
            ref={templateNameRef}
            label="Nome do Template"
            name="templateName"
            error={templateName.errorMsg.length > 0}
            errorMessage={templateName.errorMsg}
            value={templateName.value}
            setValue={templateNameHandle}
            emojiPicker
            emojiPickerPosition="bottom-end"
            onChange={handleFormTxt}
            onBlur={(e) => setTemplateName({ ...templateName, value: trimString(e.target.value) })}
          />
          <div>
            <InputTextLine
              label="Categoria"
              name="category"
              placeholder=" Informe a categoria do seu template"
              error={category.errorMsg.length > 0}
              errorMessage={category.errorMsg}
              value={category.value}
              onChange={handleFormTxt}
              onBlur={(e) => {
                const text = e.target.value;
                setCategory((p) => ({ ...p, value: trimString(text) }));
              }}
            />
            {/* <Select
              selectLabel="Categoria"
              options={categories}
              fullWidth
              placement="start"
              isSearchable
              isCreatable
              value={category}
              onChange={(val) => handleFormSelects('categories', val)}
              onCreateOption={handleCreateCategory}
            /> */}
          </div>
          <div>
            <Select
              selectLabel="Dificuldade"
              options={difficultyOpts}
              fullWidth
              placement="start"
              value={difficulty}
              onChange={(val) => handleFormSelects('difficulty', val)}
              atModal
            />
          </div>
          <TextArea
            label="Descrição"
            name="description"
            error={description.errorMsg.length > 0}
            errorMessage={description.errorMsg}
            value={description.value}
            onChange={handleFormTxt}
            onBlur={(e) => setDescription({ ...description, value: trimString(e.target.value) })}
          />
        </StepContainer>
        <StepContainer>
          <InputTextLine
            label="Tutorial (opcional)"
            placeholder="URL com o tutorial de aplicação"
            name="tutorialLink"
            error={tutorialLink.errorMsg.length > 0}
            errorMessage={tutorialLink.errorMsg}
            value={tutorialLink.value}
            onChange={handleFormTxt}
            onBlur={(e) => setTutorialLink({ ...tutorialLink, value: compactString(e.target.value) })}
          />
          <div>
            <KeywordInputContainer>
              <InputTextLine
                label="Palavras-chave (opcional)"
                placeholder="Digite uma nova palavra"
                name="keywords"
                error={inputKeyword.errorMsg.length > 0}
                errorMessage={inputKeyword.errorMsg}
                value={inputKeyword.value}
                onChange={handleFormTxt}
                onBlur={(e) => setInputKeyword({ ...inputKeyword, value: trimString(e.target.value) })}
                styleContainer={{ width: '100%' }}
              />
              <IconButton
                shape="rounded"
                variant="contained"
                onClick={() => addKeyword()}
              >
                <Add />
              </IconButton>
            </KeywordInputContainer>
            <KeywordsContainer>
              {keywords.map((k) => (
                <Badge
                  key={k.value}
                  color="secondary"
                  textTransform="uppercase"
                  size="small"
                >
                  <div>{k.value}</div>
                  <div className="btnClose" onClick={() => removeKeyword(k.value)}>
                    <Close />
                  </div>
                </Badge>
              ))}
            </KeywordsContainer>
          </div>
          {/* TODO: select com isMult e isCreatable juntos induzindo ao erro
            <div>
              <Select
                selectLabel="Adicionar Palavras-chave (opcional)"
                options={keywordsList}
                fullWidth
                isMult
                isClearable
                isSearchable
                isCreatable
                placement="start"
                value={keywords}
                onChange={(val) => handleFormSelects('keywords', val)}
                onCreateOption={(val) => keywordsList.push({ id: val, label: val, value: val })}
              />
            </div>
          */}
          <div style={{ height: '100%' }}>
            <label className="label">Imagem do Template (opcional)</label>
            <ImageContainer
              ref={wrapperRef}
              onClick={() => setShowImgOpts(true)}
              onMouseOver={() => setShowImgOpts(true)}
            >
              <ImageSquare src={templateImg} />
              {showImgOpts
                && (
                <ImageOverlay>
                  <Tooltip text="Adicionar nova imagem" direction="left" atModal>
                    <IconButton
                      onClick={() => inputFile.current.click()}
                      color="#fff"
                      size="large"
                    >
                      <UploadImage />
                      <input
                        type="file"
                        ref={inputFile}
                        accept="image/*"
                        onChange={handleDeviceImage}
                        style={{ display: 'none' }}
                      />
                    </IconButton>
                  </Tooltip>
                  {templateImg.length > 0
                    && (
                    <Tooltip text="Remover imagem atual" direction="right" atModal>
                      <IconButton
                        onClick={() => setFileImage(null)}
                        color="#fff"
                        size="large"
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                    )}
                  <Tooltip text="Buscar fotos na web" direction="left" atModal>
                    <IconButton
                      color="#fff"
                      size="large"
                      onClick={openUnsplashPicker}
                    >
                      <Finder />
                    </IconButton>
                  </Tooltip>
                </ImageOverlay>
                )}
            </ImageContainer>
          </div>

        </StepContainer>
      </FirstPageContainer>
    ),
  });

  const secondStep = ({
    label: 'Variáveis',
    validation: validateSecondPage,
    content: (
      <div style={{ display: 'flex', width: '400px' }}>
        <StepContainer>
          <VarTip>
            Forneça uma descrição para cada uma das colunas utilizadas na
            criação deste template.
            A descrição é fundamental para que os usuários possam
            aplicar o template corretamente.
          </VarTip>
          {Object.keys(varsData).map((variable) => (
            <InputTextLine
              key={variable}
              label={variable}
              error={varsData[variable].errorMsg?.length > 0}
              errorMessage={varsData[variable].errorMsg || ''}
              value={varsData[variable].description || ''}
              onChange={(e) => handleVarDesc(e.target.value, variable)}
              onBlur={(e) => {
                const text = e.target.value;
                setVarsData((old) => ({
                  ...old, [variable]: { ...old[variable], description: trimString(text) },
                }));
              }}
            />
          ))}
        </StepContainer>
      </div>
    ),
  });

  return (
    <StepProgress
      steps={[firstStep, secondStep]}
      hide={hide}
      complete={handleSubmit}
      markersWidth="medium"
    />
  );
};

CreateTemplate.propTypes = {
  /**
  * A function that sends the new data back to the parent
  */
  submitData: PropTypes.func.isRequired,
  /**
  * Object with objects representing the variables of the dashboard
  */
  templateVars: PropTypes.objectOf(
    PropTypes.shape({}),
  ),
  /**
  * Array with the category options
  */
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
  * Array with the keyword options
  */
  keywordsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
  * A function that closes the modal
  */
  hide: PropTypes.func.isRequired,
  /**
  *  Function to check if an image satisfies the desired conditions
  */
  checkImage: PropTypes.func,
};

CreateTemplate.defaultProps = {
  templateVars: {},
  categories: [{ id: 'Sem categoria', label: 'Sem categoria', value: 'Sem categoria' }],
  keywordsList: [],
  checkImage: () => {},
};

export default CreateTemplate;
