import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/Select';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import { OnboardingReportContainer, ItemContainer } from './styled/OnboardingReport.styled';

const OnboardingReport = ({
  submitData,
  hide,
  users,
}) => {

  const [reportName, setReportName] = useState({ value: '', error: false, errorMsg: '' });
  const [selectedEmail, setSelectedEmail] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const reportInputHandle = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = val.length === 0 ? 'Este campo não pode estar vazio' : '';
    setDisableSubmitButton(msg.length !== 0);
    setReportName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    submitData(`${reportName.value}.xlsx`, selectedEmail.id); // adding .xlsx extension
  };

  return (
    <>
      <OnboardingReportContainer>
        <ItemContainer>
          <InputTextLine
            label="Nome do relatório"
            placeholder="Contencioso_L1A"
            errorMessage={reportName.errorMsg}
            error={reportName.errorMsg.length > 0}
            onChange={(e) => reportInputHandle(e.target.value)}
            value={reportName.value}
          />
        </ItemContainer>
        <ItemContainer>
          <Select
            selectLabel="E-mail do responsável"
            options={users}
            fullWidth
            placement="start"
            value={selectedEmail}
            onChange={(val) => setSelectedEmail(val)}
            atModal
            alphabeticalOrder
          />
        </ItemContainer>
      </OnboardingReportContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleSubmit()}
          size="large"
          disabled={disableSubmitButton}
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

OnboardingReport.propTypes = {
  submitData: PropTypes.func,
  hide: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

OnboardingReport.defaultProps = {
  submitData: () => {},
  hide: () => {},
};

export default OnboardingReport;
