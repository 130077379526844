import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../juristec-ui/core/Select';

import {
  HeaderTips,
  FirstPageContainer,
  VarContainer,
  SelectContainer,
  SelectContainerFile,
} from './styled/FirstStep.styled';

/**
 * First step for the 'CopyDashboard'. This is where the binding between columns and variables are made.
 */
const FirstStep = ({
  variables,
  files,
  selectedFile,
  setSelectedFile,
  selectedFileVars,
  columnVarBinds,
  setColumnVarBinds,
}) => {
  const handleBinding = (variable, column) => {
    setColumnVarBinds((old) => ({ ...old, [variable]: column.value }));
  };

  function checkTypeColumn(el, columnType) {
    if (columnType === 'object' || columnType === 'category') {
      if (el.type === 'category' || el.type === 'object') return true;
      return false;
    }
    return el.type === columnType;
  }

  function isSelected(el, variable) {
    let aux = true;
    Object.keys(columnVarBinds).forEach((v) => {
      // n tirar opção da coluna da qual o valor foi selecionado. só dos outros selects
      if (v === variable) return;
      if (columnVarBinds[v] === el.value) aux = false;
    });

    return aux;
  }

  const checkColumn = (el, variable) => {
    if (!isSelected(el, variable)) return false;
    return checkTypeColumn(el, variables[variable].type);
  };

  return (
    <FirstPageContainer>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '5px'}}>
        <SelectContainerFile>
          <Select
            placement="start"
            selectLabel="Arquivo para a cópia do dashboard"
            options={files.map((f) => ({
              ...f,
              id: f.file_id,
              value: f.file_id,
              label: f.filename.replace('.metrics', ''),
            }))}
            value={selectedFile}
            onChange={setSelectedFile}
            placeholder="Selecione o arquivo"
            alphabeticalOrder
            isSearchable
            fullWidth
            atModal
          />
        </SelectContainerFile>
        <HeaderTips>
          <span style={{fontSize: '13px'}}>
            Selecione abaixo as colunas do seu arquivo correspondentes as variáveis
            necessárias para a aplicação do template.
            {/* <span className="danger"> Observação: </span>
            A associação de variáveis
            que possuem outro significado em seu arquivo, pode gerar indicadores
            errados. */}
          </span>
        </HeaderTips>
      </div>
      <VarContainer>
        {Object.keys(variables).map((variable) => (
          <SelectContainer key={variable}>
            <Select
              placement="start"
              selectLabel={variable}
              options={selectedFileVars.filter((i) => checkColumn(i, variable)) || []}
              value={{
                id: columnVarBinds[variable],
                label: columnVarBinds[variable],
                value: columnVarBinds[variable],
              }}
              onChange={(columnSelected) => handleBinding(variable, columnSelected)}
              placeholder=""
              alphabeticalOrder
              isSearchable
              fullWidth
              atModal
            />
          </SelectContainer>
        ))}
      </VarContainer>
    </FirstPageContainer>
  );
};

FirstStep.propTypes = {
  /**
   * Object with the variables from the dashboard
   */
  variables: PropTypes.objectOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * Array of files uploaded
   */
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      filename: PropTypes.string.isRequired,
      owner: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * Object that represents a file selected
   */
  selectedFile: PropTypes.shape({
    // id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Function that deals with the selection of a file
   */
  setSelectedFile: PropTypes.func.isRequired,
  /**
   * Object with the association between column name and dashboard variable
   */
  columnVarBinds: PropTypes.objectOf(PropTypes.string).isRequired,
  /**
   * Function that deals with the association between column name and dashboard variable
   */
  setColumnVarBinds: PropTypes.func.isRequired,

  /**
   * Variables from selected file.
   */
  selectedFileVars: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
};

export default FirstStep;
