import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '../../../juristec-ui/core/Button';
//
import urlHash from '../../../juristec-ui/utils/functions/urlHash';
// import urlHash from '../../utils/functions/urlHash';

import {
  MainContainer,
  SeparatorLine,
  Board,
  NotificationCard,
  CategoryTag,
} from './styled/Notifications.styled';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

const typeToSufix = {
  kpi: 'KPI',
  dash: 'Dashboard',
  user: 'Usuário',
  mural: 'Mural',
  get(k) {
    return this[k] || k;
  },
};

const actionToPrefix = {
  update: 'Atualização de',
  create: 'Criação de',
  delete: 'Remoção do',
  remove: 'Remoção do',
  share: 'Compartilhamento de',
  mention: 'Menção no',
  get(k) {
    return this[k] || k;
  },
};

const getCategory = (notification) => {
  let prefix = '';
  let sufix = '';

  prefix = actionToPrefix.get(notification.action);
  sufix = typeToSufix.get(notification.type);

  return `${prefix} ${sufix}`;
};

/**
 * A component that shows notifications received from a database
 */
const Notifications = ({
  userInfo,
  haveMore,
  notificationsLoading,
  notificationList,
  moreNotifications,
  closePopover,
  ...props
}) => {
  const history = useHistory();

  const getLink = (notification) => {
    if (notification.action === 'delete' || notification.action === 'remove') {
      return '';
    }
    if (notification.type === 'kpi') {
      return (`/home/${urlHash(`${`${notification.ownerId}_${notification.dashId}`}/${notification.ownerId}`)}`);
    } if (notification.type === 'dash') {
      return ('/home');
    } if (notification.type === 'mural') {
      return (`/home/${urlHash(`${`${notification.ownerId}_${notification.targetId}`}/${notification.ownerId}`)}`);
    }
    return '';
  };

  const handleClick = (e, link) => {
    if (!link) {
      e.preventDefault();
      return;
    }

    history.push(link);
    closePopover();
  };

  const handleMore = (e) => {
    e.stopPropagation();
    moreNotifications();
  };

  return (
    <MainContainer>
      <h2>Notificações</h2>
      <SeparatorLine />
      <Board>
        {notificationList?.length > 0 ? (
        notificationList.map((note) => {
          const link = getLink(note);
          const title = window.location.host + link;
          return (
            <NotificationCard
              key={note.id}
              isNew={!note.viewed[userInfo.id]}
              $action={note.action}
              onClick={(e) => handleClick(e, link)}
            // title="asdas"
              title={title}
              {...props}
            >
              <CategoryTag>
                <span style={{ textTransform: 'uppercase' }}>{getCategory(note)}</span>
              </CategoryTag>
              <p className="message">{note.message}</p>
              <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <div className="date">{formatDateTime(note.timestamp, { time: 'half' })}</div>
                {!note.viewed[userInfo.id] && (
                  <div className="new">Novo!</div>
                )}
              </div>
            </NotificationCard>
          );
        })
      ) : (
        <NotificationCard empty>
          <span className="empty">Você ainda não recebeu nenhuma notificação</span>
        </NotificationCard>
      )}
      </Board>
      {haveMore && <Button disabled={!haveMore || notificationsLoading} onClick={handleMore}>Ver mais</Button>}
    </MainContainer>
  );
};

Notifications.propTypes = {
  /**
   * A array of notifications object
   */
  notificationList: PropTypes.arrayOf(PropTypes.object),
  /**
   * A function to load more notifications when called
   */
  moreNotifications: PropTypes.func,

  /**
   * bool that says if have more notifications to load. If false, block the load more button.
   */
  haveMore: PropTypes.bool,

  /**
   * bool that says when the notifications is in a loading/waiting state
   */
  notificationsLoading: PropTypes.bool,

  /**
   *  Function to close the popover
   */
  closePopover: PropTypes.func,
  /**
   * user object, id is necessary
   */
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

Notifications.defaultProps = {
  moreNotifications: () => {},
  notificationList: [],
  haveMore: false,
  notificationsLoading: false,
  closePopover: () => {},
};

export default Notifications;
