import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import ReactDOM from 'react-dom';
import { Close, Return } from '../../juristec-ui/icons';
import IconButton from '../../juristec-ui/core/IconButton';
import { ContainerMessage, Dots } from './styled/Tour.styled';
import { AlertContext } from '../AlertProvider';
import { useTheme } from 'styled-components';

const TourContext = createContext(null);

const TourProvider = ({ children }) => {
  const wrongPathTour = 'Para iniciar este tutorial, será necessário redirecioná-lo para a página correspondente. Deseja ser redirecionado? ';
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [endingMsg, setEndingMsg] = useState('');
  const [currentTarget, setCurrentTarget] = useState(null);
  const [positions, setPositions] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const paddingK = 5;
  const zIndexK = 10000;
  const { setAlertConfig } = useContext(AlertContext);

  const theme = useTheme();

  const changeModalOverflow = useCallback((type = 'hidden') => {
    const modais = document.getElementsByClassName('modal-content');
    if (!modais.length) return;
    Array.from(modais).forEach((m) => {
      const mNow = m;
      mNow.style.overflow = type;
    });
  }, []);

  const handleOpen = useCallback(() => {
    changeModalOverflow();
    setOpen(true);
  }, [changeModalOverflow]);

  const closeTour = useCallback(() => {
    setStep(0);
    setSteps([]);
    setPositions(null);
    setCurrentTarget(null);
    changeModalOverflow('auto');
    setOpen(false);
  }, [changeModalOverflow]);

  const prevStep = useCallback((backAction) => {
    if (backAction) {
      const trigger = (key, i, array) => {
        switch (typeof key) {
          case 'string': {
            const elem = document.getElementsByClassName(key)[0] || document.querySelector(key);
            if (elem) elem.click();
          }
            break;
          case 'function':
            key();
            break;
          case 'number':
            setStep((s) => s - key);
            break;
          default:
            break;
        }
      };
      backAction.map(trigger);
      return;
    }
    setStep((s) => s - 1);
  }, []);

  const nextStepArrow = useCallback((targetAction) => {
    if (targetAction) {
      const elem2 = document.getElementsByClassName(targetAction)[0]
          || document.querySelector(targetAction);

      if (elem2) elem2.click();
    } else {
      setStep((s) => s + 1);
    }
  }, []);

  const nextStep = useCallback((param = 1) => {
    setStep((s) => s + param);
  }, []);

  const setElementPositions = useCallback((target) => {
    const elem = document.getElementsByClassName(target)[0] || document.querySelector(target);

    console.log('elem', elem);
    console.log('target: ', target);

    if (elem) {
      if (steps[step]?.scrollToview) elem.scrollIntoView();
      changeModalOverflow('hidden');
      const bounding = elem.getBoundingClientRect();

      // console.log('bounding', bounding);
      setPositions({
        top: bounding.y,
        left: bounding.x,
        w: bounding.width,
        h: bounding.height,
      });
      setRefresh(false);
    }
  }, [steps, step, changeModalOverflow]);

  const refreshTour = useCallback(() => {
    console.log('refresh');
    setRefresh(true);
  }, []);

  useEffect(() => {
    // console.log('useEffect do currentTarget', currentTarget);
    if (!open) return;
    try {
      if (currentTarget) {
        setElementPositions(currentTarget);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setElementPositions, currentTarget, open]);

  useEffect(() => {
    if (!open) return;
    if (refresh) {
      console.log('refresh: ', true);
      try {
        if (currentTarget) {
          setElementPositions(currentTarget);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [setElementPositions, currentTarget, refresh, open]);

  useEffect(() => {
    if (open && (step > steps.length - 1)) {
      closeTour();
      setAlertConfig({ type: 'success', text: `Parabéns! Você finalizou o tutorial de ${endingMsg}` });
      return;
    }

    if (step >= 0) {
      setTimeout(() => setCurrentTarget(steps[step]?.target),
          steps[step]?.delay || 0);
    }
  }, [step, setAlertConfig, closeTour, endingMsg, open, steps]);

  useEffect(() => {
    // console.log('useEffect do open', open);
    if (open) {
      console.log('open true');
      setCurrentTarget(steps[0]?.target);
    }
  }, [open, steps]);

  const dynamic2 = (msgRef) => {
    let top = 0;
    let left = 0;
    const maxHeight = window.innerHeight;
    const msgPos = msgRef.current.getBoundingClientRect();
    const MsgSizeK = (msgPos?.width || 325) + 5;
    const msgH = msgPos?.height || 200;
    const totalDistanceLeft = positions.left + positions.w + 10;
    const maxWidth = window.innerWidth;

    if (positions.top + positions.h + msgH > maxHeight) {
      top = positions.top + positions.h - msgH;
    } else {
      top = positions.top + (positions.h / 2);
    }

    if (totalDistanceLeft + MsgSizeK > maxWidth) {
      left = positions.left - MsgSizeK;
      if (left < 0) left = positions.w / 2;
    } else { left = totalDistanceLeft; }

    if (steps[step]?.fixedTop) {
      top = positions.top / 2;
    }
    if (steps[step]?.width) {
      if (msgH > maxHeight / 2) {
        return { top, left, width: steps[step]?.width };
      }
    }
    if (steps[step]?.fixedBottom) {
      // criado apenas pra corrigir a validacao de arquivos pfvr nao usar isso
      top = positions.top * 2;
      const width = '400px';
      return { top, left, width };
    }

    return { top, left };
  };

  const Overlay = () => {
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, []);
    return (
      (open && positions) && (
      <>
        <div
          id="top"
          style={{
            top: 0,
            left: 0,
            display: 'flex',
            position: 'absolute',
            height: `${positions.top - paddingK}px`,
            width: '100%',
            background: theme.fadedBackground,
            zIndex: zIndexK,
          }}
        />

        <div
          id="right"
          style={{
            top: `${positions.top - paddingK}px`,
            left: `${positions.left + positions.w + paddingK}px`,
            display: 'flex',
            position: 'absolute',
            height: `${positions.h + paddingK * 2}px`,
            width: `${window.innerWidth - (positions.left + positions.w + paddingK)}px`,
            background: theme.fadedBackground,
            zIndex: zIndexK,
          }}
        />

        <div
          id="bottom"
          style={{
            top: `${positions.top + positions.h + paddingK}px`,
            left: `${positions.left - paddingK}px`,
            display: 'flex',
            position: 'absolute',
            height: `${window.innerHeight - (positions.top + positions.h + paddingK)}px`,
            width: `${window.innerWidth - (positions.left) + paddingK}px`,
            background: theme.fadedBackground,
            zIndex: zIndexK,
          }}
        />
        <div
          id="left"
          style={{
            top: `${positions.top - paddingK}px`,
            left: 0,
            display: 'flex',
            position: 'absolute',
            height: `calc(100vh - ${positions.top - paddingK}px)`,
            width: `${positions.left - paddingK}px`, // `calc(100vw - ${positions.left}px)`,
            background: theme.fadedBackground,
            zIndex: zIndexK,
          }}
        />

      </>
      )
    );
  };

  const Mensagem = () => {
    const [contStyle, setContStyle] = useState({});

    const msgRef = useRef();
    useLayoutEffect(() => {
      if (open && positions) setContStyle(dynamic2(msgRef));
    }, []);

    const getText = (text) => {
      if (!text) return <span>no text detect</span>;
      if (Array.isArray(text)) {
        console.log('is array', text);
        return (
          <span>
            {text.map((i) => {
              console.log(i);
              if (typeof i !== 'string') {
                const I = i;
                return I;
              }
              return i;
            })}
          </span>
        );
      }
      return text;
    };

    return (
      (open && positions) && (
      <ContainerMessage
        style={contStyle}
        ref={msgRef}
        zIndex={zIndexK + 1}
      >
        <span className="badge">{`Passo ${step + 1}`}</span>
        <IconButton className="close" onClick={closeTour} variant="pattern" size="small" color={theme.grey}>
          <Close aria-hidden="true" aria-label="Close" />
        </IconButton>
        <span>
          {/* {steps[step]?.text || 'textado'} */}
          {getText(steps[step]?.text)}
        </span>

        <div className="container-buttons">
          <button type="button" className="prev" onClick={() => prevStep(steps?.[step]?.backAction)} disabled={step === 0 || steps?.[step]?.cantGoBack}>
            <Return />
          </button>
          <div style={{ display: 'flex', margin: '0px 10px' }}>
            {steps.map((st, index) => (
              <Dots
                key={`${index}_${st?.target}`}
                current={index === step}
              />
            ))}
          </div>
          <button type="button" className="next" onClick={() => nextStepArrow(steps?.[step]?.targetAction)} disabled={steps?.[step]?.block}>
            <Return />
          </button>
        </div>
      </ContainerMessage>
      )
    );
  };

  const verifyURL = useCallback((tour) => {
    const { pathname } = window.location;
    switch (tour.type) {
      case 'createDashboard':
        if (pathname !== '/home') {
          setAlertConfig({
            type: 'warning',
            text: wrongPathTour,
            withFunction: true,
            withoutConfirm: true,
            confirmFunction: () => { history.push('/home'); setTimeout(() => handleOpen(), 100); },
          });
          return;
        }
        break;
      case 'createKpi': {
        if (pathname.substring(0, 6) !== '/home/') {
          setSteps([{
            target: 'outerGrid',
            text: 'Escolha um de seus dashboards.',
            block: true,
          }, ...tour.steps]);

          if (pathname !== '/home') {
            setAlertConfig({
              type: 'warning',
              text: wrongPathTour,
              withFunction: true,
              withoutConfirm: true,
              confirmFunction: () => {
                history.push('/home');
                setTimeout(() => handleOpen(), 350);
              },
            });
            return;
          }
        }
        setTimeout(() => handleOpen(), 100);
        return;
      }
      case 'uploadFile':
        if (pathname !== '/arquivos') {
          setAlertConfig({
            type: 'warning',
            text: wrongPathTour,
            withFunction: true,
            withoutConfirm: true,
            confirmFunction: () => { history.push('/arquivos'); setTimeout(() => handleOpen(), 100); },
          });
          return;
        }
        break;
      case 'createTemplate':
        if (pathname !== '/arquivos') {
          setAlertConfig({
            type: 'warning',
            text: wrongPathTour,
            withFunction: true,
            withoutConfirm: true,
            confirmFunction: () => { history.push('/arquivos'); setTimeout(() => handleOpen()); },
          });
          return;
        }
        break;
      case 'shareDashboard':
        if (pathname !== '/home') {
          setAlertConfig({
            type: 'warning',
            text: wrongPathTour,
            withFunction: true,
            withoutConfirm: true,
            confirmFunction: () => { history.push('/home'); setTimeout(() => handleOpen(), 350); },
          });
          return;
        }
        break;
      case 'createUser':
        // if (pathname !== '/usuarios') {
        //   setSteps([
        //     {
        //       target: 'perfil_shortcut',
        //       text: 'Para adicionar novos usuários, é necessário que você seja um usuário cientista. Se você é cientista, selecione o ícone do seu perfil',
        //       targetAction: 'perfil_shortcut',
        //     }, {
        //       target: 'manage_users',
        //       text: 'Selecione a opção Gerenciar usuários',
        //       targetAction: 'manage_users',
        //     }, ...tour.steps]);
        //   setTimeout(() => handleOpen(), 0);
        //   return;
        // }
        break;
      case 'createStories': {
        if (pathname.substring(0, 6) !== '/home/') {
          setSteps([{
            target: 'outerGrid',
            text: 'Escolha um de seus dashboards.',
            block: true,
          }, ...tour.steps]);
          if (pathname !== '/home') {
            setAlertConfig({
              type: 'warning',
              text: wrongPathTour,
              withFunction: true,
              withoutConfirm: true,
              confirmFunction: () => {
                history.push('/home');
                setTimeout(() => handleOpen(), 350);
              },
            });
            return;
          }
          setTimeout(() => handleOpen(), 100);
          return;
        }
        break;
      }
      default:
        break;
    }
    handleOpen();
  }, [setAlertConfig, history, handleOpen]);

  const remakeTourList = useCallback((param) => {
    switch (param) {
      case 'updateFile': {
        const newSteps = [{
          cantGoBack: true,
          target: 'updateFileModal',
          targetAction: 'submit_update_file',
          text: 'textando',
        }];
        const newData = steps;
        newData.splice(2, 4, ...newSteps);

        nextStep();
        break;
      }
      case 'imageUpload': {
        break;
      }
      case 'searchImage': {
        const newSteps = [{
          cantGoBack: true,
          target: 'unsplash-modal',
          text: 'Selecione uma imagem e, em seguida, clique no no botão "Selecionar"',
          targetAction: 'select-image-unsplash',
        }];
        const newData = steps;
        newData.splice(steps.length - 1, 0, ...newSteps);
        nextStep();
        break;
      }
      case 'solidColor': {
        const newSteps = [
          {
            target: 'color-picker-modal',
            text: 'Selecione uma cor para o fundo do seu dashboard e, em seguida, clique no botão "Confirmar"',
            stopClick: true,
            targetAction: 'button_color_selection',
            // backAction: ['color-picker-modal_close_btn', 1],
          }];
        const newData = steps;
        newData.splice(steps.length - 1, 0, ...newSteps);
        nextStep();
        break;
      }

      default:
        break;
    }
  }, [step, steps, nextStep]);

  const init = useCallback((param) => {
    console.log('carregando tutorial', param);
    setSteps([...param.steps]);
    setEndingMsg(param.endingMsg);
    verifyURL(param);
  }, [verifyURL]);

  const contextValues = useMemo(() => ({
    init, tourOpen: open, nextStep, closeTour, refreshTour, remakeTourList,
  }), [open, nextStep, closeTour, init, refreshTour, remakeTourList]);

  return (
    <TourContext.Provider value={contextValues}>
      {children}
      <Overlay />
      <Mensagem />
    </TourContext.Provider>
  );
};

TourProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

TourProvider.defaultProps = {
  children: '',
};

export { TourContext, TourProvider };

// const dynamic = (side) => {
//   const MsgSizeK = 330;
//   if (side === 'top') {
//     const maxHeight = window.innerHeight;
//     if (positions.top + positions.h > maxHeight) {
//       return positions.top - positions.h / 2;
//     }
//     return positions.top + positions.h / 2;
//   } if (side === 'left') {
//     const totalDistanceLeft = positions.left + positions.w + 10;
//     const maxWidth = window.innerWidth;
//     if (totalDistanceLeft + MsgSizeK > maxWidth) {
//       return positions.left - MsgSizeK;
//     }
//     return totalDistanceLeft;
//   }
//   return 0;
// };
