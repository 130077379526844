/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import { format } from 'date-fns';
import Button from '../../juristec-ui/core/Button';
import MainTabs from '../../juristec-ui/core/Tabs';
import Select from '../../juristec-ui/core/Select';
import Loader from '../../juristec-ui/core/Loader';
import CustomSide from '../../juristec-ui/kpis/controllers';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import SelectTips from '../Popovers/SelectTips';
import KpiFilter from '../KpiFilter';
import KpiFilterApplied from '../KpiFilterApplied';
import useKpiData from '../../hooks/useKpiData';
import {
  dateOptions,
  filterTypeOptionsDate,
  filterTypeOptionsNumber,
  filterTypeOptionsText,
  // groupTypeOptionsDate,
  getGroupTypeDescription,
  getFilterTypeDescription,
  controlVarDescription,
  filterTypeOptionsSimple,
} from '../../options';
import { error } from '../../label';
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';
import { AlertContext } from '../../context/AlertProvider';
import { TourContext } from '../../context/TourProvider';
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';

import {
  StyledContainerDoubleSelect,
  StyledContentTab,
  StyledContainerButtonActions,
  LabelSmall,
  ModifiersContainer,
} from './styled/KpiCreate.styled';

import {
  Gears,
} from '../../juristec-ui/icons';

function KpiSideBar({
  dataFrameOptions,
  dataFrameTypes,
  selectedDatabase,
  setPivotTable,
  setMetaGen,
  toEditOptions,
  chartType,
  styleConfig,
  handleStyleConfig,
  refresh,
  pivotTable,
  setShowGenChartAlert,
  resetCustomSort,
}) {
  const { currentUser } = useContext(AuthContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { filesAPI } = useContext(FilesContext);

  const [kpiDataAPI] = useKpiData(currentUser);

  const [isLoading, setIsLoading] = useState(false);

  const [functionOptions, setFunctionOptions] = useState([
    { label: 'Contagem', value: 'count', id: 'count' },
    { label: 'Contagem únicos', value: 'nunique', id: 'nunique' },
    { label: 'Soma', value: 'sum', id: 'sum' },
    { label: 'Media', value: 'mean', id: 'mean' },
    { label: 'Desvio padrão', value: 'std', id: 'std' },
  ]);

  const [selectedLine, setSelectedLine] = useState(undefined);
  const [selectedLineMap, setSelectedLineMap] = useState(undefined);
  const [selectedColumn, setSelectedColumn] = useState(undefined);
  const [selectedColumnMap, setSelectedColumnMap] = useState(undefined);

  const [selectedValue, setSelectedValue] = useState(undefined);
  const [selectedFunction, setSelectedFunction] = useState(functionOptions[0]);

  const [selectedControl, setSelectedControl] = useState(undefined);
  const [selectedControlMap, setSelectedControlMap] = useState(undefined);

  const [filterConfig, setFilterConfig] = useState({
    column: undefined,
    selector: undefined,
    type: { label: 'Simples', value: 'simple', id: 'simple' },
    format: { value: 'values', label: 'Por valores', id: 'values' },
    values: [],
  });
  const [filterValuesOptions, setFilterValuesOptions] = useState([]);
  // const [filtersOptions, setFiltersOptions] = useState([]);
  const [filtersToSend, setFiltersToSend] = useState([]);

  const [groupConfig, setGroupConfig] = useState({
    column: undefined,
    type: { label: 'Simples', value: 'simple', id: 'simple' },
    format: { value: 'values', label: 'Por valores', id: 'values' },
    rule: '',
    values: [],
  });
  // const [groupAddedType, setGroupAddedType] = useState({ lines: null, columns: null });
  const [groupValuesOptions, setGroupValuesOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [linesMap, setLinesMap] = useState([]);
  const [columnsMap, setColumnsMap] = useState([]);

  const [generateBtnDisabled, setGenerateBtnDisabled] = useState(true);
  const [didMount, setDidMount] = useState(false);
  const { tourOpen, nextStep, refreshTour } = useContext(TourContext);
  useEffect(() => setDidMount(true), []);

  /**
   * Filters variable options, removing already selected variables
   * @param {string} selectType Select identifier ('value', 'line', 'column' or 'control')
   * @returns {array} Filtered options
   */
  const getVarsOptions = (selectType) => {
    if (didMount) {
      if (selectType === 'control') return dataFrameOptions;
      return dataFrameOptions.filter((opt) => (
        (selectType === 'value' || (opt?.value !== selectedValue?.value || opt?.type === 'datetime64[ns]'))
        && (selectType === 'line' || (opt?.value !== selectedLine?.value || opt?.type === 'datetime64[ns]'))
        && (selectType === 'column' || (opt?.value !== selectedColumn?.value || opt?.type === 'datetime64[ns]'))
      ));
    }
    return [];
  };

  /**
   * Filters date format options, removing already selected formats
   * @param {string} selectType Select identifier ('line' or 'column')
   * @returns {array} Filtered options
   */
  const getDateFormatOptions = (selectType) => {
    if (didMount) {
      return dateOptions.filter((opt) => (
        selectedColumn?.value !== selectedLine?.value || (
          (selectType === 'line' || (!selectedLine?.value || opt?.value !== selectedLineMap?.value))
          && (selectType === 'column' || (!selectedColumn?.value || opt?.value !== selectedColumnMap?.value))
        )
      ));
    }
    return [];
  };

  function addUid(modifier) {
    modifier.forEach((mod) => {
      if (!mod.uid) mod.uid = uuidv4();
    });
    return modifier;
  }

  /** Para formatar agrupamentos antigos no novo formato */
  function objectToArray(obj) {
    if (Array.isArray(obj)) return addUid(obj);
    if (obj?.rules) {
      return Object.keys(obj.rules).reduce((aux, name) => {
        aux.push({
          uid: uuidv4(),
          ftype: 'values',
          column: obj.target,
          values: obj.rules[name],
          selector: '',
          inverse: false,
          rule: name,
        });
        return aux;
      }, []);
    }
    return [];
  }

  useEffect(() => {
    if (toEditOptions) {
      const {
        values, filters, linesMap: lMap, columnsMap: cMap,
      } = toEditOptions.meta;

      const selectedObject = {};
      ['lines', 'columns', 'control'].forEach((key) => {
        if (toEditOptions.meta[key]?.length > 0) {
          const tmpValue = toEditOptions.meta[key][0];
          selectedObject[key] = dataFrameOptions.find((opt) => opt.value === tmpValue.column);
          selectedObject[`${key}Map`] = dateOptions.find((opt) => opt.value === tmpValue.map) || {
            label: tmpValue.map,
            value: tmpValue.map,
          };
        }
      });

      const tmpValues = values[0];
      const selectedValues = dataFrameOptions.find((opt) => opt.value === tmpValues.column);
      const functionValue = functionOptions.find(
        (opt) => opt.value === tmpValues.map,
      );

      setFiltersToSend(addUid(filters));

      setSelectedLine(selectedObject.lines);
      setSelectedLineMap(selectedObject.linesMap);
      setSelectedColumn(selectedObject.columns);
      setSelectedColumnMap(selectedObject.columnsMap);
      setSelectedControl(selectedObject.control);
      setSelectedControlMap(selectedObject.controlMap);

      setSelectedValue(selectedValues);
      setSelectedFunction(functionValue);

      setLinesMap(objectToArray(lMap));
      setColumnsMap(objectToArray(cMap));
    }
  }, []);

  const handleConfigModifiers = (key, val, state, setState) => {
    const newConfig = { ...state };
    switch (key) {
      case 'type':
        newConfig.type = val;
        newConfig.selector = null;
        newConfig.values = [];
        if (val.value === 'simple') {
          newConfig.format = { value: 'values', label: 'Por valores', id: 'values' };
        } else {
          newConfig.format = { value: '', label: '', id: '' };
        }
        break;
      case 'format':
        newConfig.format = val;
        switch (val.value) {
          case 'not-contains':
          case 'contains':
          case 'not-values':
          case 'values':
            newConfig.values = [];
            break;
          case 'not-between':
          case 'between':
          case 'not-between_inc':
          case 'between_inc':
            if (dataFrameTypes[state.column.value] === 'datetime64[ns]') {
              newConfig.values = [new Date(), new Date()];
            } else {
              newConfig.values = ['', ''];
            }
            break;
          case 'not-before':
          case 'before':
          case 'not-before_inc':
          case 'before_inc':
          case 'not-after':
          case 'after':
          case 'not-after_inc':
          case 'after_inc':
            newConfig.values = [new Date()];
            break;
          default:
            newConfig.values = [''];
            break;
        }
        break;
      case 'column':
        newConfig.column = val;
        if (newConfig.type.value === 'simple') {
          newConfig.format = { value: 'values', label: 'Por valores', id: 'values' };
        } else {
          newConfig.format = { value: '', label: '', id: '' };
        }
        break;
      default:
        newConfig[key] = val;
        break;
    }
    setState(newConfig);
  };

  const handleGroupConfig = (key, val) => {
    handleConfigModifiers(key, val, groupConfig, setGroupConfig);
  };

  const handleFilterConfig = (key, val) => {
    handleConfigModifiers(key, val, filterConfig, setFilterConfig);
  };

  useEffect(() => {
    if (filterConfig.values.length > 0) {
      handleFilterConfig('values', []);
    }
  }, [filterValuesOptions]);

  useEffect(() => {
    if (groupConfig.values.length > 0) {
      handleGroupConfig('values', []);
    }
  }, [groupValuesOptions]);

  const handleFilterModifiers = (selected, state, setState, valueOptions) => {
    const regexFloat = /^-{0,1}\d*\.{0,1}\d*/;
    const regexInt = /^\d*/;
    switch (state.format.value) {
      case 'not-values':
      case 'values':
        if (!selected) setState('values', []); // qnd limpa tudo
        else if (selected.length === 0) setState('values', []);
        else if (selected[0].value === 'select_all') setState('values', valueOptions);
        else if (selected.length > 0) setState('values', selected);
        break;
      case 'not-contains':
      case 'contains':
        const auxVals = new Set(state.values);
        auxVals[selected.operation](selected.val);
        setState('values', [...auxVals]);
        break;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        let val1 = selected.start;
        let val2 = selected.end;
        if (dataFrameTypes[state.column.value] !== 'datetime64[ns]') {
          [val1] = selected.start?.toString()?.replace(',', '.')?.match(regexFloat) || '';
          [val2] = selected.end?.toString()?.replace(',', '.')?.match(regexFloat) || '';
        }
        setState('values', [
          val1 || (val1 !== '' ? state.values[0] : ''), val2 || (val2 !== '' ? state.values[1] : ''),
        ]);
        break;
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        setState('values', [selected]);
        break;
      case 'not-greater':
      case 'greater':
      case 'not-lesser':
      case 'lesser':
      case 'not-greater_inc':
      case 'greater_inc':
      case 'not-lesser_inc':
      case 'lesser_inc':
        const [valF] = selected.toString().replace(',', '.').match(regexFloat);
        setState('values', [valF || '']);
        break;
      default:
        const [val] = selected.toString().match(regexInt);
        setState('values', [val || '']);
        break;
    }
  };

  const handleGroupChange = (selected) => {
    handleFilterModifiers(selected, groupConfig, handleGroupConfig, groupValuesOptions);
  };

  const handleFilterChange = (selected) => {
    handleFilterModifiers(selected, filterConfig, handleFilterConfig, filterValuesOptions);
  };

  const checkValsModifiers = (modifierObj) => {
    switch (modifierObj.format.value) {
      case 'not-contains':
      case 'contains':
      case 'not-values':
      case 'values':
        return modifierObj.values.length > 0;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        let val1 = modifierObj.values[0];
        let val2 = modifierObj.values[1];
        if (dataFrameTypes[modifierObj.column.value] !== 'datetime64[ns]') {
          if (val1?.length > 0 && val2?.length > 0) {
            val1 = Number(modifierObj.values[0]);
            val2 = Number(modifierObj.values[1]);
          } else {
            return false;
          }
        }
        return !Number.isNaN(val1) && !Number.isNaN(val2) && val2 > val1;
      case 'not-before':
      case 'before':
      case 'not-before_inc':
      case 'before_inc':
      case 'not-after':
      case 'after':
      case 'not-after_inc':
      case 'after_inc':
        return modifierObj.values[0] instanceof Date;
      default:
        if (modifierObj.values[0]?.length > 0) {
          const val = Number(modifierObj.values[0]);
          return !Number.isNaN(val);
        }
        return false;
    }
  };

  const getSelectorByType = (ftype) => (
    {
      lastY: 'year',
      lastM: 'M',
      lastD: 'D',
      lastQ: 'Q',
      lastW: 'W',
      'not-lastY': 'year',
      'not-lastM': 'M',
      'not-lastD': 'D',
      'not-lastQ': 'Q',
      'not-lastW': 'W',
    }[ftype] || ''
  );

  const getActualTypeAndInverse = (ftype) => {
    const ftypeInfo = ftype.split('-');
    const actualFtype = ftypeInfo?.[1] || ftypeInfo?.[0];
    return [
      ftypeInfo?.[0] === 'not',
      ['lastY', 'lastM', 'lastD', 'lastQ', 'lastW'].includes(actualFtype) ? 'last' : actualFtype,
    ];
  };

  const formatValue = (value, mFormat, isDate, isSimple) => {
    if (isSimple) {
      if (value === 'select_all') return null;
      return value.value;
    }
    if (isDate && [
      'between', 'between_inc', 'before', 'before_inc', 'after', 'after_inc',
    ].includes(mFormat)) {
      return format(value, 'yyyy-MM-dd');
    }
    if (mFormat !== 'contains') {
      return Number(value);
    }
    return value;
  };

  const addFilter = () => {
    if (filterConfig.column && checkValsModifiers(filterConfig)) {
      const columnType = dataFrameTypes[filterConfig.column.value];
      const [isInverse, actualType] = getActualTypeAndInverse(filterConfig.format.value);
      const isSimple = filterConfig.type.value === 'simple';

      const fObjAux = {
        uid: uuidv4(),
        column: filterConfig.column.value,
        type: columnType,
        ftype: actualType || 'values',
        selector: isSimple ? (filterConfig?.selector?.value || '') : getSelectorByType(filterConfig.format.value),
        values: filterConfig.values.reduce((aux, v) => {
          const val = formatValue(v, actualType, columnType === 'datetime64[ns]', isSimple);
          if (val || val === 0) aux.push(val);
          return aux;
        }, []),
        inverse: isInverse,
      };
      const nfilters = [...filtersToSend].concat([fObjAux]);
      // console.log(fObjAux);
      setFiltersToSend(nfilters);
      setFilterConfig({
        column: null,
        selector: null,
        type: { label: 'Simples', value: 'simple', id: 'simple' },
        format: { value: 'values', label: 'Por valores', id: 'values' },
        values: [],
      });
      setGenerateBtnDisabled(false);
    } else {
      setAlertConfig({ type: 'error', text: error.generic, child: 'Filtro ainda não foi especificado' });
    }
  };

  const addGroup = () => {
    if (groupConfig.column && groupConfig.rule !== '' && checkValsModifiers(groupConfig)) {
      // const isDateType = dataFrameTypes[groupConfig.column.value] === 'datetime64[ns]';
      const columnType = dataFrameTypes[groupConfig.column.value];
      const [isInverse, actualType] = getActualTypeAndInverse(groupConfig.format.value);
      const isSimple = groupConfig.type.value === 'simple';

      const gObjAux = {
        uid: uuidv4(),
        column: groupConfig.column.value,
        type: columnType,
        ftype: actualType || 'values',
        selector: isSimple ? (groupConfig?.selector?.value || '') : getSelectorByType(groupConfig.format.value),
        values: groupConfig.values.reduce((aux, v) => {
          const val = formatValue(v, actualType, columnType === 'datetime64[ns]', isSimple);
          if (val || val === 0) aux.push(val);
          return aux;
        }, []),
        inverse: isInverse,
        rule: groupConfig.rule,
      };
      if (groupConfig.column.value === selectedLine?.value) {
        if (columnType === 'datetime64[ns]' && groupConfig.type.value === 'simple') {
          gObjAux.selector = selectedLineMap.value;
        }
        setLinesMap((old) => [...old].concat([gObjAux]));
      } else if (groupConfig.column?.value === selectedColumn?.value) {
        if (columnType === 'datetime64[ns]' && groupConfig.type.value === 'simple') {
          gObjAux.selector = selectedColumnMap.value;
        }
        setColumnsMap((old) => [...old].concat([gObjAux]));
      }
      // console.log(gObjAux);
      setGroupConfig({
        column: undefined,
        type: { label: 'Simples', value: 'simple', id: 'simple' },
        format: { value: 'values', label: 'Por valores', id: 'values' },
        rule: '',
        values: [],
      });
      setGenerateBtnDisabled(false);
    } else {
      setAlertConfig({ type: 'error', text: error.generic, child: 'Agrupamento ainda não foi especificado' });
      // toggleAlert();
    }
  };

  const getUnique = async (filterVal, filterSelectorVal) => {
    const { res, msg, error: er } = await filesAPI.getUnique(
      selectedDatabase.value,
      filterVal,
      filterSelectorVal,
    );
    if (er) {
      setAlertConfig({ type: 'error', text: error.generic, child: msg });
      return [];
    }
    return res.map((val) => ({ label: val, value: val, id: val }));
  };

  useEffect(() => {
    const getUniqueBySelected = (selected, sFormat) => {
      if (selected) {
        // handleFilterConfig('sFormat',{value: 'values',label:'Filtro por valores',id: 'values'});
        if (dataFrameTypes[selected.value] !== 'datetime64[ns]') {
          return getUnique(selected.value, '');
        } if (sFormat) {
          return getUnique(selected.value, sFormat.value);
        }
      }
      return [];
    };
    (async () => {
      if (filterConfig.column) {
        setFilterValuesOptions(
          await getUniqueBySelected(filterConfig.column, filterConfig.selector),
        );
      } else if (groupConfig.column) {
        if (groupConfig.column.value === selectedLine.value) {
          setGroupValuesOptions(await getUniqueBySelected(groupConfig.column, selectedLineMap));
        } else if (groupConfig.column.value === selectedColumn.value) {
          setGroupValuesOptions(await getUniqueBySelected(groupConfig.column, selectedColumnMap));
        }
      }
    })();
  }, [filterConfig.column, filterConfig.selector, groupConfig.column]);

  /**
   * @async Generate temporary kpi data based on metadata
   * @param {object} body The metadata to generate a kpi
   */
  const setKpi = async (body) => {
    setIsLoading(true);
    const response = await kpiDataAPI.genKpiData(body, selectedDatabase.value);
    if (response.error) {
      setAlertConfig({
        type: 'error',
        text: 'Algo está incorreto!',
        child: 'Os elementos selecionados não permitem a criação do KPI',
      });
      setIsLoading(false);
      return;
    }
    setPivotTable({ ...response });
    setIsLoading(false);
  };

  async function createKpi(noColumn) {
    // TODO tratar para que todos os campos existam senao avisar atraves do modal alert
    if (!selectedValue || !selectedLine) {
      setAlertConfig({ type: 'error', text: 'Algo está incorreto!', child: 'É necessário selecionar pelo menos o valor e a linha.' });
      return;
    }
    if (dataFrameTypes[selectedLine.value] === 'datetime64[ns]' && !selectedLineMap) {
      setAlertConfig({ type: 'error', text: 'Algo está incorreto!', child: 'É necessário selecionar o formato para linha do tipo data.' });
      return;
    }
    if (selectedColumn && dataFrameTypes[selectedColumn.value] === 'datetime64[ns]' && !selectedColumnMap) {
      setAlertConfig({ type: 'error', text: 'Algo está incorreto!', child: 'É necessário selecionar o formato para coluna do tipo data.' });
      return;
    }
    if (selectedControl && dataFrameTypes[selectedControl.value] === 'datetime64[ns]' && !selectedControlMap) {
      setAlertConfig({ type: 'error', text: 'Algo está incorreto!', child: 'É necessário selecionar o formato para controle do tipo data.' });
      return;
    }

    const linesToSend = selectedLine
      ? [
        {
          column: selectedLine.value,
          map:
            (dataFrameTypes[selectedLine.value] === 'datetime64[ns]' && selectedLineMap)
              ? selectedLineMap.value
              : 'category',
          type: dataFrameTypes[selectedLine.value],
        },
      ]
      : [];

    const columnToSend = !noColumn && selectedColumn
      ? [
        {
          column: selectedColumn.value,
          map:
            (dataFrameTypes[selectedColumn.value] === 'datetime64[ns]' && selectedColumnMap)
              ? selectedColumnMap.value
              : 'category',
          type: dataFrameTypes[selectedColumn.value],
        },
      ]
      : [];

    const valuesToSend = selectedValue
      ? [
        {
          column: selectedValue?.value,
          map: selectedFunction?.value,
          type: dataFrameTypes[selectedValue?.value],
        },
      ]
      : [];

    const controlToSend = selectedControl?.value ? ([{
      column: selectedControl.value,
      map: (dataFrameTypes[selectedControl.value] === 'datetime64[ns]' && selectedControlMap)
        ? selectedControlMap.value
        : 'category',
      type: dataFrameTypes[selectedControl.value],
    }]) : [];

    const currentBody = {
      database: selectedDatabase?.value || '',
      fileOwner: selectedDatabase?.owner || '',
      lines: linesToSend,
      columns: columnToSend,
      values: valuesToSend,
      control: controlToSend,
      filters: filtersToSend,
      linesMap,
      columnsMap,
      ownerId: currentUser.uid,
    };

    try {
      setKpi(currentBody);
      setMetaGen(currentBody);
    } catch (err) {
      setAlertConfig({ type: 'error', text: 'Algo está incorreto!', child: 'Os elementos selecionados não permitem a criação do KPI' });
    } finally {
      if (tourOpen) nextStep();
      setGenerateBtnDisabled(true);
    }
  }

  const resetPage = () => {
    setPivotTable(null);
    setSelectedValue('');
    setSelectedLine('');
    setSelectedColumn('');
    setSelectedControl('');
    setFiltersToSend([]);
    resetCustomSort();
    setShowGenChartAlert(false);
  };

  const getTypeOptions = (isSimple, columnType) => {
    if (isSimple) {
      return filterTypeOptionsSimple;
    }
    switch (columnType) {
      case 'datetime64[ns]':
        // if (isGroup) return groupTypeOptionsDate;
        return filterTypeOptionsDate;
      case 'float64':
        return filterTypeOptionsNumber;
      default:
        return filterTypeOptionsText;
    }
  };

  const removeFilterHandle = (filterUid) => {
    setAlertConfig({
      type: 'warning',
      child: 'Deseja mesmo remover esse filtro?',
      text: 'Tem certeza?',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: () => {
        setFiltersToSend(filtersToSend.filter((item) => item.uid !== filterUid));
        setGenerateBtnDisabled(false);
      },
    });
  };

  const editFilterHandle = (filterUid, filterColumn, newValues, ftype) => {
    if (newValues.length === 0) return;
    setFiltersToSend(filtersToSend.map((item) => (
      item.uid === filterUid ? {
        ...item,
        values: newValues.map((v) => (
          formatValue(v, ftype, dataFrameTypes[filterColumn] === 'datetime64[ns]', ftype === 'values')
        )),
      } : item
    )));
    setGenerateBtnDisabled(false);
  };

  const removeGroupHandle = (groupUid, groupColumn) => {
    setAlertConfig({
      type: 'warning',
      child: 'Deseja mesmo remover esse agrupamento?',
      text: 'Tem certeza?',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: () => {
        if (groupColumn === selectedLine.value) {
          setLinesMap((g) => g.filter((item) => item.uid !== groupUid));
        } else if (groupColumn === selectedColumn.value) {
          setColumnsMap((g) => g.filter((item) => item.uid !== groupUid));
        }
        setGenerateBtnDisabled(false);
      },
    });
  };

  const editGroupHandle = (groupUid, groupColumn, newValues, ftype, gName, gNameNew) => {
    if (newValues.length === 0) return;
    const gAuxEdit = {
      rule: gNameNew.trim() || gName,
      values: ftype === 'values' ? (
        newValues.map((a) => a.value).filter((b) => b.value !== 'select_all')
      ) : (
        newValues.map((v) => (
          formatValue(v, ftype, dataFrameTypes[groupColumn] === 'datetime64[ns]')
        ))
      ),
    };

    if (groupColumn === selectedLine.value) {
      setLinesMap((g) => g.map((item) => (
        item.uid === groupUid ? {
          ...item,
          ...gAuxEdit,
        } : item
      )));
    } else if (groupColumn === selectedColumn.value) {
      setColumnsMap((g) => g.map((item) => (
        item.uid === groupUid ? {
          ...item,
          ...gAuxEdit,
        } : item
      )));
    }
    setGenerateBtnDisabled(false);
  };

  useEffect(() => {
    if (selectedValue && selectedLine && !generateBtnDisabled) {
      setShowGenChartAlert(true);
    }
  }, [generateBtnDisabled]);

  useEffect(() => {
    if (!(selectedValue && selectedLine)) {
      setGenerateBtnDisabled(true);
    }
  }, [selectedValue, selectedLine]);

  useEffect(() => {
    if (didMount) { resetPage(); setGenerateBtnDisabled(false); }
  }, [refresh]);

  useEffect(() => {
    if (selectedValue?.value) {
      switch (dataFrameTypes[selectedValue.value]) {
        case 'category':
        case 'datetime64[ns]':
        case 'object':
          setFunctionOptions([
            { label: 'Contagem', value: 'count', id: 'count' },
            { label: 'Contagem únicos', value: 'nunique', id: 'nunique' },
          ]);
          setSelectedFunction({ label: 'Contagem', value: 'count', id: 'count' });
          break;

        case 'int64':
        case 'float64':
          setFunctionOptions([
            { label: 'Soma', value: 'sum', id: 'sum' },
            { label: 'Media', value: 'mean', id: 'mean' },
          ]);
          setSelectedFunction({ label: 'Soma', value: 'sum', id: 'sum' });
          break;

        default:
          setFunctionOptions([]);
          setSelectedFunction({});
          break;
      }

      if (toEditOptions && toEditOptions.meta.values[0].column === selectedValue?.value) {
        setSelectedFunction(
          functionOptions.find(
            (opt) => opt.value === toEditOptions.meta.values[0].map,
          ),
        );
      }
    }
  }, [selectedValue]);

  // useEffect(() => {
  //   setFiltersOptions(filtersToSend.map((el) => el.column));
  // }, [filtersToSend]);

  useEffect(() => {
    const getUsedVals = (aux, mp) => {
      if (!mp.ftype || mp.ftype === 'values') aux.push(mp.values);
      return aux;
    };
    setGroupOptions({
      [selectedLine?.value]: linesMap?.length ? linesMap.reduce(getUsedVals, []).flat() : [],
      [selectedColumn?.value]: columnsMap?.length ? columnsMap.reduce(getUsedVals, []).flat() : [],
    });
  }, [linesMap, columnsMap]);

  useEffect(() => {
    handleGroupConfig('column', undefined);
    handleGroupConfig('format', {
      value: 'values', label: 'Por valores', id: 'values',
    });
    setGroupOptions([]);
  }, [selectedLine, selectedColumn]);

  useEffect(() => {
    if (linesMap?.[0]?.column !== selectedLine?.value) {
      setLinesMap([]);
    }
  }, [selectedLine]);

  useEffect(() => {
    if (columnsMap?.[0]?.column !== selectedColumn?.value) {
      setColumnsMap([]);
    }
  }, [selectedColumn]);

  useEffect(() => {
    if (toEditOptions) {
      setActiveTab(3);
    }
  }, []);

  const genChart = (noColumn = true) => {
    createKpi(noColumn);
    resetCustomSort();
  };

  const genChartBtn = () => {
    genChart(false);
    setShowGenChartAlert(false);
  };

  useEffect(() => {
    if (chartType === 'Calendar' && selectedLineMap?.value === 'D' && generateBtnDisabled) {
      const years = pivotTable.index.reduce((aux, d) => {
        const y = d?.match(/\d{4}/);
        if (y) aux.push(y);
        return aux;
      }, []).sort();

      if (Number(years?.[years?.length - 1])
        && Number(years?.[0])
        && years?.[years?.length - 1] - years?.[0] > 4
      ) {
        setAlertConfig({
          type: 'warning',
          text: 'Atenção!',
          child: (
            <>
              <span>
                O gráfico de Calendário possui um intervalo muito grande entre os anos informados.
              </span>
              <br />
              <span>Recomendamos utilizar a guia filtros para otimização da leitura</span>
            </>
          ),
        });
      }
    }
    if (['Pie', 'Map', 'Funnel', 'Waffle'].includes(chartType)
    && pivotTable?.columns.length > 1 && pivotTable?.data.length > 0) {
      setSelectedColumn('');
      const getLabel = {
        Pie: 'de pizza',
        Map: 'geográfico',
        Funnel: 'de funil',
        Waffle: 'de waffle',
      };
      setAlertConfig({
        type: 'warning',
        text: `O gráfico ${getLabel[chartType]} está sendo carregado com propriedades não permitidas. Para melhorar sua experiência, retiramos as colunas para este KPI!`,
        okFunction: genChart,
      });
    }
  }, [chartType, generateBtnDisabled]);

  if (tourOpen) refreshTour();

  const changeOrderModifiers = (pos, varOp, state, setState) => {
    const newPos = pos + varOp;
    if (newPos < 0 && newPos >= state.lenght) return;
    const tempList = [...state];
    const [auxItem] = tempList.splice(pos, 1);
    tempList.splice(newPos, 0, auxItem);
    setState(tempList);
    setGenerateBtnDisabled(false);
  };

  const changeFilterOrder = (pos, varOp) => {
    changeOrderModifiers(pos, varOp, filtersToSend, setFiltersToSend);
  };

  const changeGroupOrder = (pos, varOp, varName) => {
    if (varName === 'line') {
      changeOrderModifiers(pos, varOp, linesMap, setLinesMap);
    } else if (varName === 'column') {
      changeOrderModifiers(pos, varOp, columnsMap, setColumnsMap);
    }
  };

  /**
   * Gets the description of each advanced modifier (filter or grouping)
   * @param {string} modifier The modifier type (filter or group)
   * @param {string} mFormat The modifier condition
   * @param {boolean} isDate If the variable is of type date
   * @returns {string} A descriptive text for the modifier
   */
  const getModifierTip = (modifier, mFormat, isDate) => (
    modifier === 'filter' ? getFilterTypeDescription(mFormat, isDate) : getGroupTypeDescription(mFormat, isDate)
  );

  /** Gets the options for the grouping modifier */
  const groupingOptions = () => {
    const opts = [];
    if (selectedLine) opts.push(selectedLine);
    if (selectedColumn && selectedColumn?.label !== selectedLine?.label) opts.push(selectedColumn);
    return opts;
  };

  return (
    <>
      {isLoading && <Loader />}
      <StyledContainerButtonActions className="gerar_grafico">
        <Button
          size="large"
          variant="outlined"
          onClick={resetPage}
          disabled={!selectedValue && !selectedLine && filtersToSend.length === 0}
        >
          Resetar
        </Button>
        <Button
          size="large"
          onClick={genChartBtn}
          disabled={!selectedValue || !selectedLine || generateBtnDisabled}
        >
          Gerar Gráfico
          <Gears />
        </Button>
      </StyledContainerButtonActions>
      <MainTabs
        disabled={!(selectedValue && (selectedLine || selectedColumn)) || !pivotTable}
        head={[{ text: 'Dados', id: 0 }, { text: 'Agrupamento', id: 1 }, { text: 'Filtros', id: 2 }, { text: 'Estilos', id: 3 }]}
        active={activeTab}
        setActive={setActiveTab}
      >
        <StyledContentTab className="kpi-side-content-tab">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <StyledContainerDoubleSelect>
              <Select
                isSearchable
                placeholder="Selecione"
                selectLabel="Valor*"
                onChange={(newValue) => {
                  setGenerateBtnDisabled(false);
                  setSelectedValue(newValue);
                }}
                options={getVarsOptions('value')}
                value={selectedValue || (
                  { label: selectedValue, value: selectedValue, id: selectedValue }
                )}
                isClearable
                // debuggerMode
                fullWidth
                alphabeticalOrder
              />
              {selectedValue && (
              <Select
                isSearchable
                selectLabel="Função"
                onChange={(newValue) => {
                  setGenerateBtnDisabled(false);
                  setSelectedFunction(newValue);
                }}
                value={selectedFunction}
                options={functionOptions}
                formatOptionLabel={false}
                fullWidth
              />
              )}
            </StyledContainerDoubleSelect>
            {/* <hr style={{ width: '50%' }} /> */}
            <StyledContainerDoubleSelect>
              <Select
                isSearchable
                placeholder="Selecione"
                selectLabel="Linha*"
                onChange={(newValue) => {
                  setGenerateBtnDisabled(false);
                  setSelectedLine(newValue);
                }}
                options={getVarsOptions('line')}
                value={selectedLine || (
                  { label: selectedLine, value: selectedLine, id: selectedLine }
                )}
                isClearable
                fullWidth
                alphabeticalOrder
              />
              {(selectedLine && (dataFrameTypes[selectedLine.value] === 'datetime64[ns]')) && (
                <Select
                  isSearchable
                  placeholder="Selecione"
                  selectLabel="Formato"
                  onChange={(newValue) => {
                    setGenerateBtnDisabled(false);
                    setSelectedLineMap(newValue);
                  }}
                  options={getDateFormatOptions('line')}
                  value={selectedLineMap}
                  formatOptionLabel={false}
                  fullWidth
                  alphabeticalOrder
                />
              )}
            </StyledContainerDoubleSelect>

            {!['Pie', 'Map', 'Funnel', 'Calendar', 'Waffle'].includes(chartType) && (
              <StyledContainerDoubleSelect>
                <Select
                  alphabeticalOrder
                  isSearchable
                  placeholder="Selecione"
                  selectLabel="Coluna (opcional)"
                  onChange={(newValue) => {
                    setGenerateBtnDisabled(false);
                    setSelectedColumn(newValue);
                  }}
                  options={getVarsOptions('column')}
                  value={selectedColumn || (
                    { label: selectedColumn, value: selectedColumn, id: selectedColumn }
                  )}
                  isClearable
                  menuPlacement="top"
                  maxMenuHeight={150}
                  fullWidth
                />
                {(selectedColumn && (dataFrameTypes[selectedColumn.value] === 'datetime64[ns]')) && (
                  <Select
                    alphabeticalOrder
                    isSearchable
                    placeholder="Selecione"
                    selectLabel="Formato"
                    onChange={(newValue) => {
                      setGenerateBtnDisabled(false);
                      setSelectedColumnMap(newValue);
                    }}
                    value={selectedColumnMap}
                    options={getDateFormatOptions('column')}
                    formatOptionLabel={false}
                    fullWidth
                  />
                )}
              </StyledContainerDoubleSelect>
            )}
            <StyledContainerDoubleSelect>
              <div style={{ position: 'relative' }}>
                <Select
                  isSearchable
                  placeholder="Selecione"
                  selectLabel="Controle (opcional)"
                  onChange={(newValue) => {
                    setGenerateBtnDisabled(false);
                    setSelectedControl(newValue);
                  }}
                  options={getVarsOptions('control')}
                  value={selectedControl || (
                    { label: selectedControl, value: selectedControl, id: selectedControl }
                  )}
                  isClearable
                  fullWidth
                  alphabeticalOrder
                />
                <SelectTips
                  description={controlVarDescription}
                  style={{ top: '-5px', right: '2px' }}
                />
              </div>
              {(selectedControl && (dataFrameTypes[selectedControl.value] === 'datetime64[ns]')) && (
                <Select
                  alphabeticalOrder
                  isSearchable
                  placeholder="Selecione"
                  selectLabel="Formato"
                  onChange={(newValue) => {
                    setGenerateBtnDisabled(false);
                    setSelectedControlMap(newValue);
                  }}
                  value={selectedControlMap}
                  options={dateOptions}
                  formatOptionLabel={false}
                  fullWidth
                />
              )}
            </StyledContainerDoubleSelect>

          </div>
        </StyledContentTab>

        {/* ====================== Agrupamento ====================== */}

        <StyledContentTab className="kpi-side-content-tab">
          <div className="gap-fix-first">
            <div className="gap-fix-second">
              <div style={{ width: '50%' }}>
                <Select
                  alphabeticalOrder
                  isSearchable
                  selectLabel="Agrupar por"
                  placeholder="Selecione"
                  onChange={(newValue) => handleGroupConfig('column', newValue)}
                  value={groupConfig.column || (
                    { label: groupConfig.column, value: groupConfig.column, id: groupConfig.column }
                  )}
                  options={groupingOptions()}
                  isClearable
                  fullWidth
                  style={{ minWidth: '50%' }}
                />
              </div>
              <div style={{ width: '50%' }}>
                <Select
                  selectLabel="Tipo"
                  placeholder="Selecione"
                  onChange={(newValue) => handleGroupConfig('type', newValue)}
                  value={groupConfig.type}
                  options={[
                    { label: 'Simples', value: 'simple', id: 'simple' },
                    { label: 'Avançado', value: 'advanced', id: 'advanced' },
                  ]}
                  formatOptionLabel={false}
                  fullWidth
                  style={{ minWidth: '50%' }}
                />
              </div>
            </div>
            {groupConfig.column && (
              <>
                <div style={{ position: 'relative' }}>
                  <Select
                    isSearchable
                    selectLabel="Condição"
                    onChange={(val) => handleGroupConfig('format', val)}
                    value={groupConfig.format}
                    options={
                      getTypeOptions(groupConfig.type.value === 'simple', dataFrameTypes[groupConfig.column.value])
                    }
                    menuPlacement="auto"
                    // maxMenuHeight={150}
                    menuPosition="fixed"
                    formatOptionLabel={false}
                    // alphabeticalOrder
                    closeMenuOnSelect={false}
                    placeholder="Selecione"
                    fullWidth
                  />
                  {groupConfig.format.value !== '' && (
                    <SelectTips
                      description={getModifierTip(
                        'group',
                        groupConfig.format.value,
                        dataFrameTypes[groupConfig.column.value] === 'datetime64[ns]',
                      )}
                      style={{ top: '-5px', right: '2px' }}
                    />
                  )}
                </div>
                {groupConfig.format.value !== '' && (groupConfig.type?.value === 'advanced' || groupValuesOptions?.length > 0) && (
                  <>
                    <KpiFilter
                      filterType={groupConfig.format.value}
                      handle={handleGroupChange}
                      values={groupConfig.values}
                      options={groupValuesOptions.filter((opt) => (
                        !groupOptions[groupConfig.column?.value]?.includes(opt.value)
                      ))}
                      format={dataFrameTypes[groupConfig.column.value]}
                    />
                    <InputTextLine
                      label="Nome do Agrupamento"
                      type="text"
                      style={{ marginBottom: 0 }}
                      onChange={(e) => handleGroupConfig('rule', e.target.value)}
                      value={groupConfig.rule}
                      styleContainer={{ marginTop: '-8px' }}
                      onBlur={(e) => handleGroupConfig('rule', e.target.value.split(' ').filter((v) => v).join(' '))}
                    />
                    <Button
                      onClick={() => addGroup()}
                      color="secondary"
                      disabled={
                        !(groupConfig.values.length > 0 && groupConfig.rule?.trim().length > 0)
                      }
                      style={{ margin: '10px 0 10px', width: '100%' }}
                    >
                      Adicionar agrupamento
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
          {(linesMap?.length > 0 || columnsMap?.length > 0) && (
            <LabelSmall>Agrupamentos Aplicados</LabelSmall>
          )}
          {linesMap?.length > 0 && (
            <ModifiersContainer>
              <LabelSmall style={{ margin: '5px 0 -5px' }}>Linha</LabelSmall>
              {linesMap.map((item, i) => (
                <KpiFilterApplied
                  key={item.uid}
                  modifier={item}
                  removeFunc={removeGroupHandle}
                  editFunc={editGroupHandle}
                  getOptions={getUnique}
                  usedOpts={groupOptions}
                  nameEdit
                  dataFrameTypes={dataFrameTypes}
                  position={i}
                  isLast={linesMap.length - 1 === i}
                  changePosition={(pos, varOp) => changeGroupOrder(pos, varOp, 'line')}
                />
              ))}
            </ModifiersContainer>
          )}
          {columnsMap?.length > 0 && (
            <ModifiersContainer>
              <LabelSmall style={{ margin: '5px 0 -5px' }}>Coluna</LabelSmall>
              {columnsMap.map((item, i) => (
                <KpiFilterApplied
                  key={item.uid}
                  modifier={item}
                  removeFunc={removeGroupHandle}
                  editFunc={editGroupHandle}
                  getOptions={getUnique}
                  usedOpts={groupOptions}
                  nameEdit
                  dataFrameTypes={dataFrameTypes}
                  position={i}
                  isLast={columnsMap.length - 1 === i}
                  changePosition={(pos, varOp) => changeGroupOrder(pos, varOp, 'column')}
                />
              ))}
            </ModifiersContainer>
          )}

        </StyledContentTab>

        {/* ====================== Filtros ====================== */}

        <StyledContentTab className="kpi-side-content-tab">
          <div className="gap-fix-first">
            <div className="gap-fix-second">
              <div style={{ width: '50%' }}>
                <Select
                  alphabeticalOrder
                  isSearchable
                  selectLabel="Filtrar por"
                  placeholder="Selecione"
                  onChange={(newValue) => handleFilterConfig('column', newValue)}
                  value={filterConfig.column || ({
                    label: filterConfig.column, value: filterConfig.column, id: filterConfig.column,
                  })}
                  options={dataFrameOptions}
                  isClearable
                  fullWidth
                  style={{ minWidth: '50%' }}
                />
              </div>
              <div style={{ width: '50%' }}>
                <Select
                  selectLabel="Tipo"
                  placeholder="Selecione"
                  onChange={(newValue) => handleFilterConfig('type', newValue)}
                  value={filterConfig.type}
                  options={[
                    { label: 'Simples', value: 'simple', id: 'simple' },
                    { label: 'Avançado', value: 'advanced', id: 'advanced' },
                  ]}
                  formatOptionLabel={false}
                  fullWidth
                  style={{ minWidth: '50%' }}
                />
              </div>
            </div>
            <div className="gap-fix-second">
              {filterConfig.column
              && filterConfig.type.value === 'simple'
              && dataFrameTypes[filterConfig.column.value] === 'datetime64[ns]'
              && (
                <div style={{ width: '100%' }}>
                  <Select
                    isSearchable
                    selectLabel="Formato"
                    placeholder="Selecione"
                    onChange={(newValue) => handleFilterConfig('selector', newValue)}
                    value={filterConfig.selector}
                    options={dateOptions}
                    formatOptionLabel={false}
                    fullWidth
                    style={{ minWidth: '50%' }}
                  />
                </div>
              )}
              {filterConfig.column && (
                <div style={{ position: 'relative', width: '100%' }}>
                  <Select
                    isSearchable
                    selectLabel="Condição"
                    onChange={(newValue) => handleFilterConfig('format', newValue)}
                    value={filterConfig.format}
                    options={
                      getTypeOptions(filterConfig.type.value === 'simple', dataFrameTypes[filterConfig.column.value])
                    }
                    menuPlacement="auto"
                    placeholder="Selecione"
                    // maxMenuHeight={150}
                    menuPosition="fixed"
                    formatOptionLabel={false}
                    // alphabeticalOrder
                    closeMenuOnSelect={false}
                    fullWidth
                  />
                  {filterConfig.format.value !== '' && (
                    <SelectTips
                      description={getModifierTip(
                        'filter',
                        filterConfig.format.value,
                        dataFrameTypes[filterConfig.column.value] === 'datetime64[ns]',
                      )}
                      style={{ top: '-5px', right: '2px' }}
                    />
                  )}
                </div>
              )}
            </div>
            {filterConfig.column && filterConfig.format.value !== '' && (filterConfig.type?.value === 'advanced' || filterValuesOptions?.length > 0) && (
              <>
                <KpiFilter
                  filterType={filterConfig.format.value}
                  handle={handleFilterChange}
                  values={filterConfig.values}
                  options={filterValuesOptions}
                  format={dataFrameTypes[filterConfig.column.value]}
                />
                <Button
                  onClick={addFilter}
                  color="secondary"
                  disabled={!checkValsModifiers(filterConfig)}
                  style={{ margin: '10px 0 10px', width: '100%' }}
                >
                  Adicionar filtro
                </Button>
              </>
            )}
          </div>
          {filtersToSend.length > 0 && (
            <ModifiersContainer>
              <LabelSmall>Filtros Aplicados</LabelSmall>
              {filtersToSend.map((filter, i) => (
                <KpiFilterApplied
                  key={filter.uid}
                  modifier={filter}
                  removeFunc={removeFilterHandle}
                  editFunc={editFilterHandle}
                  getOptions={getUnique}
                  dataFrameTypes={dataFrameTypes}
                  position={i}
                  isLast={filtersToSend.length - 1 === i}
                  changePosition={changeFilterOrder}
                />
              ))}
            </ModifiersContainer>
          )}
        </StyledContentTab>
        <StyledContentTab className="kpi-side-content-tab">
          <CustomSide
            type={chartType}
            config={styleConfig}
            handle={handleStyleConfig}
            data={pivotTable}
            //
            selectedLine={selectedLine}
            selectedLineMap={selectedLineMap}
            selectedColumn={selectedColumn}
            selectedColumnMap={selectedColumnMap}
            selectedValue={selectedValue}
            selectedFunction={selectedFunction}
          />
        </StyledContentTab>
      </MainTabs>
    </>
  );
}

KpiSideBar.propTypes = {
  chartType: PropTypes.string,
  dataFrameOptions: PropTypes.arrayOf(PropTypes.object),
  dataFrameTypes: PropTypes.objectOf(PropTypes.string),
  handleStyleConfig: PropTypes.func,
  pivotTable: PropTypes.shape({
    index: PropTypes.arrayOf(PropTypes.string),
  }),
  refresh: PropTypes.bool,
  setShowGenChartAlert: PropTypes.func,
  selectedDatabase: PropTypes.shape({}),
  setMetaGen: PropTypes.func,
  setPivotTable: PropTypes.func,
  styleConfig: PropTypes.shape({}),
  toEditOptions: PropTypes.shape({
    meta: PropTypes.any,
  }),
  resetCustomSort: PropTypes.func,
};

KpiSideBar.defaultProps = {
  chartType: 'Table',
  dataFrameOptions: {
    filter: () => { },
    find: () => { },
  },
  dataFrameTypes: {},
  handleStyleConfig: () => { },
  pivotTable: {},
  refresh: false,
  setShowGenChartAlert: () => { },
  selectedDatabase: {},
  setMetaGen: () => { },
  setPivotTable: () => { },
  styleConfig: {},
  toEditOptions: {
    meta: {},
  },
  resetCustomSort: () => {},
};

export default KpiSideBar;
