export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },

  // STLYES...
  ShowZeroValuesControl: { checked: true },
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  Margin: {
    top: 50,
    right: 145,
    bottom: 55,
    left: 70,
  },
  DataFormat: { type: 'absolute', target: 'global' },
  MarginTop: 50,
  MarginRight: 110,
  MarginBottom: 50,
  MarginLeft: 60,
  GroupModeControl: 'grouped',
  LayoutControl: 'vertical',
  ReverseControl: { checked: false },
  MinValueControl: { value: 0, checked: true, pai: true },
  MaxValueControl: { value: 0, checked: true, pai: true },
  PaddingControl: { value: 0.1 },
  InnerPaddingControl: { value: 0 },

  EnableAxisX: {
    axisTop: false,
    axisBot: true,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 35,
    separadorNumerico: true,
  },
  EnableAxisY: {
    axisLeft: true,
    axisRight: false,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -55,
    separadorNumerico: true,
  },
  EnableGridX: false,
  EnableGridY: true,
  AxisGlobal: {
    fontSize: 12,
    fontFamily: 'arial',
    legendFontSize: 12,
  },

  BorderRadiusControl: { value: 5 },
  BorderWidthControl: { value: 0 },
  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },

  LegendControl: { checked: true },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendPlacementControl: {
    value: {
      // PARA USAR NO SELECT DE POSICAO...
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      // anchor: 'bottom',
      translateX: 110,
      translateY: 0,
      // translateX: -92,
      // translateY: 45,
    },
  },
  LegendsSpacingControl: { value: 10 },

  SliceTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },

  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  Animate: false,
  MotionConfig: 'default',
  // LABEL
  LabelFormatControl: 'Superior',
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  LabelGroupingControl: '',
  LabelCurrencyControl: 'Nenhum',
  LabelsSeparadorNumerico: true,
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelsFontSizeControl: { value: 12 },
  LabelRotationControl: 0,
  EnableLabelControl: { checked: true },
  LabelTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  LabelSkipWidthControl: { value: 0 },
  LabelSkipHeightControl: { value: 0 },
  // ----                   -----  //
  CurrencyControl: 'Nenhum',
  EnableSeparator: { checked: true },
  // COLORS
  ColorByControl: 'auto',
  Colors: {},
  ColorTheme: { scheme: 'nivo' },

  PalletsControl: 'default', // || 'custom' // variável para poder selecionar palletas do nivo ou poder setar cada cor pessoalmente...
  //
  GroupingSelectControl: '', // axys
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  legendClick: [],
  ValueScaleControl: 'linear',
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },
  // Marker
  /* ShowMarkerControl: false,
  MarkerValueControl: 0,
  MarkerLineColorControl: '#000',
  MarkerLineSizeControl: 2,
  MarkerLineStyleControl: 'normal',
  MarkerNameControl: '',
  MarkerOrientationControl: 'horizontal',
  MarkerPositionControl: 'top',
  MarkerTextColorControl: '#000',
  MarkerItalicControl: false,
  MarkerBoldControl: false,
  MarkerFontSizeControl: 12,
  MarkerFontFamilyControl: 'arial', */
};
