import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';
import FileOptions from '../FileOptions';
import Popover from '../../../juristec-ui/core/Popover';
import Avatar from '../../../juristec-ui/core/Avatar';
import originIcon from '../../../juristec-ui/utils/originMap';

import {
  Upload,
  Sortable,
  SortAtoZ,
  SortZtoA,
  SortOnetoNine,
  SortNinetoOne,
  TemplateUse,
  UpdateError,
  UpdateWarning,
  UpdateSuccess,
} from '../../../juristec-ui/icons';

import { printSize, formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import { expDateOrder } from '../../../juristec-ui/utils/formatCharts/orderDatetime';
import compare from '../../../utils/functions/sorting';

import {
  FilesCardsContainer,
  CardsDeck,
  FileCard,
  CardHeader,
  CardMain,
  CardFooter,
  CardFooterInfo,
  IconWrapper,
  IconBtnContainer,
  LabelRow,
  SortContainer,
  SortBtnsRow,
  FileNameContainer,
} from './styled/FilesCards.styled';

const copyToClipboard = (filename) => {
  const copyText = filename;
  const textArea = document.createElement('textarea');
  textArea.textContent = copyText;
  textArea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');

  document.body.removeChild(textArea);
};

/**
* A component for displaying data sets in cards
*/
const FilesCards = ({
  files,
  viewFile,
  updateFile,
  downloadFile,
  deleteFile,
  showFileDashboards,
  applyTemplate,
  convertFileToMail,
  convertFileApiGed,
  user,
  usersList,
  deviceType,
  getReportStatus,
  userCompany,
  shareFile,
  transferFile,
  selfRemove,
  columnAlias,
}) => {
  const [dataList, setDataList] = useState([]);
  const [sortBy, setSortBy] = useState({ order: 1, field: 'filename' });
  const [openPopover, setOpenPopover] = useState(false);
  const [copied, setCopied] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    setDataList(
      [...files].sort(
        (a, b) => sortBy.order * (compare(
          expDateOrder(a[sortBy.field]), expDateOrder(b[sortBy.field]),
        )),
      ),
    );
  }, [sortBy, files]);

  const fileNameFormat = (filename) => {
    const name = filename.replace('.metrics', '');
    return (
      <Tooltip text={copied ? 'Copiado!' : 'Clique para copiar'}>
        <FileNameContainer
          onClick={() => {
            copyToClipboard(name);
            setCopied(true);
          }}
          onMouseEnter={() => setCopied(false)}
        >
          {name}
        </FileNameContainer>
      </Tooltip>
    );
  };

  const getOwnerAvatar = (id) => {
    const owner = usersList.filter((user) => user.id === id)[0];
    return (
      <Tooltip text={owner.name || ''} direction="right">
        <Avatar
          name={owner.name || ''}
          src={owner.photoUrl || ''}
          size="medium"
          onClick={(e) => e.stopPropagation()}
          style={{ cursor: 'default' }}
        />
      </Tooltip>
    );
  };

  /**
   * Checks if the file is automatically updated
   * @param {object} item file info
   * @returns {boolean} boolean
   */
  const isAutoUpdated = (item) => item.origin === 'LegalOne' || item.origin === 'FileApi' || item.origin === 'LegalOneExtractor'
  || item.origin === 'PgSql' || item.origin === 'MySql' || item.origin === 'L1ReportsApi' || item.origin === 'Drive'
  || item.origin === 'GoogleDrive' || item.origin === 'OneDrive' || item.origin === 'oneDrive' || item.origin === 'Antecipei'
  || item.origin === 'Mail' || item.origin === 'mail' || item.origin === 'Benner';

  const updateMessage = (item) => {
    if (isAutoUpdated(item)) {
      return 'Atualizado automaticamente';
    }
    if (user.id === item.owner || item.shared_write.includes(user.id)) {
      return 'Atualizar arquivo';
    }
    return 'Você não pode atualizar um arquivo de outra pessoa';
  };

  /**
   * Return visual cues depending on file update characteristics
   * @param {object} item file info
   * @returns {object} Object with corresponding update icon and color
   */
  const visualInfo = (item) => {
    if (!isAutoUpdated(item)) return { icon: <Upload />, color: 'primary' };
    if (item.lastAttemptStatus === 'ERROR') return { icon: <UpdateError />, color: 'error' };
    if (item.lastAttemptStatus === 'WARNING') return { icon: <UpdateWarning />, color: 'warning' };
    return { icon: <UpdateSuccess />, color: 'success' };
  };

  /**
   * Selects with type of function to be called on click
   * @param {object} item file info
  */
  const updateOrStatus = (item) => (isAutoUpdated(item) ? getReportStatus(item) : updateFile(item));

  return (
    <FilesCardsContainer className="files_list">
      <LabelRow>
        <h3>
          Total de arquivos
          {' '}
          <span className="file-qtd">
            (
            {files.length}
            )
          </span>
        </h3>
        <Popover
          style={{
            borderRadius: '8px',
            backgroundColor: theme.popoverBackground,
            overflow: 'hidden',
          }}
          offset={[0, 0]}
          open={openPopover}
          stayOpened
          closePopover={() => setOpenPopover(false)}
        >
          <Popover.Action>
            <Tooltip text="Ordenar arquivos">
              <IconButton
                shape="rounded"
                onClick={() => setOpenPopover(!openPopover)}
              >
                <Sortable />
              </IconButton>
            </Tooltip>
          </Popover.Action>
          <Popover.Content direction="bottom-end">
            <SortContainer>
              <h4>Ordenar por</h4>
              <span>Nome:</span>
              <SortBtnsRow toggle={sortBy} field="filename">
                <Tooltip text="Ordem crescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: 1, field: 'filename' })}
                  >
                    <SortAtoZ />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Ordem decrescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: -1, field: 'filename' })}
                  >
                    <SortZtoA />
                  </IconButton>
                </Tooltip>
              </SortBtnsRow>
              <span>Tamanho:</span>
              <SortBtnsRow toggle={sortBy} field="size">
                <Tooltip text="Ordem crescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: 1, field: 'size' })}
                  >
                    <SortOnetoNine />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Ordem decrescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: -1, field: 'size' })}
                  >
                    <SortNinetoOne />
                  </IconButton>
                </Tooltip>
              </SortBtnsRow>
              <span>Data de Criação:</span>
              <SortBtnsRow toggle={sortBy} field="created_at">
                <Tooltip text="Ordem crescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: 1, field: 'created_at' })}
                  >
                    <SortOnetoNine />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Ordem decrescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: -1, field: 'created_at' })}
                  >
                    <SortNinetoOne />
                  </IconButton>
                </Tooltip>
              </SortBtnsRow>
              <span>Data de Atualização:</span>
              <SortBtnsRow toggle={sortBy} field="updated_at">
                <Tooltip text="Ordem crescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: 1, field: 'updated_at' })}
                  >
                    <SortOnetoNine />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Ordem decrescente">
                  <IconButton
                    shape="rounded"
                    onClick={() => setSortBy({ order: -1, field: 'updated_at' })}
                  >
                    <SortNinetoOne />
                  </IconButton>
                </Tooltip>
              </SortBtnsRow>
            </SortContainer>
          </Popover.Content>
        </Popover>
      </LabelRow>
      <CardsDeck>
        {dataList && dataList.map((data) => (
          <FileCard key={`${data.filename}_${data.origin}`}>
            <CardHeader>
              <IconWrapper>{originIcon(data.origin)}</IconWrapper>
              <span>{printSize(data.size).replace('.', ',')}</span>
              <IconBtnContainer>
                {deviceType !== 'phone' && (
                  <>
                    {userCompany.plan !== 'push' && (
                      <Tooltip text={updateMessage(data)}>
                        <IconButton
                          size="medium"
                          shape="rounded"
                          style={{ padding: '1px' }}
                          color={visualInfo(data).color}
                          onClick={() => updateOrStatus(data)}
                          disabled={!isAutoUpdated(data)
                            && !(data.shared_write.includes(user.id) || user.id === data.owner)}
                        >
                          {visualInfo(data).icon}
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip text="Aplicar template">
                      <IconButton
                        size="medium"
                        shape="rounded"
                        style={{ padding: '1px' }}
                        onClick={() => applyTemplate(data)}
                      >
                        <TemplateUse />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <Tooltip text="Mais opções">
                  <FileOptions
                    size="medium"
                    shape="rounded"
                    item={data}
                    viewFile={viewFile}
                    downloadFile={downloadFile}
                    deleteFile={deleteFile}
                    showFileDashboards={showFileDashboards}
                    convertFileToMail={convertFileToMail}
                    convertFileApiGed={convertFileApiGed}
                    user={user}
                    userCompany={userCompany}
                    shareFile={shareFile}
                    transferFile={transferFile}
                    selfRemove={selfRemove}
                    columnAlias={columnAlias}
                  />
                </Tooltip>
              </IconBtnContainer>
            </CardHeader>
            <CardMain>
              {fileNameFormat(data.filename)}
            </CardMain>
            <CardFooter isOwner={data.owner === user.id}>
              {data.owner !== user.id
                && getOwnerAvatar(data.owner)}
              <CardFooterInfo isOwner={data.owner === user.id}>
                <span>
                  {`Criado: ${formatDateTime(data.created_at, { time: 'half' })}`}
                </span>
                {!!data.updated_at && (
                  <span>
                    {`Atualizado: ${formatDateTime(data.updated_at, { time: 'half' })}`}
                  </span>
                )}
              </CardFooterInfo>
            </CardFooter>
          </FileCard>
        ))}
      </CardsDeck>
    </FilesCardsContainer>
  );
};

FilesCards.propTypes = {
  /**
    * Array with the data of the files
    */
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /**
    * Function for viewing a file
    */
  viewFile: PropTypes.func.isRequired,
  /**
    * Function for updating a file
    */
  updateFile: PropTypes.func.isRequired,
  /**
    * Function for downloading a file
    */
  downloadFile: PropTypes.func.isRequired,
  /**
    * Function for deleting a file
    */
  deleteFile: PropTypes.func.isRequired,
  /**
    * Function to show the dashboards associated with the file
    */
  showFileDashboards: PropTypes.func.isRequired,
  /**
    * Function to apply a dashboard template to a file
    */
  applyTemplate: PropTypes.func.isRequired,
  /**
   * Function to convert File to Legalone Mail
   */
  convertFileToMail: PropTypes.func.isRequired,
  /**
   * Function to convert File to Legalone Api Ged report
   */
  convertFileApiGed: PropTypes.func.isRequired,
  /**
   * Function to share file from one user to others
   */
  shareFile: PropTypes.func.isRequired,
  /**
   * Function to transfer the ownership from one user to another
   */
  transferFile: PropTypes.func.isRequired,
  /**
   * Function to self remove a user from a shared file
   */
  selfRemove: PropTypes.func.isRequired,
  /**
   * Function to rename columns from a file
   */
  columnAlias: PropTypes.func.isRequired,
  /**
   * User object
   */
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  /**
   * Company users list
   */
  usersList: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  /**
   * The current device type by viewport width
   */
  deviceType: PropTypes.string.isRequired,
  /**
   * Function to get the status of a report (used to Legal One and Connect reports)
   */
  getReportStatus: PropTypes.func.isRequired,
  /**
    * Object with the user's company information
    */
  userCompany: PropTypes.shape({
    plan: PropTypes.string,
  }).isRequired,
};

export default FilesCards;
