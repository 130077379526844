import styled, { css } from 'styled-components';

export const DatePickerContainer = styled.div`
  width: 100%;
  ${({ theme }) => css`
    & .datepicker-style {
      display: flex;
    }
    & .container-style {
      z-index: 2000;
      & .react-datepicker {
        background-color: ${theme.popoverBackground};
        border-color: ${theme.grey};
      }
      & .react-datepicker__triangle {
        &:before {
          border-top-color: ${theme.grey};
        }
        &:after {
          border-top-color: ${theme.popoverBackground};
        }
      }
      & .react-datepicker__header {
        background-color: ${theme.container};
        border-color: ${theme.grey};
      }
      & .react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day {
        color: ${theme.color};
      }
      & .react-datepicker__day:hover {
        background-color: ${theme.containerHover};
      }
      & .react-datepicker__day--selected {
        background-color: ${theme.primary};
        color: ${theme.white};
        &:hover {
          background-color: ${theme.primaryHover};
        }
      }
      & [class*="--disabled"] {
        color: ${theme.disabled};
        pointer-events: none;
      }
    }
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 4px;
  right: 2px;
  box-shadow: none;
  padding: 2px;
  & svg {
    fill: ${({ theme }) => theme.grey};
  }
`;
