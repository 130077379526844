import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Content,
} from './styled/Tabs.styled';

export default function MainTabs({
  head, children, disabled, active, setActive,
}) {
  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <div className="mainTabs">
      <Tabs columns={head} className="tabs">
        {head.map((item) => (
          <Tab
            key={item.id}
            onClick={handleClick}
            active={active}
            id={item.id}
            disabled={item.id === 3 ? disabled : false}
          >
            {item.text}
          </Tab>
        ))}
      </Tabs>
      {head.map((item, idx) => (
        <Content active={active === item.id} key={item.id} className="tab-content">
          {children[idx]}
        </Content>
      ))}
    </div>
  );
}

MainTabs.propTypes = {
  active: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]),
  disabled: PropTypes.bool,
  head: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.number,
  })),
  setActive: PropTypes.func.isRequired,
};

MainTabs.defaultProps = {
  head: [],
  children: '',
  disabled: true,
};
