const free = {
  plan: { label: 'free', value: 'free' },
  snapshots: 12,
  scientists: 0,
  superS: 1,
  scientistsList: [],
  superSList: [],
  diskSize: 100,
  guests: 0,
  dashboards: { label: 'ilimitado', value: 100000 },
  inteligentDashboards: 2,
  templates: [],
  monitoring: 0,
  oldProcess: 0.5,
  newProcess: 2,
  newMonitoring: 3.5,
};

const standard = {
  plan: { label: 'standard', value: 'standard' },
  snapshots: 12,
  scientists: 0,
  superS: 1,
  scientistsList: [],
  superSList: [],
  diskSize: 250,
  guests: 1,
  dashboards: { label: 'ilimitado', value: 100000 },
  inteligentDashboards: 2,
  templates: [],
  monitoring: 0,
  oldProcess: 0.5,
  newProcess: 2,
  newMonitoring: 3.5,
};

const advanced = {
  plan: { label: 'advanced', value: 'advanced' },
  snapshots: 12,
  scientists: 1,
  superS: 1,
  superSList: [],
  scientistsList: [],
  diskSize: 500,
  guests: 3,
  dashboards: { label: 'ilimitado', value: 100000 },
  inteligentDashboards: 10,
  templates: [],
  monitoring: 0,
  oldProcess: 0.5,
  newProcess: 2,
  newMonitoring: 3.5,
};

const premium = {
  plan: { label: 'premium', value: 'premium' },
  snapshots: 12,
  scientists: 3,
  superS: 1,
  superSList: [],
  scientistsList: [],
  diskSize: 1000,
  guests: 6,
  dashboards: { label: 'ilimitado', value: 100000 },
  inteligentDashboards: 20,
  templates: [],
  monitoring: 0,
  oldProcess: 0.5,
  newProcess: 2,
  newMonitoring: 3.5,
};

const custom = {
  plan: { label: 'custom', value: 'custom' },
  snapshots: '',
  scientists: '',
  scientistsList: [],
  superS: '',
  superSList: [],
  diskSize: '',
  guests: '',
  dashboards: {},
  inteligentDashboards: '',
  templates: [],
  monitoring: 0,
  oldProcess: 0.5,
  newProcess: 2,
  newMonitoring: 3.5,
};

const push = {
  plan: { label: 'push', value: 'push' },
  snapshots: 12,
  scientists: 0,
  superS: 1,
  scientistsList: [],
  superSList: [],
  diskSize: 250,
  guests: 0,
  dashboards: { label: '10', value: 10 },
  inteligentDashboards: 2,
  templates: [],
  monitoring: 0,
  oldProcess: 0.5,
  newProcess: 2,
  newMonitoring: 3.5,
};

export default function getPlan(plan) {
  return {
    free, standard, advanced, premium, custom, push,
  }[plan] || custom;
}
