import gradient from 'gradient-color';
import { getColorSchemes } from '../controllersUtils/themeColors';

/**
 * Handles chart color format.
 * @param {number} dataLength Quantity of categories.
 * @param {object} config Style config.
 * @param {string} colorKey Custom color key.
 * @param {boolean} isMap Identifies if the chart is a map type (different theme).
 * @returns {string[]|object|function} Required color handle.
 */
const colorSelector = (dataLength, config, colorKey, isMap) => {
  switch (config.PalletsControl) {
    case 'gradient': {
      const gradColors = [
        config.GradientPickerControl?.color1 || '#4711B2',
        config.GradientPickerControl?.color2 || '#FF9626',
      ];
      if (dataLength < 3) {
        return gradColors;
      }
      return gradient(gradColors, dataLength);
    }
    case 'default': {
      const theme = config.ColorTheme || { scheme: 'nivo' };
      if (!getColorSchemes(isMap).includes(theme.scheme)) {
        return isMap ? 'nivo' : { scheme: 'nivo' };
      }
      return isMap ? theme.scheme : theme;
    }
    default:
      return (a) => (config?.Colors ? config?.Colors[a[colorKey]] : '#f2ead3');
  }
};

export default colorSelector;
