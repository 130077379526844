import styled from 'styled-components';

import { Responsive, WidthProvider } from 'react-grid-layout';
import { devices } from '../../breakpoints';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const StyledResponsiveReactGridLayout = styled(ResponsiveReactGridLayout)`
  position: relative;
  & > div {
    cursor: ${(props) => (props.dragging ? 'grabbing' : 'grab')};
    user-select: none;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      border-radius: 1rem;
      border: ${(props) => (props.editPainel ? 'dashed 2px #a9a9a9' : 'solid 2px transparent')};
    }
    & > *{
      pointer-events: ${(props) => (props.editPainel ? 'none' : 'all')};
    }
  }
`;

export const MainContainer = styled.div`
  margin: 0 5%; 
  padding: 5px;
  overflow: auto; 
  overflow-x: hidden;
  box-sizing: border-box;
  padding-bottom: 65px;
  height: calc(100vh - 138px);

  @media ${devices.tablet} {
    margin: 5px 0 0;
    height: calc(100vh - 130px);
  };

  @media ${devices.phoneS} {
    margin: 10px 0 0;
    height: calc(100vh - 152px);
  }; 
`;
