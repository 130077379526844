export default {
  // General
  TitleControl: '',
  SortValuesControl: 'Não ordenado',
  ShowTitleControl: { checked: true },
  DataFormat: { type: 'absolute', target: 'global' },

  Margin: {
    top: 30,
    right: 30,
    bottom: 110,
    left: 30,
  },
  LayoutControl: 'horizontal',
  YearSpacingControl: 30,
  MonthSpacingControl: 4,
  DaySpacingControl: 0,
  MinValueControl: { value: 0, checked: false },
  MaxValueControl: { value: 0, checked: true },
  // Style
  MonthWidthControl: 2,
  MonthColorControl: '#000',
  DayWidthControl: 1,
  DayColorControl: '#000',
  QtdColorsControl: 5,
  PalletsControl: 'gradient',
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },
  EmptyColorControl: '#eee',
  // Legend
  LegendControl: { checked: true },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendPlacementControl: {
    value: {
      // PARA USAR NO SELECT DE POSICAO...
      selectedPosition: 'bot',
      direction: 'row',
      anchor: 'bottom',
      label: 'Abaixo',
      translateX: 0,
      translateY: -100,
    },
  },
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  InteractiveDecimals: 'auto',
  // LABEL
  EnableLabelControl: { checked: true },
  LabelsFontSizeControl: { value: 15 },
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelTextColorControl: '#000',
  YearPositionControl: 'before',
  YearOffsetControl: 10,
  MonthPositionControl: 'before',
  MonthOffsetControl: 10,
  /*
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  */
  // ShowZeroValuesControl: { checked: false },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
};
