import styled from 'styled-components';
import { gap } from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  align-items: center;
`;

export const UserInfos = styled.div`
  display: flex;
  align-items: center;
  ${gap('10px', 'row')}

  & .instance-row-user-name-email {
    display: flex;
    flex-direction: column;
    ${gap('2px', 'column')}
  }

  & .name {
    font-size: 20px;
    font-weight: 600;
  }

  & .email {
    font-size: 15px;
    color: ${({ theme }) => theme.grey};
  }
`;

export const Options = styled.div`
  display: flex;
  ${gap('10px', 'row')}
`;
