import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import InstanceToolbar from '../../components/Toolbars/InstanceToolbar';
import UsersList from '../../components/UsersList';
import InstanceView from '../../components/InstanceView';
import AddUser from '../../components/Modals/AddUser';
import MoreUsers from '../../components/Modals/MoreUsers';
import MoreMemory from '../../components/Modals/MoreMemory';
import Button from '../../juristec-ui/core/Button';

import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import { TourContext } from '../../context/TourProvider';
import { AuthContext } from '../../context/AuthProvider';
import { UsersContext } from '../../context/UsersProvider';
import { FilesContext } from '../../context/FilesProvider';

import demoteDashPermission from '../../utils/demoteDashPermission';
import Loader from '../../juristec-ui/core/Loader';

const rolesLabel = {
  super: 'Proprietário',
  scientist: 'Cientista',
  guest: 'Convidado',
};

function UserManagement() {
  const history = useHistory();
  const [tab, setTab] = useState('users');
  const [inputFilter, setInputFilter] = useState('');

  const {
    user, currentUser, userCompany, claimsUser,
  } = useContext(AuthContext);
  const { state: filesState, filesAPI } = useContext(FilesContext);
  const { state: usersState, usersAPI } = useContext(UsersContext);
  const { tourOpen, nextStep, closeTour } = useContext(TourContext);
  const { setModalConfig, toggleModal } = useContext(ModalContext);
  const { setAlertConfig, toggleAlert } = useContext(AlertContext);

  useEffect(() => {
    usersAPI.getUsers();
  }, []);

  useEffect(() => {
    if (!filesState.started) {
      (async () => {
        await filesAPI.init();
      })();
    }
  }, [filesState.started, filesAPI]);

  const [usersList, setUsersList] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [adminsQtd, setAdminsQtd] = useState(0);
  const [scientistsQtd, setScientistsQtd] = useState(0);
  const [guestsQtd, setGuestsQtd] = useState(0);

  // Recebe a lista de usuarios e faz as alteracoes necessarias para a visualizacao
  useEffect(() => {
    setUsersList([]);
    setAdminsQtd(0);
    setScientistsQtd(0);
    setGuestsQtd(0);
    setUsersList(
      usersState.users?.reduce((list, userData) => {
        switch (userData.role) {
          case 'scientist':
            setScientistsQtd((before) => ++before);
            break;
          case 'guest':
            setGuestsQtd((before) => ++before);
            break;
          case 'super':
            setAdminsQtd((before) => ++before);
            break;
          default:
            break;
        }
        if (user.role === 'scientist' && user.uid !== userData.uid && userData.scientist !== user.uid) {
          return list;
        }
        list.push({
          ...userData,
          roleLabel: rolesLabel[userData.role],
          scientistName: usersState.users.filter((u) => u.id === userData.scientist)[0]?.name || '-',
        });
        return list;
      }, []) || [],
    );
  }, [usersState?.users]);

  // Filtra a lista de usuarios
  useEffect(() => {
    const filterTxt = inputFilter.trim();
    const hasText = (txt) => txt.toLowerCase().includes(filterTxt.toLowerCase());
    if (filterTxt.length > 0) {
      setFilteredUsersList(usersList.filter((userData) => (
        hasText(userData.name) || hasText(userData.email) || hasText(userData.roleLabel)
      )));
    } else {
      setFilteredUsersList(usersList);
    }
  }, [usersList, inputFilter]);

  // Adicao de novo usuario
  const handleAddUser = () => {
    if (tourOpen) nextStep();

    const checkLimit = (role) => {
      switch (role) {
        case 'scientist':
          if (scientistsQtd === userCompany.scientists) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo de cientistas atingido nesta instância',
            });
            return false;
          }
          break;
        case 'guest':
          if (guestsQtd === userCompany.guests) {
            setAlertConfig({
              type: 'error',
              text: 'Limite máximo de convidados atingido nesta instância',
            });
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    };

    const submitAddUser = async (userInfo) => {
      if (!checkLimit(userInfo.role)) return;
      const { error, msg, raw } = await usersAPI.addUser({
        ...userInfo,
        send_email: true,
        volumedata: 500,
      });

      if (error) {
        setAlertConfig({
          type: 'error',
          text: msg,
          child: raw,
        });
        usersAPI.getUsers();
        toggleModal();
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Usuário adicionado com sucesso!',
        okFunction: () => {
          usersAPI.getUsers();
          toggleModal();
        },
      });
    };

    setModalConfig({
      title: 'Adicionar Novo Usuário',
      className: 'newUser_modal',
      children: (
        <AddUser
          user={user}
          userIsAdmin={claimsUser?.is_admin}
          usersEmails={[...usersList.map((u) => u.email)]}
          hide={() => {
            toggleModal();
            if (tourOpen) closeTour();
          }}
          submitData={submitAddUser}
          fromAdmin={false}
          tourContext={{ tourOpen, nextStep }}
        />
      ),
    });
  };

  // Retorna o novo cargo de acordo com a booleana passada e o cargo atual
  const getNewUserRole = (promote, selectedUser) => {
    switch (selectedUser?.role) {
      case 'guest':
        if (promote) return 'scientist';
        return 'guest';
      case 'scientist':
        if (promote) return 'super';
        return 'guest';
      case 'super':
        if (promote) return 'super';
        return 'scientist';
      default:
        return '';
    }
  };

  // Promocao ou rebaixamento de cargo
  const handleRoleChange = async (p, selectedUser) => {
    // const { email, companyId } = selectedUser;
    const { email } = selectedUser;
    const newRole = getNewUserRole(p, selectedUser);
    // const {
    //   error, raw,
    // } = await usersAPI.editUser(email, newRole, currentUser.uid, companyId);
    const { error, msg, raw } = await usersAPI.editUser(email, newRole);
    if (error) {
      if (raw.match(/CODE 409/)) {
        setAlertConfig({
          type: 'error',
          text: 'Permissão insuficiente',
          child: 'Seu usuário não possui a permissão necessária para essa ação!',
          okFunction: () => {
            usersAPI.getUsers();
          },
        });
      } else {
        setAlertConfig({
          type: 'error',
          text: 'Algo deu errado',
          child: raw,
          okFunction: () => {
            usersAPI.getUsers();
          },
        });
      }
      return;
    }

    if (newRole === 'guest') {
      await demoteDashPermission(selectedUser.uid);
    }

    setAlertConfig({
      type: 'success',
      text: 'Usuário atualizado com sucesso!',
      okFunction: () => {
        usersAPI.getUsers();
      },
    });
  };

  // Bloqueia ou desbloqueia o usuario
  const handleUserState = async (selectedUser) => {
    const { email, disabled } = selectedUser;
    const {
      error, raw,
    } = await usersAPI.toggleUserBlock(email, disabled ? 'False' : 'True');
    if (error) {
      if (raw.match(/CODE 409/)) {
        setAlertConfig({
          type: 'error',
          text: 'Permissão insuficiente',
          child: 'Seu usuário não possui a permissão necessária para essa ação!',
          okFunction: () => {
            usersAPI.getUsers();
          },
        });
      } else {
        setAlertConfig({
          type: 'error',
          text: 'Algo deu errado',
          child: raw,
          okFunction: () => {
            usersAPI.getUsers();
          },
        });
      }
      return;
    }
    setAlertConfig({
      type: 'success',
      text: `Usuário ${!disabled ? 'desativado' : 'ativado'} com sucesso!`,
      okFunction: () => {
        usersAPI.getUsers();
      },
    });
  };

  // Lida com a remocao do usuario selecionado
  const handleDeleteUser = (selectedUser) => {
    const submitDelete = async () => {
      const { raw, error } = await usersAPI.deleteUser(selectedUser.email);
      if (error) {
        if (raw.match(/CODE 409/)) {
          setAlertConfig({
            type: 'error',
            text: 'Permissão insuficiente',
            child: 'Seu usuário não possui a permissão necessária para essa ação!',
            okFunction: () => {
              usersAPI.getUsers();
            },
          });
        } else if (raw.includes('HELPDESK delete_user: ')) {
          // Temporario, a mensagem deve vir correta do backend
          // Atualmente ela vem em um JSON com erro de sintaxe
          const msg = (raw.split('[')[1]).split(']')[0];
          const humanMsg = JSON.parse(msg.replace(/\\"/g, '"').replace(/\\\\/g, '\\'));
          setAlertConfig({
            type: 'error',
            text: 'Algo deu errado',
            child: decodeURIComponent(`${humanMsg.description} Por favor, abra um ticket no suporte para que possamos ajudá-lo!`),
            okFunction: () => {
              usersAPI.getUsers();
            },
          });
        } else {
          setAlertConfig({
            type: 'error',
            text: 'Algo deu errado',
            child: raw,
            okFunction: () => {
              usersAPI.getUsers();
            },
          });
        }
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Usuário removido com sucesso!',
        okFunction: () => {
          usersAPI.getUsers();
        },
      });
    };

    const QuestionAlertContent = () => (
      <>
        <div style={{
          width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <span>Tem certeza que deseja excluir este usuário?</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <Button
            style={{ margin: '5px' }}
            onClick={toggleAlert}
            variant="outlined"
            size="large"
          >
            Cancelar
          </Button>
          <Button
            style={{ margin: '5px' }}
            onClick={() => { submitDelete(); toggleAlert(); }}
            size="large"
          >
            sim
          </Button>
        </div>
      </>
    );

    setAlertConfig({
      type: 'warning',
      text: `Remover ${selectedUser.name}?`,
      withoutConfirm: true,
      child: <QuestionAlertContent />,
    });
  };

  // Lida com a modal de solicitações, e envia o email com o que foi solicitado
  const handleSolicitationModal = (solicitation) => {
    const sendSolicitationEmail = (data) => {
      // TODO metodo para enviar os dados solicitados por email
      console.log(data);
    };

    if (solicitation === 'users') {
      setModalConfig({
        title: 'Solicitar Novos Usuários',
        children: (
          <MoreUsers
            hide={() => {
              toggleModal();
              if (tourOpen) closeTour();
            }}
            submitData={sendSolicitationEmail}
          />
        ),
      });
    } else {
      setModalConfig({
        title: 'Solicitar Memória',
        children: (
          <MoreMemory
            hide={() => {
              toggleModal();
              if (tourOpen) closeTour();
            }}
            submitData={sendSolicitationEmail}
          />
        ),
      });
    }
  };

  return (
    <>
      {(filesState.isLoading || usersState.isLoading) && <Loader />}
      <InstanceToolbar
        goBack={history.goBack}
        tab={tab}
        setTab={setTab}
        addFunc={handleAddUser}
        currentUser={user}
        inputFilter={inputFilter}
        setInputFilter={setInputFilter}
      />
      {tab === 'users' ? (
        <UsersList
          users={filteredUsersList}
          currentUser={user}
          promoteUser={(selectedUser) => handleRoleChange(true, selectedUser)}
          demoteUser={(selectedUser) => handleRoleChange(false, selectedUser)}
          toggleUserState={handleUserState}
          deleteUser={handleDeleteUser}
          openUserHistory={() => {}}
        />
      ) : (
        <InstanceView
          users={usersList}
          memoryInfo={{ used: filesState.size, max: parseFloat(userCompany?.volumeData || 0) }}
          adminsInfo={{ used: adminsQtd, max: userCompany.superS }}
          scientistsInfo={{ used: scientistsQtd, max: userCompany.scientists }}
          guestsInfo={{ used: guestsQtd, max: userCompany.guests }}
          dashboardsInfo={{ used: filesState.dashboards, max: userCompany.dashboards }}
          templatesInfo="Ilimitado"
          storiesInfo={userCompany.snapshots}
          solicitationModal={handleSolicitationModal}
        />
      )}
    </>
  );
}

export default UserManagement;
