import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

export const MainContainer = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
`;

export const ScrollContainer = styled.div`
  max-height: calc(100vh - 200px);
  overflow: auto;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.white};
  }
`;

export const IconFlipWrapper = styled(IconWrapper)`
  transition: all 0.5s;
  ${(props) => props.flip
    && css`
      transform: rotate(180deg);
    `}
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  /* justify-content: ${({ isCsv }) => (isCsv ? 'space-between' : 'flex-end')}; */
  justify-content: space-between;
  align-items: center;
  ${gap('40px', 'row')}
  padding: .2rem 0 .5rem;

  & .invert svg {
    transform: rotate(180deg);
  }
`;

PaginationContainer.Selects = styled.div`
  display: flex;
  ${gap('10px', 'row')}
`;

PaginationContainer.ArrowBtns = styled.div`
  margin-top: ${({ isCsv }) => (isCsv ? '0px' : '11px')};
  display: flex;
  align-items: center;
  ${gap('5px', 'row')}
`;

export const LegendsContainer = styled.div`
  margin-top: ${({ isCsv }) => (isCsv ? '0px' : '11px')};
  margin-right: 120px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  & .legend-type { 
    display: flex;
    align-items: center;
  };

  @media ${devices.tablet} {
    display: none;
  }

  & .square { 
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  & .legend-texto { 
    background: ${({ theme }) => theme.typeText};
  }

  & .legend-numero { 
    background: ${({ theme }) => theme.typeNumber};
  }

  & .legend-date {
    background: ${({ theme }) => theme.typeDate};
  }
`;
