/* eslint-disable react/prop-types */
import React from 'react';
import MiniLoading from '../../../core/MiniLoading';

import { StyledEmptyKpiCard, StyledLoadContainer } from './styled/ErrorKpi.styled';

/** Category limits for each type of kpi. Use the 'get' function to get the dynamic limit. */
const kpiLimits = {
  Bar: 101,
  Pie: 101,
  Line: 101,
  Radar: 101,
  Funnel: 101,
  RadialBar: 101,
  get(key /* , dashSize */) {
    return this[key] || Infinity; // Teste com valor fixo
    // return Math.max(((this[key] || Infinity) - 2 * dashSize), 51);
  },
};

/** Error messages for when unable to render kpi. */
const kpiErrorMsgs = {
  emptyDataSet: `O seu KPI foi criado e as informações nos dados estão vazias, portanto não podemos renderizar o seu gráfico.
  Verifique as colunas e filtros fornecidos na criação de seu gráfico!`,
  tooMuchCategories: `O seu KPI possui mais de 100 categorias, o que está impactando no consumo de memória do seu navegador, portanto não podemos renderizar o seu gráfico.
  Recomendamos utilizar agrupamentos e filtros para otimização da leitura! (Recomendado 20 categorias)`,
  hasNegativeValues: `O seu KPI possui valores negativos que não são aceitos por esse formato de gráfico, portanto não podemos renderizá-lo.
  Recomendamos a utilização de outros formatos de gráficos para esses dados.`,
};

/** Component that renders error messages */
const KpiErrorMsg = ({ reason, message }) => (
  <StyledEmptyKpiCard>
    <h2>{reason}</h2>
    <p>{message}</p>
  </StyledEmptyKpiCard>
);

/**
 * Checks the amounts of data and categories, to see whether or not it can render the kpi.
 * @param {object} dataSet The object with the kpi data
 * @param {string} kpiType Type of kpi
 * @param {boolean} isVisible Whether the kpi is visible on the screen or not
 * @param {number} dashSize The amount of kpis on the dashboard
 * @param {boolean} isDataLoading Forces loading overlay to appear
 * @returns Returns null if rendering is possible.
 * If not, return the component with the error message.
 */
const checkDataSize = (dataSet, kpiType, isVisible, dashSize = 1, isDataLoading) => {
  if (!isVisible || isDataLoading) {
    return <StyledLoadContainer><MiniLoading fill="primary" /></StyledLoadContainer>;
  }
  if (dataSet) {
    if (!dataSet.data?.length > 0 && kpiType !== 'Value') return <KpiErrorMsg reason="Indicador Vazio" message={kpiErrorMsgs.emptyDataSet} />;
    if (dataSet.columns.length > kpiLimits.get(kpiType, dashSize)
      || dataSet.index.length > kpiLimits.get(kpiType, dashSize)
    /* || (dataSet.index.length * dataSet.columns.length)
    - dataSet.columns.length > kpiLimits.get(kpiType, dashSize) */
    ) {
      return <KpiErrorMsg reason="KPI com muitas categorias" message={kpiErrorMsgs.tooMuchCategories} />;
    }
  }
  return null;
};

export default checkDataSize;

export const checkNegative = (hasNegative) => (
  hasNegative ? (
    <KpiErrorMsg reason="KPI com valores negativos" message={kpiErrorMsgs.hasNegativeValues} />
  ) : <></>
);
