import styled, { css } from 'styled-components';
import { gap } from '../../../styles/theme';

export const DataListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  ${gap('5px', 'column')}
  list-style-type: none;
  padding: 0;
  margin: 0;
  ${({ rowColor, strippedRowsColor, theme }) => css`
    & li {
      border-radius: 5px;
      background-color: ${theme[rowColor] || rowColor};
      &:nth-of-type(odd) {
        background-color: ${theme[strippedRowsColor] || strippedRowsColor};
      }
      &:hover {
        background-color: ${theme.lightgrey};
      }
    }
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${gap('5px', 'row')}
  padding: 5px;
`;

export const ColumnHeader = styled.div`
  font-family: arial;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  padding: 8px 5px;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  width: 100%;
  ${gap('5px', 'row')}
  ${({ sortable, theme }) => sortable && css`
    cursor: pointer;
    &:hover {
      color: ${theme.primary};
    }
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  align-items: center;
  & svg {
    fill: ${({ theme }) => theme.disabled};
  }
  transition: all 0.5s;
  ${({ toggle, field, theme }) => toggle && field === toggle.field && css`
    & svg {
      fill: ${theme.primary};
    }
    ${toggle.order < 0 && css`transform: rotate(180deg) translate(0, -1px);`}
  `}
`;

export const CardIcon = styled.div`
`;

export const OptionsBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const PrimaryInfo = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

export const ExtraInfo = styled.div`
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  ${gap('5px', 'row')}
  & .label {
    font-weight: bold;
  }
`;

export const ExtraInfoLabel = styled(ExtraInfo)`
  font-weight: bold;
`;

export const DataListCard = styled.div`
  position: relative;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
`;
