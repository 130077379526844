import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Popover from '../../core/Popover';
import InputTextLine from '../../core/InputTextLine';
import InputRadio from '../../core/InputRadio';
import Checkbox from '../../core/Checkbox';
import IconButton from '../../core/IconButton';
import { ExpandMore, Finder, Filter } from '../../icons';

import {
  MainContainer,
  SeparatorLine,
  CategoryContainer,
  GroupContainer,
  SearchBarContainer,
  IconWrapper,
  IconFlipWrapper,
} from './styled/SearchFilter.styled';

/**
 * A search bar with an extended filter option
 */
const SearchFilter = ({
  groupList,
  selectedGeneral,
  setSelectedGeneral,
  checkedGroupList,
  setCheckedGroupList,
  textSearch,
  setTextSearch,
}) => {
  const [openPopover, setOpenPopover] = useState(false);

  const theme = useTheme();

  const handleCheckBox = (check, name) => {
    // const auxSet = new Set(checkedGroupList);
    // if (check) delete checkedGroupList[name];
    // else checkedGroupList[name] = true;
    // check ? auxSet.add(name) : auxSet.delete(name);
    setCheckedGroupList((p) => {
      const aux = p;
      if (!check) delete aux[name];
      else aux[name] = true;
      return aux;
    });
  };

  return (
    <Popover
      style={{
        borderRadius: '5px',
        backgroundColor: theme.popoverBackground,
        overflow: 'hidden',
      }}
      offset={[0, 2]}
      open={openPopover}
      stayOpened
      closePopover={() => setOpenPopover(false)}
    >
      <Popover.Action>
        <SearchBarContainer>
          <IconWrapper>
            <Finder />
          </IconWrapper>
          <InputTextLine
            placeholder="Filtre ou pesquise"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            style={{ color: theme.color }}
          />
          <IconButton shape="rounded" onClick={() => setOpenPopover(!openPopover)} style={{ padding: 0 }}>
            <Filter />
            <IconFlipWrapper flip={openPopover}>
              <ExpandMore />
            </IconFlipWrapper>
          </IconButton>
        </SearchBarContainer>
      </Popover.Action>
      <Popover.Content direction="bottom-end">
        <MainContainer>
          <CategoryContainer>
            <span>Geral</span>
            <GroupContainer>
              <InputRadio
                value="all"
                text="Todos os Dashboards"
                color="primary"
                selected={selectedGeneral}
                onChange={setSelectedGeneral}
              />
              <InputRadio
                value="my"
                text="Meus Dashboards"
                color="primary"
                selected={selectedGeneral}
                onChange={setSelectedGeneral}
              />
              <InputRadio
                value="shared"
                text="Compartilhados Comigo"
                color="primary"
                selected={selectedGeneral}
                onChange={setSelectedGeneral}
              />
              <InputRadio
                value="bookmarked"
                text="Dashboards Favoritos"
                color="primary"
                selected={selectedGeneral}
                onChange={setSelectedGeneral}
              />
            </GroupContainer>
          </CategoryContainer>
          {groupList.length > 0 && (
            <>
              <SeparatorLine />
              <CategoryContainer>
                <span>Grupos</span>
                <GroupContainer>
                  {groupList.map((g) => (
                    <Checkbox
                      key={g.label}
                      text={g.label}
                      color="primary"
                      variant="outlined"
                      checked={checkedGroupList[g.value]}
                      handleCheckboxChange={(check) => handleCheckBox(check, g.value)}
                    />
                  ))}
                </GroupContainer>
              </CategoryContainer>
            </>
          )}
        </MainContainer>
      </Popover.Content>
    </Popover>
  );
};

SearchFilter.propTypes = {
  /**
   * Array with the names of the current groups
   */
  groupList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
   * String with the selected radio filter value
   */
  selectedGeneral: PropTypes.string,
  /**
   * Function that deals with changing the value of the radio filter
   */
  setSelectedGeneral: PropTypes.func.isRequired,
  /**
   * A Set collection with the names of the selected checkboxes (iniciate with 'new Set()')
   */
  checkedGroupList: PropTypes.objectOf(PropTypes.bool),
  /**
   * Function that deals with the changes of the checkboxes
   */
  setCheckedGroupList: PropTypes.func.isRequired,
  /**
   * String input of the search bar
   */
  textSearch: PropTypes.string,
  /**
   * Function that deals with the changes on the search bar
   */
  setTextSearch: PropTypes.func.isRequired,
};

SearchFilter.defaultProps = {
  groupList: [],
  selectedGeneral: '',
  checkedGroupList: [],
  textSearch: '',
};

export default SearchFilter;
