import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

//
import IconButton from '../../../juristec-ui/core/IconButton';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import InputLine from '../../../juristec-ui/core/InputTextLine';

import {
  Add,
  ArrowLeft,
  Finder,
} from '../../../juristec-ui/icons';

import {
  Container, Tabs, NavOpt, FirstSpace, SecondSpace, SearchBarContainer, IconWrapper,
} from './styled/AdminToolbar.styled';

function AdminToolbar({
  tab, setTab, handleNewInstance, setFilterText,
}) {
  const history = useHistory();

  const goBack = () => {
    history.push('/home');
  };

  return (
    <Container>
      <FirstSpace>
        <Tooltip text="Voltar" direction="right">
          <IconButton
            variant="contained"
            color="secondary"
            size="medium"
            shape="rounded"
            onClick={goBack}
          >
            <ArrowLeft />
          </IconButton>
        </Tooltip>
        <Tabs>
          <NavOpt active={tab === 'customers'} onClick={() => setTab('customers')}>
            Gerenciar Clientes
          </NavOpt>
          <NavOpt active={tab === 'reports'} onClick={() => setTab('reports')}>
            Relatórios
          </NavOpt>
        </Tabs>
      </FirstSpace>

      {tab === 'customers' && (
        <SecondSpace>
          <div className="gap-fix">
            <SearchBarContainer>
              <IconWrapper>
                <Finder />
              </IconWrapper>
              <InputLine
                placeholder="Buscar instância"
                autoComplete="off"
                onChange={setFilterText}
              />
            </SearchBarContainer>
          </div>

          <Tooltip text="Criar instância" direction="left">
            <IconButton
              variant="contained"
              color="secondary"
              size="medium"
              shape="rounded"
              onClick={handleNewInstance}
              disabled={false}
              className="add_instance"
            >
              <Add />
            </IconButton>
          </Tooltip>
        </SecondSpace>
      )}
    </Container>
  );
}

AdminToolbar.propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func,
  handleNewInstance: PropTypes.func,
  setFilterText: PropTypes.func,
};

AdminToolbar.defaultProps = {
  tab: 'customers',
  setTab: () => {},
  handleNewInstance: () => {},
  setFilterText: () => {},
};

export default AdminToolbar;
