import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondPageContainer = styled(Column)`
  max-width: 600px;
  max-height: calc(100vh - 335px);
  gap: 5px;
`;

export const HeaderTips = styled(Column)`
  gap: 0.8rem;
  font-size: 14px;
  margin-bottom: 5px;
  & span {
    margin: 0;
    &.danger {
      font-weight: bold;
      color: ${({ theme }) => theme.error};
    }
  }
`;

export const VarContainer = styled.div`
  overflow: hidden auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  background-color: ${({ theme }) => theme.containerOdd};
  border-radius: 10px;
  padding: 1rem;
  //gap: 1rem;
`;

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const DescriptionContainer = styled.div`
  position: absolute;
  right: 4px;
  top: -2px;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.info};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: ${({ theme }) => theme.white};
    height: 10px;
    width: 10px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 3px 3px ${({ theme }) => theme.shadow};
    & svg {
      fill: ${({ theme }) => theme.info};
    }
  }
`;

export const DescriptionContent = styled(Column)`
  padding: 10px;
  gap: 5px;
  align-items: center;
  max-width: 200px;
  & * {
    margin: 0;
    white-space: normal;
  }
  & h2 {
    color: ${({ theme }) => theme.secondary};
  }
  & span {
    color: ${({ theme }) => theme.tooltipColor};
  }
`;

export const SeparatorLine = styled.hr`
    margin: 0;
    width: 80%;
    border: 0.8px solid #ddd;
`;