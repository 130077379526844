import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  StyledModalOverlay, StyledModal, StyledModalContent, StyledTypeAlert, StyledTextAlert,
} from './styled/Alert.styled';

import Button from '../Button';
import ActionsGroup from '../ActionsGroup';

import { Error, Warning, Success } from '../../icons';
// import Warning from '../../icons/Warning';
// import Success from '../../icons/Success';

import useOnPressEnter from '../../hooks/useOnPressEnter';

/**
 * Alert
*/
const Alert = ({
  openAlert,
  type,
  children,
  closeAlert,
  text,
  okFunction,
  withoutConfirm,
  withFunction,
  confirmFunction,
  noPadding,
}) => {
  const verifyType = (type) => {
    if (type === 'error') {
      return <Error />;
    }
    if (type === 'warning') {
      return <Warning />;
    }
    return <Success />;
  };

  function internalOkFunction() {
    if (okFunction) okFunction();
    closeAlert();
  }

  function internalConfirmFunction() {
    confirmFunction();
    closeAlert();
  }

  function cancelFunction() {
    closeAlert();
  }

  useOnPressEnter(withoutConfirm, internalOkFunction);

  return (openAlert ? ReactDOM.createPortal(
    <>
      <StyledModalOverlay alert >
        <StyledModal alert aria-modal aria-hidden tabIndex={-1} role="dialog" className="styledModal">
          {type !== '' && (
            <StyledTypeAlert type={type}>
              <div className="circle" />
              {verifyType(type)}
            </StyledTypeAlert>
          )}
          {text !== '' && (
            <StyledTextAlert type={type}>{text}</StyledTextAlert>
          )}
          <StyledModalContent noPadding={noPadding}>
            {children}
          </StyledModalContent>
          {
            !withoutConfirm && (
              <ActionsGroup>
                <Button
                  autoFocus
                  style={{ margin: '5px' }}
                  onClick={internalOkFunction}
                  size="large"
                >
                  OK
                </Button>
              </ActionsGroup>
            )
          }
          {withFunction && (
            <ActionsGroup>
              <Button
                variant="outlined"
                autoFocus
                style={{ margin: '5px' }}
                onClick={cancelFunction}
                size="large"
              >
                Cancelar
              </Button>
              <Button
                autoFocus
                style={{ margin: '5px' }}
                onClick={internalConfirmFunction}
                size="large"
              >
                Confirmar
              </Button>
            </ActionsGroup>
          )}
        </StyledModal>
      </StyledModalOverlay>
    </>, document.body,
  ) : null);
};

Alert.propTypes = {
  /**
  * Text that will be shown as message
  */
  text: PropTypes.string,
  /**
  * Alert type
  */
  type: PropTypes.string,
  /**
  * Alert content
  */
  children: PropTypes.node,
  /**
  * state that controls whether the alert is open / closed
  */
  openAlert: PropTypes.bool,
  /**
  * Show 'confirm' button which receives a confirmFunction function
  */
  withFunction: PropTypes.bool,
  /**
  * controls whether the okButton is shown or not
  */
  withoutConfirm: PropTypes.bool,
  /**
  * function that closes the alert
  */
  closeAlert: PropTypes.func,
  /**
  * function assigned to the confirm button
  */
  confirmFunction: PropTypes.func,
  /**
  * function assigned to the ok button
  */
  okFunction: PropTypes.func,
};

Alert.defaultProps = {
  text: '',
  type: 'success',
  children: <></>,
  openAlert: false,
  withFunction: false,
  withoutConfirm: false,
  closeAlert: () => {},
  confirmFunction: () => {},
  okFunction: () => {},
};

export default Alert;
