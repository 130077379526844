import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import Popover from '../../../juristec-ui/core/Popover';
import IconButton from '../../../juristec-ui/core/IconButton';
import List from '../../../juristec-ui/core/List';
import ListItem from '../../../juristec-ui/core/ListItem';

// icons
import {
  Dashboard,
  Download,
  MoreVert,
  Trash,
  LegalOne,
  Share,
  TransferUser,
  Visibility,
  RemoveFile,
  Rename,
} from '../../../juristec-ui/icons';

// styles
import {
  OptionsContainer,
} from './styled/FileOption.styled';

const FileOptions = ({
  user,
  item,
  viewFile,
  downloadFile,
  deleteFile,
  showFileDashboards,
  size,
  shape,
  convertFileToMail,
  convertFileApiGed,
  userCompany,
  shareFile,
  transferFile,
  selfRemove,
  columnAlias,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const theme = useTheme();

  const handleViewFile = () => viewFile(item);
  const handleDownloadFile = () => downloadFile(item);
  const handleConvertFileToMail = () => convertFileToMail(item);
  const handleConvertFileApiGed = () => convertFileApiGed(item);
  const handleColumnAlias = () => columnAlias(item);
  const handleShareFile = () => shareFile(item);
  const handleTransferFile = () => transferFile(item);
  const handleSelfRemove = () => selfRemove(item);
  const handleShowFileDashboards = () => showFileDashboards(item);
  const handleDeleteFile = () => deleteFile(item);

  return (
    <Popover
      style={{
        borderRadius: '8px',
        backgroundColor: theme.popoverBackground,
        overflow: 'hidden',
      }}
      offset={[0, 0]}
      open={openPopover}
      stayOpened={false}
      closePopover={() => setOpenPopover(false)}
    >
      <Popover.Action>
        <IconButton
          size={size}
          shape={shape}
          style={{ padding: '1px' }}
          onClick={() => setOpenPopover(!openPopover)}
        >
          <MoreVert />
        </IconButton>
      </Popover.Action>
      <Popover.Content>
        <OptionsContainer>
          <List>
            <ListItem onClick={handleViewFile} noOutline>
              <Visibility />
              Visualizar
            </ListItem>
            { (item.owner === user.id || item.shared_write.includes(user.id)) && (
              <ListItem onClick={handleDownloadFile} noOutline>
                <Download />
                Download
              </ListItem>
            )}
            { item.origin !== 'LegalOne'
              && item.origin !== 'LegalOneExtractor'
              && item.origin !== 'L1ReportsApi'
              && item.origin !== 'Mail'
              && process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics'
              && userCompany.l1reportActive
              && user.id === item.owner
              && (
              <>
                <ListItem
                  noOutline
                  onClick={handleConvertFileToMail}
                >
                  <LegalOne color={theme.grey} />
                  Criar Relatório (E-mail)
                </ListItem>
                <ListItem
                  noOutline
                  onClick={handleConvertFileApiGed}
                >
                  <LegalOne color={theme.grey} />
                  Criar Relatório (API GED)
                </ListItem>
              </>
              )}
            {user.id === item.owner
            && (item.origin === 'Benner' || item.connector === 'LegalOneDataExtractor')
            && (
              <ListItem onClick={handleColumnAlias} noOutline>
                <Rename />
                Apelidar Colunas
              </ListItem>
            )}
            {user.id === item.owner && (
              <>
                <ListItem onClick={handleShareFile} noOutline>
                  <Share />
                  Compartilhar
                </ListItem>
                <ListItem onClick={handleTransferFile} noOutline>
                  <TransferUser />
                  Transferir
                </ListItem>
              </>
            )}
            {item?.shared_read.includes(user.id) && (
              <ListItem onClick={handleSelfRemove} noOutline>
                <RemoveFile />
                Sair do compartilhamento
              </ListItem>
            )}
            <ListItem onClick={handleShowFileDashboards} noOutline>
              <Dashboard />
              Dashboards
            </ListItem>
            {userCompany.plan !== 'push' && (
              user.id === item.owner && (
                <ListItem onClick={handleDeleteFile} noOutline>
                  <Trash />
                  Apagar
                </ListItem>
              )
            )}
          </List>
        </OptionsContainer>
      </Popover.Content>
    </Popover>
  );
};

FileOptions.propTypes = {
  /**
   * User Object
   */
  user: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  /**
   * User Object
   */
  item: PropTypes.shape({
    origin: PropTypes.string,
    owner: PropTypes.string,
    shared_read: PropTypes.arrayOf(
      PropTypes.string,
    ),
    shared_write: PropTypes.arrayOf(
      PropTypes.string,
    ),
    connector: PropTypes.string,
  }).isRequired,
  /**
   * Function to show the file data
   */
  viewFile: PropTypes.func.isRequired,
  /**
   * Function to download the file
   */
  downloadFile: PropTypes.func.isRequired,
  /**
   * Function to delete the file
   */
  deleteFile: PropTypes.func.isRequired,
  /**
   * Function to show dashboards related to the file
   */
  showFileDashboards: PropTypes.func.isRequired,
  /**
   * String that determines the size of the icons
   */
  size: PropTypes.string,
  /**
   * String that determines the size of the icons
   */
  shape: PropTypes.string,
  /**
   * Function to create a Legal One report email
   */
  convertFileToMail: PropTypes.func.isRequired,
  /**
   * Function to create a Legal One report API GED
   */
  convertFileApiGed: PropTypes.func.isRequired,
  /**
   * Function to share file from one user to others
   */
  shareFile: PropTypes.func.isRequired,
  /**
   * Function to transfer the ownership from one user to another
   */
  transferFile: PropTypes.func.isRequired,
  /**
   * Function to self remove a user from a shared file
   */
  selfRemove: PropTypes.func.isRequired,
  /**
   * Function to rename columns from a file
   */
  columnAlias: PropTypes.func.isRequired,
  /**
   * String that determines the size of the icons
   */
  userCompany: PropTypes.shape({
    plan: PropTypes.string,
    l1reportActive: PropTypes.bool,
  }).isRequired,
};

FileOptions.defaultProps = {
  size: 'medium',
  shape: 'rounded',
};

export default FileOptions;
