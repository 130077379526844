import React from 'react';

const AddKpi = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.6219 10.4611V0C13.5946 0.217383 15.4657 0.987736 17.0196 2.22228C18.5735 3.45683 19.747 5.10531 20.4047 6.97776C21.0625 8.85022 21.1778 10.8704 20.7374 12.8056C20.6646 13.1251 20.5772 13.4403 20.4757 13.75H19.7188V11.1562C19.7188 10.0517 18.8233 9.15625 17.7188 9.15625C16.6142 9.15625 15.7188 10.0517 15.7188 11.1562V13.75H13.125C12.0204 13.75 11.125 14.6454 11.125 15.75C11.125 16.8546 12.0204 17.75 13.125 17.75H15.7188V19.5979C14.8142 20.1181 13.833 20.5035 12.8056 20.7374C10.8704 21.1778 8.85022 21.0625 6.97776 20.4047C5.10531 19.747 3.45683 18.5735 2.22228 17.0196C0.987736 15.4657 0.217383 13.5946 0 11.6219H10.4611C11.1022 11.6219 11.6219 11.1022 11.6219 10.4611ZM3.02008 3.02008C4.71182 1.32834 6.92926 0.263176 9.30723 0V9.30723H0C0.263176 6.92926 1.32834 4.71182 3.02008 3.02008Z"/>
            <path d="M17.7188 20.3438V11.1562M13.125 15.75H22.3125" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default AddKpi;