/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import fontOptions from '../utils/controllersUtils/options';

import ControllersGroup from './ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  ButtonLineController,
  SelectController,
  // RangeController,
  // ChoiceBoxController,
} from './Base';

import TableControllers from '../charts/Table/controllers';
import ValueControllers from '../charts/Value/controllers';
import BarControllers from '../charts/Bar/controllers';
import LineControllers from '../charts/Line/controllers';
import PieControllers from '../charts/Pie/controllers';
import RadarControllers from '../charts/Radar/controllers';
import MapControllers from '../charts/Map/controllers';
import FunnelControllers from '../charts/Funnel/controllers';
import RadialBarControllers from '../charts/RadialBar/controllers';
import CalendarControllers from '../charts/Calendar/controllers';
import WaffleControllers from '../charts/Waffle/controllers';

const controllersGallery = {
  Bar: BarControllers,
  Pie: PieControllers,
  Line: LineControllers,
  Value: ValueControllers,
  Radar: RadarControllers,
  Map: MapControllers,
  Funnel: FunnelControllers,
  RadialBar: RadialBarControllers,
  Table: TableControllers,
  Calendar: CalendarControllers,
  Waffle: WaffleControllers,
};

const getControllersByType = (type) => (controllersGallery[type] || null);

const CustomSide = ({
  type,
  handle,
  config,
  data,
  selectedLine,
  selectedColumn,
  selectedValue,
  selectedFunction,
}) => {
  const [extend, setExtend] = useState('title');

  useEffect(() => {
    setExtend('title');
  }, [type]);

  const KpiControllers = getControllersByType(config.type);

  return (data && config ? (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      gap: '2px',
    }}
    >
      <ControllersGroup title="Título" id="title" extend={extend} setExtend={setExtend}>
        <InputLineController
          label="Título do KPI"
          handle={handle}
          configValue={config?.TitleControl}
          kWord="TitleControl"
          fullWidth
        />
        <ToggleSwitchController
          label="Mostrar Título"
          handle={handle}
          kWord="ShowTitleControl"
          configValue={config?.ShowTitleControl?.checked}
          firstOption=""
          secondOption=""
          fullWidth
        />
        <SelectController
          label="Fonte"
          handle={handle}
          kWord="TitleFontFamily"
          options={fontOptions}
          configValue={config?.StyleTitleControl?.fontFamily}
          disabled={!config?.ShowTitleControl?.checked}
        />
        <InputLineController
          label="Tamanho"
          handle={handle}
          kWord="TitleFontSize"
          configValue={config?.StyleTitleControl?.fontSize}
          min={1}
          max={80}
          step={1}
          precision={0}
          isNumber
          disabled={!config?.ShowTitleControl?.checked}
        />
        <ButtonLineController
          handle={handle}
          boldKWord="TitleBold"
          boldVal={config?.StyleTitleControl?.bold}
          italicKWord="TitleItalic"
          italicVal={config?.StyleTitleControl?.italic}
          underlineKWord="TitleUnderline"
          underlineVal={config?.StyleTitleControl?.underline}
          fontColorKWord="TitleFontColor"
          fontColorVal={config?.StyleTitleControl?.color}
          disabled={!config?.ShowTitleControl?.checked}
          fullWidth
        />
      </ControllersGroup>
      {KpiControllers ? (
        <KpiControllers
          handle={handle}
          config={config}
          data={data}
          extend={extend}
          setExtend={setExtend}
          selectedValue={selectedValue}
          selectedLine={selectedLine}
          selectedColumn={selectedColumn}
          selectedFunction={selectedFunction}
        />
      ) : <></>}
    </div>
  ) : <></>
  );
};

CustomSide.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  type: PropTypes.string,
  selectedColumn: PropTypes.objectOf(PropTypes.any),
  selectedValue: PropTypes.objectOf(PropTypes.any),
  selectedLine: PropTypes.objectOf(PropTypes.any),
  selectedFunction: PropTypes.objectOf(PropTypes.any),
};

CustomSide.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  type: '',
  selectedColumn: undefined,
  selectedValue: undefined,
  selectedLine: undefined,
  selectedFunction: undefined,
};

export default CustomSide;
