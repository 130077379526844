import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import PublicGridKpisCard from '../../../juristec-ui/kpis/grid/PublicGridKpisCard';
import KpiViewer from '../../KpiViewer';
import { ModalContext } from '../../../context/ModalProvider';

// styles
import {
  StyledResponsiveReactGridKpiLayout,
  StyledOuterGridKpiLayout,
  StyledCustomDiv,
} from './styled/GridKpisLayout.styled';

import '../../../juristec-ui/styles/react-grid-layout.css';
import '../../../juristec-ui/styles/react-resizable.css';

// ReactGridLayout variables
const rglCols = {
  lg: 48,
  md: 20,
  sm: 12,
  xs: 8,
  xxs: 4,
};

const breakpoints = {
  lg: 960,
  sm: 600,
  xxs: 0,
};

export default function GridKpis({
  // editPainel,
  // saveLayout,
  kpiItemList,
  dashboardDoc,
  kpisLayout,
}) {
  const { setModalConfig, closeModal } = useContext(ModalContext);

  const [gridBreakpoint, setGridBreakpoint] = useState('lg');
  const getSpaceBetween = (obj) => {
    if (obj) return [+obj.spaceBetween || 0, +obj.spaceBetween || 0];

    return [10, 10];
  };

  const holdBreakpoint = (bk) => {
    setGridBreakpoint(bk);
  };

  const getLayoutOrder = useMemo(() => {
    const tempL = [
      ...kpisLayout[gridBreakpoint],
    ].filter((l) => !!kpiItemList.find((k) => k.id === l.i));
    const qtdCols = rglCols[gridBreakpoint];
    return tempL.sort((a, b) => a.y * qtdCols + a.x >= b.y * qtdCols + b.x);
  }, [gridBreakpoint, kpisLayout]);

  const getKpiSize = (kpiId) => {
    const nodeKpi = document.getElementById(kpiId);
    return { h: nodeKpi?.clientHeight || 0, w: nodeKpi?.clientWidth || 0 };
  };

  const openKpiViewer = (id) => {
    setModalConfig({
      title: '',
      transparent: true,
      children: (
        <KpiViewer
          show
          close={closeModal}
          kpiList={kpiItemList}
          kpiId={id}
          layout={getLayoutOrder}
          getKpiSize={getKpiSize}
          cardStyles={dashboardDoc?.cardStyles}
          // setKpi={openKpiViewer}
        />
      ),
    });
  };

  const kpiQtd = useMemo(() => (
    kpiItemList?.filter((kpi) => !['label', 'Value'].includes(kpi.type)).length || 1
  ), [kpiItemList]);

  return (
    <StyledOuterGridKpiLayout editPainel={false}>
      <StyledResponsiveReactGridKpiLayout
        editPainel={false}
        isResizable={false}
        isDraggable={false}
        margin={getSpaceBetween(dashboardDoc?.cardStyles)}
        breakpoints={breakpoints}
        cols={rglCols}
        rowHeight={20}
        layouts={kpisLayout}
        onLayoutChange={() => {}}
        compactType="vertical"
        preventCollision={false}
        useCSSTransforms={false}
        measureBeforeMount={false}
        onBreakpointChange={holdBreakpoint}
      >
        {kpiItemList.map((item) => (
          <StyledCustomDiv key={item.id} cardStyles={dashboardDoc?.cardStyles}>
            <PublicGridKpisCard item={item} openKpiViewer={openKpiViewer} kpiQtd={kpiQtd} />
          </StyledCustomDiv>
        ))}
      </StyledResponsiveReactGridKpiLayout>
    </StyledOuterGridKpiLayout>
  );
}

GridKpis.propTypes = {
  kpiItemList: PropTypes.arrayOf(PropTypes.shape({})),
  dashboardDoc: PropTypes.objectOf(PropTypes.any),
  kpisLayout: PropTypes.shape({}),
};

GridKpis.defaultProps = {
  dashboardDoc: {},
  kpisLayout: {},
  kpiItemList: [],
};
