import styled, { keyframes, css } from 'styled-components';
import { devices } from '../../breakpoints';

const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${(props) => (props.alert ? '1050' : '1040')};
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledModal = styled.div`
    z-index: ${({ alert }) => (alert ? '110' : '100')};
    background: ${({ theme }) => theme.modal};
    position: relative;
    border-radius: 20px;
    height: auto;
    max-height: 90vh;
    overflow: hidden;
    outline: none;
    padding: ${(props) => (props.alert ? '15px' : '0px')};
    transition: all 500ms;

    max-width: 50vw;
    min-width: ${(props) => (props.minWidth ? 'auto' : '25vw')};
    width: auto;

    @media ${devices.desktopS} {
        max-width: 85vw;
        min-width: ${(props) => (props.minWidth ? 'auto' : '30vw')};
        width: auto;
    }

    @media ${devices.tablet} {
        max-width: 90vw;
        min-width: ${(props) => (props.minWidth ? 'auto' : '40vw')};
        width: auto;
    }

    @media ${devices.phoneL} {
        max-width: 90vw;
        min-width: ${(props) => (props.minWidth ? 'auto' : '40vw')};
        width: auto;
    }

    @media ${devices.phoneS} {
        max-width: 95vw;
        min-width: ${(props) => (props.minWidth ? 'auto' : '40vw')};
        width: 95vw;
    }
`;

// justify-content: flex-end;
const StyledModalHeader = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.modalHeader};
    height: 50px;
    border-radius: 20px 20px 0px 0px;
    align-items: center;
    position: relative;
    span {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: ${({ theme }) => theme.white};
        cursor: pointer;    
        margin: 0.25rem;
        padding: 0px 30px;
        text-align: center;
    }
    button {
        position: absolute;
        right: 5px;
        top: 5px;
    }
`;

const StyledModalContent = styled.div`
    word-break: break-word;
    max-height: ${(props) => (props.notLabel === '' && props.yesLabel === '' ? 'calc(90vh - 85px)' : 'calc(90vh - 121px)')};
    overflow: auto;
    box-sizing: border-box;
    padding: ${(props) => (props.noPadding ? '0px' : '5px 10px')};
`;

const StyledModalCloseButton = styled.div`
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    color: ${({ theme }) => theme.white};
    cursor: pointer;    
    margin: 0.25rem;
`;

const spin = keyframes`
  100% { 
    transform: rotateZ(360deg);
  }
`;

const types = {
  error: { border: '2.5px solid #CA0B00', 'background-color': '#CA0B00', color: '#CA0B00' },
  warning: { border: '2.5px solid #FF8800', 'background-color': '#FF8800', color: '#FF8800' },
  success: { border: '2.5px solid #4BB543', 'background-color': '#4BB543', color: '#4BB543' },
  getType(type) {
    return this[type] || '';
  },
};

const StyledTypeAlert = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        position: absolute;
        color: ${({ theme }) => theme.white};
        width: 55px;
        height: 55px;
        top: 50px;
        left: 50px;
        transform: translate(-50%, -50%);
    }

    & .circle {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        padding: 2.5px;
        background-clip: content-box;
        /* animation: ${spin} 10s linear infinite; */
        
        ${(props) => css`
            border: ${types.getType(props.type).border};
            background-color: ${types.getType(props.type)['background-color']};
        `}
    }

    /* &:active .circle {
        animation: ${spin} 2s linear infinite;
    } */
`;

const StyledTextAlert = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 0px 1px 0px ${({ theme }) => theme.shadow};;
    ${(props) => css`
        color: ${types.getType(props.type).color};
    `}
`;

const StyledModalData = StyledModal;

export {
  StyledModalOverlay,
  StyledModal,
  StyledModalHeader,
  StyledModalCloseButton,
  StyledTypeAlert,
  StyledModalContent,
  StyledTextAlert,
};
export default StyledModalData;
