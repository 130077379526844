/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../juristec-ui/core/DatePicker';
import Select from '../../juristec-ui/core/Select';
import IconButton from '../../juristec-ui/core/IconButton';
import InputTextLine from '../../juristec-ui/core/InputTextLine';

import { trimString } from '../../juristec-ui/utils/functions/formatString';

import { Add, Close } from '../../juristec-ui/icons';

import {
  RowContainer,
  BadgeContainer,
  ScrollContainer,
  FilterBadge,
  BadgeClose,
} from './styled/KpiFilter.styled';
import 'react-datepicker/dist/react-datepicker.css';

const FilterByValues = ({ options, values, handle }) => (
  <Select
    isSearchable
    selectLabel="Valores"
    onChange={handle}
    value={values}
    options={options}
    menuPlacement="auto"
    maxMenuHeight={150}
    menuPosition="fixed"
    formatOptionLabel={false}
    allowSelectAll
    alphabeticalOrder
    isMult
    closeMenuOnSelect={false}
    fullWidth
  />
);

const FilterByN = ({ values, handle }) => (
  <RowContainer>
    <InputTextLine
      label="Valor de N"
      value={values[0]?.toString().replace('.', ',')}
      onChange={(e) => handle(trimString(e.target.value))}
    />
  </RowContainer>
);

const FilterBetweenAB = ({ values, handle }) => (
  <RowContainer>
    <InputTextLine
      label="Valor de A"
      value={values[0]?.toString().replace('.', ',')}
      onChange={(e) => handle({ start: trimString(e.target.value) })}
    />
    <InputTextLine
      label="Valor de B"
      value={values[1]?.toString().replace('.', ',')}
      onChange={(e) => handle({ end: trimString(e.target.value) })}
    />
  </RowContainer>
);

const FilterBetweenDates = ({ values, handle }) => (
  <RowContainer>
    <DatePicker
      value={values[0]}
      onChange={(val) => handle({ start: val })}
      label="Valor de A"
      startDate={values[0]}
      endDate={values[1]}
      maxDate={values[1]}
    />
    <DatePicker
      value={values[1]}
      onChange={(val) => handle({ end: val })}
      label="Valor de B"
      startDate={values[0]}
      endDate={values[1]}
      minDate={values[0]}
    />
  </RowContainer>
);

const FilterByNDate = ({ values, handle }) => (
  <RowContainer>
    <DatePicker
      value={values[0]}
      onChange={(val) => handle(val)}
      label="Valor de N"
    />
  </RowContainer>
);

const FilterBySearch = ({ values, handle }) => {
  const [inputVal, setInputVal] = useState('');
  const handleInput = (e) => setInputVal(e.target.value);

  const addVal = () => {
    const val = inputVal.trim();
    if (inputVal.trim().length > 0) {
      handle({ val, operation: 'add' });
      setInputVal('');
    }
  };

  const delVal = (val) => {
    handle({ val, operation: 'delete' });
  };

  const keyHandle = (e) => {
    if (e.key === 'Enter') addVal();
  };

  return (
    <>
      <RowContainer>
        <InputTextLine
          label="Valor de busca"
          value={inputVal}
          onChange={handleInput}
          onKeyDown={keyHandle}
        />
        <IconButton
          color="secondary"
          shape="rounded"
          variant="contained"
          onClick={addVal}
          style={{ height: '35px', width: '35px', margin: 'auto' }}
        >
          <Add />
        </IconButton>
      </RowContainer>
      <ScrollContainer>
        <BadgeContainer>
          {values.map((val, i) => (
            <FilterBadge
              key={`${val}${i}`}
              color="secondary"
              textTransform="uppercase"
              size="small"
            >
              <span>{val}</span>
              <BadgeClose onClick={() => delVal(val)}>
                <Close />
              </BadgeClose>
            </FilterBadge>
          ))}
        </BadgeContainer>
      </ScrollContainer>
    </>
  );
};

const KpiFilter = ({
  filterType, options, values, handle, format,
}) => {
  if (filterType !== 'values' && filterType !== 'not-values' && format === 'datetime64[ns]') {
    if (values.length <= 0) return <></>;
    switch (filterType) {
      case 'not-after':
      case 'after':
      case 'not-before':
      case 'before':
      case 'not-after_inc':
      case 'after_inc':
      case 'not-before_inc':
      case 'before_inc':
        return <FilterByNDate values={values} handle={handle} />;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        return <FilterBetweenDates values={values} handle={handle} isDate />;
      default:
        return <FilterByN values={values} handle={handle} />;
    }
  } else {
    switch (filterType) {
      case 'not-values':
      case 'values':
        return <FilterByValues options={options} values={values} handle={handle} />;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        return <FilterBetweenAB values={values} handle={handle} />;
      case 'not-contains':
      case 'contains':
        return <FilterBySearch values={values} handle={handle} />;
      default:
        return <FilterByN values={values} handle={handle} />;
    }
  }
};

KpiFilter.propTypes = {
  filterType: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  values: PropTypes.arrayOf(PropTypes.any),
  handle: PropTypes.func,
  format: PropTypes.string,
};

KpiFilter.defaultProps = {
  filterType: 'values',
  options: [],
  values: [],
  handle: () => {},
  format: '',
};

export default KpiFilter;
