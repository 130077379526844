/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import Bar from './Bar';
import Pie from './Pie';
import Line from './Line';
import Value from './Value';
import Radar from './Radar';
import Map from './Map';
import Funnel from './Funnel';
import RadialBar from './RadialBar';
import Table from './Table';
import Calendar from './Calendar';
import Waffle from './Waffle';

const chartGallery = {
  Bar,
  Pie,
  Line,
  Value,
  Radar,
  Map,
  Funnel,
  RadialBar,
  Table,
  Calendar,
  Waffle,
};

function getChart(type) {
  return chartGallery[type] || null;
}

const Chart = ({
  type, data, styleConfig, kpiSize, handleStyleConfig, isEdit, valueFunction,
}) => {
  const Component = getChart(styleConfig?.type || type);
  return (Component) ? (
    <Component
      data={data}
      styleConfig={styleConfig}
      kpiSize={kpiSize}
      handleStyleConfig={handleStyleConfig}
      isEdit={isEdit}
      valueFunction={valueFunction}
    />
  ) : null;
};

export default Chart;
