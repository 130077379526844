import React from 'react';
import PropTypes from 'prop-types';
import StyledListItem from './styled/ListItem.styled';

// import { Container } from './styles';

function ListItem({
  children, active, closeIconStyle, noOutline, withoutIconStyle, ...props
}) {
  return (
    <StyledListItem
      noOutline={noOutline}
      active={active}
      withoutIconStyle={withoutIconStyle}
      closeIconStyle={closeIconStyle}
      {...props}
    >
      {children}
    </StyledListItem>
  );
}

ListItem.propTypes = {
  /**
  * ---
  */
  active: PropTypes.bool,
  /**
  * List content
  */
  children: PropTypes.node.isRequired,
  /**
  * ---
  */
  closeIconStyle: PropTypes.bool,
  /**
  * Draw a line outside the element
  */
  noOutline: PropTypes.bool,
  /**
  * signals if the component will receive an icon and changes svg props
  */
  withoutIconStyle: PropTypes.bool,
};

ListItem.defaultProps = {
  active: false,
  closeIconStyle: false,
  noOutline: false,
  withoutIconStyle: false,
};

export default ListItem;
