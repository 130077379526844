export default {
  TitleControl: '',
  ShowTitleControl: { checked: true },
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  ShowZeroValuesControl: { checked: true },
  Margin: {
    top: 50,
    right: 110,
    bottom: 50,
    left: 60,
  },
  MarginTop: 50,
  MarginRight: 110,
  MarginBottom: 50,
  MarginLeft: 60,
  AreaControl: { checked: false, opacity: 0.2 },
  DataFormat: { type: 'absolute', target: 'global' },

  // POINTS
  EnablePointsControl: {
    checked: true,
    pointsSize: 10,
    border: 2,

  },
  DotsLabel: {
    fontSize: 10,
    color: '#222222',
    fontFamily: 'sans-serif',
  },
  LineWidth: 2,
  PointsColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  LabelsColorControl: '#333333',
  EnableLabelControl: { checked: true },
  LinesFormatControl: 'linear',
  EnableGridX: false,
  EnableGridY: false,

  XScale: { type: 'point' },
  YScale: {
    type: 'linear',
    min: 'auto',
    max: 'auto',
    stacked: true,
    reverse: false,
  },
  MinValueControl: { checked: true }, // true = auto | false = number
  MaxValueControl: { checked: true },
  PaddingControl: { value: 0.1 },
  InnerPaddingControl: { value: 0 },

  PointSize: 10,
  PointColor: { theme: 'background' },
  PointBorderWidth: 2,
  PointBorderColor: { from: 'serieColor' },
  PointLabel: 'y',
  PointLabelYOffset: -12,

  AxisTop: null,
  AxisRight: null,
  EnableAxisX: {
    axisTop: false,
    axisBot: true,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 35,
    separadorNumerico: true,
  },
  EnableAxisY: {
    axisLeft: true,
    axisRight: false,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -55,
    separadorNumerico: true,
  },
  AxisGlobal: {
    fontSize: 12,
    fontFamily: 'arial',
    legendFontSize: 12,
    color: '#222',
  },
  EnableSeparator: { checked: true },
  LegendControl: { checked: true },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendPlacementControl: {
    value: {
      // PARA USAR NO SELECT DE POSICAO...
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      // anchor: 'bottom',
      translateX: 110,
      translateY: 0,
      // translateX: -92,
      // translateY: 45,
    },
  },
  LegendsSpacingControl: { value: 10 },

  LabelTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  SliceTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  ColorTheme: { scheme: 'nivo' },
  Colors: {},
  PalletsControl: 'default', // || 'custom' // variável para poder selecionar palletas do nivo ou poder setar cada cor pessoalmente...
  //
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  LabelGroupingControl: '',
  LabelCurrencyControl: 'Nenhum',
  LabelsSeparadorNumerico: true,
  LabelAxisSelect: 'y',
  LabelRotationControl: 0,

  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  Animate: false,
  MotionConfig: 'default',

  GroupingSelectControl: '', // axis
  CurrencyControl: 'Nenhum',
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  legendClick: [],
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },
  // Marker
  /* ShowMarkerControl: false,
  MarkerValueControl: 0,
  MarkerLineColorControl: '#000',
  MarkerLineSizeControl: 2,
  MarkerLineStyleControl: 'normal',
  MarkerNameControl: '',
  MarkerOrientationControl: 'horizontal',
  MarkerPositionControl: 'top',
  MarkerTextColorControl: '#000',
  MarkerItalicControl: false,
  MarkerBoldControl: false,
  MarkerFontSizeControl: 12,
  MarkerFontFamilyControl: 'arial', */
};
