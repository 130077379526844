import styled, { css } from 'styled-components';

// function hexToRgb(hex, opacity) {
//   let h = hex.replace('#', '');
//   h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'));

//   if (h.length) {
//     h.forEach((item, i) => {
//       h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
//     });
//   }

//   if (typeof opacity !== 'undefined') h.push(opacity);

//   return `rgba(${h.join(',')})`;
// }

const StyledCardKpiTitle = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;    
  //overflow: hidden;
  background-color: ${(props) => props.background};
  ${(props) => props.style && css`
    span {
      font-size: ${props.style.fontSize}px;
      color: ${props.style.color};
      font-family: '${props.style.fontFamily}', Arial;
      ${props.style.bold && 'font-weight: bold'};
      ${props.style.underline && 'text-decoration: underline'};
      ${props.style.italic && 'font-style: italic'};
    }
  `}
`;

const StyledCardKpiContent = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  ${(props) => (props.type === 'Table' ? css`
      & > div:first-child {
        overflow-y: auto;
        height: 100%;
      }
  ` : '')}
`;

const StyledCardKpi = styled.div`
  border: 0px;  
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;

  ${StyledCardKpiTitle} {
    width: 100%;
    height: ${({ titleFontSize }) => `max(${titleFontSize}px, 10%)`};
    
    writing-mode: horizontal-tb;
    text-orientation: mixed;
  }

  ${StyledCardKpiContent} {
    width: 100%;
    height: ${({ titleFontSize, showTitle }) => (showTitle ? css`
      calc(100% - max(${titleFontSize}px, 10%))
    ` : '100%')};
  }
`;

export {
  StyledCardKpi, StyledCardKpiTitle, StyledCardKpiContent,
};
