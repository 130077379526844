import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Select from '../../../../juristec-ui/core/Select';
import Popover from '../../../../juristec-ui/core/Popover';
import { Question } from '../../../../juristec-ui/icons';

import {
  HeaderTips,
  SecondPageContainer,
  VarContainer,
  SelectContainer,
  IconWrapper,
  DescriptionContainer,
  DescriptionContent,
} from './styled/SecondStep.styled';

/**
 * Second step for the 'ApplyTemplate'. This is where the binding between columns and variables are made.
 */
const SecondStep = ({
  selected,
  fileColumns,
  columnVarBinds,
  setColumnVarBinds,
}) => {

  const theme = useTheme();

  const [showDescription, setShowDescription] = useState(
    Object.keys(selected.columnsRequired).reduce((states, key) => {
      states[key] = false;
      return states;
    }, {}),
  );

  const handlePopoverDesc = (variable, isOpen) => {
    setShowDescription((old) => ({ ...old, [variable]: isOpen }));
  };

  const handleBinding = (variable, column) => {
    setColumnVarBinds((old) => ({ ...old, [variable]: column.value }));
  };
  function checkTypeColumn(el, columnType) {
    if (columnType === 'object' || columnType === 'category') {
      if (el.type === 'category' || el.type === 'object') return true;
      return false;
    }
    return el.type === columnType;
  }

  function isSelected(el, variable) {
    let aux = true;
    Object.keys(columnVarBinds).forEach((v) => {
      // n tirar opção da coluna da qual o valor foi selecionado. só dos outros selects
      if (v === variable) return;
      if (columnVarBinds[v] === el.value) aux = false;
    });

    return aux;
  }

  const checkColumn = (el, variable) => {
    if (!isSelected(el, variable)) return false;
    return checkTypeColumn(el, selected.columnsRequired[variable].type);
  };

  return (
    <SecondPageContainer>
      <HeaderTips>
        <span>
          Selecione as colunas do seu arquivo correspondentes as variáveis
          necessárias para a aplicação do template.
        </span>
        <span>
          <span className="danger">Observação: </span>
          A associação de variáveis
          que possuem outro significado em seu arquivo, pode gerar indicadores
          errados
        </span>
      </HeaderTips>
      <VarContainer>
        {Object.keys(selected.columnsRequired).map((variable) => (
          <SelectContainer key={variable}>
            <DescriptionContainer>
              <Popover
                open={showDescription[variable]}
                style={{
                  zIndex: '1050',
                  backgroundColor: theme.tooltipBackground,
                  borderTopRightRadius: '0',
                }}
                offset={[-18, 2]}
              >
                <Popover.Action>
                  <IconWrapper
                    onMouseEnter={() => handlePopoverDesc(variable, true)}
                    onMouseLeave={() => handlePopoverDesc(variable, false)}
                  >
                    <Question />
                  </IconWrapper>
                </Popover.Action>
                <Popover.Content>
                  <DescriptionContent>
                    {/* <h2>{variable}</h2>
                    <SeparatorLine /> */}
                    <span>{selected.columnsRequired[variable]?.description}</span>
                  </DescriptionContent>
                </Popover.Content>
              </Popover>
            </DescriptionContainer>
            <Select
              placement="start"
              selectLabel={variable}
              options={fileColumns.filter((el) => checkColumn(el, variable))}
              value={{
                id: columnVarBinds[variable],
                label: columnVarBinds[variable],
                value: columnVarBinds[variable],
              }}
              onChange={(columnSelected) => handleBinding(variable, columnSelected)}
              placeholder=""
              alphabeticalOrder
              isSearchable
              fullWidth
              atModal
            />
          </SelectContainer>
        ))}
      </VarContainer>
    </SecondPageContainer>
  );
};

SecondStep.propTypes = {
  /**
   * Array of columns names of the selected file
   */
  fileColumns: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
  })).isRequired,
  /**
   * Object with the association between column name and template variable
   */
  columnVarBinds: PropTypes.objectOf(PropTypes.string.isRequired),
  /**
   * Function that deals with the association between column name and template variable
   */
  setColumnVarBinds: PropTypes.func.isRequired,
  /**
   * Template selected
   */
  selected: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tutorial: PropTypes.string,
    category: PropTypes.string,
    difficulty: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    imageSrc: PropTypes.string,
    columnsRequired: PropTypes.objectOf(PropTypes.shape({
      description: PropTypes.string,
      type: PropTypes.string,
    })),
  }).isRequired,
};

export default SecondStep;
