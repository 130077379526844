import React from 'react';
import {
  useParams, useHistory,
} from 'react-router-dom';

import urlHash from '../../utils/functions/urlHash';

import LabelCreate from '../../components/LabelCreate';

export default function LabelCreatePage() {
  const history = useHistory();
  const { hash } = useParams();

  const getParams = () => {
    const str = urlHash(hash, true).split('/');
    return { dashboardKey: str[0], kpiKey: str[1] };
  };
  const { dashboardKey, kpiKey } = getParams();

  return (
    <>
      <LabelCreate
        dashboardKey={dashboardKey}
        goBack={() => history.goBack()}
        kpiKey={kpiKey}
      />
    </>
  );
}
