export default {
  // TITLE
  TitleControl: '',
  ShowTitleControl: { checked: true },
  Colors: {},

  // STLYES...
  ShowZeroValuesControl: { checked: true },
  SortValuesControl: 'Não ordenado',
  Margin: {
    top: 50,
    right: 130,
    bottom: 50,
    left: 70,
  },

  DataFormat: { type: 'absolute', target: 'global' },
  MarginTop: 50,
  MarginRight: 110,
  MarginBottom: 50,
  MarginLeft: 60,

  RotationControl: { lambda: 50, phy: 15, gamma: 0 },
  ProjectionLambda: 50,
  ProjectionPhy: 16,
  ReverseControl: { checked: false },
  MinValueControl: { value: 0, checked: true, pai: true },
  MaxValueControl: { value: 0, checked: true, pai: true },
  PaddingControl: { value: 0.1 },
  InnerPaddingControl: { value: 0 },

  EnableGridX: false,
  EnableGridY: true,
  AxisGlobal: {
    fontSize: 12,
    fontFamily: 'arial',
    legendFontSize: 12,
  },

  BorderRadiusControl: { value: 0 },
  BorderWidthControl: { value: 0 },
  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },

  LegendControl: { checked: true },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendPlacementControl: {
    value: {
      // PARA USAR NO SELECT DE POSICAO...
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      // anchor: 'bottom',
      // translateX: 110,
      // translateY: 0,
      translateX: -92,
      translateY: 45,
    },
  },
  LegendsSpacingControl: { value: 10 },

  SliceTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },

  ColorTheme: { scheme: 'nivo' },
  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  // LABEL
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  LabelGroupingControl: '',
  LabelCurrencyControl: 'Nenhum',
  LabelsSeparadorNumerico: true,
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelsFontSizeControl: { value: 12 },
  LabelRotationControl: 0,
  EnableLabelControl: { checked: true },
  LabelTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  LabelSkipWidthControl: { value: 0 },
  LabelSkipHeightControl: { value: 0 },
  // ----                   -----  //
  CurrencyControl: 'Nenhum',
  EnableSeparator: { checked: true },
  ColorByControl: 'id',
  PalletsControl: 'default',
  GroupingSelectControl: '', // axys
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  BordeColorMapsControl: '',
  StateControl: 'Brasil',
  ProjectionScaleControl: { value: 100 },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },

};
