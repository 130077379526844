/**
 * Splits a string by whitespace and joins with a custom spacer
 * @param {string} str raw string
 * @param {string} joinSpacer custom spacer
 * @returns formatted string
 */
function removeWhiteSpaces(str, joinSpacer = ' ') {
  if (str) return str.split(' ').filter((v) => v).join(joinSpacer);
  return '';
}

/**
 * Removes unnecessary whitespace from a string
 * @param {string} str raw string
 * @returns formatted string
 */
export const trimString = (str) => removeWhiteSpaces(str);

/**
 * Removes all whitespace from a string
 * @param {string} str raw string
 * @returns formatted string
 */
export const compactString = (str) => removeWhiteSpaces(str, '');
