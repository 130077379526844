import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import { ManageStoryContainer } from './styled/ManageStory.styled';

/**
* A component for creating or editing a story. The mode is changed from create to edit by informing the non-empty attribute 'storyName'
*/
const ManageStory = ({
  snapshot,
  closeModal,
  submitStoryName,
  deleteStory,
}) => {
  const isEdit = snapshot.name && snapshot.name.length > 0;
  const [name, setName] = useState(
    { value: isEdit ? snapshot.name : '', error: !isEdit, errorMsg: '' },
  );
  const inputRef = useRef();

  const trimName = (event) => {
    const { value } = event.target;
    setName((p) => ({
      ...p,
      value: value.split(' ').filter((v) => v).join(' '),
    }));
  };

  const handleNameInput = (val) => {
    const msg = val.trim().length === 0 ? "O campo 'Nome' não pode estar vazio" : '';
    setName({
      value: val,
      error: msg.length > 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name.error) {
      await submitStoryName(inputRef.current.value, snapshot.snapId);
    } else {
      handleNameInput(name.value);
    }
  };

  return (
    <>
      <ManageStoryContainer>
        {isEdit
          ? <span className="date">{new Date(snapshot.snapId).toLocaleString('pt-BR')}</span>
          : (
            <span className="tip">
              Adicione um story para salvar uma configuração de layout do seu dashboard.
            </span>
          )}
        <div style={{display: 'flex'}}>
          <InputTextLine
            label="Nome"
            ref={inputRef}
            errorMessage={name.errorMsg}
            error={name.errorMsg.length > 0}
            value={name.value}
            setValue={handleNameInput}
            emojiPicker
            emojiPickerPosition="top-end"
            onChange={(e) => handleNameInput(e.target.value)}
            onBlur={trimName}
          />
        </div>
      </ManageStoryContainer>
      <ActionsGroup>
        {isEdit && (
          <Button
            style={{ margin: '5px' }}
            onClick={() => deleteStory(snapshot)}
            variant="pattern"
            size="large"
          >
            Apagar
          </Button>
        )}
        <Button
          style={{ margin: '5px' }}
          onClick={closeModal}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
        >
          {isEdit ? 'Salvar' : 'Adicionar'}
        </Button>
      </ActionsGroup>
    </>
  );
};

ManageStory.propTypes = {
  /**
    * The current snapshot when it is available
    */
  snapshot: PropTypes.object,
  /**
    * Function responsible to close the modal
    */
  closeModal: PropTypes.func.isRequired,
  /**
    * Function that sends the story name back to the parent to create or update
    */
  submitStoryName: PropTypes.func.isRequired,
  /**
    * Function that handles the deletion of a story
    */
  deleteStory: PropTypes.func,

};

ManageStory.defaultProps = {
//   storyName: '',
  deleteStory: () => {},
  snapshot: { },
};

export default ManageStory;
