import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../juristec-ui/core/DataTable';
import Popover from '../../juristec-ui/core/Popover';
import IconButton from '../../juristec-ui/core/IconButton';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import Select from '../../juristec-ui/core/Select';

import {
  DateIcon, ExpandMore, Last, Letters, Next, Number,
} from '../../juristec-ui/icons';
import {
  MainContainer,
  ScrollContainer,
  IconWrapper,
  IconFlipWrapper,
  PaginationContainer,
  LegendsContainer,
} from './styled/FileViewData.styled';

import { csvDateFormatOptions, decimalOptions } from '../../options';

const typeColor = {
  float: 'typeNumber',
  abc: 'typeText',
  date: 'typeDate',
};

const typeIcon = {
  float: <Number />,
  abc: <Letters />,
  date: <DateIcon />,
};

/**
 * A component to display the file data
 */
const FileViewData = ({
  fileData,
  columnTypeChange,
  page,
  setPage,
  fileLength,
  canManipulate,
  columnTypes,
  origin,
  //
  dateFormat,
  setDateFormat,
  decimalFormat,
  setDecimalFormat,
  //
  isCsv,
}) => {
  const [openPopoverType, setOpenPopoverType] = useState({});

  const theme = useTheme();

  const totalPages = Math.floor(fileLength / 20);

  const handlePopoverType = (field, isOpen) => {
    setOpenPopoverType((old) => ({ ...old, [field]: isOpen }));
  };

  // Seletor de tipos no header da tabela
  const TypePicker = (field) => (
    <Popover
      open={openPopoverType[field] || false}
      closePopover={() => handlePopoverType(field, false)}
      style={{
        zIndex: '1050',
      }}
    >
      <Popover.Action>
        <div style={{
          display: 'flex', alignItems: 'center', gap: '10px', padding: '1px',
        }}
        >
          {field}
          {(origin !== 'LegalOne' && origin !== 'LegalOneExtractor') && (canManipulate) && (
            <IconButton
              onClick={() => handlePopoverType(field, !openPopoverType[field])}
              shape="rounded"
              variant="contained"
              color={theme[typeColor[columnTypes[field]]]}
              style={{ padding: '0 0 0 5px', marginLeft: '5px' }}
            >
              <IconWrapper>{typeIcon[columnTypes[field]]}</IconWrapper>
              <IconFlipWrapper flip={openPopoverType[field]}>
                <ExpandMore />
              </IconFlipWrapper>
            </IconButton>
          )}
        </div>
      </Popover.Action>
      <Popover.Content>
        <List>
          <ListItem
            noOutline
            onClick={() => columnTypeChange(field, 'abc')}
          >
            <Letters />
            Validar como texto
          </ListItem>
          <ListItem
            noOutline
            onClick={() => columnTypeChange(field, 'float')}
          >
            <Number />
            Validar como número
          </ListItem>

          <ListItem
            noOutline
            onClick={() => columnTypeChange(field, 'date')}
          >
            <DateIcon />
            Validar como data
          </ListItem>

        </List>
      </Popover.Content>
    </Popover>
  );

  return (
    <MainContainer className="file-view-data-container">
      <ScrollContainer>
        <DataTable
          columns={fileData?.columns.reduce((aux, column, i) => {
            if (column === 'index') return aux;
            aux.push({
              tools: TypePicker(column),
              dataColor: theme[typeColor[columnTypes[column]]],
              valueGetter: (p) => p[i],
            });
            return aux;
          }, []) || []}
          rowData={fileData?.data || []}
          headerColor="transparent"
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
        />
      </ScrollContainer>
      <PaginationContainer>
        <>
          <PaginationContainer.ArrowBtns isCsv={isCsv}>
            <IconButton
              shape="rounded"
              className="invert"
              disabled={page === 0}
              onClick={() => setPage(0)}
            >
              <Last />
            </IconButton>
            <IconButton
              shape="rounded"
              className="invert"
              disabled={page === 0}
              onClick={() => setPage((p) => --p)}
            >
              <Next />
            </IconButton>

            <span>
              Página
              {' '}
              {page + 1}
              {' '}
              de
              {' '}
              {totalPages + 1}
            </span>

            <IconButton
              shape="rounded"
              disabled={page === (totalPages)}
              onClick={() => setPage((p) => ++p)}
            >
              <Next />
            </IconButton>
            <IconButton
              shape="rounded"
              disabled={page === (totalPages)}
              onClick={() => setPage(totalPages)}
            >
              <Last />
            </IconButton>
          </PaginationContainer.ArrowBtns>
          {isCsv && (
          <PaginationContainer.Selects>
            <Select
              options={csvDateFormatOptions}
              value={dateFormat}
              onChange={setDateFormat}
              selectLabel="Selecione o formato de data"
            />
            <Select
              options={decimalOptions}
              value={decimalFormat}
              onChange={setDecimalFormat}
              selectLabel="Selecione o separador decimal"
            />
          </PaginationContainer.Selects>
          )}
        </>
        <LegendsContainer isCsv={isCsv}>
          <div className="legend-type">
            <div className="square legend-texto" />
            Texto
          </div>
          <div className="legend-type">
            <div className="square legend-numero" />
            Número
          </div>
          <div className="legend-type">
            <div className="square legend-date" />
            Data
          </div>
        </LegendsContainer>
      </PaginationContainer>
    </MainContainer>
  );
};

FileViewData.propTypes = {
  /**
   * A string with the name of the file
   */
  // fileName: PropTypes.string.isRequired,
  /**
   * A object that contains the data of the file (columns, rows, types)
   */
  fileData: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.string,
    ),
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  /**
   * The data row quantity of the file
   */
  fileLength: PropTypes.number.isRequired,
  /**
   * The file page to be displayed
   * Each page has a maximum of 20 rows
   * Starts at 0
   */
  page: PropTypes.number.isRequired,
  /**
   * The origin type of file
   */
  origin: PropTypes.string.isRequired,
  /**
   * check wheter or not the user can manipulate data in the file
   */
  canManipulate: PropTypes.bool.isRequired,
  /**
   * A function change the page
   */
  setPage: PropTypes.func.isRequired,
  /**
   * An object to store the types of each column in the file
   */
  columnTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  /**
     * A function to handle the change of the column type
     */
  columnTypeChange: PropTypes.func.isRequired,

  /**
   * object to store the selected Date format
   */
  dateFormat: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,

  /**
   *
   */
  setDateFormat: PropTypes.func.isRequired,

  /**
   * object to store the selected decimal separator format
   */
  decimalFormat: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,

  /**
   * function to change the select
   */
  setDecimalFormat: PropTypes.func.isRequired,
  //
  isCsv: PropTypes.bool.isRequired,
};

export default FileViewData;
