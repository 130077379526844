import React from 'react';

import {
  LegalOne,
  GoogleDriveNew,
  Connect,
  Postgresql,
  Mysql,
  Excel,
  Csv,
  Antecipei,
  AtSign,
  File,
  BennerSimple,
  OneDrive,
} from '../icons';

const originIcon = (origin) => {
  switch (origin) {
    case 'Desktop':
    case 'xls':
    case 'xlsx':
      return <Excel />;

    case 'csv':
      return <Csv />;

    case 'LegalOne':
    case 'LegalOneExtractor':
    case 'L1ReportsApi':
      return <LegalOne />;

    case 'Drive':
    case 'GoogleDrive':
      return <GoogleDriveNew />;

    case 'OneDrive':
    case 'oneDrive':
      return <OneDrive />;

    case 'FileApi':
      return <Connect />;

    case 'PgSql':
      return <Postgresql />;

    case 'MySql':
      return <Mysql />;

    case 'Antecipei':
      return <Antecipei />;

    case 'Mail':
    case 'mail':
      return <AtSign />;

    case 'Benner':
      return <BennerSimple />;

    default:
      return <File />;
  }
};

export default originIcon;

export const getOriginOptions = [
  {
    id: 'origin-Desktop',
    label: 'Desktop',
    value: 'Desktop',
    icon: <Excel />,
  },
  {
    id: 'origin-LegalOne,LegalOneExtractor,L1ReportsApi',
    label: 'Legal One',
    value: 'LegalOne,LegalOneExtractor,L1ReportsApi',
    icon: <LegalOne />,
  },
  {
    id: 'origin-GoogleDrive,Drive',
    label: 'Google Drive',
    value: 'GoogleDrive,Drive',
    icon: <GoogleDriveNew />,
  },
  {
    id: 'origin-OneDrive',
    label: 'One Drive',
    value: 'OneDrive',
    icon: <OneDrive />,
  },
  {
    id: 'origin-FileApi',
    label: 'Connect',
    value: 'FileApi',
    icon: <Connect />,
  },
  {
    id: 'origin-PgSql',
    label: 'PgSql',
    value: 'PgSql',
    icon: <Postgresql />,
  },
  {
    id: 'origin-MySql',
    label: 'MySql',
    value: 'MySql',
    icon: <Mysql />,
  },
  {
    id: 'origin-Antecipei',
    label: 'Antecipei',
    value: 'Antecipei',
    icon: <Antecipei />,
  },
  {
    id: 'origin-Mail,mail',
    label: 'Email',
    value: 'Mail,mail',
    icon: <AtSign />,
  },
  {
    id: 'origin-Benner',
    label: 'Benner',
    value: 'Benner',
    icon: <BennerSimple />,
  },
];

export const getConnectorOptions = [
  { id: 'connector-desktop', label: 'desktop', value: 'desktop' },
  { id: 'connector-l1reportsapi', label: 'l1reportsapi', value: 'l1reportsapi' },
  { id: 'connector-LegalOneDataExtractor', label: 'LegalOneDataExtractor', value: 'LegalOneDataExtractor' },
  { id: 'connector-web', label: 'web', value: 'web' },
  { id: 'connector-sql', label: 'sql', value: 'sql' },
  { id: 'connector-mail', label: 'mail', value: 'mail' },
  { id: 'connector-fileapi', label: 'fileapi', value: 'fileapi' },
];
