/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { customValueTooltip } from '../formatCharts/handleCustomValue';

import { CustomTooltipContainer, ColorLabelSymbol } from './styled/KpiCreate.styled';

const BarTooltip = ({ tData, styleConfig }) => (
  <CustomTooltipContainer key={tData.id}>
    <ColorLabelSymbol color={tData.color} />
    <span>
      {tData.id} - {tData.indexValue}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
    </span>
  </CustomTooltipContainer>
);

const LineToolTip = ({ tData, styleConfig }) => (
  <CustomTooltipContainer key={tData.serieId}>
    <ColorLabelSymbol color={tData.borderColor} />
    <span>
      {tData.serieId} - {tData.data.x}: <strong>{customValueTooltip(tData.data.y, styleConfig)}</strong>
    </span>
  </CustomTooltipContainer>
);

const PieToolTip = ({ tData, styleConfig }) => (
  <CustomTooltipContainer key={tData.label + tData.value}>
    <ColorLabelSymbol color={tData.color} />
    <span>
      {tData.label}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
    </span>
  </CustomTooltipContainer>
);

const RadarToolTip = ({ tData, styleConfig }) => {
  const auxData = tData?.data;
  auxData.sort((a, b) => a.id.localeCompare(b.id));
  return (
    auxData.map((item) => (
      <CustomTooltipContainer key={item.index + item.id}>
        <ColorLabelSymbol color={item.color} />
        <span>
          {item.id} - {tData.index}: <strong>{customValueTooltip(item.value, styleConfig)}</strong>
        </span>
      </CustomTooltipContainer>
    )));
};

const MapTooltip = ({ tData, styleConfig }) => (
  tData.feature.data
    ? (
      <CustomTooltipContainer key={tData.feature.label + tData.feature.value}>
        <ColorLabelSymbol color={tData.feature.color} />
        <span>
          {tData.feature.label}: <strong>{customValueTooltip(tData.feature.value, styleConfig)}</strong>
        </span>
      </CustomTooltipContainer>
    )
    : <></>
);

const RadialBarTooltip = ({ tData, styleConfig }) => (
  <CustomTooltipContainer key={tData.groupId}>
    <ColorLabelSymbol color={tData.color} />
    <span>
      {tData.groupId} - {tData.data.x}: <strong>{customValueTooltip(tData.data.y, styleConfig)}</strong>
    </span>
  </CustomTooltipContainer>
);

const CalendarTooltip = ({ tData, styleConfig }) => {
  const fDate = (d) => new Date(`${d} 00:00`).toLocaleDateString('pt-br');
  return (
    <CustomTooltipContainer key={tData.id}>
      <ColorLabelSymbol color={tData.color} />
      <span>
        {fDate(tData.day)}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
      </span>
    </CustomTooltipContainer>
  );
};

const FunnelTooltip = ({ tData, styleConfig }) => {
  const dt = tData.part;
  return (
    <CustomTooltipContainer key={dt.data.label + dt.data.value}>
      <ColorLabelSymbol color={dt.color} />
      <span>
        {dt.data.label}: <strong>{customValueTooltip(dt.data.value, styleConfig)}</strong>
      </span>
    </CustomTooltipContainer>
  );
};

const WaffleTooltip = ({ tData, styleConfig }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  }}
  >
    <ColorLabelSymbol color={tData.color} />
    <span>
      {tData.label}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
    </span>
  </div>
);

const customTooltip = (tData, type, styleConfig) => {
  switch (type) {
    case 'bar': return BarTooltip({ tData, styleConfig });
    case 'line': return LineToolTip({ tData, styleConfig });
    case 'pie': return PieToolTip({ tData: tData.datum, styleConfig });
    case 'radar': return RadarToolTip({ tData, styleConfig });
    case 'map': return MapTooltip({ tData, styleConfig });
    case 'radialbar': return RadialBarTooltip({ tData: tData.bar, styleConfig });
    case 'calendar': return CalendarTooltip({ tData, styleConfig });
    case 'waffle': return WaffleTooltip({ tData, styleConfig });
    case 'funnel': return FunnelTooltip({ tData, styleConfig });
    default: return null;
  }
};

export default customTooltip;
