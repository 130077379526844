import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import Badge from '../../../../juristec-ui/core/Badge';
import Tooltip from '../../../../juristec-ui/core/Tooltip';
import Button from '../../../../juristec-ui/core/Button';
import IconButton from '../../../../juristec-ui/core/IconButton';
import {
  DifficultyBar, Finder, Play, Edit, Trash,
} from '../../../../juristec-ui/icons';

import {
  FirstPageContainer,
  StepContainer,
  SearchBarContainer,
  IconWrapper,
  BtnIconWrapper,
  TemplatesDeck,
  TemplateCard,
  InfoContainer,
  ScrollContainer,
  InfoRow,
  BadgersContainer,
} from './styled/FirstStep.styled';

/**
 * First step for the 'ApplyTemplate'. This is where the template is chosen.
 */
const FirstStep = ({
  templates, selected, setSelected, editFunc, deleteFunc,
}) => {
  const [textSearch, setTextSearch] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const cronRef = useRef();
  const inputRef = useRef('');
  const theme = useTheme();

  const handleSearch = (txt) => {
    inputRef.current.value = txt;
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setTextSearch(txt);
    }, 200);
  };

  const difficultyMap = {
    easy: { label: 'Iniciante', level: 1 },
    medium: { label: 'Intermediário', level: 2 },
    advanced: { label: 'Avançado', level: 3 },
    expert: { label: 'Expert', level: 4 },
  };

  const openInNewTab = (url) => {
    url = url.startsWith('https://') ? url : `https://${url}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const text = textSearch.trim().toLowerCase();
    if (text.length > 1) {
      setFilteredTemplates(
        templates.filter(
          (tmp) => tmp.name?.toLowerCase().includes(text)
            || tmp.category?.toLowerCase().includes(text)
            || difficultyMap[tmp.difficulty]?.label.toLowerCase().includes(text)
            || tmp.keywords?.toString().toLowerCase().includes(text)
            || Object.keys(tmp.columnsRequired || []).toString().toLowerCase().includes(text),
        ),
      );
    } else {
      setFilteredTemplates(templates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, textSearch]);

  return (
    <FirstPageContainer>
      <StepContainer>
        <SearchBarContainer>
          <IconWrapper>
            <Finder />
          </IconWrapper>
          <InputTextLine
            ref={inputRef}
            placeholder="Filtrar por..."
            styleContainer={{ width: '100%' }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </SearchBarContainer>
        <ScrollContainer>
          <TemplatesDeck>
            {filteredTemplates.map((template) => (
              <TemplateCard
                key={template.id}
                src={template.image}
                selected={template.id === selected.id}
                onClick={() => setSelected(template)}
              >
                <div className="card-header">
                  <span>{template.name}</span>
                </div>
                <div className="card-footer">
                  <span>{template.category}</span>
                  <div>
                    <Tooltip
                      text={difficultyMap[template.difficulty]?.label || ''}
                      atModal
                    >
                      <DifficultyBar
                        level={difficultyMap[template.difficulty]?.level || ''}
                        levelColor={theme.secondary}
                      />
                    </Tooltip>
                  </div>
                </div>
              </TemplateCard>
            ))}
          </TemplatesDeck>
        </ScrollContainer>
      </StepContainer>
      <StepContainer style={{ width: '90%' }}>
        {selected && (
          <ScrollContainer style={{ paddingTop: '0px' }}>
            {editFunc && deleteFunc && (
              <InfoRow style={{ justifyContent: 'flex-end' }}>
                <InfoContainer>
                  <Tooltip text="Editar template" atModal>
                    <IconButton onClick={editFunc}><Edit /></IconButton>
                  </Tooltip>
                  <Tooltip text="Remover template" atModal>
                    <IconButton onClick={deleteFunc}><Trash /></IconButton>
                  </Tooltip>
                </InfoContainer>
              </InfoRow>
            )}
            <InfoRow>
              <InfoContainer>
                <p>
                  <b>Template: </b>
                  {selected.name}
                </p>
              </InfoContainer>
              {selected.category && (
                <InfoContainer>
                  <p>
                    <b>Categoria: </b>
                    {selected.category}
                  </p>
                </InfoContainer>
              )}
            </InfoRow>
            <InfoRow>
              <InfoContainer>
                <b>Dificuldade: </b>
                <DifficultyBar
                  level={difficultyMap[selected.difficulty]?.level}
                  levelColor={theme.secondary}
                />
                <p>{difficultyMap[selected.difficulty]?.label}</p>
              </InfoContainer>
              {selected.tutorialLink?.length > 0 && (
                <InfoContainer>
                  <Button
                    variant="pattern"
                    size="small"
                    shape="rounded"
                    color="info"
                    onClick={() => openInNewTab(selected.tutorialLink)}
                    style={{ gap: '5px', minWidth: 'auto' }}
                  >
                    <BtnIconWrapper>
                      <Play />
                    </BtnIconWrapper>
                    Veja como fazer
                  </Button>
                </InfoContainer>
              )}
            </InfoRow>
            <InfoContainer>
              <p>
                <b>Descrição: </b>
                {selected.description}
              </p>
            </InfoContainer>
            <BadgersContainer>
              <b>Informações necessárias: </b>
              {Object.keys(selected.columnsRequired).map((variable) => (
                <Badge
                  key={variable}
                  color="secondary"
                  textTransform="uppercase"
                  size="small"
                >
                  <div>{variable}</div>
                </Badge>
              ))}
            </BadgersContainer>
            {selected.keywords?.length > 0 && (
              <BadgersContainer>
                <b>Palavras-chaves: </b>
                {selected.keywords.map((word) => (
                  <Badge
                    key={word}
                    color="secondary"
                    textTransform="uppercase"
                    size="small"
                  >
                    <div>{word}</div>
                  </Badge>
                ))}
              </BadgersContainer>
            )}
          </ScrollContainer>
        )}
      </StepContainer>
    </FirstPageContainer>
  );
};

FirstStep.propTypes = {
  /**
   * Array of available templates
   */
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tutorial: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.string,
      keywords: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.string,
      variables: PropTypes.shape({}),
    }),
  ).isRequired,
  /**
   * Template selected
   */
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tutorial: PropTypes.string,
    category: PropTypes.string,
    difficulty: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    columnsRequired: PropTypes.objectOf(PropTypes.shape({
      description: PropTypes.string,
      type: PropTypes.string,
    })),
  }).isRequired,
  /**
   * Function that deals with the selection of a template
   */
  setSelected: PropTypes.func.isRequired,
  /**
   * Function to edit the selected template (admin only)
   */
  editFunc: PropTypes.func,
  /**
   * Function to remove the selected template (admin only)
   */
  deleteFunc: PropTypes.func,
};

FirstStep.defaultProps = {
  editFunc: null,
  deleteFunc: null,
};

export default FirstStep;
