import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTheme } from 'styled-components';

//
import Header from '../Header';
//
import Loader from '../../juristec-ui/core/Loader';
import MfaLogin from '../ProgressiveModals/MfaLogin';
import ReAuthentication from '../Modals/ReAuthentication';
import RegisterPhone from '../ProgressiveModals/RegisterPhone';

import firebase from '../../utils/firebase';
import Multifactor from '../../utils/multifactor';
import useRecaptchaV2 from '../../hooks/useRecaptchaV2';

import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { StyleContext } from '../../context/StyleProvider';
import { ModalContext } from '../../context/ModalProvider';

import {
  verifyFileImgType,
  verifyFileSize,
} from '../../juristec-ui/utils/validators/fileValidators';

import { getRefCompany, getRefUser } from '../../utils/firestore';
import handleLoginErrors from '../../utils/firestoreErrors/HandleLoginErrors';

const imageSizeLimit = 1000000;

const SwitchHeader = () => {
  const {
    currentUser,
    userCompany,
    setCompanyPhotoUrl,
    user: currentUserInfo,
    claimsUser,
  } = useContext(AuthContext);

  const theme = useTheme();

  const v2Verifier = useRecaptchaV2();

  const { setAlertConfig, toggleAlert } = useContext(AlertContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { state: styleState, styleAPI } = useContext(StyleContext);

  const [isLoading, setIsLoading] = useState(false);
  // Temporário para exibir a imagem alterada sem recarregar
  const [companyLogo, setCompanyLogo] = useState(theme.loginLogo);

  useEffect(() => {
    if (userCompany?.photoUrl) setCompanyLogo(userCompany.photoUrl);
  }, [userCompany]);

  useEffect(() => {
    styleAPI.init();
  }, []);

  const riskyChanges = (afterLogin, showFeedback = true) => {
    const reAuthToSave = (currentPassword) => {
      setIsLoading(true);
      firebase.reAuthentication(currentUser.email, currentPassword)
        .then(() => {
          afterLogin();
        })
        .then(() => {
          setIsLoading(false);
          if (showFeedback) {
            setAlertConfig({
              type: 'success',
              text: 'Alterações feitas com sucesso',
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.code === 'auth/multi-factor-auth-required') {
            const mfa = new Multifactor(v2Verifier.getVerifier(), err)
              .success(() => {
                afterLogin()
                  .then(() => {
                    setIsLoading(false);
                    if (showFeedback) {
                      setAlertConfig({
                        type: 'success',
                        text: 'Alterações feitas com sucesso',
                      });
                    }
                  });
              })
              .error((er) => {
                const { text, message } = handleLoginErrors(er);
                setIsLoading(false);
                setAlertConfig({
                  type: 'error',
                  text,
                  child: message,
                });
              });
            /* Modal sendCode function */
            setModalConfig({
              title: 'Autenticação multifator',
              children: (
                <MfaLogin
                  hide={closeModal}
                  mfa={mfa}
                  setLoading={setIsLoading}
                />
              ),
            });
          } else {
            const { text, message } = handleLoginErrors(err);
            setIsLoading(false);
            setAlertConfig({
              type: 'error',
              text,
              child: message,
            });
          }
        });
    };
    setAlertConfig({
      type: 'warning',
      text: 'Operações críticas',
      child: <ReAuthentication close={toggleAlert} submitPassword={reAuthToSave} />,
      withoutConfirm: true,
    });
  };

  const saveProfileChanges = (changes) => {
    switch (changes.type) {
      case 'profile': {
        if (changes.userName) {
          setIsLoading(true);
          getRefUser(currentUser.uid)
            .update({ name: changes.userName })
            .then(() => firebase.updateProfile({ displayName: changes.userName }))
            .then(() => {
              setAlertConfig({
                type: 'success',
                text: 'Alterações feitas com sucesso',
              });
              setIsLoading(false);
            })
            .catch((err) => {
              setAlertConfig({ type: 'error', text: `Erro inexperado: ${err}` });
              setIsLoading(false);
            });
        }
        break;
      }
      case 'password': {
        if (changes.newPassword) {
          riskyChanges(async () => {
            firebase.updatePassword(changes.newPassword);
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const uploadProfileImage = async (file) => {
    const path = `companies/${userCompany.id}/images/${currentUser.uid}/`;
    return firebase.uploadImageOverwrite(path, file, 'profileImg');
  };

  const uploadCompanyImage = async (file) => {
    const path = `companies/${userCompany.id}/logo/`;
    return firebase.uploadImageOverwrite(path, file, 'companyLogo');
  };

  const saveProfilePic = (image) => {
    getRefUser(currentUser.uid)
      .update({ photoUrl: image })
      .then(() => firebase.updateProfile({ photoURL: image }))
      .then(() => {
        // URL.revokeObjectURL(image);
        setIsLoading(false);
        setAlertConfig({
          type: 'success',
          text: 'Alterações feitas com sucesso',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertConfig({ type: 'error', text: `Erro inesperado: ${err}` });
      });
  };

  const saveCompanyPic = (image) => {
    getRefCompany(userCompany.id)
      .update({ photoUrl: image })
      .then(() => {
        setCompanyPhotoUrl(image);
        // URL.revokeObjectURL(image);
        setIsLoading(false);
        setCompanyLogo(image);
        setAlertConfig({
          type: 'success',
          text: 'Alterações feitas com sucesso',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertConfig({ type: 'error', text: `Erro inesperado: ${err}` });
      });
  };

  const checkFileIsImg = (file) => {
    const errorMsg = verifyFileImgType(file) || verifyFileSize(file, imageSizeLimit);
    if (errorMsg.length > 0) {
      setAlertConfig({ type: 'error', text: errorMsg });
      return false;
    }
    return true;
  };

  const changeAvatarFile = async (image) => {
    setIsLoading(true);
    if (typeof image === 'string') {
      saveProfilePic(image);
    } else if (checkFileIsImg(image)) {
      const url = await uploadProfileImage(image);
      saveProfilePic(url);
    } else {
      setIsLoading(false);
    }
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file && checkFileIsImg(file)) {
      setIsLoading(true);
      const url = await uploadCompanyImage(file);
      saveCompanyPic(url);
    }
  };

  const logout = async () => {
    await firebase.logout();
  };

  const confirmLogout = () => {
    setAlertConfig({
      type: 'warning',
      child: 'Deseja mesmo deslogar?',
      text: 'Tem certeza?',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: logout,
    });
  };

  const getMultifactors = () => firebase.getMfaUser().enrolledFactors;

  const newMfaNumber = () => {
    riskyChanges(async () => {
      const mfa = new Multifactor(v2Verifier.getVerifier())
        .success(() => {
          setIsLoading(false);
          closeModal();
          setAlertConfig({
            type: 'success',
            text: 'Número adicionado com sucesso',
          });
        })
        .error((er) => {
          const { text, message } = handleLoginErrors(er);
          setIsLoading(false);
          setAlertConfig({
            type: 'error',
            text,
            child: message,
          });
        });
      setModalConfig({
        title: 'Adicionar multifator',
        children: (
          <RegisterPhone
            hide={closeModal}
            mfa={mfa}
            setLoading={setIsLoading}
          />
        ),
      });
    }, false);
  };

  const delMfaNumber = async (selectedFactor) => {
    const mfa = firebase.getMfaUser();

    riskyChanges(async () => mfa.unenroll(selectedFactor));
  };

  return (
    <>
      {(isLoading || styleState.isLoading) && <Loader />}
      <Header
        user={currentUser}
        userInfo={currentUserInfo}
        userMultifactors={getMultifactors}
        userIsAdmin={claimsUser?.is_admin}
        clientLogo={companyLogo}
        nightMode={styleState.themeMode === 'dark'}
        setNightMode={styleAPI.toggleThemeMode}
        handleProfileChanges={saveProfileChanges}
        handleAvatarChange={changeAvatarFile}
        handleLogout={confirmLogout}
        handleLogoChange={handleLogoChange}
        checkImage={checkFileIsImg}
        newMfaNumber={newMfaNumber}
        delMfaNumber={delMfaNumber}
      />
    </>
  );
};

export default SwitchHeader;
