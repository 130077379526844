import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Row)`
  display: flex;
  padding: 0.8rem 5%;
  align-items: center;
  justify-content: space-between;
  
  @media ${devices.tablet} {
    padding: 0.8rem 20px;
  }

  @media ${devices.phoneS} {
    justify-content: flex-start;
    ${gap('10px', 'row')}
  }
`;

export const ItensContainer = styled(Column)`
  width: 50%;
  & h3.filename {
    font-size: 20px;
    color: ${({ theme }) => theme.primary};
    margin: 0;
    white-space: nowrap;
    
    @media ${devices.phoneS} {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & span.fileinfo {
    color: ${({ theme }) => theme.grey};
    font-size: 12px;
    white-space: nowrap;

    @media ${devices.phoneS} {
      font-size: 12px;
    }
  }
  &.left{
    flex-direction: row;
    align-items: flex-start;
  }
  &.right{
    align-items: flex-end;
  }
  &.center {
    flex-direction: row;
    ${gap('10px', 'row')}
    width: 100%;
    justify-content: center;
    align-items: center;

    @media ${devices.phoneS} {
      align-items: flex-start;
    }
  }
`;

export const FileInfo = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
