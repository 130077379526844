import React from 'react';
import PropTypes from 'prop-types';

import { Container, LeftArea, TitleArea } from './styled/KpiCreateToolbar.styled';

import { ArrowLeft } from '../../../icons';
import Button from '../../../core/Button';
import IconButton from '../../../core/IconButton';
import Tooltip from '../../../core/Tooltip';

const KpiCreateToolbar = ({
  kpiTitle,
  dashboardTitle,
  handleClickReturn,
  openSaveModal,
  disableSave,
}) => (
  <Container>
    <LeftArea>
      <Tooltip text="Voltar" direction="right">
        <IconButton
          variant="contained"
          color="secondary"
          size="medium"
          shape="rounded"
          onClick={handleClickReturn}
        >
          <ArrowLeft />
        </IconButton>
      </Tooltip>
      <TitleArea>
        {`${dashboardTitle || 'Título do dashboard'} / ${kpiTitle || 'Novo Kpi'}`}
      </TitleArea>
    </LeftArea>

    <Button
      variant="contained"
      color="secondary"
      size="medium"
      shape="rounded"
      onClick={openSaveModal}
      disabled={disableSave}
      className="save_button"
    >
      Salvar
    </Button>
  </Container>
);

KpiCreateToolbar.propTypes = {
  kpiTitle: PropTypes.string,
  dashboardTitle: PropTypes.string,
  handleClickReturn: PropTypes.func,
  openSaveModal: PropTypes.func,
  disableSave: PropTypes.bool,
};

KpiCreateToolbar.defaultProps = {
  kpiTitle: '',
  dashboardTitle: '',
  handleClickReturn: () => {},
  openSaveModal: () => {},
  disableSave: true,
};

export default KpiCreateToolbar;
