export const verifyInput = (value, required = false, min = 0, max = Infinity) => {
  const val = value.split(' ').filter((v) => v).join(' ');
  if (required && val.length === 0) return 'Este campo não pode estar vazio';
  if (val.length < min) return `Este campo precisa ter no mínimo ${min} caracteres`;
  if (val.length > max) return `Este campo possui limite de ${max} caracteres`;
  return '';
};

export const verifyCurrentPassword = (value) => {
  if (value.length === 0) return 'Este campo não pode estar vazio';
  return '';
};

export const oldVerifyPassword = (value, similarValue, checkOnlyEqual) => { // used in RegisterPage
  if (similarValue && value === similarValue) return 'A senha não pode ser igual a atual';
  if (!checkOnlyEqual) {
    if (value.length === 0) return 'Este campo não pode estar vazio';
    if (value.length < 8) return 'A senha deve ter no mínimo 8 caracteres';
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])');
    if (!strongRegex.test(value)) return 'Maiúscula, minúscula, número e caractere especial';
  }
  return '';
};

export const verifyPassword = (value) => {
  let message = '';
  let specialCharacterError = false;
  let numberError = false;
  let capitalError = false;
  let lowerError = false;
  let lengthError = false;
  // Check if value has a special character
  const specialCharacter = new RegExp('^(?=.*[^A-Za-z0-9])');
  if (!specialCharacter.test(value)) {
    message = 'A senha precisa ter ao menos um caractere especial';
    specialCharacterError = true;
  }
  // Check if value has a number
  const number = new RegExp('^(?=.*[0-9])');
  if (!number.test(value)) {
    message = 'A senha precisa ter ao menos um número';
    numberError = true;
  }
  // Check if value has a capital letter
  const capitalLetter = new RegExp('^(?=.*[A-Z])');
  if (!capitalLetter.test(value)) {
    message = 'A senha precisa ter ao menos uma letra maiúscula';
    capitalError = true;
  }
  // Check if value has a lower letter
  const lowerLetter = new RegExp('^(?=.*[a-z])');
  if (!lowerLetter.test(value)) {
    message = 'A senha precisa ter ao menos uma letra minúscula';
    lowerError = true;
  }
  if (value.length < 8) {
    if (value.length === 0) {
      message = 'Este campo não pode estar vazio';
    } else {
      message = 'A senha deve ter ao menos 8 caracteres';
    }
    lengthError = true;
  }
  return {
    message,
    specialCharacterError,
    numberError,
    capitalError,
    lowerError,
    lengthError,
  };
};

export const verifyNewPassword = (value, similarValue, checkOnlyEqual) => {
  if (similarValue && value === similarValue) return 'A nova senha não pode ser igual a atual';
  if (!checkOnlyEqual) {
    if (value.length === 0) return 'Este campo não pode estar vazio';
    if (value.length < 8) return 'A nova senha deve ter no mínimo 8 caracteres';
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])');
    if (!strongRegex.test(value)) return 'É necessário uma maiúscula, uma minúscula, um número e um caractere especial';
  }
  return '';
};

export const verifyRepeatPassword = (value, similarValue) => {
  if (value !== similarValue) return "Informe a senha igual a do campo 'Senha'";
  return '';
};

export const verifyRepeatNewPassword = (value, similarValue) => {
  if (value !== similarValue) return "Informe a senha igual a do campo 'Nova senha'";
  return '';
};

export const verifyGoogleDriveSheetLink = (value) => {
  if (value.length === 0) return 'Informe um link válido';
  const linkReg = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/.+$/;
  if (!linkReg.test(value)) return 'O link informado não é de uma planilha do Google Drive';
  return '';
};

export const verifyOneDriveSheetLink = (value) => {
  if (value.length === 0) return 'Informe um link válido';
  // const linkReg = /^https:\/\/1drv\.ms\/x\/s!.+$/;
  // if (!linkReg.test(value)) return 'O link informado não é de uma planilha do One Drive';
  return '';
};

export const verifyLink = (value) => {
  if (value.length === 0) return '';
  const linkReg = /^(((https?:\/\/)|(www\.))[^\s]+)$/;
  if (!linkReg.test(value)) return 'Informe um link válido';
  return '';
};

export const verifyEmail = (value, required = false) => {
  if (value.length === 0) return required ? 'Este campo não pode estar vazio' : '';

  const emailParts = value.split('@');
  if (emailParts.length !== 2 || emailParts[0].length > 64 || emailParts[1].length > 255) return 'Informe um email válido';

  if (emailParts[1].split('.').some((part) => (part.length > 63))) return 'Informe um email válido';

  const emailReg = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!emailReg.test(value)) return 'Informe um email válido';
  return '';
};

export const verifyRepeatEmail = (value, similarValue) => {
  if (value !== similarValue) return "Informe o email igual a do campo 'Novo email'";
  return '';
};

export const verifyZipCode = (value, required = false) => {
  if (value.length === 0) return required ? 'Este campo não pode estar vazio' : '';
  if (value.length !== 8) return 'Informe um cep válido';
  return '';
};

const prefixesDDD = [
  11, 12, 13, 14, 15, 16, 17, 18, 19,
  21, 22, 24, 27, 28, 31, 32, 33, 34,
  35, 37, 38, 41, 42, 43, 44, 45, 46,
  47, 48, 49, 51, 53, 54, 55, 61, 62,
  64, 63, 65, 66, 67, 68, 69, 71, 73,
  74, 75, 77, 79, 81, 82, 83, 84, 85,
  86, 87, 88, 89, 91, 92, 93, 94, 95,
  96, 97, 98, 99,
];

export const verifyPhone = (value, required = false, place) => {
  if (value.length === 0) return required ? 'Este campo não pode estar vazio' : '';
  if (place === 'Brazil') {
    if (value.length < 12 || value.length > 13) return 'Informe um telefone válido';
    if (!prefixesDDD.includes(parseInt(value.slice(0, 2), 10))) return 'Informe um DDD válido';
  }
  return '';
};

const cpfBlacklist = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
  '12345678909',
];

const cnpjBlacklist = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999',
];

export const verifyCpf = (value, required = false) => {
  if (value.length === 0) return required ? 'Este campo não pode estar vazio' : '';
  if (value.length !== 11 || cpfBlacklist.includes(value)) return 'Informe um cpf válido';

  const cpfVerifierDigit = (digits) => {
    const nbs = digits.split('').map((n) => parseInt(n, 10));
    const modulus = nbs.length + 1;
    const multiplied = nbs.map((number, index) => number * (modulus - index));
    const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;
    return (mod < 2 ? 0 : 11 - mod);
  };

  let verifiedNumbers = value.substr(0, 9);
  verifiedNumbers += cpfVerifierDigit(verifiedNumbers);
  verifiedNumbers += cpfVerifierDigit(verifiedNumbers);

  if (value.substr(-2) !== verifiedNumbers.substr(-2)) return 'Informe um cpf verdadeiro';
  return '';
};

export const verifyCnpj = (value, required = false) => {
  if (value.length === 0) return required ? 'Este campo não pode estar vazio' : '';
  if (value.length !== 14 || cnpjBlacklist.includes(value)) return 'Informe um cnpj válido';

  const cnpjVerifierDigit = (digits) => {
    let index = 2;
    const reverse = digits.split('').reduce(
      (buffer, number) => [parseInt(number, 10)].concat(buffer), [],
    );
    const sum = reverse.reduce((buffer, number) => {
      // eslint-disable-next-line no-param-reassign
      buffer += number * index;
      index = (index === 9 ? 2 : index + 1);
      return buffer;
    }, 0);
    const mod = sum % 11;
    return (mod < 2 ? 0 : 11 - mod);
  };

  let verifiedNumbers = value.substr(0, 12);
  verifiedNumbers += cnpjVerifierDigit(verifiedNumbers);
  verifiedNumbers += cnpjVerifierDigit(verifiedNumbers);

  if (value.substr(-2) !== verifiedNumbers.substr(-2)) return 'Informe um cnpj verdadeiro';
  return '';
};

export const verifySqlCode = (value) => {
  if (value.length === 0) return 'Este campo não pode estar vazio';
  if (!(value.match(/select/gi)?.length === 1)) return 'Utilize apenas um comando "SELECT"';
  if (value.match(/update|delete|insert|create|alter|drop/gi) !== null) {
    return 'Apenas o comando "SELECT" é permitido';
  }
  return '';
};

export const verifyFileName = (value) => {
  if (value.length === 0) return 'Este campo não pode estar vazio!';
  const regex = /[\\/|<>:*?,.]/g;
  if (regex.test(value)) return 'O nome do arquivo não pode conter os seguintes caracteres: \\, /, |, <, >, *, :, ., ? ou vírgula (,)';
  return '';
};

export const verifyMasterCrawlerUrl = (value) => {
  if (value.length === 0) return 'Este campo não pode estar vazio!';
  const urlReg = /^https:\/\/mastercrawler\.juristecsystems\.com\/processos\/report\/.+$/;
  if (!urlReg.test(value)) return 'A URL deve ser do tipo https://mastercrawler.juristecsystems.com/processos/report/CLIENT_NAME';
  return '';
};

export const verifyColumnName = (value, nameSet) => {
  const val = value.split(' ').filter((v) => v).join(' ');
  if (val.length === 0) return 'O nome não pode ser vazio';
  if (val.length < 1) return 'O nome precisa ter no mínimo 1 caracter';
  if (val.length > 30) return 'O nome possui limite de 30 caracteres';
  if (nameSet.has(val)) return 'Este nome já está em uso';
  return '';
};
