import { useCallback, useMemo } from 'react';

import getRequestMeta from '../utils/functions/generateMeta';

import { ip } from '../utils/functions/urls';
import { error as errorLabels } from '../label';

const getToken = async (u) => u.getIdToken();

function useKpiData(currentUser) {
  /**
   * [Backend-request]
   *
   * Generates a temporary kpi data
   * @param {object} kpiMeta Kpi meta data
   * @callback genKpiData
   */
  const genKpiData = useCallback(async (kpiMeta, database) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          ...kpiMeta,
          file_id: database,
        }),
      };
      const res = await fetch(`${ip}/kpis/create`, opt);
      const json = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabels.useKpiData.genKpiData, raw: json };
      }
      return { error: false, data: json.info };
    } catch (er) {
      return { error: true, msg: errorLabels.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'genKpiData': {
        const getRes = await genKpiData(action.kpiMeta, action.database);
        if (getRes.error) return getRes;
        return getRes.data;
      }
      default:
        return {
          error: false, msg: 'DEFAULT', raw: {}, default: true,
        };
    }
  }, [genKpiData]);

  const kpiAPI = useMemo(() => ({
    genKpiData: async (kpiMeta, database) => middleware({
      type: 'genKpiData', kpiMeta, database,
    }),
  }), [middleware]);

  return [kpiAPI];
}

export default useKpiData;
