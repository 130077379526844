import { customValueAxis } from './handleCustomValue';

const customAxis = (AxisPos, config, useFormat = true) => {
  switch (AxisPos) {
    case 'top':
      return config?.EnableAxisX?.axisTop ? {
        ...config?.EnableAxisX,
        legendOffset: config?.EnableAxisX?.legendOffset * -1,
        format: (value) => (useFormat ? customValueAxis(value, config, 'X') : value),
      } : null;
    case 'bottom':
      return config?.EnableAxisX?.axisBot ? {
        ...config?.EnableAxisX,
        format: (value) => (useFormat ? customValueAxis(value, config, 'X') : value),
      } : null;
    case 'right':
      return config?.EnableAxisY?.axisRight ? {
        ...config?.EnableAxisY,
        legendOffset: config?.EnableAxisY?.legendOffset * -1,
        format: (value) => (useFormat ? customValueAxis(value, config, 'Y') : value),
      } : null;
    case 'left':
      return config?.EnableAxisY?.axisLeft ? {
        ...config?.EnableAxisY,
        format: (value) => (useFormat ? customValueAxis(value, config, 'Y') : value),
      } : null;
    default:
      break;
  }
  return null;
};

export default customAxis;
