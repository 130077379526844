import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints'; // '../../../Juristec/breakpoints';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainContainer = styled(Column)`
  padding: .5rem 1rem;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ModalHeader = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  & span, .container-input-select {
    margin: 0;
    ${({ isCsv }) => (isCsv ? css`
      flex: 1;
    ` : css``)}
  };
  & span { 
    width: 100%;
  };

  & .container-input-select {
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }

  & .input-container  {
    &:not(first-child) {
      margin-left: 1rem;
    }
  }

  & #file-validation-decimal {
    margin-top: 0px;
  }

`;

export const ModalFooter = styled(Row)`
  margin-top: 1rem;
  align-items: center;
  justify-content: start;
  gap: 2rem;
`;

export const ColorLabelContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  gap: 5px;
  & .grey-square,
  .yellow-square,
  .green-square {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }
  & .grey-square {
    background-color: ${({ theme }) => theme.typeText};
  }

  & .yellow-square {
    background-color: ${({ theme }) => theme.typeDate};
  }
  & .green-square {
    background-color: ${({ theme }) => theme.typeNumber};
  }
`;

export const HelpContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.info};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.white};
  }
`;

export const LinkIconWrapper = styled(IconWrapper)`
  background-color: ${({ theme }) => theme.info};
  border-radius: 50%;
`;

export const IconFlipWrapper = styled(IconWrapper)`
  transition: all 0.5s;
  ${(props) => props.flip
    && css`
      transform: rotate(180deg);
    `}
`;

export const ScrollXContainer = styled.div`
  max-height: 320px;
  @media ${devices.tablet} { 
    max-height: 300px;
  }

  @media ${devices.phoneL} {
    max-height: 280px;
  }

  @media ${devices.phoneS} { 
    max-height: 250px;
  }

  overflow-y: auto;
  overflow-x: auto;

  outline-style: none;
`;
