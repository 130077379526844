import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  & .buttons {
    width: 100%;
    align-items: center;
    box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.shadow};
    border-radius: 8px;
    height: 100%;
    margin-top: 3px;
  }
`;

export const MainRow = styled(Row)`
  @media ${devices.phoneL} {
    flex-direction: column-reverse;
  };
`;

export const MainColumn = styled(Column)`
  width: 300px;
  gap: 10px;
  @media ${devices.phoneL} {
    width: 100%;
  };
`;

export const MainContainer = styled(Column)`
  padding: 0.7rem 1rem;

  & .choicebox-btn {
    font-size: 13px;
    min-width: auto;
    width: 100%;
    box-shadow: none;
    white-space: nowrap;
    border-width: 1px;
    border-right-width: 0;
    height: 100%;
    & svg {
      height: 18px;
    }
  }
  & .choicebox-btn:first-child {
    border-radius: 8px 0 0 8px;
  }
  & .choicebox-btn:last-child {
    border-radius: 0 8px 8px 0;
    border-right-width: 1px;
  }
`;

export const Label = styled.label`
  font-size:0.85rem;
  color:${({ theme }) => theme.grey};
  font-weight: bold;
`;

export const ContainerInput = styled(Column)`
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.3;
  `}
  height: 50px;
`;

export const ContainerInputRow = styled(ContainerInput)`
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  align-items: center;
`;

export const NumberControls = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2px;
  bottom: 2px;
  & button {
    height: 14px;
    width: 20px;
    padding: 0;
    border: none;
    background-color: ${({ theme }) => theme.inputNumberControl};
    &:hover {
      background-color: ${({ theme }) => theme.inputNumberControlHover};
    }
  }
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.grey};
  }
  & .number-increase {
    transform: rotate(180deg);
  }
`;

export const CardPreview = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  ${(props) => css`
    background-color: ${props.cardStyle.backgroundColor};
    border-color: ${props.cardStyle.borderStyle.color};
    border-width: ${props.cardStyle.borderStyle.size}px;
    border-style: ${props.cardStyle.borderStyle.line};
    border-radius: ${props.cardStyle.borderStyle.rounded}px;
    box-shadow: ${props.cardStyle.shading ? `0px 4px 4px ${({ theme }) => theme.shadow}` : 'none'};
  `}
`;

export const CardPreviewContainer = styled(Column)`
  height: 150px;
  max-height: 150px;
  background-color: ${({ theme }) => theme.lightgrey};
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
