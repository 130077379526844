import styled from 'styled-components';

export const ManageStoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem 1rem;
  max-width: 400px;
  gap: 5px;
  & span.tip {
    margin-top: 1rem;
  }
  & span.date {
    font-size: 14px;
    color: ${({ theme }) => theme.grey};
    align-self: end;
  }
  & span.errorMessage {
    position: absolute;
  }
`;