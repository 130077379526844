import React from 'react';
import PropTypes from 'prop-types';
import StyledIconButton from './styled/IconButton.styled';

// import { Container } from './styles';

/**
 * An icon button is used when some functionality is fully understandable through a simple icon
*/
function IconButton({
  color, variant, shape, size, children, useDynamicColor, ...props
}) {
  return (
    <StyledIconButton
      color={color}
      variant={variant}
      shape={shape}
      size={size}
      useDynamicColor={useDynamicColor}
      {...props}
    >
      {children}
    </StyledIconButton>
  );
}

IconButton.propTypes = {
  /**
  * Color of the icon button. Choose from seven different options or pass a hex color.
  */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'success', 'warning', 'info', 'dark']), PropTypes.string]),
  /**
  * Variant of the icon button. Choose among three variants.
  */
  variant: PropTypes.oneOf(['contained', 'outlined', 'pattern']),
  /**
  * Shape of the button. Choose among three shapes.
  */
  shape: PropTypes.oneOf(['square', 'rounded', 'circle']),
  /**
  * Size of the button. Choose among three sizes.
  */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
 * Accepts a simple text, a function, an object or a javascript node.
 */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  /**
   * A function to perform some action when the user clicks the button 
   */
  onClick: PropTypes.func,
  /**
   * Decides if text color can change based on background color (only in contained button)
   */
  useDynamicColor: PropTypes.bool,
};

IconButton.defaultProps = {
  color: 'primary',
  variant: 'pattern',
  shape: 'circle',
  size: 'medium',
  useDynamicColor: false,
  onClick: () => {},
};

export default IconButton;
