/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BoxLegendSvg } from '@nivo/legends';

/**
 * Custom legend component.
 * To be used when the chart does not have legend toggle.
 * Called inside `Layers` API.
 */
const CustomLegend = ({
  chartDataAr, colorParts, sizes, config, setToggleIds, isEdit,
}) => {
  const handleClick = (dt) => {
    setToggleIds((state) => {
      if (state.includes(dt.id)) return state.filter((item) => item !== dt.id);
      if (state.length < chartDataAr.length - 1) return [...state, dt.id];
      return state;
    });
  };

  return (
    <BoxLegendSvg
      data={chartDataAr.map((p) => {
        const key = p.label || p;
        return ({
          id: key, label: key, color: colorParts[key] || '#000', hidden: !colorParts[key],
        });
      })}
      itemTextColor="#999"
      symbolShape={config?.LegendSymbolControl || 'circle'}
      itemsSpacing={parseInt(config?.LegendsSpacingControl?.value, 10)}
      justify={false}
      containerHeight={sizes.height}
      containerWidth={sizes.width}
      {...config?.LegendPlacementControl?.value}
      itemWidth={100}
      itemHeight={18}
      symbolSize={18}
      onClick={!isEdit ? handleClick : undefined}
      effects={[{ on: 'hover', style: { itemTextColor: '#000' } }]}
    />
  );
};

CustomLegend.propTypes = {
  /** Chart data array */
  chartDataAr: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  /** Object with color by parts `{ partKey: color }` */
  colorParts: PropTypes.objectOf(PropTypes.string).isRequired,
  /** Container size */
  sizes: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  /** Styleconfig object */
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Function to toggle legend items */
  setToggleIds: PropTypes.func.isRequired,
  /** Boolean representing whether the graph has been edited or not */
  isEdit: PropTypes.bool.isRequired,
};

export default CustomLegend;
