export const roleOpt = [
  { label: 'Proprietário', value: 'super', id: 'super' },
  { label: 'Cientista', value: 'scientist', id: 'scientist' },
  { label: 'Convidado', value: 'guest', id: 'guest' },
];
export const planOpt = [
  // { label: 'Acadêmico', value: 'free', id: 'free' },
  { label: 'Starter', value: 'standard', id: 'standard' },
  { label: 'Law Firms', value: 'advanced', id: 'advanced' },
  { label: 'Corporate', value: 'premium', id: 'premium' },
  { label: 'Push', value: 'push', id: 'push' },
  // { label: 'Customizado', value: 'custom', id: 'custom' },
];

export const operationalStatusOpt = [
  { label: 'Em espera', value: 'Em espera', id: 'Em espera' },
  { label: 'Em implantação', value: 'Em implantação', id: 'Em implantação' },
  { label: 'Suspenso', value: 'Suspenso', id: 'Suspenso' },
  { label: 'Operacional', value: 'Operacional', id: 'Opercional' },
  { label: 'Cancelado', value: 'Cancelado', id: 'Cancelado' },
  { label: 'Comercial', value: 'Comercial', id: 'Comercial' },
  { label: 'Teste', value: 'Teste', id: 'Teste' },
];

export const diskOpt = [
  { label: '500MB', value: '500', id: '500' },
  { label: '1GB', value: '1000', id: '1000' },
  { label: '2GB', value: '2000', id: '2000' },
  { label: '5GB', value: '5000', id: '5000' },
  { label: '10GB', value: '10000', id: '10000' },
  { label: '20GB', value: '20000', id: '20000' },
  { label: '50GB', value: '50000', id: '50000' },
];

export const dashboardOpt = [
  { label: '5', value: 5, id: '5' },
  { label: '10', value: 10, id: '10' },
  { label: '25', value: 25, id: '25' },
  { label: '50', value: 50, id: '50' },
  { label: '100', value: 100, id: '100' },
  { label: '250', value: 250, id: '250' },
  { label: '500', value: 500, id: '500' },
  { label: '1000', value: 1000, id: '1000' },
  { label: '2000', value: 2000, id: '2000' },
  { label: '5000', value: 5000, id: '5000' },
  { label: 'ilimitado', value: 100000, id: 'ilimitado' },
];

export const templatesOpt = [
  { label: 'Nível 1', value: 'level1', id: 'level1' },
  { label: 'Nível 2', value: 'level2', id: 'level2' },
  { label: 'Nível 3', value: 'level3', id: 'level3' },
  { label: 'Nível 4', value: 'level4', id: 'level4' },
];

export const scientistOpt = [
  { label: '1', value: '1', id: '1' },
  { label: '2', value: '2', id: '2' },
  { label: '5', value: '5', id: '5' },
  { label: '10', value: '10', id: '10' },
  { label: '15', value: '15', id: '15' },
  { label: '20', value: '20', id: '20' },
];

export const superOpt = [
  { label: '1', value: '1', id: '1' },
  { label: '2', value: '2', id: '2' },
  { label: '5', value: '5', id: '5' },
  { label: '10', value: '10', id: '10' },
  { label: '15', value: '15', id: '15' },
  { label: '20', value: '20', id: '20' },
];

export const guestOpt = [
  { label: '10', value: '10', id: '10' },
  { label: '20', value: '20', id: '20' },
  { label: '50', value: '50', id: '50' },
  { label: '100', value: '100', id: '100' },
  { label: '200', value: '200', id: '200' },
  { label: '500', value: '500', id: '500' },
  { label: '1000', value: '1000', id: '1000' },
  { label: '2000', value: '2000', id: '2000' },
  { label: '5000', value: '5000', id: '5000' },
  { label: '10000', value: '10000', id: '10000' },
];

export const snapshotOpt = [
  { label: '1', value: '1', id: '1' },
  { label: '3', value: '3', id: '3' },
  { label: '6', value: '6', id: '6' },
  { label: '12', value: '12', id: '12' },
];

export const supportOpt = [
  { label: 'Sem suporte', value: 'noSupport', id: 'noSupport' },
  { label: 'Suporte por e-mail', value: 'emailSupport', id: 'emailSupport' },
  { label: 'Suporte por whatsapp', value: 'whatsSupport', id: 'whatsSupport' },
  { label: 'Suporte por conferência', value: 'confSupport', id: 'confSupport' },
];
export const monthNames = ['01', '02', '03', '04', '05', '06',
  '07', '08', '09', '10', '11', '12'];

export const dateOptions = [
  { label: 'Ano-Mês-Dia (A-M-D)', value: 'D', id: 'D' },
  { label: 'Mês-Ano (M-A)', value: 'M', id: 'M' },
  { label: 'Ano (#)', value: 'year', id: 'year' },
  { label: 'Semana (A-M-D/A-M-D)', value: 'W', id: 'W' },
  { label: 'Trimestre (A-T#)', value: 'Q', id: 'Q' },
  { label: 'Mês do Ano [Jan-Dez]', value: 'month', id: 'month' },
  { label: 'Trim. do Ano [1ºTrim-4ºTrim]', value: 'quarter', id: 'quarter' },
  { label: 'Dia da Semana', value: 'dayofweek', id: 'dayofweek' },
  { label: 'Dia do Mês [1-31]', value: 'day', id: 'day' },
  { label: 'Semana do Ano [1-53]', value: 'week', id: 'week' },
  { label: 'Dia do Ano [1-366]', value: 'dayofyear', id: 'dayofyear' },
];

export const dateFormatOptions = [
  { label: 'Dia/Mês/Ano (dd/mm/aaaa ou dd-mm-aaaa)', value: 'D-M-A', id: 'D-M-A' },
  { label: 'Mês/Dia/Ano (mm/dd/aaaa ou mm-dd-aaaa)', value: 'M-D-A', id: 'M-D-A' },
  { label: 'Ano/Mês/Dia (aaaa/mm/dd ou aaaa-mm-dd)', value: 'A-M-D', id: 'A-M-D' },
  { label: 'Ano/Dia/Mês (aaaa/dd/mm ou aaaa-dd-mm)', value: 'A-D-M', id: 'A-D-M' },
  { label: 'Mês/Ano/Dia (mm/aaaa/dd ou mm-aaaa-dd)', value: 'M-A-D', id: 'M-A-D' },
  { label: 'Dia/Ano/Mês (dd/aaaa/mm ou dd-aaaa-mm)', value: 'D-A-M', id: 'D-A-M' },
];

export const xlsxDateFormatOptions = [
  { label: 'Ano/Mês/Dia (aaaa/mm/dd ou aaaa-mm-dd)', value: 'A-M-D', id: 'A-M-D' },
];

export const csvDateFormatOptions = [
  { label: 'Dia/Mês/Ano (dd/mm/aaaa ou dd-mm-aaaa)', value: 'D-M-A', id: 'D-M-A' },
  { label: 'Ano/Mês/Dia (aaaa/mm/dd ou aaaa-mm-dd)', value: 'A-M-D', id: 'A-M-D' },
];

export const decimalOptions = [
  { value: '.', label: 'Ponto (.)', id: 'ponto' },
  { value: ',', label: 'Vírgula (,)', id: 'virgula' },
];

export const erpOptions = [
  { value: 'none', label: 'Nenhum', id: 'none' },
  { value: 'Advbox', label: 'Advbox', id: 'Advbox' },
  { value: 'Ajur', label: 'Ajur', id: 'Ajur' },
  { value: 'Astrea', label: 'Astrea', id: 'Astrea' },
  { value: 'Auxilium', label: 'Auxilium', id: 'Auxilium' },
  { value: 'Benner', label: 'Benner', id: 'Benner' },
  { value: 'Brainlaw', label: 'Brainlaw', id: 'Brainlaw' },
  { value: 'CPJ-3C', label: 'CPJ-3C', id: 'CPJ-3C' },
  { value: 'CP-Pro', label: 'CP-Pro', id: 'CP-Pro' },
  { value: 'Datajuri', label: 'Datajuri', id: 'Datajuri' },
  { value: 'DataLawyer', label: 'DataLawyer', id: 'DataLawyer' },
  { value: 'DataVenia', label: 'DataVenia', id: 'DataVenia' },
  { value: 'Digesto', label: 'Digesto', id: 'Digesto' },
  { value: 'Easyjur', label: 'Easyjur', id: 'Easyjur' },
  { value: 'Ebt-Juris', label: 'Ebt-Juris', id: 'Ebt-Juris' },
  { value: 'E-Law', label: 'E-Law', id: 'E-Law' },
  { value: 'Espaider', label: 'Espaider', id: 'Espaider' },
  { value: 'Eterno Jurídico', label: 'Eterno Jurídico', id: 'Eterno Jurídico' },
  { value: 'e-Xyon', label: 'e-Xyon', id: 'e-Xyon' },
  { value: 'FAZ', label: 'FAZ', id: 'FAZ' },
  { value: 'Forelegal', label: 'Forelegal', id: 'Forelegal' },
  { value: 'Gepro', label: 'Gepro', id: 'Gepro' },
  { value: 'Gojur', label: 'Gojur', id: 'Gojur' },
  { value: 'Harpa', label: 'Harpa', id: 'Harpa' },
  { value: 'Integra', label: 'Integra', id: 'Integra' },
  { value: 'Kurier Analytics', label: 'Kurier Analytics', id: 'Kurier Analytics' },
  { value: 'Kurier Meridio', label: 'Kurier Meridio', id: 'Kurier Meridio' },
  { value: 'Lawsoft', label: 'Lawsoft', id: 'Lawsoft' },
  { value: 'Lawyer Eleven', label: 'Lawyer Eleven', id: 'Lawyer Eleven' },
  { value: 'Legal Control', label: 'Legal Control', id: 'Legal Control' },
  { value: 'Legal Manager', label: 'Legal Manager', id: 'Legal Manager' },
  { value: 'Legal One', label: 'Legal One', id: 'Legal One' },
  { value: 'Legitmvs', label: 'Legitmvs', id: 'Legitmvs' },
  { value: 'Lysis', label: 'Lysis', id: 'Lysis' },
  { value: 'MaisJurídico', label: 'MaisJurídico', id: 'MaisJurídico' },
  { value: 'Prawo', label: 'Prawo', id: 'Prawo' },
  { value: 'Projud', label: 'Projud', id: 'Projud' },
  { value: 'Projuris', label: 'Projuris', id: 'Projuris' },
  { value: 'Promad', label: 'Promad', id: 'Promad' },
  { value: 'SAJ-Adv', label: 'SAJ-Adv', id: 'SAJ-Adv' },
  { value: 'Tecnojuris', label: 'Tecnojuris', id: 'Tecnojuris' },
  { value: 'Tedesco', label: 'Tedesco', id: 'Tedesco' },
  { value: 'Themis', label: 'Themis', id: 'Themis' },
  { value: 'Totvs Legal Desk', label: 'Totvs Legal Desk', id: 'Totvs Legal Desk' },
  { value: 'Totvs Sisjuri', label: 'Totvs Sisjuri', id: 'Totvs Sisjuri' },
  { value: 'other', label: 'Outro', id: 'other' },
];

export const fontOptions = [
  { value: 'arial', label: 'Arimo', id: 'arial' },
  { value: 'bookman', label: 'Libre Baskerville', id: 'bookman' },
  { value: 'courier', label: 'Courier Prime', id: 'Courier' },
  { value: 'cursive', label: 'Kalam', id: 'cursive' },
  { value: 'fantasy', label: 'Oswald', id: 'fantasy' },
  { value: 'helvetica', label: 'Lato', id: 'helvetica' },
  { value: 'lucida console', label: 'Old Standard TT', id: 'lucida console' },
  { value: 'monospace', label: 'Fira Mono', id: 'monospace' },
  { value: 'open sans', label: 'Open Sans', id: 'open sans' },
  { value: 'roboto', label: 'Roboto', id: 'roboto' },
  { value: 'rubik', label: 'Rubik', id: 'rubik' },
  { value: 'sans-serif', label: 'Nunito Sans', id: 'sans-serif' },
  { value: 'serif', label: 'Playfair Display', id: 'Serif' },
  { value: 'tahoma', label: 'Signika', id: 'tahoma' },
  { value: 'times new roman', label: 'Tinos', id: 'times new roman' },
  { value: 'verdana', label: 'Jost', id: 'verdana' },
];

export const filterTypeOptionsSimple = [
  { value: 'values', label: 'Valores selecionados', id: 'values' },
  { value: 'not-values', label: 'Valores não selecionados', id: 'not-values' },
];

export const filterTypeOptionsDate = [
  // { value: 'values', label: 'Por valores', id: 'values' },
  { value: 'lastY', label: 'Últimos N anos', id: 'lastY' },
  { value: 'not-lastY', label: 'Inverso dos últimos N anos', id: 'not-lastY' },
  { value: 'lastM', label: 'Últimos N meses', id: 'lastM' },
  { value: 'not-lastM', label: 'Inverso dos últimos N meses', id: 'not-lastM' },
  { value: 'lastD', label: 'Últimos N dias', id: 'lastD' },
  { value: 'not-lastD', label: 'Inverso dos últimos N dias', id: 'not-lastD' },
  { value: 'lastQ', label: 'Últimos N trimestres', id: 'lastQ' },
  { value: 'not-lastQ', label: 'Inverso dos últimos N trimestres', id: 'not-lastQ' },
  { value: 'lastW', label: 'Últimas N semanas', id: 'lastW' },
  { value: 'not-lastW', label: 'Inverso das últimas N semanas', id: 'not-lastW' },
  { value: 'after', label: 'A partir da data N (exclusive)', id: 'after' },
  // { value: 'not-after', label: '[NOT] A partir da data N (exclusive)', id: 'not-after' },
  { value: 'before', label: 'Antes da data N (exclusive)', id: 'before' },
  // { value: 'not-before', label: '[NOT] Antes da data N (exclusive)', id: 'not-before' },
  { value: 'between', label: 'Entre datas A e B (exclusive)', id: 'between' },
  { value: 'not-between_inc', label: 'Não está entre as datas A e B (inclusive)', id: 'not-between_inc' },
  { value: 'after_inc', label: 'A partir da data N (inclusive)', id: 'after_inc' },
  // { value: 'not-after_inc', label: '[NOT] A partir da data N (inclusive)', id: 'not-after_inc' },
  { value: 'before_inc', label: 'Antes da data N (inclusive)', id: 'before_inc' },
  // { value: 'not-before_inc', label: '[NOT] Antes da data N (inclusive)', id: 'not-before_inc' },
  { value: 'between_inc', label: 'Entre datas A e B (inclusive)', id: 'between_inc' },
  { value: 'not-between', label: 'Não está entre as datas A e B (exclusive)', id: 'not-between' },
];

export const groupTypeOptionsDate = [
  // { value: 'values', label: 'Por valores', id: 'values' },
  { value: 'last', label: 'Últimas N datas', id: 'last' },
  { value: 'not-last', label: 'Inverso das últimas N datas', id: 'not-last' },
  { value: 'after', label: 'A partir da data N (exclusive)', id: 'after' },
  // { value: 'not-after', label: '[NOT] A partir da data N (exclusive)', id: 'not-after' },
  { value: 'after_inc', label: 'A partir da data N (inclusive)', id: 'after_inc' },
  // { value: 'not-after_inc', label: '[NOT] A partir da data N (inclusive)', id: 'not-after_inc' },
  { value: 'before', label: 'Antes da data N (exclusive)', id: 'before' },
  // { value: 'not-before', label: '[NOT] Antes da data N (exclusive)', id: 'not-before' },
  { value: 'before_inc', label: 'Antes da data N (inclusive)', id: 'before_inc' },
  // { value: 'not-before_inc', label: '[NOT] Antes da data N (inclusive)', id: 'not-before_inc' },
  { value: 'between', label: 'Entre datas A e B (exclusive)', id: 'between' },
  { value: 'not-between_inc', label: 'Não está entre datas A e B (inclusive)', id: 'not-between_inc' },
  { value: 'between_inc', label: 'Entre datas A e B (inclusive)', id: 'between_inc' },
  { value: 'not-between', label: 'Não está entre datas A e B (exclusive)', id: 'not-between_inc' },
];

export const filterTypeOptionsText = [
  // { value: 'values', label: 'Por valores', id: 'values' },
  { value: 'most', label: 'N mais frequentes (sem empate)', id: 'most' },
  { value: 'not-most', label: 'Não está entre os N mais frequentes (sem empate)', id: 'not-most' },
  { value: 'most_inc', label: 'N mais frequentes (com empate)', id: 'most_inc' },
  { value: 'not-most_inc', label: 'Não está entre os N mais frequentes (com empate)', id: 'not-most_inc' },
  { value: 'least', label: 'N menos frequentes (sem empate)', id: 'least' },
  { value: 'not-least', label: 'Não está entre os N menos frequentes (sem empate)', id: 'not-least' },
  { value: 'least_inc', label: 'N menos frequentes (com empate)', id: 'least_inc' },
  { value: 'not-least_inc', label: 'Não está entre os N menos frequentes (com empate)', id: 'not-least_inc' },
  { value: 'contains', label: 'Contém o texto', id: 'contains' },
  { value: 'not-contains', label: 'Não contém o texto', id: 'not-contains' },
];

export const filterTypeOptionsNumber = [
  // { value: 'values', label: 'Por valores', id: 'values' },
  { value: 'greater', label: 'Maiores que N', id: 'greater' },
  // { value: 'not-greater', label: '[NOT] Maiores que N', id: 'not-greater' },
  { value: 'greater_inc', label: 'Maiores ou iguais a N', id: 'greater_inc' },
  // { value: 'not-greater_inc', label: '[NOT] Maiores ou iguais a N', id: 'not-greater_inc' },
  { value: 'lesser', label: 'Menores que N', id: 'lesser' },
  // { value: 'not-lesser', label: '[NOT] Menores que N', id: 'not-lesser' },
  { value: 'lesser_inc', label: 'Menores ou iguais a N', id: 'lesser_inc' },
  // { value: 'not-lesser_inc', label: '[NOT] Menores ou iguais a N', id: 'not-lesser_inc' },
  { value: 'between', label: 'Entre A e B (exclusive)', id: 'between' },
  { value: 'not-between_inc', label: 'Não está entre A e B (inclusive)', id: 'not-between_inc' },
  { value: 'between_inc', label: 'Entre A e B (inclusive)', id: 'between_inc' },
  { value: 'not-between', label: 'Não está entre A e B (exclusive)', id: 'not-between' },
];

export const filterTypeOptionsValues = [
  { value: 'none', label: 'Nenhum', id: 'none' },
  { value: 'greater', label: 'Maiores que N', id: 'greater' },
  { value: 'lesser', label: 'Menores que N', id: 'lesser' },
  { value: 'between', label: 'Entre A e B (exclusive)', id: 'between' },
  { value: 'greater_inc', label: 'Maiores ou iguais a N', id: 'greater_inc' },
  { value: 'lesser_inc', label: 'Menores ou iguais a N', id: 'lesser_inc' },
  { value: 'between_inc', label: 'Entre A e B (inclusive)', id: 'between_inc' },
  { value: 'most', label: 'N Maiores (sem empate)', id: 'most' },
  { value: 'least', label: 'N Menores (sem empate)', id: 'least' },
  { value: 'most_inc', label: 'N Maiores (com empate)', id: 'most_inc' },
  { value: 'least_inc', label: 'N Menores (com empate)', id: 'least_inc' },
];

export const getFilterTypeDescription = (format, isDate) => {
  switch (format) {
    case 'lastY':
      // Últimos N anos
      return `
        Considera apenas as datas correspondentes
        aos N últimos anos (incluindo o ano atual)
        da data selecionada
      `;
    case 'not-lastY':
      // Complementar dos últimos N anos
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos anos (excluindo o ano atual)
        da data selecionada
      `;
    case 'lastM':
      // Últimos N meses
      return `
        Considera apenas as datas correspondentes
        aos N últimos meses (incluindo o mês atual)
        da data selecionada
      `;
    case 'not-lastM':
      // Complementar dos últimos N meses
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos meses (excluindo o mês atual)
        da data selecionada
      `;
    case 'lastD':
      // Últimos N dias
      return `
        Considera apenas as datas correspondentes
        aos N últimos dias (incluindo o dia atual)
        da data selecionada
      `;
    case 'not-lastD':
      // Complementar dos últimos N dias
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos dias (excluindo o dia atual)
        da data selecionada
      `;
    case 'lastQ':
      // Últimos N trimestres
      return `
        Considera apenas as datas correspondentes
        aos N últimos trimestres (incluindo o trimestre
        atual) da data selecionada
      `;
    case 'not-lastQ':
      // Complementar dos últimos N trimestres
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos trimestres (excluindo
        o trimestre atual) da data selecionada
      `;
    case 'lastW':
      // Últimas N semanas
      return `
        Considera apenas as datas correspondentes
        as N últimas semanas (incluindo a semana
        atual) da data selecionada
      `;
    case 'not-lastW':
      // Complementar das últimos N semanas
      return `
        Considera apenas as datas correspondentes ao
        complementar das N últimos semanas (excluindo
        a semana atual) da data selecionada
      `;
    case 'after':
      // A partir da data N (exclusive)
      return `
        Considera apenas as datas posteriores,
        e não incluindo, a data selecionada
      `;
    case 'after_inc':
      // A partir da data N (inclusive)
      return `
        Considera apenas as datas posteriores,
        e incluindo, a data selecionada
      `;
    case 'before':
      // Antes da data N (exclusive)
      return `
        Considera apenas as datas anteriores,
        e não incluindo, a data selecionada
      `;
    case 'before_inc':
      // Antes da data N (inclusive)
      return `
        Considera apenas as datas anteriores,
        e incluindo, a data selecionada
      `;
    case 'between':
      if (isDate) {
        // Entre datas A e B (exclusive)
        return `
          Considera apenas as datas entre
          A e B, não incluindo A e B
        `;
      }
      // Entre A e B (exclusive)
      return `
        Considera apenas números entre
        A e B, não incluindo A e B
      `;
    case 'not-between':
      if (isDate) {
        // Não estão entre datas A e B (exclusive)
        return `
          Considera apenas as datas que não estão
          emtre A e B, não incluindo A e B
        `;
      }
      // Não estão entre A e B (exclusive)
      return `
        Considera apenas números que não estão
        entre A e B, não incluindo A e B
      `;
    case 'between_inc':
      if (isDate) {
        // Entre datas A e B (inclusive)
        return `
          Considera apenas as datas entre
          A e B, incluindo A e B
        `;
      }
      // Entre A e B (inclusive)
      return `
        Considera apenas números entre
        A e B, incluindo A e B
     `;
    case 'not-between_inc':
      if (isDate) {
        // Não estão entre datas A e B (inclusive)
        return `
          Considera apenas as datas que não estão
          entre A e B, incluindo A e B
        `;
      }
      // Não estão entre A e B (inclusive)
      return `
        Considera apenas números que não estão
        entre A e B, incluindo A e B
      `;
    case 'most':
      // N mais frequentes (sem empate)
      return `
        Considera apenas os N elementos que
        aparecem mais vezes na coluna, não
        considerando empates
      `;
    case 'not-most':
      // Não está entre os N mais frequentes (sem empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem mais 
        vezes na coluna, não considerando empates
      `;
    case 'most_inc':
      // N mais frequentes (com empate)
      return `
        Considera apenas os N elementos
        que aparecem mais vezes na coluna,
        considerando empates
      `;
    case 'not-most_inc':
      // Não está entre os N mais frequentes (com empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem mais 
        vezes na coluna, considerando empates
      `;
    case 'least':
      // N menos frequentes (sem empate)
      return `
        Considera apenas os N elementos que
        aparecem menos vezes na coluna, não
        considerando empates
      `;
    case 'not-least':
      // Não está entre os N menos frequentes (sem empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem menos 
        vezes na coluna, não considerando empates
      `;
    case 'least_inc':
      // N menos frequentes (com empate)
      return `
        Considera apenas os N elementos
        que aparecem menos vezes na coluna,
        considerando empates
      `;
    case 'not-least_inc':
      // Não está entre os N menos frequentes (com empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem menos 
        vezes na coluna, considerando empates
      `;
    case 'greater':
      // Maiores que N
      return `
        Considera apenas os valores maiores
        do que N, não incluindo N
      `;
    case 'greater_inc':
      // Maiores ou iguais a N
      return `
        Considera apenas os números maiores
        ou iguais a N
      `;
    case 'lesser':
      // Menores que N
      return `
        Considera apenas os valores menores
        do que N, não incluindo N
      `;
    case 'lesser_inc':
      // Menores que N (com empate)
      return `
        Considera apenas os números menores
        ou iguais a N
      `;
    case 'contains':
      // Contém correspondente
      return `
        Considera apenas os valores que 
        contêm o texto selecionado
      `;
    case 'not-contains':
      // Não contém correspondente
      return `
        Considera apenas os valores que 
        não contêm o texto selecionado
      `;
    default:
      return '';
  }
};

export const getGroupTypeDescription = (format, isDate) => {
  switch (format) {
    case 'lastY':
      // Últimos N anos
      return `
        Considera apenas as datas correspondentes
        aos N últimos anos (incluindo o ano atual)
        da data selecionada
      `;
    case 'not-lastY':
      // Complementar dos últimos N anos
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos anos (excluindo o ano atual)
        da data selecionada
      `;
    case 'lastM':
      // Últimos N meses
      return `
        Considera apenas as datas correspondentes
        aos N últimos meses (incluindo o mês atual)
        da data selecionada
      `;
    case 'not-lastM':
      // Complementar dos últimos N meses
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos meses (excluindo o mês atual)
        da data selecionada
      `;
    case 'lastD':
      // Últimos N dias
      return `
        Considera apenas as datas correspondentes
        aos N últimos dias (incluindo o dia atual)
        da data selecionada
      `;
    case 'not-lastD':
      // Complementar dos últimos N dias
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos dias (excluindo o dia atual)
        da data selecionada
      `;
    case 'lastQ':
      // Últimos N trimestres
      return `
        Considera apenas as datas correspondentes
        aos N últimos trimestres (incluindo o trimestre
        atual) da data selecionada
      `;
    case 'not-lastQ':
      // Complementar dos últimos N trimestres
      return `
        Considera apenas as datas correspondentes ao
        complementar dos N últimos trimestres (excluindo
        o trimestre atual) da data selecionada
      `;
    case 'lastW':
      // Últimas N semanas
      return `
        Considera apenas as datas correspondentes
        as N últimas semanas (incluindo a semana
        atual) da data selecionada
      `;
    case 'not-lastW':
      // Complementar das últimos N semanas
      return `
        Considera apenas as datas correspondentes ao
        complementar das N últimos semanas (excluindo
        a semana atual) da data selecionada
      `;
    case 'after':
      // A partir da data N (exclurisve)
      return `
        Considera apenas as datas posteriores,
        e não incluindo, a data selecionada
      `;
    case 'after_inc':
      // A partir da data N (inclusive)
      return `
        Considera apenas as datas posteriores,
        e incluindo, a data selecionada
      `;
    case 'before':
      // Antes da data N (exclusive)
      return `
        Considera apenas as datas anteriores,
        e não incluindo, a data selecionada
      `;
    case 'before_inc':
      // Antes da data N (inclusive)
      return `
        Considera apenas as datas anteriores,
        e incluindo, a data selecionada
      `;
    case 'between':
      if (isDate) {
        // Entre datas A e B (exclusive)
        return `
          Considera apenas as datas entre
          A e B, não incluindo A e B
        `;
      }
      // Entre A e B (exclusive)
      return `
        Considera apenas números entre
        A e B, não incluindo A e B
      `;
    case 'not-between':
      if (isDate) {
        // Não estão entre datas A e B (exclusive)
        return `
          Considera apenas as datas que não estão
          emtre A e B, não incluindo A e B
        `;
      }
      // Não estão entre A e B (exclusive)
      return `
        Considera apenas números que não estão
        entre A e B, não incluindo A e B
      `;
    case 'between_inc':
      if (isDate) {
        // Entre datas A e B (inclusive)
        return `
          Considera apenas as datas entre
          A e B, incluindo A e B
        `;
      }
      // Entre A e B (inclusive)
      return `
        Considera apenas números entre
        A e B, incluindo A e B
      `;
    case 'not-between_inc':
      if (isDate) {
        // Não estão entre datas A e B (inclusive)
        return `
          Considera apenas as datas que não estão
          entre A e B, incluindo A e B
        `;
      }
      // Não estão entre A e B (inclusive)
      return `
        Considera apenas números que não estão
        entre A e B, incluindo A e B
      `;
    case 'most':
      // N mais frequentes (sem empate)
      return `
        Considera apenas os N elementos que
        aparecem mais vezes na coluna, não
        considerando empates
      `;
    case 'not-most':
      // Não está entre os N mais frequentes (sem empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem mais 
        vezes na coluna, não considerando empates
      `;
    case 'most_inc':
      // N mais frequentes (com empate)
      return `
        Considera apenas os N elementos
        que aparecem mais vezes na coluna,
        considerando empates
      `;
    case 'not-most_inc':
      // Não está entre os N mais frequentes (com empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem mais 
        vezes na coluna, considerando empates
      `;
    case 'least':
      // N menos frequentes (sem empate)
      return `
        Considera apenas os N elementos que
        aparecem menos vezes na coluna, não
        considerando empates
      `;
    case 'not-least':
      // Não está entre os N menos frequentes (sem empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem menos 
        vezes na coluna, não considerando empates
      `;
    case 'least_inc':
      // N menos frequentes (com empate)
      return `
        Considera apenas os N elementos
        que aparecem menos vezes na coluna,
        considerando empates
      `;
    case 'not-least_inc':
      // Não está entre os N menos frequentes (com empate)
      return `
        Considera apenas os elementos que
        não estão entre os N que aparecem menos 
        vezes na coluna, considerando empates
      `;
    case 'greater':
      // Maiores que N (sem empate)
      return `
        Considera apenas os valores maiores
        do que N, não incluindo N
      `;
    case 'greater_inc':
      // Maiores que N (com empate)
      return `
        Considera apenas os valores maiores
        ou iguais a N
      `;
    case 'lesser':
      // Menores que N (sem empate)
      return `
        Considera apenas os valores menores
        do que N, não incluindo N
      `;
    case 'lesser_inc':
      // Menores que N (com empate)
      return `
        Considera apenas os valores menores
        ou iguais a N
      `;
    case 'contains':
      // Contém correspondente
      return `
        Considera apenas os valores que 
        contêm o texto selecionado
      `;
    case 'not-contains':
      // Não contém correspondente
      return `
        Considera apenas os valores que 
        não contêm o texto selecionado
      `;
    default:
      return '';
  }
};

export const controlVarDescription = `
  Permite criar visualizações diversas para o KPI criado a partir de filtros de valores desta variável.
`;

export const positionOptions = (isMapOrRadar) => (
  {
    Acima: {
      direction: 'row',
      anchor: 'top',
      translateX: 0,
      translateY: isMapOrRadar ? -50 : -30,
      selectedPosition: 'top',
      label: 'Acima',
    },
    Esquerda: {
      direction: 'column',
      anchor: 'left',
      translateX: -30,
      translateY: 0,
      selectedPosition: 'left',
      label: 'Esquerda',
    },
    Direita: {
      direction: 'column',
      anchor: 'right',
      translateX: isMapOrRadar ? -190 : 110,
      translateY: 0,
      selectedPosition: 'right',
      label: 'Direita',
    },
    Abaixo: {
      direction: 'row',
      anchor: 'bottom',
      translateX: 0,
      translateY: isMapOrRadar ? -100 : 30,
      selectedPosition: 'bot',
      label: 'Abaixo',
    },
  }
);

export const legendPositionOptions = (pos, opts = { isMapOrRadar: false, isWaffle: false }) => {
  switch (pos) {
    case 'Acima': return {
      direction: 'row',
      anchor: 'top',
      translateX: 0,
      translateY: opts.isMapOrRadar ? -50 : -30,
      selectedPosition: 'top',
      label: 'Acima',
    };
    case 'Esquerda': return {
      direction: 'column',
      anchor: 'left',
      translateX: opts.isWaffle ? 30 : -30,
      translateY: 0,
      selectedPosition: 'left',
      label: 'Esquerda',
    };
    case 'Direita': return {
      direction: 'column',
      anchor: 'right',
      translateX: opts.isMapOrRadar ? -190 : (opts.isWaffle ? 0 : 110),
      translateY: 0,
      selectedPosition: 'right',
      label: 'Direita',
    };
    case 'Abaixo': return {
      direction: 'row',
      anchor: 'bottom',
      translateX: 0,
      translateY: opts.isMapOrRadar ? -100 : 30,
      selectedPosition: 'bot',
      label: 'Abaixo',
    };
    default:
      return {};
  }
};

/**
 * Uploader constants start
 */
export const waitMessagesUpload = [
  'Aguarde...',
  'Aguarde..',
  'Aguarde.',
  'Verificando as colunas de texto',
  'Verificando as colunas numéricas',
  'Verificando as colunas de data',
  'Preparando o banco de dados',
  'Realizando alguns tratamentos',
  'Criando os metadados',
  'Ajustando alguns detalhes',
];
export const waitMessagesReUpload = [
  'Aguarde...',
  'Aguarde..',
  'Aguarde.',
  'Verificando a compatibilidade dos dados',
  'Atualizando as informações de metadados',
  'Alterando as informações no banco de dados',
  "Atualizando os KPI's",
  'Modificando os dashboards',
];

export const waitMessagesDonwload = [
  '',
  'O Legal Metrics está preparando o seu download.',
  'Isso pode demorar um pouco!',
  'Aguarde...',
  'Aguarde..',
  'Aguarde.',
];

/**
 * Uploader constants end
 */

/**
 * FilesViewer constants start
 */

export const filesViewerWaitMessagesReUpload = [
  'Aguarde...',
  'Aguarde..',
  'Aguarde.',
  'Verificando a compatibilidade dos dados',
  'Atualizando as informações de metadados',
  'Alterando as informações no banco de dados',
  "Atualizando os KPI's",
  'Modificando os dashboards',
];

/**
 * FilesViewer constants end
 */

/**
 * FilesViewer constants start
 */

export const labelCreateInitialState = {
  bold: false,
  underline: false,
  italic: false,
  name: '',
  link: '',
  fontColor: '#111',
  bgColor: '#ddd',
  fontFamily: { value: 'arial', label: 'Arial' },
  fontSize: 16,
  image: '',
  colorOpacityText: '#ddd',
  opacityText: 1,
  opacityImage: 1,
  imageFit: 'contain',
  textPosition: 'center',
};

/**
 * FilesViewer constants end
 */

/**
 * Create Instance constants start
 */

export const CreateInstanceFirstLoginMessages = [
  'Aguarde...',
  'Aguarde..',
  'Aguarde.',
  'Aguarde enquanto estamos carregando suas informações',
  'Se este for o seu primeiro acesso, sua instância está sendo criada. Isto pode demorar um pouquinho',
  'Verificando a autenticação do usuário',
  'Verificando informações adicionais de acesso',
  'Aguarde.',
  'Aguarde..',
  'Aguarde...',
];

/**
 * Create Instance constants end
 */

export const MuralLogActions = {
  user_add: 'adicionou o usuário',
  user_edit: 'alterou a permissão do usuário',
  user_remove: 'removeu o usuário',
  user_update: 'atualizou o usuário',
  dashboard_add: 'adicionou o dashboard',
  dashboard_edit: 'alterou o nome do dashboard',
  dashboard_remove: 'removeu o dashboard',
  dashboard_update: 'atualizou o dashboard',
  kpi_add: 'criou o kpi',
  kpi_edit: 'editou o kpi',
  kpi_remove: 'removeu o kpi',
  kpi_update: 'atualizou o kpi',
  story_add: 'criou o story',
  story_edit: 'alterou o nome do story',
  story_remove: 'removeu o story',
  story_update: 'atualizou o story',
  insight_add: 'criou um insight',
  insight_edit: 'editou um insight',
  insight_remove: 'removeu um insight',
  insight_update: 'atualizou um insight',
  get(type, action) {
    return this[`${type}_${action}`] || '';
  },
};

export const shareDashOptions = {
  visualizador: {
    id: 'read-id',
    label: 'Visualização',
    value: 'visualizador',
  },
  edit: {
    id: 'edit-id',
    label: 'Permitir cópia',
    value: 'edit',
  },
  get(k) {
    return this[k].label || 'Visualização';
  },
  getOptions() {
    return [this.visualizador, this.edit];
  },
};

export const shareFileOptions = {
  read: {
    id: 'read-id',
    label: 'Visualização',
    value: 'read',
  },
  write: {
    id: 'write-id',
    label: 'Permitir atualização',
    value: 'write',
  },
  get(k) {
    return this[k].label || 'Visualização';
  },
  getOptions() {
    return [this.read, this.write];
  },
};
