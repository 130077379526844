import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';

import { Last, Next } from '../../icons';

import { ArrowBtns, PaginationContainer } from './styled/TablePagination.styled';

/** Controls for DataTable pagination */
const TablePagination = ({
  page, totalPages, setPage,
}) => {
  /** Advances 1 page */
  const forwardPage = () => setPage((p) => p + 1);

  /** Returns 1 page */
  const backPage = () => setPage((p) => p - 1);

  /** Advance to the last page */
  const lastPage = () => setPage(totalPages);

  /** Returns to the first page */
  const firstPage = () => setPage(0);

  return (
    <PaginationContainer>
      <ArrowBtns>
        <IconButton
          shape="rounded"
          className="invert"
          disabled={page === 0}
          onClick={firstPage}
        >
          <Last />
        </IconButton>
        <IconButton
          shape="rounded"
          className="invert"
          disabled={page === 0}
          onClick={backPage}
        >
          <Next />
        </IconButton>

        <span>
          Página
          {' '}
          {page + 1}
          {' '}
          de
          {' '}
          {totalPages + 1}
        </span>

        <IconButton
          shape="rounded"
          disabled={page === (totalPages)}
          onClick={forwardPage}
        >
          <Next />
        </IconButton>
        <IconButton
          shape="rounded"
          disabled={page === (totalPages)}
          onClick={lastPage}
        >
          <Last />
        </IconButton>
      </ArrowBtns>
    </PaginationContainer>
  );
};

TablePagination.propTypes = {
  /** Actual page (starts at 0) */
  page: PropTypes.number.isRequired,
  /** Total of pages (length) */
  totalPages: PropTypes.number,
  /** Function to set a new actual page */
  setPage: PropTypes.func.isRequired,
};

TablePagination.defaultProps = {
  totalPages: 1,
};

export default TablePagination;
