import React from "react";

function DifficultyBar({ level = 1, levelColor = "#FF9626" }) {
  return (
    <svg
      width="37"
      height="10"
      viewBox="0 0 37 10"
      fill={levelColor + '30'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5H9.5V9.5H5C2.51472 9.5 0.5 7.48528 0.5 5Z"
        fill={level > 0 ? levelColor : ''}
        stroke="#212121"
      />
      <rect
        x="9.5"
        y="0.5"
        width="9"
        height="9"
        fill={level > 1 ? levelColor : ''}
        stroke="#212121"
      />
      <rect
        x="18.5"
        y="0.5"
        width="9"
        height="9"
        fill={level > 2 ? levelColor : ''}
        stroke="#212121"
      />
      <path
        d="M27.5 0.5H32C34.4853 0.5 36.5 2.51472 36.5 5C36.5 7.48528 34.4853 9.5 32 9.5H27.5V0.5Z"
        fill={level > 3 ? levelColor : ''}
        stroke="#212121"
      />
    </svg>
  );
}

export default DifficultyBar;
