import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../core/Tooltip';
import IconButton from '../../core/IconButton';

import {
  Chain, Close, Finder, UploadImage,
} from '../../icons';

import {
  StyledKpiContentLabel,
  BackgroundOptions,
  ImageOverlay,
} from './styled/Insight.styled';

const Insight = ({
  item, isEdit, handleImageUpload, openUnsplashPicker, removeImg, previewImg,
}) => {
  const inputFile = useRef(null);
  const setImg = () => (typeof item?.style?.image === 'string' ? item.style.image : previewImg);

  return (
    <StyledKpiContentLabel styles={item.style} img={setImg()} isEdit={isEdit}>
      <span style={{ zIndex: 2 }}>{item.name}</span>
      {item.link?.length > 0 && (
        <div style={{
          position: 'absolute', left: '4px', bottom: '4px', fontSize: 'initial',
        }}
        >
          <Tooltip direction="right" text={`Redirecionar para ${item.link}`}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <IconButton
                variant="outlined"
                size="small"
                shape="rounded"
                iconSize="18px"
                style={{ boxShadow: 'none' }}
              >
                <Chain />
              </IconButton>
            </a>
          </Tooltip>
        </div>
      )}
      {isEdit && (
        <ImageOverlay>
          <BackgroundOptions img={setImg()}>
            <Tooltip text="Adicionar nova imagem" direction="left" atModal>
              <IconButton
                onClick={() => inputFile.current.click()}
                color="#FFFFFF"
                size="large"
                className="image_trigger"
              >
                <UploadImage />
                <input
                  type="file"
                  ref={inputFile}
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
              </IconButton>
            </Tooltip>
            {setImg()?.length > 0 && (
              <Tooltip text="Remover imagem atual" direction="right" atModal>
                <IconButton
                  onClick={removeImg}
                  color="#FFFFFF"
                  size="large"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip text="Buscar fotos na web" direction="left" atModal>
              <IconButton
                color="#FFFFFF"
                size="large"
                onClick={openUnsplashPicker}
              >
                <Finder />
              </IconButton>
            </Tooltip>
          </BackgroundOptions>
        </ImageOverlay>
      )}
    </StyledKpiContentLabel>
  );
};

Insight.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool,
  handleImageUpload: PropTypes.func,
  openUnsplashPicker: PropTypes.func,
  removeImg: PropTypes.func,
  previewImg: PropTypes.string,
};

Insight.defaultProps = {
  isEdit: false,
  handleImageUpload: () => {},
  openUnsplashPicker: () => {},
  removeImg: () => {},
  previewImg: '',
};

export default Insight;
