export default {
  TitleControl: '',
  ShowTitleControl: { checked: true },
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  Colors: {},
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  ColumnToSortControl: 'index',
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  GroupingSelectControl: '',
  EnableGridX: true,
  EnableGridY: true,
  // LABELS
  LabelsSeparadorNumerico: true,
  LabelGroupingControl: 'Nenhum',
  LabelCurrencyControl: 'Nenhum',
  LabelsFontSizeControl: { value: 14 },
  LabelsFontFamilyControl: { value: 'arial' },
  SortValuesControl: 'Não ordenado',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
};
