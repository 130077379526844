import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../controllers/ControllersGroup';
import {
  InputLineController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  ButtonLineController,
  SimpleColorPickerController,
} from '../../controllers/Base';

import fontOptions from '../../utils/controllersUtils/options';

const OptionControllers = ({
  handle,
  config,
}) => (
  <>
    <ControllersGroup title="Opções" id="option" extend="option" alwaysOpen key={config?.kpiKey}>
      <InputLineController
        label="Conteúdo (texto)"
        handle={handle}
        kWord="name"
        configValue={config?.name?.trim()}
        fullWidth
      />
      <SelectController
        label="Posição do conteúdo"
        handle={handle}
        kWord="textPosition"
        options={[
          { label: 'Superior Esquerda', value: 'top-left', id: 'top-left' },
          { label: 'Superior Centralizado', value: 'top', id: 'top' },
          { label: 'Superior Direita', value: 'top-right', id: 'top-right' },
          { label: 'Central Esquerda', value: 'center-left', id: 'center-left' },
          { label: 'Centralizado', value: 'center', id: 'center' },
          { label: 'Central Direita', value: 'center-right', id: 'center-right' },
          { label: 'Inferior Esquerda', value: 'bottom-left', id: 'bottom-left' },
          { label: 'Inferior Centralizado', value: 'bottom', id: 'bottom' },
          { label: 'Inferior Direita', value: 'bottom-right', id: 'bottom-right' },
        ]}
        configValue={config?.textPosition}
        disabled={config?.name?.length === 0}
        fullWidth
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="fontFamily"
        options={fontOptions}
        configValue={config?.fontFamily?.value}
        disabled={config?.name?.length === 0}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="fontSize"
        configValue={config?.fontSize}
        min={1}
        max={80}
        isNumber
        disabled={config?.name?.length === 0}
      />
      <ButtonLineController
        handle={handle}
        boldKWord="bold"
        boldVal={config?.bold}
        italicKWord="italic"
        italicVal={config?.italic}
        underlineKWord="underline"
        underlineVal={config?.underline}
        fontColorKWord="fontColor"
        fontColorVal={config?.fontColor}
        fullWidth
        disabled={config?.name?.length === 0}
      />
      <SimpleColorPickerController
        label="Cor de destaque"
        handle={handle}
        kWord="colorOpacityText"
        configValue={config?.colorOpacityText}
        fullWidth
        orientation="row"
        disabled={config?.name?.length === 0}
      />
      <RangeController
        label="Opacidade do destaque"
        handle={handle}
        kWord="opacityText"
        configValue={config?.opacityText}
        min={0}
        max={1}
        step={0.1}
        precision={1}
        fullWidth
        disabled={config?.name?.length === 0}
      />
      <SimpleColorPickerController
        label="Cor de fundo"
        handle={handle}
        kWord="bgColor"
        configValue={config?.bgColor}
        fullWidth
        orientation="row"
      />
      <InputLineController
        label="Link externo (opcional)"
        handle={handle}
        kWord="link"
        configValue={config?.link}
        fullWidth
      />
      <ChoiceBoxController
        label="Ajuste da imagem"
        handle={handle}
        kWord="imageFit"
        options={[
          { label: 'Original', id: 'contain', value: 'contain' },
          { label: 'Preencher', id: 'cover', value: 'cover' },
        ]}
        configValue={config?.imageFit || 'contain'}
        fullWidth
        disabled={config?.image?.length === 0}
      />
      <RangeController
        label="Opacidade da imagem"
        handle={handle}
        kWord="opacityImage"
        configValue={config?.opacityImage}
        min={0.1}
        max={1}
        step={0.1}
        precision={1}
        fullWidth
        disabled={config?.image?.length === 0}
      />
    </ControllersGroup>
  </>
);

OptionControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
};

OptionControllers.defaultProps = {
  config: undefined,
  handle: () => {},
};

export default OptionControllers;
