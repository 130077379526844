import React, {
  useState, useRef, useMemo, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import { AlertContext } from '../../../context/AlertProvider';

// components
// import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import InputNumberLine from '../../../juristec-ui/core/InputNumberLine';
import Popover from '../../../juristec-ui/core/Popover';
import DataTable from '../../../juristec-ui/core/DataTable';
import IconButton from '../../../juristec-ui/core/IconButton';
import Button from '../../../juristec-ui/core/Button';
import ListItem from '../../../juristec-ui/core/ListItem';
import List from '../../../juristec-ui/core/List';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Select from '../../../juristec-ui/core/Select';

// others
import {
  DateIcon, ExpandMore, Letters, Number, Play,
} from '../../../juristec-ui/icons';

import {
  csvDateFormatOptions,
  xlsxDateFormatOptions,
  decimalOptions,
} from '../../../options';
import {
  MainContainer,
  ModalHeader,
  ModalFooter,
  ColorLabelContainer,
  HelpContainer,
  IconWrapper,
  IconFlipWrapper,
  LinkIconWrapper,
  ScrollXContainer,
} from './styled/FileValidation.styled';

const typeColor = {
  float: 'typeNumber',
  abc: 'typeText',
  date: 'typeDate',
};

const typeIcon = {
  float: <Number />,
  abc: <Letters />,
  date: <DateIcon />,
};

const legacyTypes = {
  float64: 'float',
  int64: 'float',
  'datetime64[ns]': 'date',
  object: 'abc',
  category: 'abc',
  get(k) {
    return this[k] || k;
  },
};

const FileValidation = ({
  hide,
  fileData: fileDataProp,
  filename,
  reopenFile,
  submitData,
  required,
  tourContext = { tourOpen: false },
}) => {
  const theme = useTheme();
  const [columnTypes, setColumnTypes] = useState(
    Object.keys(fileDataProp.types).reduce((acc, cur) => {
      acc[cur] = legacyTypes.get(fileDataProp.types[cur]);
      return acc;
    }, {}),
  );

  const [inputValue, setInputValue] = useState(1);
  const [blockInput, setBlockInput] = useState(false);
  const [openPopoverType, setOpenPopoverType] = useState(
    fileDataProp.columns.reduce((aux, column) => {
      aux[column] = false;
      return aux;
    }, {}),
  );

  const isCsv = useMemo(() => filename.split('.').pop() === 'csv', [filename]);
  const [selectedDateFormat, setSelectedDateFormat] = useState(isCsv
    ? csvDateFormatOptions[0] : xlsxDateFormatOptions[0]);
  // const startLine = useRef(0);

  const [selectedDecimal, setSelecteDecimal] = useState(decimalOptions[0]);
  const [fileData, setFileData] = useState(fileDataProp);

  const cronRef = useRef(null);
  const scrollRef = useRef(null);
  const inputRef = useRef(null);

  const { setAlertConfig } = useContext(AlertContext);

  const handlePopoverType = (field, isOpen) => {
    setOpenPopoverType((old) => ({ ...old, [field]: isOpen }));
  };

  const handleColumnType = (field, value) => {
    setColumnTypes((old) => ({
      ...old,
      [field]: value,
    }));
  };

  const handleReOpen = async (value) => {
    setBlockInput(true);
    const res = await reopenFile(filename, selectedDecimal?.value || ',', value);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      setInputValue(inputRef?.current?.lastvalue || 1);
    } else {
      const aux = Object.keys(res.res.types).reduce((acc, cur) => {
        acc[cur] = legacyTypes.get(res.res.types[cur]);
        return acc;
      }, {});
      setColumnTypes(aux);
      setFileData(res.res);
      inputRef.current.lastvalue = value + 1;
    }
    setBlockInput(false);
  };

  const handleStartLine = (value) => {
    if (value.length === 0) {
      setInputValue(value);
      return;
    }
    if (value === '0') {
      value = 1;
      setInputValue(1);
    } else if (/^[0-9]+$/.test(value)) {
      setInputValue(value);
    }

    if (value === inputValue) return;

    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      if (value) handleReOpen(value - 1);
    }, 300);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleSubmit = () => {
    if (tourContext.tourOpen) tourContext.nextStep();
    const floatColumns = [];
    const dateColumns = [];
    const textColumns = [];
    Object.keys(columnTypes).forEach((column) => {
      if (columnTypes[column] === 'float') floatColumns.push(column);
      else if (columnTypes[column] === 'date') dateColumns.push(column);
      else textColumns.push(column);
    });

    submitData({
      selectedDecimal: selectedDecimal.value,
      dateFormat: selectedDateFormat.value,
      floatColumns,
      dateColumns,
      textColumns,
      startLine: inputValue - 1,
      columnTypes,
    });
  };

  useEffect(() => {
    if (!blockInput && inputRef.current) inputRef.current.focus(); // para qnd sair do disable
  }, [blockInput]);

  // useEffect(() => {
  //   const scrollToHorizontal = (e) => {
  //     if (!e.deltaY) return;
  //     e.preventDefault();
  //     scrollRef.current.scrollLeft += e.deltaY + e.deltaX;
  //   };

  //   scrollRef.current.addEventListener('wheel', scrollToHorizontal, { passive: false });
  //   return () => scrollRef.current.removeEventListener('wheel', scrollToHorizontal);
  // }, []);

  // Seletor de tipos no header da tabela
  const TypePicker = (field) => (
    <Popover
      open={openPopoverType[field]}
      closePopover={() => handlePopoverType(field, false)}
      style={{
        zIndex: '1050',
      }}
    >
      <Popover.Action>
        <IconButton
          onClick={() => handlePopoverType(field, !openPopoverType[field])}
          shape="rounded"
          variant="contained"
          color={theme[typeColor[legacyTypes.get(columnTypes[field])]]}
          style={{ padding: '0 0 0 5px', marginLeft: '5px' }}
        >
          <IconWrapper>{typeIcon[legacyTypes.get(columnTypes[field])]}</IconWrapper>
          <IconFlipWrapper flip={openPopoverType[field]}>
            <ExpandMore />
          </IconFlipWrapper>
        </IconButton>
      </Popover.Action>
      <Popover.Content direction="bottom-end">
        <List>
          <ListItem
            noOutline
            onClick={() => handleColumnType(field, 'abc')}
          >
            <Letters />
            Validar como texto
          </ListItem>
          <ListItem
            noOutline
            onClick={() => handleColumnType(field, 'float')}
          >
            <Number />
            Validar como número
          </ListItem>

          <ListItem
            noOutline
            onClick={() => handleColumnType(field, 'date')}
          >
            <DateIcon />
            Validar como data
          </ListItem>

        </List>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <MainContainer className="modal_validation_content">
        <ModalHeader isCsv={isCsv}>
          <span>
            Voilá! O seu relatório foi configurado com sucesso.
            Agora, você precisa apenas validar se a classificação das
            colunas da sua tabela está correta.
            Por favor, verifique se as informações conferem e, se necessário,
            faça as alterações convenientes.
          </span>
          <div className="container-input-select">
            {required && isCsv && (
              <>
                <div className="input-container">
                  <Select
                    selectLabel="Selecione o formato para data"
                    options={isCsv ? csvDateFormatOptions : xlsxDateFormatOptions}
                    value={selectedDateFormat}
                    onChange={setSelectedDateFormat}
                    atModal
                  />
                </div>
                <div className="input-container">
                  <Select
                    selectLabel="Separador decimal"
                    id="file-validation-decimal"
                    // style={{ width: '50%' }}
                    options={decimalOptions}
                    value={selectedDecimal}
                    onChange={setSelecteDecimal}
                    placeholder="Selecione o separador decimal"
                    fullWidth
                    atModal
                  />
                </div>
              </>
            )}
            {required && (
              <div className="input-container">
                <InputNumberLine
                  ref={inputRef}
                  label="Iniciar a partir da linha"
                  disabled={blockInput}
                  value={inputValue}
                  onChange={(e) => handleStartLine(e.target.value)}
                  setValue={handleStartLine}
                  min={1}
                  // onBlur={(e) => checkStartLine(e.target.value)}
                />
              </div>
            )}
          </div>
        </ModalHeader>
        <ScrollXContainer ref={scrollRef} className="file-validation-table-scrolls" tabIndex="0">
          <DataTable
            columns={fileData.columns.reduce((aux, column, i) => {
              if (column === 'index') return aux;
              aux.push({
                ...column,
                label: column,
                field: column,
                tools: TypePicker(column),
                dataColor: theme[typeColor[legacyTypes.get(columnTypes[column])]],
                valueGetter: (param) => param[i],
              });
              return aux;
            }, [])}
            rowData={fileData.data.slice(0, 5)}
            tbodyStyle={{
              fontSize: '13px',
            }}
            headerColor="transparent"
            theadStyle={{
              position: 'sticky',
              top: '-5px',
              zIndex: 1,
              backgroundColor: theme.modal,
            }}
          />
        </ScrollXContainer>
        <ModalFooter className="validation_footer">
          <ColorLabelContainer>
            <div className="grey-square" />
            <span>Texto</span>
          </ColorLabelContainer>
          <ColorLabelContainer>
            <div className="green-square" />
            <span>Número</span>
          </ColorLabelContainer>
          <ColorLabelContainer>
            <div className="yellow-square" />
            <span>Data</span>
          </ColorLabelContainer>

          <HelpContainer>
            <Button
              variant="pattern"
              size="small"
              shape="rounded"
              color="info"
              // TODO: trocar para o link do tutorial
              onClick={() => openInNewTab('https://www.google.com.br')}
              style={{ gap: '5px', minWidth: 'auto' }}
            >
              <LinkIconWrapper>
                <Play />
              </LinkIconWrapper>
              Veja como fazer!
            </Button>
          </HelpContainer>
        </ModalFooter>
      </MainContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          {required ? 'Cancelar' : 'Pular'}
        </Button>
        <Button style={{ margin: '5px' }} size="large" onClick={handleSubmit}>
          Validar
        </Button>
      </ActionsGroup>
    </>
  );
};

FileValidation.propTypes = {
  /**
   * The name of the file
   */
  filename: PropTypes.string.isRequired,
  /**
   * A object that contains the data of the file (columns, rows, types)
   */
  fileData: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.string,
    ),
    data: PropTypes.arrayOf(PropTypes.string),
    types: PropTypes.objectOf(PropTypes.string.isRequired),
  }).isRequired,
  /**
   *
  */
  reopenFile: PropTypes.func.isRequired,
  /**
   * A function that sends the data back to the parent
   */
  submitData: PropTypes.func.isRequired,
  /**
   * A function that closes the modal
   */
  hide: PropTypes.func.isRequired,

  required: PropTypes.bool,
};

FileValidation.defaultProps = {
  required: true,
};

export default FileValidation;
