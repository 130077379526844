import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Tooltip from '../../../core/Tooltip';
import IconButton from '../../../core/IconButton';

import { Container, CarouselButton, CarouselContainer } from './styled/KpiCarousel.styled';

import {
  ChartValue,
  ChartPie,
  ChartLine,
  ChartBar,
  ChartRadar,
  ChartWorldMap,
  ChartFunnel,
  ChartTable,
  ChartRadialBar,
  ChartCalendar,
  ChartWaffle,
  ExpandMoreWide,
} from '../../../icons';

const KpiCarousel = ({
  selectedChartType, setChartType, disabled,
}) => {
  const [showControls, setShowControls] = useState(false);
  const carouselRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      if (carouselRef?.current?.scrollWidth > carouselRef?.current?.offsetWidth) {
        setShowControls(true);
      } else {
        setShowControls(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = (side) => {
    if (carouselRef.current) {
      if (side === 'right') {
        carouselRef.current.scrollLeft = carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
      } else {
        carouselRef.current.scrollLeft = 0;
      }
    }
  };

  return (
    <Container>
      {showControls && (
        <CarouselButton className="left-arrow" onClick={() => handleScroll('left')}><ExpandMoreWide /></CarouselButton>
      )}
      <CarouselContainer id="kpiType" showControls={showControls} ref={carouselRef}>
        <Tooltip text="Tabela" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Table')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Table' ? 'active' : ''}`}
          >
            <ChartTable primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Valor" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Value')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Value' ? 'active' : ''}`}
          >
            <ChartValue primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Barra" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Bar')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Bar' ? 'active' : ''}`}
          >
            <ChartBar primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Linha" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Line')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Line' ? 'active' : ''}`}
          >
            <ChartLine primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Pizza" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Pie')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Pie' ? 'active' : ''}`}
          >
            <ChartPie primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Radar" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Radar')}
            className={`kpi-carousel-charts-btns radar ${selectedChartType === 'Radar' ? 'active' : ''}`}
          >
            <ChartRadar primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Geográfico" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Map')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Map' ? 'active' : ''}`}
          >
            <ChartWorldMap primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Funil" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Funnel')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Funnel' ? 'active' : ''}`}
          >
            <ChartFunnel primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Barra Radial" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('RadialBar')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'RadialBar' ? 'active' : ''}`}
          >
            <ChartRadialBar primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Calendário" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Calendar')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Calendar' ? 'active' : ''}`}
          >
            <ChartCalendar primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>

        <Tooltip text="Waffle" className={disabled ? 'disabled' : ''}>
          <IconButton
            disabled={disabled}
            shape="rounded"
            color="n"
            variant="contained"
            size="large"
            onClick={() => setChartType('Waffle')}
            className={`kpi-carousel-charts-btns ${selectedChartType === 'Waffle' ? 'active' : ''}`}
          >
            <ChartWaffle primary={theme.primary} secondary={theme.secondary} />
          </IconButton>
        </Tooltip>
      </CarouselContainer>
      {showControls && (
        <CarouselButton className="right-arrow" onClick={() => handleScroll('right')}><ExpandMoreWide /></CarouselButton>
      )}
    </Container>
  );
};

KpiCarousel.propTypes = {
  selectedChartType: PropTypes.string,
  disabled: PropTypes.bool,
  setChartType: PropTypes.func,
};

KpiCarousel.defaultProps = {
  selectedChartType: '',
  disabled: false,
  setChartType: () => {},
};

export default KpiCarousel;
