/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import GeneralControllers from './GeneralControllers';
import StyleControllers from './StyleControllers';
import LegendControllers from './LegendControllers';
import InteractivityController from './InteractivityController';

const Controllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
}) => (
  <>
    {[
      GeneralControllers,
      StyleControllers,
      LegendControllers,
      InteractivityController,
    ].map((Group) => (
      <Group
        key={Group.name}
        handle={handle}
        config={config}
        data={data}
        extend={extend}
        setExtend={setExtend}
      />
    ))}
  </>
);

Controllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

Controllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default Controllers;
