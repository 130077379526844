import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
import FourthStep from './Steps/FourthStep';

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { dateOptions } from '../../../options';
import compare from '../../../utils/functions/sorting';

/**
 * Component with steps to apply a template to a file
 */
const ApplyTemplate = ({
  hide,
  templates,
  fileColumns,
  onSelectFilter,
  submitData,
  editTemplate,
  deleteTemplate,
  tourContext = { tourOpen: false },
}) => {
  const [columnToFilter, setColumnToFilter] = useState({ label: '', value: '' });
  const [dateFormat, setDateFormat] = useState(dateOptions[0]);
  const [selected, setSelected] = useState(templates[0]);
  const [columnVarBinds, setColumnVarBinds] = useState({});
  //
  const [filterColumns, setFilterColumns] = useState([]);
  const [columnData, setColumnData] = useState([]);
  //
  const [newDashName, setNewDashName] = useState({
    value: '',
    error: true,
    errorMsg: '',
  });

  useEffect(() => {
    setColumnVarBinds({});
    setFilterColumns([]);
    setNewDashName({
      value: '',
      error: true,
      errorMsg: '',
    });
  }, [selected]);

  useEffect(() => {
    if (columnToFilter.value) {
      (async () => {
        const aux = await onSelectFilter(
          columnToFilter.value,
          columnToFilter.type === 'datetime64[ns]' ? dateFormat.value : '',
        );
        setColumnData(aux);
      })();
    }
  }, [columnToFilter, dateFormat]);

  const checkVarBinds = () => (
    Object.keys(selected.columnsRequired).length
      === Object.keys(columnVarBinds).length
  );

  const newDashNameHandle = (val) => {
    const msg = verifyInput(val, true, 2, 40);
    setNewDashName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const checkNewDashName = () => {
    if (newDashName.error) {
      newDashNameHandle(newDashName.value);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    submitData({
      template: selected,
      name: newDashName.value,
      bindings: columnVarBinds,
      filters: filterColumns,
    });
  };

  const handleEdit = () => {
    editTemplate(selected);
    hide();
  };

  const handleDelete = () => {
    deleteTemplate(selected);
  };

  const firstStep = {
    label: 'Template',
    content: (
      <FirstStep
        templates={templates.sort((a, b) => compare(a.name, b.name))}
        selected={selected}
        setSelected={setSelected}
        editFunc={editTemplate ? handleEdit : null}
        deleteFunc={deleteTemplate ? handleDelete : null}
      />
    ),
  };

  const secondStep = {
    label: 'Variáveis',
    validation: checkVarBinds,
    content: (
      <SecondStep
        selected={selected}
        columnVarBinds={columnVarBinds}
        setColumnVarBinds={setColumnVarBinds}
        fileColumns={fileColumns}
      />
    ),
  };

  const thirdStep = {
    label: 'Filtro',
    content: (
      <ThirdStep
        fileColumns={fileColumns}
        columnToFilter={columnToFilter}
        setColumnToFilter={setColumnToFilter}
        columnData={columnData}
        filterColumns={filterColumns}
        setFilterColumns={setFilterColumns}
        dateFormat={dateFormat}
        setDateFormat={setDateFormat}
        dateOptions={dateOptions}
      />
    ),
  };

  const fourthStep = {
    label: 'Criação',
    validation: checkNewDashName,
    content: (
      <FourthStep
        newDashName={newDashName}
        newDashNameHandle={newDashNameHandle}
      />
    ),
  };

  return (
    <>
      <StepProgress
        steps={[firstStep, secondStep, thirdStep, fourthStep]}
        hide={hide}
        complete={handleSubmit}
        markersWidth="large"
        /* additionalButton={editTemplate ? (
          {
            label: 'Editar',
            action: () => { editTemplate(selected); hide(); },
            steps: [0],
          }
        ) : null} */
        tourContext={tourContext}
      />
    </>
  );
};

ApplyTemplate.propTypes = {
  /**
   * Function to close the modal
   */
  hide: PropTypes.func.isRequired,
  /**
   * Array of available templates
   */
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tutorial: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.string,
      keywords: PropTypes.arrayOf(PropTypes.string),
      imageSrc: PropTypes.string,
      variables: PropTypes.shape({}),
    }),
  ).isRequired,
  /**
   * Array of columns names of the selected file
   */
  fileColumns: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
  })).isRequired,

  /**
   * A function that sends the new data back to the parent
   */
  submitData: PropTypes.func.isRequired,

  /**
   * A function that runs when filter column is selected.
   */
  onSelectFilter: PropTypes.func.isRequired,
  /**
   * A function that allows the admin to edit the selected template
   */
  editTemplate: PropTypes.func,

  /**
   * A function that allows the admin to remove the selected template
   */
  deleteTemplate: PropTypes.func,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

ApplyTemplate.defaultProps = {
  tourContext: {
    tourOpen: false,
  },
  editTemplate: null,
  deleteTemplate: null,
};

export default ApplyTemplate;
