import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

import { getOptionsThemes } from '../../../utils/controllersUtils/themeColors';

const StyleControllers = ({
  handle,
  config,
  data,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup 
      title="Estilos"
      id="styles"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={config?.PalletsControl === 'custom' ? data?.index?.length : 0}
    >
      <RangeController
        label="Tamanho da borda"
        handle={handle}
        kWord="BorderWidthControl"
        configValue={config?.BorderWidthControl?.value}
        min={0}
        max={20}
        fullWidth
      />
      <ChoiceBoxController
        label="Cor da borda"
        handle={handle}
        kWord="BorderColorControl2"
        options={[
          { value: 'brighter', label: 'Clara', id: 'brighter' },
          { value: 'darker', label: 'Escura', id: 'darker' },
          { value: 'custom', label: 'Customizada', id: 'custom' },
        ]}
        configValue={config?.BorderColorControl?.modifiers?.[0]?.[0] || 'custom'}
        fullWidth
        disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
      />
      {typeof config?.BorderColorControl !== 'string' && (
        <RangeController
          label="Opacidade da borda"
          handle={handle}
          kWord="BorderOpacityControl"
          configValue={config?.BorderColorControl?.modifiers?.[0]?.[1] || '1'}
          min={0}
          max={2}
          step={0.1}
          precision={1}
          fullWidth
          disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
        />
      )}
      {typeof config?.BorderColorControl === 'string' && (
        <SimpleColorPickerController
          label="Cor customizada"
          handle={handle}
          kWord="BorderColorControl"
          configValue={config?.BorderColorControl}
          orientation="row"
          fullWidth
          disabled={!config?.BorderWidthControl?.value || config?.BorderWidthControl?.value.toString() === '0'}
        />
      )}
      <ChoiceBoxController
        label="Paleta de cores"
        handle={handle}
        kWord="PalletsControl"
        options={[
          { label: 'Padrão', value: 'default', id: 'default' },
          { label: 'Degradê', value: 'gradient', id: 'gradient' },
          { label: 'Customizada', value: 'custom', id: 'custom' },
        ]}
        configValue={config?.PalletsControl}
        fullWidth
      />
      {config?.PalletsControl === 'default' && (
        <SelectController
          label="Tema"
          handle={handle}
          kWord="ColorTheme"
          options={getOptionsThemes()}
          configValue={config?.ColorTheme?.scheme}
          fullWidth
        />
      )}
      {config?.PalletsControl === 'gradient' && (
        <>
          <SimpleColorPickerController
            label="Cor inicial"
            handle={handle}
            kWord="GradientFirstColor"
            configValue={config?.GradientPickerControl?.color1 || '#4711B2'}
            orientation="row"
          />
          <SimpleColorPickerController
            label="Cor final"
            handle={handle}
            kWord="GradientSecondColor"
            configValue={config?.GradientPickerControl?.color2 || '#FF9626'}
            orientation="row"
          />
        </>
      )}
      {config?.PalletsControl === 'custom' && (
        data?.index?.map((col, i) => {
          if (col === 'Totais') return null;
          return (
            <SimpleColorPickerController
              dataColorTarget={col.toString()}
              key={`${col}_i${i}`}
              label={col}
              handle={handle}
              kWord="Colors"
              configValue={config?.Colors[col]}
              orientation="row"
              fullWidth
            />
          );
        })
      )}
    </ControllersGroup>
  </>
);

StyleControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

StyleControllers.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default StyleControllers;
