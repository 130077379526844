import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../../charts';
import InsightCard from '../../insight';

import filterByValues from '../../utils/formatCharts/filterByValues';
import groupByValues from '../../utils/formatCharts/groupByValues';

import useOnScreen from '../../../hooks/useOnScreen';
import checkDataSize from '../../utils/chartTools/kpiMsg';

import {
  StyledCardKpi,
  StyledCardKpiTitle,
  StyledCardKpiContent,
} from './styled/CardKpi.styled';

// import handleStyleConf from '../KpiCreate/handleStyleConfig';

const CardKpi = React.forwardRef((props, ref) => {
  const {
    item,
    handleStyleConfig,
    stateDashboardLayout,
    editPainel,
    dashSize,
    id,
    showToPrint,
    isDataLoading,
  } = props;

  const {
    name: title,
  } = item;

  // Retorna se o kpi esta visivel na tela ou nao
  const isVisible = showToPrint || useOnScreen(ref);

  function modifyData(filterList, groupList, data, lineName) {
    let newData = data;
    if (filterList?.length > 0) {
      newData = filterByValues(filterList, newData, lineName);
    }
    if (groupList?.length > 0) {
      newData = groupByValues(groupList, newData, lineName);
    }
    return newData;
  }

  const data = (item?.data?.data?.length > 0) ? (
    modifyData(
      item?.meta?.filterValues,
      item?.meta?.groupValues,
      item.data,
      item?.meta?.lines?.[0]?.column,
    )
  ) : (
    { index: [], data: [], columns: [] }
  );

  return (
    <StyledCardKpi
      ref={ref}
      titleFontSize={
        item.style?.ShowTitleControl?.checked ? item.style?.StyleTitleControl?.fontSize : 0
      }
      showTitle={item.style?.ShowTitleControl?.checked}
      id={id}
    >
      {item.type === 'label' ? (
        <>
          <InsightCard
            item={item}
          />
        </>
      ) : (
        <>
          {item.style?.ShowTitleControl?.checked === true ? (
            <StyledCardKpiTitle
              style={item.style.StyleTitleControl}
              background={item.style.type !== 'Value' ? '' : item.style.GlobalValue.background}
            >
              <span>{title}</span>
            </StyledCardKpiTitle>
          ) : <></>}
          <StyledCardKpiContent type={item.type}>
            {checkDataSize(data, item.type, isVisible, dashSize, isDataLoading) || (
              <>
                <Chart
                  id="svg"
                  type={item.type}
                  data={data}
                  valueFunction={item.meta?.values?.[0]?.map}
                  styleConfig={item.style}
                  kpiSize={stateDashboardLayout?.lg?.filter((d) => d?.i === item?.id)}
                  handleStyleConfig={(param1, param2) => handleStyleConfig(param2, param1)}
                  isEdit={editPainel}
                />
              </>
            )}
          </StyledCardKpiContent>
        </>
      )}
    </StyledCardKpi>
  );
});

CardKpi.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleStyleConfig: PropTypes.func,
  editPainel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  dashSize: PropTypes.number,
  id: PropTypes.string,
  showToPrint: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  stateDashboardLayout: PropTypes.objectOf(PropTypes.any),
};

CardKpi.defaultProps = {
  dashSize: undefined,
  id: undefined,
  stateDashboardLayout: undefined,
  handleStyleConfig: undefined,
  showToPrint: false,
  isDataLoading: false,
};

export default CardKpi;
