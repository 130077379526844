import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ResponsiveValue, { Container } from './styled/Value.styled';
import { genDataValue } from '../../utils/formatCharts/handleData';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';

const Value = ({ data, styleConfig, valueFunction }) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genDataValue(data, styleConfig, valueFunction === 'mean'));
  }, [data, valueFunction, styleConfig?.TotalFunctionControl]);

  const formatValue = (value) => customValueLabel(value, styleConfig);

  return chartData.ready ? (
    <Container background={styleConfig?.GlobalValue?.background}>
      <ResponsiveValue
        fontSize={styleConfig?.GlobalValue?.fontSize || 20}
        color={styleConfig?.GlobalValue?.color || '#222222'}
        fontFamily={styleConfig?.GlobalValue?.fontFamily || 'Arial'}
        bold={styleConfig?.GlobalValue?.bold}
        underline={styleConfig?.GlobalValue?.underline}
        italic={styleConfig?.GlobalValue?.italic}
      >
        {formatValue(chartData.value)}
        <span className="desc">{styleConfig?.DescriptionControl}</span>
      </ResponsiveValue>
    </Container>
  ) : <></>;
};

Value.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  styleConfig: PropTypes.shape({
    DescriptionControl: PropTypes.string,
    GlobalValue: PropTypes.shape({
      background: PropTypes.string,
      bold: PropTypes.bool,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.number,
      italic: PropTypes.bool,
      underline: PropTypes.bool,
    }),
    TotalFunctionControl: PropTypes.string,
    GroupingSelectControl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    LabelCurrencyControl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    LabelsSeparadorNumerico: PropTypes.bool,
    separadorNumericoCasas: PropTypes.number,
    PrefixControl: PropTypes.string,
    SufixControl: PropTypes.string,
  }).isRequired,
  valueFunction: PropTypes.string.isRequired,
};

export default Value;
