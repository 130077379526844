/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import RangeBar from '../../../juristec-ui/core/RangeBar';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Toggle from '../../../juristec-ui/core/Toggle';
import ColorPicker from '../../../juristec-ui/core/ColorPicker2';

import {
  BackgroundColor, LineColor, ExpandMore, ChartLine,
} from '../../../juristec-ui/icons';

import {
  MainContainer,
  MainColumn,
  Row,
  Label,
  ContainerInput,
  ContainerInputRow,
  NumberControls,
  CardPreviewContainer,
  CardPreview,
  MainRow,
} from './styled/CardStyle.styled';

const CardStyle = ({ stylesData, submit, close }) => {
  const cron = useRef();

  const [_stylesData, _setStylesData] = useState(stylesData || {
    backgroundColor: '#ffffff',
    borderStyle: {
      color: '#9a9a9a',
      line: 'solid',
      rounded: 4,
      size: 1,
    },
    shading: false,
    spaceBetween: 10,
  });

  const handleSubmit = () => {
    submit(_stylesData);
  };

  const simpleHandle = (key, value) => {
    _setStylesData({ ..._stylesData, [key]: value });
  };

  const handleBorderStyle = (key, value) => {
    _setStylesData({ ..._stylesData, borderStyle: { ..._stylesData.borderStyle, [key]: value } });
  };

  const handleInput = (key, value, isBorder) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      if (isBorder) {
        handleBorderStyle(key, value);
      } else {
        simpleHandle(key, value);
      }
    }, 200);
  };

  const handleOpsBtns = (value = '', op = 0, max, key, isBorder) => {
    const numericVal = Math.max(Math.min((Number(value) || 0) + op, max), 0).toFixed(0);
    if (isBorder) handleBorderStyle(key, numericVal);
    else simpleHandle(key, numericVal);
  };

  const handleTxtVal = (value = '', key, max, isBorder) => {
    [value] = value.toString().match(/^\d*/);
    if (value === '') {
      if (isBorder) handleBorderStyle(key, value);
      else simpleHandle(key, value);
    } else {
      const numericVal = (Number(value) || 0);
      if (value && !Number.isNaN(+value)) {
        value = Math.max(Math.min(numericVal, max), 0);
      } else {
        value = 0;
      }
      if (isBorder) handleBorderStyle(key, value);
      else simpleHandle(key, value);
    }
  };

  const handleNumVal = (value = '', key, isBorder) => {
    if (value === '') {
      if (isBorder) handleBorderStyle(key, 0);
      else simpleHandle(key, 0);
    }
  };

  return (
    <MainContainer>
      <MainRow style={{ columnGap: '10px' }}>
        <MainColumn>
          <ContainerInput>
            <Label>Tipo da borda</Label>
            <Row className="buttons">
              <Button
                shape="square"
                size="small"
                className="choicebox-btn"
                variant={_stylesData.borderStyle.line === '' ? 'contained' : 'outlined'}
                onClick={() => handleBorderStyle('line', '')}
              >
                Nenhuma
              </Button>
              <Button
                shape="square"
                size="small"
                className="choicebox-btn"
                variant={_stylesData.borderStyle.line === 'solid' ? 'contained' : 'outlined'}
                onClick={() => handleBorderStyle('line', 'solid')}
              >
                Contínua
              </Button>
              <Button
                shape="square"
                size="small"
                className="choicebox-btn"
                variant={_stylesData.borderStyle.line === 'dashed' ? 'contained' : 'outlined'}
                onClick={() => handleBorderStyle('line', 'dashed')}
              >
                Tracejada
              </Button>
              <Button
                shape="square"
                size="small"
                className="choicebox-btn"
                variant={_stylesData.borderStyle.line === 'dotted' ? 'contained' : 'outlined'}
                onClick={() => handleBorderStyle('line', 'dotted')}
              >
                Pontilhada
              </Button>
            </Row>
          </ContainerInput>

          <ContainerInputRow disabled={_stylesData.borderStyle.line === ''}>
            <Label>Cor da borda</Label>
            <ColorPicker
              onChange={(val) => handleInput('color', val, true)}
              color={_stylesData.borderStyle.color}
              isIconButton
              atModal
            >
              <LineColor />
            </ColorPicker>
          </ContainerInputRow>

          <ContainerInput disabled={_stylesData.borderStyle.line === ''}>
            <Label>Espessura da borda</Label>
            <Row style={{ width: '100%', alignItems: 'center', gap: '10px' }}>
              <Row style={{ width: '35%', position: 'relative' }}>
                <InputTextLine
                  value={_stylesData.borderStyle.size}
                  onChange={(e) => handleTxtVal(e.target.value, 'size', 10, true)}
                  onBlur={() => handleNumVal(_stylesData.borderStyle.size, 'size', true)}
                  style={{ textAlign: 'center' }}
                />
                <NumberControls>
                  <button
                    type="button"
                    className="number-increase"
                    onClick={() => handleOpsBtns(_stylesData.borderStyle.size, 1, 10, 'size', true)}
                  >
                    <ExpandMore />
                  </button>
                  <button
                    type="button"
                    className="number-decrease"
                    onClick={() => handleOpsBtns(_stylesData.borderStyle.size, -1, 10, 'size', true)}
                  >
                    <ExpandMore />
                  </button>
                </NumberControls>
              </Row>
              <RangeBar
                min={0}
                max={10}
                step={1}
                value={_stylesData.borderStyle.size}
                onChange={(value) => handleTxtVal(value, 'size', 10, true)}
              />
            </Row>
          </ContainerInput>

          <ContainerInput disabled={_stylesData.borderStyle.line === ''}>
            <Label>Arredondamento da borda</Label>
            <Row style={{ width: '100%', alignItems: 'center', gap: '10px' }}>
              <Row style={{ width: '35%', position: 'relative' }}>
                <InputTextLine
                  value={_stylesData.borderStyle.rounded}
                  onChange={(e) => handleTxtVal(e.target.value, 'rounded', 20, true)}
                  onBlur={() => handleNumVal(_stylesData.borderStyle.rounded, 'rounded', true)}
                  style={{ textAlign: 'center' }}
                />
                <NumberControls>
                  <button
                    type="button"
                    className="number-increase"
                    onClick={() => handleOpsBtns(_stylesData.borderStyle.rounded, 1, 20, 'rounded', true)}
                  >
                    <ExpandMore />
                  </button>
                  <button
                    type="button"
                    className="number-decrease"
                    onClick={() => handleOpsBtns(_stylesData.borderStyle.rounded, -1, 20, 'rounded', true)}
                  >
                    <ExpandMore />
                  </button>
                </NumberControls>
              </Row>
              <RangeBar
                min={0}
                max={20}
                step={1}
                value={_stylesData.borderStyle.rounded}
                onChange={(value) => handleTxtVal(value, 'rounded', 20, true)}
              />
            </Row>
          </ContainerInput>

          <ContainerInput>
            <Label>Separação entre os cards</Label>
            <Row style={{ width: '100%', alignItems: 'center', gap: '10px' }}>
              <Row style={{ width: '35%', position: 'relative' }}>
                <InputTextLine
                  value={_stylesData.spaceBetween}
                  onChange={(e) => handleTxtVal(e.target.value, 'spaceBetween', 100)}
                  onBlur={() => handleNumVal(_stylesData.spaceBetween, 'spaceBetween', 100)}
                  style={{ textAlign: 'center' }}
                />
                <NumberControls>
                  <button
                    type="button"
                    className="number-increase"
                    onClick={() => handleOpsBtns(_stylesData.spaceBetween, 10, 100, 'spaceBetween')}
                  >
                    <ExpandMore />
                  </button>
                  <button
                    type="button"
                    className="number-decrease"
                    onClick={() => handleOpsBtns(_stylesData.spaceBetween, -10, 100, 'spaceBetween')}
                  >
                    <ExpandMore />
                  </button>
                </NumberControls>
              </Row>
              <RangeBar
                min={0}
                max={100}
                step={10}
                value={_stylesData.spaceBetween}
                onChange={(value) => handleTxtVal(value, 'spaceBetween', 100)}
              />
            </Row>
          </ContainerInput>
        </MainColumn>

        <MainColumn style={{ justifyContent: 'space-between' }}>
          <CardPreviewContainer>
            <CardPreview cardStyle={_stylesData}>
              <ChartLine />
            </CardPreview>
          </CardPreviewContainer>

          <div style={{ width: '100%' }}>
            <ContainerInputRow>
              <Label>Cor do fundo</Label>
              <ColorPicker
                onChange={(val) => handleInput('backgroundColor', val)}
                color={_stylesData.backgroundColor}
                isIconButton
                atModal
              >
                <BackgroundColor />
              </ColorPicker>
            </ContainerInputRow>

            <ContainerInputRow>
              <Label>Sombreamento</Label>
              <Toggle
                label=""
                checked={_stylesData.shading || false}
                handleChange={() => simpleHandle('shading', !_stylesData.shading)}
                // styleOption={{ color: theme.grey, fontSize: 14 }}
                styleContainer={{ margin: 'auto 0' }}
                highlightOption={false}
                size="small"
              />
            </ContainerInputRow>
          </div>
        </MainColumn>
      </MainRow>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          className="button_color_selection"
        >
          Confirmar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

CardStyle.propTypes = {
  stylesData: PropTypes.objectOf(PropTypes.any),
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

CardStyle.defaultProps = {
  stylesData: undefined,
};

export default CardStyle;
