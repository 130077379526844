import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledToggleContainer,
  InputContainer,
  Label,
  Slider,
  Input,
  Title,
} from './styled/Toggle.styled';

/**
 * A toggle button allows the user to change a setting between two states.
 * Commonly used as an on/off button.
 */
const Toggle = ({
  label,
  handleChange,
  firstColor,
  secondColor,
  size,
  secondOption,
  firstOption,
  checked,
  highlightOption,
  optionPosition,
  styleOption,
  styleContainer,
  ...inputProps
}) => {
  const setOption = (side) => {
    if (optionPosition === side) {
      return checked ? firstOption : secondOption;
    }
    return side === 'left' ? secondOption : firstOption;
  };

  return (
    <StyledToggleContainer
      size={size}
      color={checked ? firstColor : secondColor}
      title={label}
      style={styleContainer}
    >
      <Title htmlFor={`toggle${label}`} className="toggle-title">{label}</Title>
      {optionPosition && (optionPosition === 'left' || optionPosition === 'both') && (
        <Label style={styleOption} className="toggle-label-left" isChecked={!checked && highlightOption}>
          {setOption('left')}
        </Label>
      )}
      <InputContainer onClick={(e) => e.stopPropagation()}>
        <Input
          id={`toggle${label}`}
          onChange={handleChange}
          type="checkbox"
          checked={checked}
          {...inputProps}
        />
        <Slider />
      </InputContainer>
      {optionPosition && (optionPosition === 'right' || optionPosition === 'both') && (
        <Label style={styleOption} className="toggle-label-right" isChecked={checked && highlightOption}>
          {setOption('right')}
        </Label>
      )}
    </StyledToggleContainer>
  );
};

Toggle.propTypes = {
  /**
   * The label of the toggle
   */
  label: PropTypes.string,
  /**
    * Size of the toggle. Choose among three sizes.
    */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
    * Color of the toggle when selected. Choose from seven different options or pass a hex color.
    */
  firstColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info', 'dark']), PropTypes.string]),
  /**
    * Color of the toggle when not selected.
    * Choose from seven different options or pass a hex color.
    */
  secondColor: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info', 'dark']), PropTypes.string]),
  /**
    * The label associated with the toggle state. Left side (off)
    */
  firstOption: PropTypes.string,
  /**
    * The label associated with the toggle state. Right side (on)
    */
  secondOption: PropTypes.string,
  /**
    * A function to perform some action when the toggle state changes
    */
  handleChange: PropTypes.func.isRequired,
  /**
    * A boolean state that indicates wheter the toggle is selected or not
    */
  checked: PropTypes.bool,
  /**
    * A boolean that indicates whether the label
    * will be highlighted according to the state of the toggle
    */
  highlightOption: PropTypes.bool,
  /**
   * Defines the position of the options labels
   */
  optionPosition: PropTypes.oneOf(['left', 'right', 'both', 'none']),

  styleOption: PropTypes.shape({}),

  styleContainer: PropTypes.shape({}),
};

Toggle.defaultProps = {
  label: '',
  size: 'medium',
  firstOption: '',
  secondOption: '',
  checked: false,
  firstColor: 'primary',
  secondColor: 'grey',
  highlightOption: true,
  styleOption: {},
  styleContainer: {},
  optionPosition: 'none',
};

export default Toggle;
