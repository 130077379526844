export default {
  TitleControl: '',
  ShowTitleControl: { checked: true },
  GlobalValue: {
    fontSize: 20,
    color: '#00F',
    fontFamily: 'arial',
    background: '#ddd',
    bold: false,
    italic: false,
    underline: false,
  },

  DescriptionControl: '',
  PrefixControl: '',
  SufixControl: '',
  GroupingSelectControl: '',
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  Colors: {},
  LabelsSeparadorNumerico: false,
  LabelCurrencyControl: 'Nenhum',
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
};
