/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';

import getMapJson from './mapConfigs/getMapJson';
import getRotationByState from './mapConfigs/getPreRotation';
import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import { genData1DMap } from '../../utils/formatCharts/handleData';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';

const REGIOES_BR = ['Brasil', 'SUDESTE', 'NORDESTE', 'SUL', 'SUDESTE', 'CENTROOESTE', 'NORTE'];

const Map = ({ data, styleConfig }) => {
  const [chartData, setChartData] = useState({ ready: false });
  const [mapFit, setMapFit] = useState(getRotationByState('Brasil'));
  const [json, setJson] = useState({ features: [] });
  const [fieldKey, setFieldKey] = useState('UF');

  useEffect(() => {
    setChartData(genData1DMap(data, styleConfig, fieldKey));
  }, [data, styleConfig.DataFormat, fieldKey]);

  useEffect(() => {
    if (!styleConfig.StateControl) return;
    const field = REGIOES_BR.includes(styleConfig.StateControl) ? 'UF' : 'NOME';

    const importJson = async () => {
      const tmpJson = await getMapJson(styleConfig.StateControl, field);
      setJson(tmpJson);
    };
    importJson();
    setFieldKey(field);
    setMapFit(getRotationByState(styleConfig.StateControl));
  }, [styleConfig.StateControl]);

  const createLabel = (value) => customValueLabel(value, styleConfig);

  const createTooltip = (val) => customTooltip(val, 'map', styleConfig);

  return chartData.ready ? (
    <ResponsiveChoropleth
      data={chartData.data}
      features={json.features}
      margin={
        styleConfig?.Margin || {
          top: 50,
          right: 130,
          bottom: 50,
          left: 60,
        }
      }
      domain={chartData.domain}
      unknownColor="#666666"
      label={`properties.${fieldKey}`}
      valueFormat={createLabel}
      projectionTranslation={[0.5, 0.5]}
      projectionRotation={[mapFit.x, mapFit.y, 0]}
      projectionScale={(styleConfig?.zoom || 1) * (mapFit.zoom * 5)}
      enableGraticule={false}
      graticuleLineColor="#dddddd"
      lineWidth={styleConfig.LineWidth}
      borderColor={styleConfig?.BordeColorMapsControl || '#000000'}
      borderWidth={styleConfig?.BorderWidthControl?.value || 0}
      colors={colorSelector(Math.min(chartData.data.length, 10), styleConfig, 'id', true)}
      tooltip={createTooltip}
      isInteractive={styleConfig.InteractiveControl}
      legends={styleConfig?.LegendControl?.checked ? [{
        ...styleConfig?.LegendPlacementControl?.value,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: styleConfig?.LegendSymbolControl || 'circle',
        itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
        effects: [{
          on: 'hover',
          style: {
            itemTextColor: '#000',
          },
        }],
      }] : []}
      theme={{
        legends: {
          text: {
            fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
            fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
          },
        },
      }}
      animate={false}
    />
  ) : <></>;
};

Map.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Map;
