export default {
  TitleControl: '',
  ShowTitleControl: { checked: true },
  MaxValueControl: { value: 0, checked: true },
  SortValuesControl: 'Alfabética A-Z',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  CurveControl: 'linearClosed',
  Margin: {
    top: 70,
    right: 80,
    bottom: 40,
    left: 80,
  },
  MarginTop: 70,
  MarginRight: 80,
  MarginBottom: 40,
  MarginLeft: 80,
  BorderWidthControl: { value: 0 },
  BorderColorControl: { from: 'color', modifier: [] },
  DataFormat: { type: 'absolute', target: 'global' },

  DotControl: true,
  DotSizeControl: { value: 6 },
  DotBorderWidthControl: { value: 2 },
  DotLabelControl: true,
  DotLabelYOffsetControl: { value: -12 },
  FillOpacityControl: { value: 0.25 },
  GridLevelControl: { value: 5 },
  GridShapeControl: 'circular',
  GridLabelOffsetControl: { value: 16 },

  LegendControl: { checked: true },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendPlacementControl: {
    value: {
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      // anchor: 'bottom',
      // translateX: 110,
      translateX: -92,
      translateY: 45,
    },
  },
  LegendsSpacingControl: { value: 10 },

  // TOOLTIP / INTERACTIVE
  // InteractivityControl: { checked: true },
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  Animate: false,
  MotionConfig: 'default',
  // LABEL
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  LabelRotationControl: 0,
  LabelGroupingControl: '',
  LabelCurrencyControl: 'Nenhum',
  LabelsSeparadorNumerico: true,
  LabelsFontSizeControl: { value: 16 },
  LabelsFontFamilyControl: { value: 'arial' },

  //
  CurrencyControl: 'Nenhum',
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,

  // colors
  ColorTheme: { scheme: 'nivo' },
  Colors: {},
  PalletsControl: 'default', // || 'custom' // variável para poder selecionar palletas do nivo ou poder setar cada cor pessoalmente...
  ShowZeroValuesControl: { checked: false },
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  legendClick: [],
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },
  DotColorControl: true,
  DotColorValue: '#000000',

};
