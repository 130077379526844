import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/Select';
import TextArea from '../../../juristec-ui/core/TextArea';
import InputNumberLine from '../../../juristec-ui/core/InputNumberLine';

import {
  Database,
  DesktopUpload,
  File,
  GoogleDriveNew,
  Memory,
  OneDrive,
  Cloud,
  ExpandMoreWide,
  BennerSimpleGray,
  // LegalOne,
} from '../../../juristec-ui/icons';

import { printSize } from '../../../juristec-ui/utils/functions/lab';

import {
  verifyGoogleDriveSheetLink, verifySqlCode, verifyInput, verifyOneDriveSheetLink, verifyFileName,
} from '../../../juristec-ui/utils/validators/inputTextValidators';

import { verifyFileSheetType, verifyFileSize } from '../../../juristec-ui/utils/validators/fileValidators';

import {
  MainContainer,
  FormContainer,
  UploadArea,
  UploadBorder,
  UploadOpts,
  FileInfoContainer,
  InputContainer,
  FileInfo,
  InfoLabels,
  TabGroup,
  TabButton,
  InputRow,
  OptionGroup,
  Option,
} from './styled/FileUpload.styled';

/**
* A modal for uploading files from desktop or google drive link
*/
const FileUpload = ({
  availableSize,
  uploadFile,
  uploadFileByLink,
  uploadBySql,
  uploadBennerReport,
  updateMode,
  updatingFileName,
  hide,
  tourContext = { tourOpen: false },
}) => {
  const [tab, setTab] = useState('desktop');
  const [dragging, setDragging] = useState(false);
  const [inputLink, setInputLink] = useState({ url: '', errorMsg: '' });
  const [inputLinkFilename, setInputLinkFilename] = useState({ value: '', errorMsg: '' });
  const [inputLinkStartLine, setInputLinkStartLine] = useState({ value: 1, errorMsg: '' });
  const [inputLinkConnector, setInputLinkConnector] = useState('GoogleDrive');

  const [selectedFile, setSelectedFile] = useState({ file: null, errorMsg: '' });

  const [inputDBType, setInputDBType] = useState({ label: 'PostgreSQL', value: 'pgsql' });
  const [inputDBHost, setInputDBHost] = useState({ value: '', errorMsg: '' });
  const [inputDBPort, setInputDBPort] = useState({ value: '', errorMsg: '' });
  const [inputDBName, setInputDBName] = useState({ value: '', errorMsg: '' });
  const [inputDBFilename, setInputDBFilename] = useState({ value: '', errorMsg: '' });
  const [inputDBUser, setInputDBUser] = useState({ value: '', errorMsg: '' });
  const [inputDBPass, setInputDBPass] = useState({ value: '', errorMsg: '' });
  const [inputDBCode, setInputDBCode] = useState({ value: '', errorMsg: '' });

  const [inputBennerURL, setInputBennerURL] = useState({ value: '', errorMsg: '' });
  const [inputBennerFilename, setInputBennerFilename] = useState({ value: '', errorMsg: '' });
  const [inputBennerUsername, setInputBennerUsername] = useState({ value: '', errorMsg: '' });
  const [inputBennerPassword, setInputBennerPassword] = useState({ value: '', errorMsg: '' });
  const [inputBennerStartLine, setInputBennerStartLine] = useState({ value: 1, errorMsg: '' });

  const fileInputRef = useRef(null);

  const txtareaRef = useRef(null);

  useEffect(() => {
    if (!txtareaRef?.current) return;
    const listener = (e) => {
      if (e.keyCode === 9) {
        e.preventDefault();
        txtareaRef.current.setRangeText(
          '\t',
          txtareaRef.current.selectionStart,
          txtareaRef.current.selectionStart,
          'end',
        );
      }
    };
    txtareaRef.current.addEventListener('keydown', listener);
    // eslint-disable-next-line consistent-return
    return () => {
      if (txtareaRef?.current) txtareaRef.current.removeEventListener('keydown', listener);
    };
  }, [txtareaRef?.current]);

  const dragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleFile = (files) => {
    const errorMsg = verifyFileSheetType(files[0]) || verifyFileSize(files[0], availableSize);
    setSelectedFile({
      file: errorMsg.length === 0 ? files[0] : null,
      errorMsg,
    });
    if (tourContext.tourOpen && errorMsg.length === 0) tourContext.nextStep();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files;
    if (file.length) {
      handleFile(file);
    }
  };

  const handleFileLink = (e) => {
    const val = e.target.value.trim();
    setInputLink({
      url: val,
      errorMsg: inputLinkConnector === 'GoogleDrive' ? verifyGoogleDriveSheetLink(val) : verifyOneDriveSheetLink(val),
    });
  };

  const handleConnectorLink = (connector) => {
    setInputLink((o) => ({
      ...o,
      errorMsg: connector === 'GoogleDrive' ? verifyGoogleDriveSheetLink(o.url) : verifyOneDriveSheetLink(o.url),
    }));
    setInputLinkConnector(connector);
  };

  const handleLinkFilename = (e) => {
    const val = e.target.value.trim();
    setInputLinkFilename({ value: val, errorMsg: verifyFileName(val) });
  };

  const handleLinkStartLine = (value) => {
    if (value.length === 0) {
      setInputLinkStartLine({ value, errorMsg: 'Este campo não pode estar vazio' });
      return;
    }
    if (value === '0') {
      // eslint-disable-next-line no-param-reassign
      value = 1;
      setInputLinkStartLine({ value: 1, errorMsg: '' });
    } else if (/^[0-9]+$/.test(value)) {
      setInputLinkStartLine({ value, errorMsg: '' });
    }
  };

  const fileSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFile(fileInputRef.current.files);
    }
  };

  const handleDBType = (selection) => {
    setInputDBType(selection);
  };

  const handleDBHost = (e) => {
    const val = e.target.value.trim();
    setInputDBHost({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleDBPort = (e) => {
    const val = e.target.value.trim();
    setInputDBPort({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleDBName = (e) => {
    const val = e.target.value.trim();
    setInputDBName({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleDBFilename = (e) => {
    const val = e.target.value.trim();
    setInputDBFilename({ value: val, errorMsg: verifyFileName(val) });
  };

  const handleDBUser = (e) => {
    const val = e.target.value.trim();
    setInputDBUser({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleDBPass = (e) => {
    const val = e.target.value.trim();
    setInputDBPass({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleDBCode = (e) => {
    const val = e.target.value;
    setInputDBCode({ value: val, errorMsg: verifySqlCode(val.trim()) });
  };

  const handleBennerURL = (e) => {
    const val = e.target.value.trim();
    setInputBennerURL({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleBennerFilename = (e) => {
    const val = e.target.value.trim();
    setInputBennerFilename({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleBennerUsername = (e) => {
    const val = e.target.value.trim();
    setInputBennerUsername({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleBennerPassword = (e) => {
    const val = e.target.value.trim();
    setInputBennerPassword({ value: val, errorMsg: verifyInput(val, true) });
  };

  const handleBennerStartLine = (value) => {
    if (value.length === 0) {
      setInputBennerStartLine({ value, errorMsg: 'Este campo não pode estar vazio' });
      return;
    }
    if (value === '0') {
      // eslint-disable-next-line no-param-reassign
      value = 1;
      setInputBennerStartLine({ value: 1, errorMsg: '' });
    } else if (/^[0-9]+$/.test(value)) {
      setInputBennerStartLine({ value, errorMsg: '' });
    }
  };

  const toggleUploadBtn = () => {
    switch (tab) {
      case 'drive':
        return (
          inputLink.url.length > 0 && inputLink.errorMsg.length === 0
          && inputLinkFilename.value.length > 0 && inputLinkFilename.errorMsg.length === 0
          && inputLinkStartLine.value > 0 && inputLinkStartLine.errorMsg.length === 0
        );
      case 'desktop':
        return selectedFile.file && selectedFile.errorMsg.length === 0;
      case 'sql':
        return (
          inputDBCode.value.trim().length > 0 && inputDBCode.errorMsg.length === 0
          && inputDBHost.value.length > 0 && inputDBHost.errorMsg.length === 0
          && inputDBPort.value.length > 0 && inputDBPort.errorMsg.length === 0
          && inputDBName.value.length > 0 && inputDBName.errorMsg.length === 0
          && inputDBFilename.value.length > 0 && inputDBFilename.errorMsg.length === 0
          && inputDBUser.value.length > 0 && inputDBUser.errorMsg.length === 0
          && inputDBPass.value.length > 0 && inputDBPass.errorMsg.length === 0
          && inputDBCode.value.length > 0 && inputDBCode.errorMsg.length === 0
        );
      case 'benner':
        return (
          inputBennerURL.value.length > 0 && inputBennerURL.errorMsg.length === 0
          && inputBennerFilename.value.length > 0 && inputBennerFilename.errorMsg.length === 0
          && inputBennerUsername.value.length > 0 && inputBennerUsername.errorMsg.length === 0
          && inputBennerPassword.value.length > 0 && inputBennerPassword.errorMsg.length === 0
        );
      default:
        return false;
    }
  };

  const handleUpload = () => {
    switch (tab) {
      case 'drive':
        uploadFileByLink(
          inputLink.url,
          inputLinkFilename.value,
          inputLinkStartLine.value - 1,
          inputLinkConnector,
        );
        break;
      case 'desktop':
        uploadFile(selectedFile.file);
        break;
      case 'sql':
        uploadBySql(
          {
            filename: inputDBFilename.value,
            dialect: inputDBType.value,
            username: inputDBUser.value,
            password: inputDBPass.value,
            host: inputDBHost.value,
            port: inputDBPort.value,
            dbname: inputDBName.value,
            query: inputDBCode.value.trim(),
          },
        );
        break;
      case 'benner':
        uploadBennerReport(
          inputBennerFilename.value,
          inputBennerURL.value,
          inputBennerUsername.value,
          inputBennerPassword.value,
          inputBennerStartLine.value,
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <MainContainer>
        {!updateMode && (
          <TabGroup>
            <TabButton active={tab === 'desktop'} onClick={() => setTab('desktop')}>
              <Memory />
              <span>Meu dispositivo</span>
              <div className="icon-detail">
                <ExpandMoreWide />
              </div>
            </TabButton>
            <TabButton active={tab === 'drive'} onClick={() => setTab('drive')}>
              <Cloud />
              <span>Drive (Nuvem)</span>
              <div className="icon-detail">
                <ExpandMoreWide />
              </div>
            </TabButton>
            <TabButton active={tab === 'sql'} onClick={() => setTab('sql')}>
              <Database />
              <span>Base SQL</span>
              <div className="icon-detail">
                <ExpandMoreWide />
              </div>
            </TabButton>
            {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'benner-metrics' && (
              <TabButton active={tab === 'benner'} onClick={() => setTab('benner')}>
                <BennerSimpleGray />
                <span>Relatório Benner</span>
                <div className="icon-detail">
                  <ExpandMoreWide />
                </div>
              </TabButton>
            )}
            {/*
            TAB PARA RELATÓRIOS DO LEGAL ONE SUSPENSA POR ENQUANTO.
            SOMENTE QUANDO O DATA EXTRACTOR FUNCIONAR
            */}
            {/*
            {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
              <TabButton active={tab === 'legalOne'} onClick={() => setTab('legalOne')}>
                <LegalOne />
                Relatório LegalOne
              </TabButton>
            )}
            */}
          </TabGroup>
        )}
        <FormContainer>
          {tab === 'desktop' && (
            <UploadArea
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              dragging={dragging}
            >
              <UploadBorder>
                <span>
                  Arraste um arquivo
                  {' '}
                  <b>.xlsx</b>
                  {' '}
                  ou
                  {' '}
                  <b>.csv</b>
                </span>
                <span><b>ou</b></span>
                <UploadOpts>
                  <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    style={{ display: 'none' }}
                    onChange={fileSelected}
                  />
                  <Button
                    size="small"
                    shape="rounded"
                    onClick={() => fileInputRef.current.click()}
                    className="modal_upload_file"
                  >
                    <DesktopUpload />
                    Procure no dispositivo...
                  </Button>
                </UploadOpts>
                <FileInfoContainer>
                  <>
                    {selectedFile.errorMsg.length > 0 ? (
                      <span className="errorMsg">{selectedFile.errorMsg}</span>
                    ) : (
                      selectedFile.file && (
                        <FileInfo>
                          <File />
                          <span>{selectedFile.file.name}</span>
                        </FileInfo>
                      ))}
                  </>
                </FileInfoContainer>
              </UploadBorder>
            </UploadArea>
          )}
          {tab === 'drive' && (
            <InputContainer>
              <span>
                Informe os campos abaixo para enviar um arquivo compartilhado no drive.
                Obs: o arquivo deve estar compartilhado com acesso público.
              </span>
              <OptionGroup>
                <Option
                  onClick={() => handleConnectorLink('GoogleDrive')}
                  active={inputLinkConnector === 'GoogleDrive'}
                >
                  <GoogleDriveNew />
                  Google Drive
                </Option>
                <Option
                  onClick={() => handleConnectorLink('OneDrive')}
                  active={inputLinkConnector === 'OneDrive'}
                >
                  <OneDrive />
                  One Drive
                </Option>
              </OptionGroup>
              <InputTextLine
                label="Nome do arquivo resultante"
                value={inputLinkFilename.value}
                onChange={handleLinkFilename}
                error={inputLinkFilename.errorMsg.length > 0}
                errorMessage={inputLinkFilename.errorMsg}
              />
              <InputTextLine
                label="Link de compartilhamento público"
                // placeholder="Link do arquivo"
                error={inputLink.errorMsg.length > 0}
                errorMessage={inputLink.errorMsg}
                value={inputLink.url}
                onChange={handleFileLink}
              />
              <InputRow style={{ alignItems: 'center' }}>
                <span className="extra-label">Iniciar a partir da linha</span>
                <InputNumberLine
                  // label="Iniciar a partir da linha"
                  value={inputLinkStartLine.value}
                  onChange={(e) => handleLinkStartLine(e.target.value)}
                  setValue={handleLinkStartLine}
                  min={1}
                  error={inputLinkStartLine.errorMsg.length > 0}
                  errorMessage={inputLinkStartLine.errorMsg}
                />
              </InputRow>
            </InputContainer>
          )}
          {tab === 'sql' && (
            <InputContainer style={{ height: '100%' }}>
              <span>
                Informe os campos abaixo para gerar um arquivo a partir de uma base SQL.
                Recomendamos que o usuário de acesso possua apenas permissão de leitura.
              </span>
              <InputRow>
                <div>
                  <Select
                    selectLabel="Tipo"
                    placeholder="Selecione"
                    options={[
                      { label: 'PostgreSQL', value: 'pgsql', id: 'PGSQL' },
                      { label: 'MySQL', value: 'MYSQL', id: 'MYSQL' },
                    ]}
                    value={inputDBType}
                    onChange={handleDBType}
                    fullWidth
                    atModal
                  />
                </div>
                <InputTextLine
                  label="Host"
                  value={inputDBHost.value}
                  onChange={handleDBHost}
                  error={inputDBHost.errorMsg.length > 0}
                  errorMessage={inputDBHost.errorMsg}
                />
                <InputTextLine
                  label="Porta"
                  wrapperStyle={{ width: '20%' }}
                  value={inputDBPort.value}
                  onChange={handleDBPort}
                  error={inputDBPort.errorMsg.length > 0}
                  errorMessage={inputDBPort.errorMsg}
                />
              </InputRow>
              <InputRow>
                <InputTextLine
                  label="Nome da base de dados"
                  value={inputDBName.value}
                  onChange={handleDBName}
                  error={inputDBName.errorMsg.length > 0}
                  errorMessage={inputDBName.errorMsg}
                />
                <InputTextLine
                  label="Nome do arquivo resultante"
                  value={inputDBFilename.value}
                  onChange={handleDBFilename}
                  error={inputDBFilename.errorMsg.length > 0}
                  errorMessage={inputDBFilename.errorMsg}
                />
              </InputRow>
              <InputRow>
                <InputTextLine
                  label="Usuário da base"
                  value={inputDBUser.value}
                  onChange={handleDBUser}
                  error={inputDBUser.errorMsg.length > 0}
                  errorMessage={inputDBUser.errorMsg}
                />
                <InputTextLine
                  label="Senha da base"
                  type="password"
                  value={inputDBPass.value}
                  onChange={handleDBPass}
                  error={inputDBPass.errorMsg.length > 0}
                  errorMessage={inputDBPass.errorMsg}
                />
              </InputRow>
              <InputRow style={{ height: '100%' }}>
                <TextArea
                  ref={txtareaRef}
                  label="Declaração SQL"
                  styleContainer={{ width: '100%', boxSizing: 'border-box' }}
                  style={{ fontSize: '12px', padding: '5px 10px' }}
                  placeholder="SELECT ... FROM ... WHERE ..."
                  value={inputDBCode.value}
                  onChange={handleDBCode}
                  error={inputDBCode.errorMsg.length > 0}
                  errorMessage={inputDBCode.errorMsg}
                />
              </InputRow>
            </InputContainer>
          )}
          {(process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'benner-metrics' && tab === 'benner') && (
            <InputContainer style={{ height: '100%' }}>
              <span>
                Informe os campos abaixo para gerar um relatório do ERP Benner.
              </span>
              <InputRow>
                <InputTextLine
                  label="URL Benner"
                  value={inputBennerURL.value}
                  onChange={handleBennerURL}
                  error={inputBennerURL.errorMsg.length > 0}
                  errorMessage={inputBennerURL.errorMsg}
                />
                <InputTextLine
                  label="Nome do arquivo resultante"
                  value={inputBennerFilename.value}
                  onChange={handleBennerFilename}
                  error={inputBennerFilename.errorMsg.length > 0}
                  errorMessage={inputBennerFilename.errorMsg}
                />
              </InputRow>
              <InputRow>
                <InputTextLine
                  label="Usuário Benner"
                  value={inputBennerUsername.value}
                  onChange={handleBennerUsername}
                  error={inputBennerUsername.errorMsg.length > 0}
                  errorMessage={inputBennerUsername.errorMsg}
                />
                <InputTextLine
                  label="Senha Benner"
                  type="password"
                  value={inputBennerPassword.value}
                  onChange={handleBennerPassword}
                  error={inputBennerPassword.errorMsg.length > 0}
                  errorMessage={inputBennerPassword.errorMsg}
                />
              </InputRow>
              <InputRow style={{ alignItems: 'center' }}>
                <span className="extra-label">Iniciar a partir da linha</span>
                <InputNumberLine
                  // label="Iniciar a partir da linha"
                  value={inputBennerStartLine.value}
                  onChange={(e) => handleBennerStartLine(e.target.value)}
                  setValue={handleBennerStartLine}
                  min={1}
                  error={inputBennerStartLine.errorMsg.length > 0}
                  errorMessage={inputBennerStartLine.errorMsg}
                />
              </InputRow>
            </InputContainer>
          )}
        </FormContainer>
      </MainContainer>
      <InfoLabels>
        {updateMode && (
          <span>
            {`Atualizando: ${updatingFileName}`}
          </span>
        )}
        <span>
          {`Espaço livre: ${printSize(availableSize)}`}
        </span>
      </InfoLabels>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleUpload()}
          size="large"
          disabled={!toggleUploadBtn()}
          className="modal_upload_file_advance"
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

FileUpload.propTypes = {
  /**
    * The maximum size available for upload
    */
  availableSize: PropTypes.number,
  /**
    * A function to handle a file upload from desktop
    */
  uploadFile: PropTypes.func.isRequired,
  /**
    * A function to handle a file upload from Google Drive
    */
  uploadFileByLink: PropTypes.func.isRequired,
  /**
    * A function to handle a file upload from sql database
    */
  uploadBySql: PropTypes.func.isRequired,
  /**
    * A function to create a Benner Report
    */
  uploadBennerReport: PropTypes.func.isRequired,
  /**
    * A boolean to toggle between add or update mode
    */
  updateMode: PropTypes.bool,
  /**
    * The name of the file to be updated. Used only when 'updateMode' is true
    */
  updatingFileName: PropTypes.string,
  /**
    * A function that closes the modal
    */
  hide: PropTypes.func.isRequired,

  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

FileUpload.defaultProps = {
  availableSize: 0,
  updateMode: false,
  updatingFileName: '',
  tourContext: { tourOpen: false, nextStep: () => {} },
};

export default FileUpload;
