import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

// Styled
import {
  Container,
  FirstRow,
  ContainerRow,
} from './styled';

/**
 * Formata o nome do arquivo para que nao contenha caracteres invalidos
 * @param {string} fileName O nome do arquivo
 * @returns O nome formatado
 */
const safeFileName = (fileName) => (
  fileName?.split(' ')?.filter((v) => v)?.join('_')?.replace(/[^\w| ]/g, '') || ''
);

const OverrideFilesName = ({
  files, existentFiles, submit, close,
}) => {
  const cron = useRef();
  const [newFileNames, setNewFileNames] = useState({});
  const [blockBtn, setBlockBtn] = useState(false);

  /**
   * Checks if the user has a file with the same name
   * @param {string} nName The new filename
   * @returns {boolean} Whether it matches or not
   */
  const hasFileName = (nName) => !!existentFiles.find((f) => f.filename.split('.')[0] === nName);

  /**
   * Checks if the user has typed the same name twice
   * @param {string} nName The new filename
   * @param {string} key The original filename, used as key
   * @returns {boolean} Whether it matches or not
   */
  const hasUsedNewName = (key, nName) => (
    !!Object.entries(newFileNames).find((objNFN) => (
      objNFN[0] !== key && objNFN[1].newName === nName
    ))
  );

  /**
   * Handles the name input
   * @param {Event} e onChange DOM event
   */
  const handleNewFileNames = (e) => {
    const newName = safeFileName(e.target.value.trim());
    const { filename } = e.target.dataset;

    setNewFileNames((p) => ({
      ...p,
      [filename]: {
        newName,
        errorMessage: '',
        error: p[filename]?.error || false,
      },
    }));

    // Checks for errors
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      if (newName.length === 0) {
        setNewFileNames((p) => ({
          ...p,
          [filename]: {
            newName,
            errorMessage: 'Informe um nome para o arquivo',
            error: true,
          },
        }));
      } else if (hasFileName(newName) || hasUsedNewName(filename, newName)) {
        setNewFileNames((p) => ({
          ...p,
          [filename]: {
            newName,
            errorMessage: 'Este nome já está em uso',
            error: true,
          },
        }));
      } else {
        setNewFileNames((p) => ({
          ...p,
          [filename]: {
            newName,
            errorMessage: '',
            error: false,
          },
        }));
      }
    }, 200);
  };

  /** Submit the new filenames */
  const handleSubmit = () => {
    submit(newFileNames);
  };

  useEffect(() => {
    const namesAux = files.reduce((acc, cur) => {
      acc[cur] = { newName: '', error: true, errorMessage: '' };
      return acc;
    }, {});
    setNewFileNames(namesAux);
  }, [files]);

  useEffect(() => {
    setBlockBtn(!!Object.keys(newFileNames).find((name) => newFileNames[name].error));
  }, [newFileNames]);

  return (
    <Container>
      <FirstRow>
        Você já possui arquivos com os mesmos nomes.
        <br />
        Por favor, informe nomes diferentes para os novos arquivos.
      </FirstRow>
      {Object.keys(newFileNames).map((fName) => (
        <ContainerRow key={fName}>
          <InputTextLine
            label={fName.split('.')[0]}
            autoComplete="off"
            placeholder="Novo nome de arquivo"
            value={newFileNames[fName]?.newName}
            onChange={handleNewFileNames}
            data-filename={fName}
            error={newFileNames[fName]?.error}
            errorMessage={newFileNames[fName]?.errorMessage}
          />
        </ContainerRow>
      ))}
      <ActionsGroup style={{ gap: '10px' }}>
        <Button
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>

        <Button
          onClick={handleSubmit}
          size="large"
          disabled={blockBtn}
        >
          Confirmar
        </Button>
      </ActionsGroup>
    </Container>
  );
};

OverrideFilesName.propTypes = {
  files: PropTypes.arrayOf(PropTypes.string),
  submit: PropTypes.func,
  close: PropTypes.func,
  existentFiles: PropTypes.arrayOf(PropTypes.any),
};

OverrideFilesName.defaultProps = {
  files: [],
  submit: () => {},
  close: () => {},
  existentFiles: [],
};

export default OverrideFilesName;
