import React, {
  useReducer,
  createContext,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

// import firebase from '../../utils/firebase';
import firestoreErrors from '../../utils/firestoreErrors';
import { error as errorLabels } from '../../label';
import { getCompanies } from '../../utils/firestore';
import { ip, ipAdmin } from '../../utils/functions/urls';
import getRequestMeta from '../../utils/functions/generateMeta';

//
import { AuthContext } from '../AuthProvider';

const getToken = async (u) => u.getIdToken();

const initialState = {
  instances: [],
  // legalOneReports: null,
  // connectReports: null,
  // legalOneApiReports: null,
  reports: {
    list: [],
    pageTotal: null,
  },
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setInstances':
      return {
        ...state,
        isLoading: false,
        instances: action.instances,
      };

    case 'setInstancesUsers':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            return {
              ...instance,
              users: action.users,
            };
          }

          return instance;
        }),
        isLoading: false,
      };

    case 'editInstance':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.companyinfo.companyid) {
            return {
              ...instance,
              ...action.companyinfo, // outras variáveis n mudou os nomes
              superS: action.companyinfo.supers,
              volumeData: action.companyinfo.volumedata,
            };
          }

          return instance;
        }),
        isLoading: false,
      };

    case 'blockInstance':
      return {
        ...state,
        isLoading: false,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            return {
              ...instance,
              disabled: action.block,
            };
          }
          return instance;
        }),
      };

    case 'deleteInstance':
      return {
        ...state,
        instances: state.instances.filter((instance) => instance.id !== action.instanceId),
        isLoading: false,
      };

    // users
    case 'blockUser':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            const users = instance.users?.map((u) => (u.email === action.email
              ? {
                ...u,
                disabled: action.newValue,
              } : u));

            return {
              ...instance,
              users,
            };
          }

          return instance;
        }),
        isLoading: false,
      };

    case 'deleteUser':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            const users = instance.users?.filter((u) => (u.email !== action.email));

            return {
              ...instance,
              users,
            };
          }
          return instance;
        }),
        isLoading: false,
      };

    case 'changeUserRole':
      return {
        ...state,
        instances: state.instances.map((instance) => {
          if (instance.id === action.instanceId) {
            const users = instance.users?.map((u) => (u.email === action.email ? {
              ...u,
              role: action.newRole,
              scientist: action.scientist,
            } : u));

            return {
              ...instance,
              users,
            };
          }

          return instance;
        }),
      };

    // reports
    case 'setReports': {
      const currentReports = action.reset ? [] : [...state.reports.list];
      currentReports[action.page] = action.reports;

      return {
        ...state,
        isLoading: false,
        reports: {
          list: currentReports,
          pageTotal: action.pageTotal,
        },
      };
    }

    case 'updateReports': {
      const flatArray = state.reports.list.flat(1);
      const updatedArray = flatArray.map((rep) => {
        if (rep.id === action.reportId) {
          return ({
            ...rep,
            lastAttemptStatus: action.updated.status,
            updatedAt: action.updated.attemptAt,
            updatedById: action.updated.byId,
            updatedByEmail: action.updated.byEmail,
            attempt: {
              errors: action.updated.errors,
              message: action.updated.message,
            },
          });
        }
        return rep;
      });
      const resultArray = [];
      while (updatedArray.length > 0) resultArray.push(updatedArray.splice(0, 20));

      return {
        ...state,
        isLoading: false,
        reports: {
          ...state.reports,
          list: resultArray,
        },
      };
    }

    case 'removeReport': {
      const flatArray = state.reports.list.flat(1);
      const filteredArray = flatArray.filter((rep) => rep.id !== action.reportId);
      const page = Math.ceil(filteredArray.length / 20);
      const resultArray = [];
      while (filteredArray.length > 0) resultArray.push(filteredArray.splice(0, 20));

      return {
        ...state,
        isLoading: false,
        reports: {
          ...state.reports,
          list: resultArray,
          pageTotal: page,
        },
      };
    }

    case 'emptyReportList':
      return {
        ...state,
        reports: {
          ...state.reports,
          list: [],
        },
      };

    case 'setLoading':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const InstanceContext = createContext();

function InstanceProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setLoading = useCallback((l = true) => dispatch({ type: 'setLoading', isLoading: l }), []);

  const { currentUser } = useContext(AuthContext);

  const getInstances = useCallback(async () => {
    try {
      const res = await getCompanies().get();
      const instances = res.docs.map((d) => ({ id: d.id, ...d.data() }));
      return { error: false, msg: '', instances };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.instanceProvider.getInstances,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  const getInstanceUsers = useCallback(async (instanceId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({ company_id: instanceId }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user/list`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabels.instanceProvider.getInstanceUsers, raw: json.error };
      }

      return { error: false, users: json.info.map((u) => ({ ...u, instanceId })) };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.getInstanceUsers,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const createInstance = useCallback(async (companyinfo, emails_super = [], emails_scientist = [], image = '') => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          ...companyinfo,
          emails_super,
          emails_scientist,
          image,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.createInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.createInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const editInstance = useCallback(async (companyinfo, image = '') => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          ...companyinfo,
          image,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.editInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.editInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const blockInstance = useCallback(async (instanceId, block = true) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          company_id: instanceId,
          disabled: block,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.blockInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.blockInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteInstance = useCallback(async (instanceId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
        body: JSON.stringify({ company_id: instanceId }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/instance`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteInstance,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  // USERS ON INSTANCE
  const blockUser = useCallback(async (
    instanceId,
    scientistId,
    email,
    newValue,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          company_id: instanceId,
          scientist_uid: scientistId,
          email,
          field: 'disabled',
          new_value: newValue,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        const parsed = JSON.parse(json);
        return {
          error: true,
          msg: parsed?.errors || errorLabels.instanceProvider.blockInstance,
          raw: json,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.blockInstance,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const addUser = useCallback(async (instance, scientist = '', email, role) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          company_id: instance,
          scientist_uid: scientist,
          new_email: email,
          role,
          send_email: true,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.addUser,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.addUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteUser = useCallback(async (instance, email) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
        body: JSON.stringify({
          company_id: instance,
          email,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        const parsed = JSON.parse(json);
        return {
          error: true,
          msg: parsed?.errors || errorLabels.instanceProvider.deleteUser,
          raw: json,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const changeUserRole = useCallback(async (instanceId, email, newRole, scientist) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT'),
        body: JSON.stringify({
          email,
          companyId: instanceId,
          scientist,
          role: newRole,
          update: 'role',
        }),
      };

      const resFetch = await fetch(`${ip}/manage/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        const parsed = JSON.parse(json);
        return {
          error: true,
          msg: parsed?.errors || errorLabels.instanceProvider.deleteUser,
          raw: json,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const changeUserRoleByAdmin = useCallback(async (instanceId, scientistId = '', email, newRole) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'PUT', 'JSON'),
        body: JSON.stringify({
          email,
          company_id: instanceId,
          scientist_uid: scientistId,
          field: 'role',
          new_value: newRole,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/user`, opt);
      const json = await resFetch.json();
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.editUser,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.editUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const transferUserData = useCallback(async (instanceId, userSender, userReceiver) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          origin_uid: userSender,
          destination_uid: userReceiver,
          company_uid: instanceId,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/admin/transfer`, opt);
      const json = await resFetch.json();

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.transferUser,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.transferUser,
          raw: json.error,
        };
      }
      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.transferUser,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  // REPORTS
  const addAntecipeiReport = useCallback(async (
    filename,
    ownerId,
    companyId,
    url,
    sendWithoutDataProc,
    prettyColumns,
    reportToken,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          predefined_connector: 'Antecipei',
          filename: `${filename}.xlsx`,
          ownerId,
          companyId,
          url: `${url}?send_without_dataproc=${sendWithoutDataProc}&pretty_columns=${prettyColumns}`,
          method: 'GET',
          auth: {
            method: 'x-api-token',
            token: reportToken,
          },
          payload: {},
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.addAntecipeiReport,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.addAntecipeiReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const addBennerReport = useCallback(async (
    filename,
    ownerId,
    companyId,
    url,
    username,
    password,
  ) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          predefined_connector: 'Benner',
          filename: `${filename}.xlsx`,
          ownerId,
          companyId,
          url,
          method: 'GET',
          auth: {
            method: 'Basic Auth',
            username,
            password,
          },
          payload: {},
          header_skip: 0,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/create/web`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.addBennerReport,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      return {
        error: true,
        msg: errorLabels.instanceProvider.addBennerReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const createOnboardingReport = useCallback(async (filename, instance, ownerId) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
        body: JSON.stringify({
          filename,
          companyId: instance,
          ownerId,
        }),
      };

      const resFetch = await fetch(`${ipAdmin}/connect/create/l1dataextractor`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.createOnboardingReport,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.createOnboardingReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getReports = useCallback(async (page, filters) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };

      let strFilters = '';
      if (filters?.origin?.selected?.length > 0) strFilters += `&origin=${filters.origin.selected.join(',')}`;
      if (filters?.status?.selected?.length > 0) strFilters += `&status=${filters.status.selected.join(',')}`;
      if (filters?.connector?.selected?.length > 0) strFilters += `&connector=${filters.connector.selected.join(',')}`;
      if (filters?.companyId?.selected?.length > 0) strFilters += `&companyId=${filters.companyId.selected.join(',')}`;

      const resFetch = await fetch(`${ipAdmin}/connect/list?withInfo=true&page=${page + 1}&active=true&size=20${strFilters}`, opt);
      const json = await resFetch.json();
      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReports,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReports,
          raw: json.error,
        };
      }
      const reps = Object.keys(json.info.reports).map((k) => (
        {
          ...json.info.reports[k],
          status: json.info.reports[k]?.lastAttemptStatus || 'SUCCESS',
        }
      ));
      return {
        error: false,
        msg: '',
        data: {
          reports: reps,
          pageTotal: json.info.pageTotal,
        },
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getReports,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const getReportStatus = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token),
      };
      let json = {};

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/${hashcode}`, opt);
      json = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReportStatus,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.getReportStatus,
          raw: json.error,
        };
      }

      return { error: false, msg: '', data: json.info };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.getReportStatus,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const deleteReport = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }

      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'DELETE', 'JSON'),
      };

      const resFetchF = await fetch(`${ipAdmin}/connect/datasource/${hashcode}`, opt);
      const jsonF = await resFetchF.json();
      if (resFetchF.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }
      if (resFetchF.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.deleteReport,
          raw: jsonF.error,
        };
      }

      return { error: false, msg: '', deletedId: hashcode };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.deleteReport,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const manualReportUpdate = useCallback(async (hashcode, origin, file, filename) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.manualReportUpdate,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }
      const token = await getToken(currentUser);
      let json = {};
      let resFetch = {};
      const todayDate = new Date();
      const date = todayDate.toISOString().split('T')[0];

      if (origin === 'FileApi' || origin === 'Desktop') {
        const data = new FormData();
        console.log(file);
        data.append('file', file, file.name);
        // data.append('overwrite', overwrite);
        const opt = { ...await getRequestMeta(token, 'PUT'), body: data };

        resFetch = await fetch(`${ipAdmin}/connect/datasource/${hashcode}?date=${date}`, opt);
        json = await resFetch.json();
      } else {
        const opt = {
          ...await getRequestMeta(token, 'PUT', 'JSON'),
        };

        resFetch = await fetch(`${ipAdmin}/connect/datasource/${hashcode}?uploaded_file=${filename}.xlsx&date=${date}`, opt);
        json = await resFetch.json();
      }

      if (resFetch.status >= 500) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.manualReportUpdate,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
          type: 'error',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.manualReportUpdate,
          raw: json.error,
          type: 'error',
        };
      }

      return {
        error: false,
        msg: '',
        data: json.info,
        type: json.info.status === 'WARNING' ? 'warning' : 'success',
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.manualReportUpdate,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const requestApiCredentials = useCallback(async (hashcode) => {
    try {
      if (!hashcode) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.requestApiCredentials,
          raw: 'Hashcode nulo ou não encontrado',
        };
      }
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, 'POST', 'JSON'),
      };

      const resFetch = await fetch(`${ip.replace('/analytics', '/l1dataextractor')}/v1/credentials/${hashcode}`, opt);
      const json = await resFetch.json();

      if (resFetch.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      if (resFetch.status !== 200) {
        return {
          error: true,
          msg: errorLabels.instanceProvider.requestApiCredentials,
          raw: json.error,
        };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: errorLabels.instanceProvider.requestApiCredentials,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [currentUser]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'getInstances': {
        setLoading(true);
        const res = await getInstances();
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setInstances', instances: res.instances });
        }
        return res;
      }

      case 'getInstanceUsers': {
        setLoading(true);
        const res = await getInstanceUsers(action.instanceId);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setInstancesUsers', users: res.users, instanceId: action.instanceId });
        }
        return res;
      }

      case 'createInstance': {
        setLoading(true);
        const res = await createInstance(action.companyinfo, action.emails, action.image);
        setLoading(false);
        // if(res.error) {
        //   setLoading(false);
        // }
        return res;
      }

      case 'editInstance': {
        setLoading(true);
        const res = await editInstance(action.companyinfo);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'editInstance', companyinfo: action.companyinfo });
        }
        return res;
      }

      case 'blockInstance': {
        setLoading(true);
        const res = await blockInstance(action.instanceId, action.block);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'blockInstance', instanceId: action.instanceId, block: action.block });
        }
        return res;
      }

      case 'deleteInstance': {
        setLoading(true);
        const res = await deleteInstance(action.instanceId);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'deleteInstance', instanceId: action.instanceId });
        }
        return res;
      }

      // USERS
      case 'blockUser': {
        setLoading(true);
        const res = await blockUser(
          action.instanceId,
          action.scientistId,
          action.email,
          action.newValue,
        );
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'blockUser',
            email: action.email,
            instanceId: action.instanceId,
            scientistId: action.scientistId,
            newValue: action.newValue,
          });
        }
        return res;
      }

      case 'addUser': {
        setLoading(true);
        const res = await addUser(action.instance, action.scientist, action.email, action.role);
        setLoading(false);
        return res;
      }

      case 'deleteUser': {
        setLoading(true);
        const res = await deleteUser(action.instanceId, action.email);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'deleteUser', instanceId: action.instanceId, email: action.email });
        }
        return res;
      }

      case 'changeUserRole': {
        setLoading(true);
        const {
          instanceId, email, newRole, scientist,
        } = action;
        const res = await changeUserRole(instanceId, email, newRole, scientist);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'changeUserRole',
            instanceId,
            email,
            newRole,
            scientist,
          });
        }

        return res;
      }

      case 'changeUserRoleByAdmin': {
        setLoading(true);
        const {
          instanceId, scientistId, email, newRole,
        } = action;
        const res = await changeUserRoleByAdmin(instanceId, scientistId, email, newRole);
        setLoading(false);
        return res;
      }

      case 'transferUserData': {
        setLoading(true);
        const res = await transferUserData(
          action.instanceId, action.userSender, action.userReceiver,
        );
        setLoading(false);
        return res;
      }

      // REPORTS
      case 'addAntecipeiReport': {
        setLoading(true);
        const res = await addAntecipeiReport(
          action.filename,
          action.ownerId,
          action.companyId,
          action.url,
          action.sendWithoutDataProc,
          action.prettyColumns,
          action.reportToken,
        );
        setLoading(false);
        dispatch({ type: 'emptyReportList' });
        return res;
      }

      case 'addBennerReport': {
        setLoading(true);
        const res = await addBennerReport(
          action.filename,
          action.ownerId,
          action.companyId,
          action.url,
          action.username,
          action.password,
        );
        setLoading(false);
        dispatch({ type: 'emptyReportList' });
        return res;
      }

      case 'createOnboardingReport': {
        setLoading(true);
        const res = await createOnboardingReport(action.filename, action.instance, action.ownerId);
        setLoading(false);
        dispatch({ type: 'emptyReportList' });
        return res;
      }

      case 'getReports': {
        setLoading(true);
        const res = await getReports(action.page, action.filters);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'setReports',
            reports: res.data.reports,
            page: action.page,
            pageTotal: res.data.pageTotal,
            reset: action.reset,
          });
        }
        return res;
      }

      case 'getReportStatus': {
        setLoading(true);
        const res = await getReportStatus(action.hashcode);
        setLoading(false);
        return res;
      }

      case 'deleteReport': {
        setLoading(true);
        const res = await deleteReport(action.hashcode);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'removeReport',
            reportId: res.deletedId,
          });
        }
        return res;
      }

      case 'manualReportUpdate': {
        setLoading(true);
        const res = await manualReportUpdate(action.hashcode, action.origin, action.file, action.filename);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({
            type: 'updateReports',
            updated: res.data,
            reportId: action.hashcode,
          });
        }
        return res;
      }

      case 'requestApiCredentials': {
        setLoading(true);
        const res = await requestApiCredentials(action.hashcode);
        setLoading(false);
        return res;
      }

      default:
        dispatch(action);
        return { error: false, msg: 'default' };
    }
  },
  [
    getInstances,
    createInstance,
    editInstance,
    blockInstance,
    deleteInstance,
    // users
    blockUser,
    addUser,
    deleteUser,
    changeUserRole,
    changeUserRoleByAdmin,
    transferUserData,
    // reports
    addAntecipeiReport,
    addBennerReport,
    createOnboardingReport,
    getInstanceUsers,
    getReports,
    getReportStatus,
    deleteReport,
    manualReportUpdate,
    //
    requestApiCredentials,
    setLoading,
  ]);

  const instanceAPI = useMemo(() => ({
    getInstances: async () => middleware({ type: 'getInstances' }),
    getInstanceUsers: async (instanceId) => middleware({ type: 'getInstanceUsers', instanceId }),
    createInstance: async (companyinfo, emails, image) => middleware({
      type: 'createInstance', companyinfo, emails, image,
    }),
    editInstance: async (companyinfo) => middleware({ type: 'editInstance', companyinfo }),
    blockInstance: async (instanceId, block) => middleware({ type: 'blockInstance', instanceId, block }),
    deleteInstance: async (instanceId) => middleware({ type: 'deleteInstance', instanceId }),
    //
    blockUser: async (instanceId, scientistId, email, newValue) => middleware({
      type: 'blockUser', instanceId, scientistId, email, newValue,
    }),
    deleteUser: async (instanceId, email) => middleware({ type: 'deleteUser', instanceId, email }),
    addUser: async (instance, scientist, email, role) => middleware({
      type: 'addUser', instance, scientist, email, role,
    }),
    addAntecipeiReport: async (
      filename,
      ownerId,
      companyId,
      url,
      sendWithoutDataProc,
      prettyColumns,
      reportToken,
    ) => middleware({
      type: 'addAntecipeiReport',
      filename,
      ownerId,
      companyId,
      url,
      sendWithoutDataProc,
      prettyColumns,
      reportToken,
    }),
    addBennerReport: async (
      filename,
      ownerId,
      companyId,
      url,
      username,
      password,
    ) => middleware({
      type: 'addBennerReport',
      filename,
      ownerId,
      companyId,
      url,
      username,
      password,
    }),
    createOnboardingReport: async (filename, instance, ownerId) => middleware({
      type: 'createOnboardingReport', filename, instance, ownerId,
    }),
    changeUserRole: async (instanceId, email, role) => middleware({
      type: 'changeUserRole', instanceId, email, role,
    }),
    changeUserRoleByAdmin: async (instanceId, scientistId, email, newRole) => middleware({
      type: 'changeUserRoleByAdmin', instanceId, scientistId, email, newRole,
    }),
    transferUserData: async (instanceId, userSender, userReceiver) => middleware({
      type: 'transferUserData', instanceId, userSender, userReceiver,
    }),
    getReports: async (page, filters, reset) => middleware({ type: 'getReports', page, filters, reset }),
    getReportStatus: async (hashcode) => middleware({
      type: 'getReportStatus', hashcode,
    }),
    deleteReport: async (hashcode) => middleware({
      type: 'deleteReport', hashcode,
    }),
    manualReportUpdate: async (hashcode, origin, file, filename) => middleware({
      type: 'manualReportUpdate', hashcode, origin, file, filename,
    }),
    requestApiCredentials: async (hashcode) => middleware({
      type: 'requestApiCredentials', hashcode,
    }),
  }), [middleware]);

  return (
    <InstanceContext.Provider value={{ state, instanceAPI }}>
      {children}
    </InstanceContext.Provider>
  );
}

InstanceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
};

InstanceProvider.defaultProps = {
  children: null,
};

export default InstanceProvider;
