import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const LabelCreateToolbarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.toolbarFont};

  // input para a baixo
  @media ${devices.phoneS} {
    padding: 10px 20px;
  }

  @media ${devices.tablet} {
    padding: 10px 20px;
  } ;
`;

export const LeftLabelToolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  span {
    font-size: 24px;
  }

  @media ${devices.tablet} {
    span {
      font-size: 20px;
    }
  }

  @media ${devices.phoneS} {
    span {
      font-size: 18px;
    }
  } ;
`;

export const OuterLabelCreate = styled.main`
  @page {
    size: auto;
    margin: auto;
  }
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  margin: 0 5%;

  @media ${devices.tablet} {
    width: auto;
    margin: 0px 0px;
  }

  @media ${devices.phoneL} {
    width: auto;
    margin: 0px 0px;
    height: calc(100vh - 165px) !important;
  }
`;

export const GridContainer = styled.div`
  margin: 0.5vw;
  display: grid;
  grid-template-columns: 25% auto;
  grid-gap: 10px;
  grid-template-areas: 'sideBar main';

  @media ${devices.phoneS}, ${devices.phoneL} {
    display: flex;
    flex-direction: column;
    .sideBar {
      margin-bottom: 5px;
    }
  }
  .sideBar {
    grid-area: sideBar;
    display: flex;
    flex-direction: column;
  }
  .main {
    grid-area: main;
    display: flex;
    justify-content: center;
    min-width: 0px;
    border-radius: 4px;
    max-height: calc(100vh - 185px);
    min-height: 250px;
    background-color: transparent;
  }
`;

export const ScrollContainer = styled.div`
  padding: 2px 5px 10px;
  overflow: hidden auto;
  max-height: calc(100vh - 170px);
`;
