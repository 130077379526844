import styled, { css } from 'styled-components';
import { devices } from '../../../../../juristec-ui/core/breakpoints';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0.5rem;
  width: 100%;

  & .label {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.inputLabel};
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }

  & .errorMessage {
    position: absolute;
  }
`;

export const FirstPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  //max-width: 800px;
  height: calc(100vh - 366px);
  gap: 5px;

  @media ${devices.phoneS}, ${devices.phoneL} {
    width: 100%;
  }
`;

export const SeparatorLine = styled.hr`
  margin: 0.7rem auto;
  width: 100%;
  border: 0.8px solid #ddd;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: 0px 3px 5px ${({ theme }) => theme.shadow};
  padding: 0.3rem 0.6rem;
  & svg {
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.grey};
  }
  & .inputTxt {
    padding-top: 5px;
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const BtnIconWrapper = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.info};
  border-radius: 50%;
  & svg {
    fill: ${({ theme }) => theme.white};
  }
`;

export const TemplatesDeck = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
`;

export const TemplateCard = styled.div`
  ${(props) => (props.src?.length > 0
    ? css`
          background-image: url(${props.src});
          background-size: cover;
        `
    : css`
          background-image: url(${({ theme }) => theme.loginLogo});
          background-size: contain;
        `)}
  background-color: ${({ theme }) => theme.header};
  user-select: none;
  border-radius: 10px;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center;
  height: 110px;
  overflow: hidden;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //box-sizing: border-box;
  ${(props) => (props.selected
    ? css`
      box-shadow: 0 0 0px 5px ${({ theme }) => theme.success};
    `
    : css`
          cursor: pointer;
          &:hover {
            box-shadow: 0px 3px 3px ${({ theme }) => theme.shadow};
          }
        `)};

  & .card-header,
  .card-footer {
    background-color: #000000aa;
    font-weight: bold;
    padding: 5px 10px;
    color: white;
  }

  & .card-header {
    text-align: center;
  }

  & .card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 14px;
  align-items: center;

  & p {
    margin: 2px 0;
  }

  & button {
    font-size: 12px;
  }
`;

export const BadgersContainer = styled(InfoContainer)`
  & b {
    width: 100%;
  }
  & button {
    min-width: auto;
    font-size: 10px;
  }
`;

export const ScrollContainer = styled.div`
  overflow: hidden auto;
  padding: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media ${devices.tablet} {
    flex-direction: column;
  }
`;
