import React from 'react';

const LightBulb = () => {
    return (
        <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.93988 18.6351C3.94029 18.8931 4.01658 19.1457 4.15972 19.3606L4.86066 20.4143C4.9804 20.5944 5.14281 20.7422 5.33344 20.8443C5.52407 20.9465 5.737 21 5.95329 21H8.48431C8.7006 21 8.91354 20.9465 9.10417 20.8443C9.29479 20.7422 9.45721 20.5944 9.57695 20.4143L10.2779 19.3606C10.421 19.1457 10.4974 18.8933 10.4977 18.6351L10.4994 17.0622H3.93783L3.93988 18.6351ZM0 7.21863C0 9.03846 0.674693 10.6987 1.7866 11.9673C2.46417 12.7404 3.52399 14.3556 3.92798 15.7181C3.92962 15.7288 3.93085 15.7394 3.93249 15.7501H10.5047C10.5063 15.7394 10.5076 15.7292 10.5092 15.7181C10.9132 14.3556 11.973 12.7404 12.6506 11.9673C13.7625 10.6987 14.4372 9.03846 14.4372 7.21863C14.4372 3.2242 11.1933 -0.0122695 7.19604 3.49683e-05C3.01212 0.0127495 0 3.40303 0 7.21863ZM7.2186 3.93745C5.40944 3.93745 3.93742 5.40947 3.93742 7.21863C3.93742 7.5812 3.64375 7.87487 3.28118 7.87487C2.91861 7.87487 2.62494 7.5812 2.62494 7.21863C2.62494 4.68556 4.68553 2.62498 7.2186 2.62498C7.58117 2.62498 7.87483 2.91864 7.87483 3.28122C7.87483 3.64379 7.58117 3.93745 7.2186 3.93745Z" />
        </svg>
    );
};

export default LightBulb;