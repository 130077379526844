import { useReducer, useCallback, useMemo, useEffect } from 'react';
import {
  getBatch,
  getRefUser,
  // layout
  layoutAll2Firestore,
  layoutInsert2Firestore,
  // kpis
  getRefKpiItem,
  getRefKpiLayout,
  getRefKpiItemList,
  // comments
  getRefComment,
  getRefCommentsList,
  // shared
  getRefSharedItem,
  getRefSharedItemList,
  getRefPublicLayout,
  getRefPublicDashboard,
  // dashboard
  getRefDashboardItem,
  getRefDashboardLayout,
  getRefDashboardItemList,
} from '../utils/firestore';
// import secureFileName from '../utils/secureFileNames';
import firebase from '../utils/firebase';
import genPublicKpisFunc from '../utils/functions/genPublicKpis';

import notification_triggers from './notifications';
import createLog from './createLog';

// fetch
import { ip } from '../utils/functions/urls';
import getRequestMeta from '../utils/functions/generateMeta';

import { error as errorLabel } from '../label';
import FirestoreErrorCodes from '../utils/firestoreErrors';
import firestoreErrors from '../utils/firestoreErrors';
// import { Container } from './styles';
const initialState = {
  dashboards: [],
  sharedDashboards: [],
  dashboardLayout: {
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: [],
  },
  groups: {},
  isLoading: true,
  error: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        dashboards: action.dashboards,
        dashboardLayout: action.dashboardLayout,
        sharedDashboards: action.sharedDashboards,
        isLoading: false,
        groups: action.groups,
      };

    case 'addDashboard': { 
      const dashs = [...state.dashboards, action.dashboard];
      return {
        ...state,
        dashboards: dashs,
        dashboardLayout: action.dashboardLayout,
        isLoading: false,
        groups: getGroups(dashs),
      };
    }

      // todo? o middleware n tem o estado novo...
    case 'editDashboard':
      const tmp = state.dashboards.map((a => a.id === action.dashboard.id ?  action.dashboard : a ));
      return { ...state, dashboards: tmp, isLoading: false };

    case 'toggleTrashDashboard':
      const tmpDashs = state.dashboards.map((a => a.id === action.dashboard.id ?  action.dashboard : a ));
      return { ...state, dashboards: tmpDashs, isLoading: false };

    case 'removeDashboard': { 
      const dashs = state.dashboards.filter((d) => d.id !== action.dashId);
      return {
        ...state,
        dashboards: dashs,
        dashboardLayout: action.dashboardLayout,
        isLoading: false,
        groups: getGroups(dashs),
      };
    }
      
    case 'setLayout':
      return { 
        ...state,
        isLoading: false,
        dashboardLayout: action.dashboardLayout,
      };
      case 'bookMark':
        const aux = { 
          ...state,
          isLoading: false,
        }
        if(action.isShared) { 
          const dashs = state.sharedDashboards.map((d) => {
            if(d.id === action.dashId) return { ...d, bookMarked: !action.bookMark };
            return d;
          });
          return {  ...aux,  sharedDashboards: dashs };
        }

        const dashs = state.dashboards.map((d) => {
          if(d.id === action.dashId) return { ...d, bookMarked: !action.bookMark };
          return d;
        })
        return { ...aux, dashboards: dashs };
    
    case 'attDashDocPublic': {
      return { 
        ...state,
        dashboards: state.dashboards.map((d) => {
          if(d.id === action.dashDoc.id) { 
            return {
              ...d, 
              publicTimestamp: action.publicTimestamp,
              publicUrl: action.publicUrl,
            }
          }
          else return d;
        }),
        isLoading: false,
      }
    }
    case 'setLoading':
      return { ...state, isLoading: action.loading };

    default:
      return state;
  }
};

// const toMb = (size) => parseFloat((size / (1024 * 1024)).toFixed(2));
const isString = (s) => typeof s === 'string' || s instanceof String;
const isFile = (f) => f instanceof Blob || f instanceof File;

const getFormatFromMetricsFile = (t) => {
  const ax = t.replace('.metrics', '').split('.');
  return ax?.[1] || 'xlsx';
};

const genNewDb = (deparaFiles, oldDb, format) => (deparaFiles?.[oldDb]?.dst_name.includes(`${format}.metrics`) ? deparaFiles?.[oldDb]?.dst_name
: `${deparaFiles?.[oldDb]?.dst_name.trim()}.${format}.metrics`);

const getToken = async (u) =>  await u.getIdToken();

const getGroups = (dashs) => {
  return dashs.reduce((acc, cur) => {
    if (!acc) return acc;
    if(!cur.group.label) return acc;
    if (!acc[cur.group.label]) {
      acc[cur.group.label] = {
        value: cur.group.value, 
        label: cur.group.label,
        kpis: [cur.id]
      };
    } else { 
      acc[cur.group.label].kpis.push(cur.id);
    }
    return acc;
  }, {});
};

function useGridDashboard(currentUser, userDoc) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setLoading = (l = true) => dispatch({ type: 'setLoading', loading: l });

  const getDashLayout = async (userId) => {
    const lytRef = await getRefDashboardLayout(userId).get();
    return lytRef.data();
  };

  const getOwnDashboards = async (userId) => {
    const ownDashboardsRef = await getRefDashboardItemList(userId).get();
    return ownDashboardsRef
      .docs.map((d) => ({ ...d.data(), id: d.id, shared: false }));
  };

  const getSharedDashboards = async (userId) => {
    const sharedDashboardsRef = await getRefSharedItemList(userId).get();
    return sharedDashboardsRef
      .docs.map((d) => ({ ...d.data(), id: d.id, shared: true }));
  };

  const getAll = useCallback(async (userId) => {
    try  {
      const [dashboards, sharedDashboards, dashboardLayout] = await Promise
      .all([getOwnDashboards(userId), getSharedDashboards(userId), getDashLayout(userId)]);
      const aux = dashboards.concat(sharedDashboards);
      const groups = getGroups(aux);
      const refUser = getRefUser(userId);
      refUser.update({ nr_dashboards: dashboards.length });

      return { dashboards, sharedDashboards, groups, dashboardLayout, error: false, msg: '' };
    } catch(er) { 
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic,
        dashboards: null,
        sharedDashboards: null,
        dashboardLayout: null,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, []);

  ///////////////// CREATE DASH  /////////////
  const createDash = useCallback(async (itemToAdd, stateLayout) => {
   try {
    const batch = getBatch();

    let imgUrl = isString(itemToAdd.image) ? itemToAdd.image : ''; // companies/${userCompany.id}/images/${currentUser.uid}/
    if (isFile(itemToAdd.image)) imgUrl = await firebase.uploadImage(
      `companies/${userDoc.company}/images/${currentUser.uid}/`, itemToAdd.image,
    );

    const refDashboardItemList = getRefDashboardItemList(currentUser.uid);
    const refUser = getRefUser(currentUser.uid);

    const addedDashboardRef = refDashboardItemList.doc();
    batch.set(addedDashboardRef, { ...itemToAdd, image: imgUrl, created_at: firebase.serverTimestamp() });

    const ndoc = {
      i: addedDashboardRef.id,
      w: 1,
      h: 1,
      x: 0,
      y: 1000,
    };

    const layouts2 = layoutInsert2Firestore(stateLayout, ndoc);
    const firelayout = getRefDashboardLayout(currentUser.uid);
    batch.set(firelayout, layouts2, { merge: true });
    batch.update(refUser, { nr_dashboards: firebase.increment(1) });
    await batch.commit();

    return { error: false, msg: '', addedDashboardRef, id: addedDashboardRef.id, dashboardlayoutRef: firelayout };
  } catch(er) {
    console.log(er)
    return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
  }
  }, [currentUser.uid]);

  //////////////////  EDIT DASH ////////////////
  const editDash = useCallback(async (dashId, sharedWith, editInfo) => {
  try {  const refDashboardItemList = getRefDashboardItemList(currentUser.uid);
    const editedDashRef = refDashboardItemList.doc(dashId);
 
    let batch = getBatch();
    let imgUrl = isString(editInfo.image) ? editInfo.image : '';

    if (isFile(editInfo.image)) imgUrl = await firebase.uploadImage(
      `companies/${userDoc.company}/images/${currentUser.uid}/`, editInfo.image,
    );

    if (sharedWith) {
      const promises = [];
      const sharedIds = Object.keys(sharedWith);
      sharedIds.forEach((sharedUserId) => {
        const sharedItem = getRefSharedItem(sharedUserId, `${currentUser.uid}_${dashId}`);
        promises.push(sharedItem.get());
      });

      const allDocs = await Promise.all(promises);
      allDocs.forEach((d) => {
        if (d.exists) {
          batch.update(d, {
            group: editInfo.group,
            name: editInfo.name,
            image: imgUrl,
            mainColor: editInfo.mainColor,
            headerColor: editInfo.headerColor,
            lastUpdateUser: firebase.serverTimestamp(),
          });
        }
      });

      batch = notification_triggers('editDash', {editInfo, sharedIds, dashId, userDoc}, batch)

    }

    batch = createLog('dashboard', 'edit', {editInfo, dashId, userDoc},batch)

    batch.update(editedDashRef, { ...editInfo, image: imgUrl, lastUpdateUser: firebase.serverTimestamp() });
    await batch.commit();
    return { error: false, msg: '', editInfo, editedDashRef, id: editedDashRef.id }; 
  } catch(er) { 
    console.log(er);
    return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
  }
  }, [currentUser.uid]);

  ////////////////// MOVE DASH TO TRASH ///////////////
  const trashDash = useCallback(async (dashId, sharedWith, isDeleted) => {
    try {  const refDashboardItemList = getRefDashboardItemList(currentUser.uid);
      const deletedDashRef = refDashboardItemList.doc(dashId);
      let batch = getBatch();
  
      if (sharedWith) {
        const promises = [];
        const sharedIds = Object.keys(sharedWith);
  
        sharedIds.forEach((sharedUserId) => {
          const sharedItem = getRefSharedItem(sharedUserId, `${currentUser.uid}_${dashId}`);
          promises.push(sharedItem.get());
        });
        
        const allDocs = await Promise.all(promises);
        allDocs.forEach((d) => {
          if (d.exists) {
            batch.update(d, { isDeleted });
          }
        });
      }
  
      batch.update(deletedDashRef, { isDeleted });
      await batch.commit();
      return { error: false, msg: '', isDeleted, deletedDashRef, id: deletedDashRef.id }; 
    } catch(er) {
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
    }, [currentUser.uid]);

  ////////////////// REMOVE DASH ///////////////
  const removeDash = async (userId, dashDocument) => {
   try {
      const snapKeys = ('snapshots' in dashDocument) ? (
      dashDocument.snapshots.map((k) => {
        if (Object.prototype.toString.call(k) === '[object String]') return k;
        return k.snapId;
      })
    ) : [];
    const collections = [...['kpis'], ...snapKeys];

    // deletando collections (KPIS e snapshots) com seus os layouts
    const batch = getBatch();
    const res = collections.map(async (col) => {
      const refDashLayout = getRefKpiLayout(userId, dashDocument.id, col);
      batch.delete(refDashLayout);

      const commentList = await getRefCommentsList(userId, dashDocument.id).get();
      commentList.docs.forEach((doc) => {
        const commentRef = getRefComment(userId, dashDocument.id, doc.id);
        batch.delete(commentRef);
      });

      const kpisList = await getRefKpiItemList(userId, dashDocument.id, col).get();
      return kpisList.docs.map((doc) => {
        const kpiRef = getRefKpiItem(userId, dashDocument.id, doc.id, col);
        batch.delete(kpiRef);
        return `${doc.id}`;
      });
    });
    await Promise.all(res);

    // apagando docs em /shared que compartilham este dashboard com outro usuario
    if ('sharedWith' in dashDocument) {
      Object.keys(dashDocument.sharedWith).forEach((sharedUserId) => {
        const sharedItem = getRefSharedItem(sharedUserId, `${userId}_${dashDocument.id}`);
        batch.delete(sharedItem);
      });
    }

    // apagando docs em /public
    if ('publicUrl' in dashDocument) {
      const publicDash = getRefPublicDashboard(userId, dashDocument.id);
      const publicLayout = getRefPublicLayout(userId, dashDocument.id);
      batch.delete(publicDash);
      batch.delete(publicLayout);
    }

    // deletando documento do dashboard
    const refDash = getRefDashboardItem(userId, dashDocument.id);
    batch.delete(refDash);

    // atualizando numero de dasbaords desse usuario
    const refUser = getRefUser(userId);
    batch.update(refUser, { nr_dashboards: firebase.increment(-1) });

    await batch.commit();
    return { error: false, msg: '' };
    } catch(er) { 
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  };

  //  ********** COPY_TO_ME *******************
  // const dashId = item.id.split('_')[1];
  const databaseDashAndKpiCopy = useCallback(async (dashId, currentUser, sharedFromUserId, deparaFiles) => {
    try {
      const batch = getBatch();
      // SOURCE: owner (sharedFrom)
      const dsDocRaw = await getRefDashboardItem(sharedFromUserId, dashId).get();
      const kpisListRaw = await getRefKpiItemList(sharedFromUserId, dashId).get();
      const kpiLayoutRef = await getRefKpiLayout(sharedFromUserId, dashId).get();
      
      //se n encontrar os dash do source error:
      if(!dsDocRaw.exists) return { error: true, msg: errorLabel.useGridDash.dashNotFound, raw: errorLabel.useGridDash.dashNotFound };
      if(!kpiLayoutRef.exists) return { error: true, msg: errorLabel.useGridDash.layoutNotFound, raw: errorLabel.useGridDash.layoutNotFound };

      // TARGET: currentUser.uid
      const clonnedDashRef = getRefDashboardItemList(currentUser.uid).doc();
      const clonnedLayoutRef = getRefKpiLayout(currentUser.uid, clonnedDashRef.id);
      const clonnedKpisRef = getRefKpiItemList(currentUser.uid, clonnedDashRef.id);
      const refUser = getRefUser(currentUser.uid);

      const dbs = dsDocRaw.get('databases') || [];

      const clonnedDash = {
        ...dsDocRaw.data(),
        databases: dbs.map((d) => (
          deparaFiles?.[d]?.newName ? `${deparaFiles?.[d]?.newName.trim()}.${getFormatFromMetricsFile(d)}` : d
        )),
        clonnedFrom: { owner: sharedFromUserId, dashId },
        lastUpdateUser: firebase.serverTimestamp(),
        sharedWith: {},
        displayName: currentUser.displayName,
        owner: currentUser.uid,
        name: `Cópia de ${dsDocRaw.get('name')}`,
        group: { color: '', label: '', value: '' },
      };

      const clonnedLayout = {
        ...kpiLayoutRef.data(),
        clonnedFrom: { owner: sharedFromUserId, dashId: clonnedDashRef.id },
      };

      batch.set(clonnedDashRef, clonnedDash);
      batch.set(clonnedLayoutRef, clonnedLayout);
      batch.update(refUser, { nr_dashboards: firebase.increment(1) });

      kpisListRaw.docs.forEach((d) => {
        // console.log(d.id);
        const kpiRef = clonnedKpisRef.doc(d.id);
        const kpi = d.data();

        // para quando for clonar e for mudar o nome do arquivo...
        if (kpi.database && deparaFiles?.[kpi.database]?.newName) {
          const newDb = `${deparaFiles?.[kpi.database]?.newName.trim()}.${getFormatFromMetricsFile(kpi.database)}`;

          kpi.meta = kpi.meta.replace(kpi.database, newDb);
          kpi.database = newDb;
        }
        batch.set(kpiRef, { ...kpi, clonnedFrom: { kpiId: d.id, owner: sharedFromUserId } });
      });

      await batch.commit();
      return { error: false, msg: '', clonnedDashRef };
    } catch (er) {
      console.log('databaseDashAndKpiCopy: ', er);
      return { error: true, msg: firestoreErrors(er?.code), raw: `Erro do sistema: ${er.toString()}` };
    }
  }, []);

  const copyDashboardAndFiles = useCallback(async (dashId, sharedfromUserId, files, depara = {}) => {
    if(files?.length) {
      try  {
        const token = await getToken(currentUser);
        const opt = {
          ...await getRequestMeta(token, "POST", 'JSON'), 
          body: JSON.stringify({ filename: files, ownerId: sharedfromUserId }),
        };
        const resFetch = await fetch(`${ip}/db/clone`, opt);

        if(resFetch.status === 500) {
          return { error: true, msg: errorLabel.useGridDash.generic, raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!'}
        }
        const json = await resFetch.json();
        console.log(json);
        // const jsonResponse = JSON.parse(json);

        if(resFetch.status !== 200) {
          return { error: true, msg: errorLabel.useGridDash.copyDash, raw: json.error };
        };

      } catch(er) { 
        console.log(er);
        return { error: true, msg: errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
      }
    }

    return await databaseDashAndKpiCopy(dashId, currentUser, sharedfromUserId, depara);

  }, [currentUser, databaseDashAndKpiCopy]);
  //  ********** FIM COPY_TO_ME *******************

  ///////////// DASH AS TEMPLATE /////////////////////
  const dashboardAsTemplate = useCallback(async(dashTagetId, dashboardName, database, templateMap, filters, group) => {
    try { 
      const token = await getToken(currentUser);
      const refUser = getRefUser(currentUser.uid);
      const batch = getBatch();
      const opt = {
        ...await getRequestMeta(token, "POST", 'JSON'),
        body: JSON.stringify({ id: dashTagetId, dashboardName,  filename: database, templateMap, filters, group, ownerId: currentUser.uid })
      };
      const res = await fetch(`${ip}/kpis/template`, opt);
      const raw = await res.json();
      if(res.status !== 200) return { error: true, msg: errorLabel.useGridDash.fetchGeneric, raw: raw.error };

      batch.update(refUser, { nr_dashboards: firebase.increment(1) });
      await batch.commit();

      return { error: false, msg: null, res: raw };
    } catch(er) { 
      console.log(er);
      return { error: true, msg: errorLabel.useGridDash.fetchGeneric, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  /**
   * @async
   * Makes an exact copy of a dashboard (same files)
   */
  const cloneDashboard = useCallback(async (dashId, sharedOwnerId = null) => {
    try {
      const ownerId = sharedOwnerId || currentUser.uid;
      const batch = getBatch();
      const dsDocRaw = await getRefDashboardItem(ownerId, dashId).get();
      const kpiLayoutRef = await getRefKpiLayout(ownerId, dashId).get();
      const kpisListRaw = await getRefKpiItemList(ownerId, dashId).get();
      
      if(!dsDocRaw.exists) return {
        error: true, msg: errorLabel.useGridDash.dashNotFound, raw: errorLabel.useGridDash.dashNotFound
      };
      if(!kpiLayoutRef.exists) return {
        error: true, msg: errorLabel.useGridDash.layoutNotFound, raw: errorLabel.useGridDash.layoutNotFound
      };

      const clonnedDashRef = getRefDashboardItemList(currentUser.uid).doc();
      const clonnedLayoutRef = getRefKpiLayout(currentUser.uid, clonnedDashRef.id);
      const clonnedKpisRef = getRefKpiItemList(currentUser.uid, clonnedDashRef.id);
      const refUser = getRefUser(currentUser.uid);

      const dbs = dsDocRaw.get('databases') || [];

      const clonnedDash = {
        ...dsDocRaw.data(),
        created_at: firebase.serverTimestamp(),
        lastUpdateUser: firebase.serverTimestamp(),
        sharedWith: {},
        displayName: currentUser.displayName,
        owner: currentUser.uid,
        name: `Clone de ${dsDocRaw.get('name')}`,
      };

      const clonnedLayout = { ...kpiLayoutRef.data() };

      if (sharedOwnerId) {
        clonnedDash.clonnedFrom = { owner: sharedOwnerId, dashId };
        clonnedDash.group = { color: '', label: '', value: '' };
        clonnedLayout.clonnedFrom = { owner: sharedOwnerId, dashId: clonnedDashRef.id };
      }

      batch.set(clonnedDashRef, clonnedDash);
      batch.set(clonnedLayoutRef, clonnedLayout);
      batch.update(refUser, { nr_dashboards: firebase.increment(1) });

      kpisListRaw.docs.forEach((kpi) => {
        const kpiRef = clonnedKpisRef.doc(kpi.id);
        const kpiData = kpi.data();
        if (sharedOwnerId) {
          kpiData.clonnedFrom = { kpiId: kpi.id, owner: sharedOwnerId };
        }
        batch.set(kpiRef, kpiData);
      });
      
      await batch.commit();
      return { error: false, msg: '', clonnedDashRef, dbs };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabel.useGridDash.fetchGeneric, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);
  
   const getColumnsRequired = useCallback((kpiItemList) => {
    const metas = kpiItemList.docs.map((doc) => {
      const card = doc.data();
      if (card.meta) {
        return JSON.parse(card.meta);
      }
      return null;      
    }).filter((kpi) => kpi);
  
    const columnsRequired = {};
    metas.forEach((kpi) => {
      if (kpi.columns?.length > 0) {
        columnsRequired[kpi.columns[0].column] = kpi.columns[0].type || 'category';
      }
      if (kpi.control?.length > 0) {
        columnsRequired[kpi.control[0].column] = kpi.control[0].type || 'category';
      }
      if (kpi.filters?.length > 0) {
        kpi.filters.forEach((filter) => {
          if (filter?.column !== kpi.columns[0]?.column) {
            columnsRequired[filter.column] = filter.type || 'category';
          }
        });
      }
      columnsRequired[kpi.lines[0].column] = kpi.lines[0].type || 'category';
      columnsRequired[kpi.values[0].column] = kpi.values[0].type || 'category';
    });

    return columnsRequired;
  }, []);

  const genRequiredFromDashboard = useCallback(async (dashId, userId) => {
    try {
      const kpisList = await getRefKpiItemList(userId, dashId).get();
      const reqColumns = getColumnsRequired(kpisList);      
      const reqVariablesDescription = {};
      Object.keys(reqColumns).forEach((variableName) => {
        reqVariablesDescription[variableName] = { description: '', type: reqColumns[variableName] };
      });
      return { reqColumns, reqVariablesDescription, error: false, msg: '' };
    } catch(er) { 
      return { 
        error: true,
        msg: errorLabel.useGridDash.genRequiredFromDashboard,
        raw: `Erro do sistema: ${er.toString()}`,
      }
    }
  }, []);
  

  //////////// SAVE LAYOUT /////////////////
  const saveLayout = useCallback(async (layout) => {
    try { 
      const layoutRef = getRefDashboardLayout(currentUser.uid);
      await layoutRef.set(layoutAll2Firestore(layout), { merge: true });

      return { error: false, msg: '' };
    } catch(er) { 
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);


  ///////////// GET DASH INFO //////////////////
  const getDashInfos = useCallback(async (dashId) => {
    try { 
      const { uid } = currentUser;

      const raw = await getRefDashboardItem(uid, dashId).get();

      if(!raw.exists) return { error: true, msg: errorLabel.useGridDash.dashNotFound, dashboard: null };
      return { error: false, msg: '', dashboard: { ...raw.data(), id: raw.id } };
    } catch(er) { 
     return { error: true, msg: FirestoreErrorCodes(er.code) || errorLabel.useGridDash.dashNotFound, dashboard: null, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  // Internal use
  const changeFilePermission = useCallback(async (fileId, selected) => {
    try {
      const token = await getToken(currentUser);
      const opt = {
        ...await getRequestMeta(token, "PUT", 'JSON'),
        body: JSON.stringify({
          users: [
            ...selected.map((u) => ({
              uid: u.id,
              read: ['visualizador', 'edit'].includes(u.sharePermission?.value),
            })),
          ],
        }),
      };
      const resFetch = await fetch(`${ip}/db/file/${fileId}/permissions/edit`, opt);
      const json = await resFetch.json();
  
      if (resFetch.status !== 200) {
        return { error: true, msg: errorLabel.useGridDash.generic, raw: json.error };
      }

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return { error: true, msg: errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  ///////////////////// SHARE DASHBOARD ///////////////
  const shareDashboard = useCallback(async (dashboard, usersList, selectedUsers, removedUsers, currentUserFiles) => {
    const { uid } = currentUser;
    let batch = getBatch();
    const refDash = getRefDashboardItem(uid, dashboard.id);
    const copyOfShareds = { ...dashboard?.sharedWith };
    const dashUpdate = {};
    const sharedAt = firebase.serverTimestamp();

    const idsToMetadata = {};
    Object.keys(copyOfShareds).forEach((sid) => {
      idsToMetadata[`shared_${sid}`] = null;
      dashUpdate[`sharedWith.${sid}`] = firebase.deleteField();
      const refSharedDashboard = getRefSharedItem(sid, `${uid}_${dashboard.id}`);
      batch.delete(refSharedDashboard);
    });

    if (dashboard.sharedWith) {
      const deletedUsers = Object.keys(dashboard.sharedWith).filter((v) => !usersList.map((u) => u.id).includes(v));
      deletedUsers.forEach((dstUserId) => {
        dashUpdate[`sharedWith.${dstUserId}`] = firebase.deleteField();
      });
    }

    //creating data to notification 
    const oldSharesIds = Object.keys(dashboard?.sharedWith || []);
    const selectedsIds = selectedUsers.map((user)=> user.id)
    const usersAdd     = selectedsIds.filter((id)=> !oldSharesIds.includes(id));


    //logs 
    // selectedsIds.forEach(id=>{
    //   if(dashboard.sharedWith[id].value === )
    // })

    selectedUsers.forEach(user =>{
      if(dashboard.sharedWith?.[user.id] && user.sharePermission.value !== dashboard.sharedWith[user.id]?.value){
        batch = createLog('user', 'edit', {
          userDoc,
          dashId: dashboard.id,
          target: { targetId: user.id, targetName: user.name, newUserPermission: user.sharePermission.value },
        }, batch);
      }
    })



    if(removedUsers.length > 0){
      batch = notification_triggers('shareDash', { users: removedUsers, key: 'remove', dashboard, usersList,userDoc }, batch )
    }
    if(usersAdd.length > 0){
      batch = notification_triggers('shareDash', { users: usersAdd, key: 'add', dashboard, usersList, userDoc }, batch )
    }


    selectedUsers.forEach((user) => {
      const refSharedDashboard = getRefSharedItem(user.id, `${uid}_${dashboard.id}`);
      idsToMetadata[`shared_${user.id}`] = true;
      batch.set(refSharedDashboard, {
        id: dashboard.id,
        name: dashboard.name,
        owner: dashboard.owner,
        created_at: sharedAt,
        displayName: dashboard.displayName,
        group: dashboard.group,
        image: dashboard.image || '',
        dashPermission: user.sharePermission.value,
        headerColor: dashboard.headerColor || '',
        mainColor: dashboard.mainColor || '',
      });
      dashUpdate[`sharedWith.${user.id}`] = {
        sharedAt, ...user.sharePermission,
      };
    });


    batch.update(refDash, dashUpdate);

    try {
      await batch.commit();

      if(dashboard.image) await firebase.updateCustomFileMetadataUsingUrl(dashboard.image, idsToMetadata);

      const promises = [];
      const { databases } = dashboard;
      
      const missingFiles = { deleted: [], noPermission: []};

      if (databases) {
        databases.forEach((file_id) => {
          const match = currentUserFiles.find((f) => f.file_id === file_id);
          if (match) {
            if (match.origin !== 'LegalOne') {
              if (match.owner === uid) {
                promises.push(changeFilePermission(match.file_id, selectedUsers));
              } else {
                missingFiles.noPermission.push(match.file_id);
              }
            }
          } else {
            missingFiles.deleted.push(file_id);
          }
          // promises.push(firebase.updateCustomFileMetadataUsingPath(`/users/${uid}/databases/${secureFileName(fileName)}`, idsToMetadata));
        });
        const multRes = await Promise.all(promises);
        const err = multRes.find((res) => res.error);
        if (err) {
          return err;
        }
      }

      return { error: false, msg: '', missing: missingFiles };
    } catch (er) {
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.generic, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser,userDoc]);

  const bookMarkDashboard = useCallback(async (dashId, bookMark, isShared) => {
    try { 
      const dashboardRef = isShared ? 
        getRefSharedItem(currentUser.uid, dashId) :
        getRefDashboardItem(currentUser.uid, dashId);

      await dashboardRef.set({ bookMarked: !bookMark }, { merge: true });
      return { error: false, msg: '' };
    } catch(er) { 
      console.log(er);
      return { error: true, msg: firestoreErrors(er.code) || errorLabel.useGridDash.bookMarkDashboard, raw: `Erro do sistema: ${er.toString()}` };
    }
  }, [currentUser]);

  const genPublicKpis = useCallback(async (dashDoc, revokeLink, snapId = 'kpis') => {
    try {
      const { publicUrl, localTimestamp } = await genPublicKpisFunc(userDoc, dashDoc, snapId, revokeLink);
      return {
        error: false, msg: '', publicUrl, localTimestamp,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code),
        raw: `Erro do sistema: ${er.toString()}`,
        link: '',
      };
    }
  }, [userDoc]);

  // TODO: tratar erros
  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'init':
        setLoading();
        const { dashboards, sharedDashboards, dashboardLayout, groups, error: getAllError, msg: getAllMsg, raw: initRaw } = await getAll(currentUser.uid);
      if(getAllError) { 
        setLoading(false);
      } else { 
        dispatch({
          type: 'init',
          dashboards,
          sharedDashboards,
          dashboardLayout,
          groups,
        });
      }
      return { error: false, msg: getAllMsg, raw: initRaw };

      case 'saveLayout':
        setLoading();
        const reslyt = await saveLayout(action.layout);
        if(reslyt.error) {
          setLoading(false);
          return reslyt;
        } 

        dispatch({ type: 'setLayout', dashboardLayout: action.layout });
        return { error: reslyt.error, msg: reslyt.msg, raw: reslyt.raw };

      case 'createDashboard':
        setLoading();
        const { itemToAdd, stateLayout } = action;
        const { addedDashboardRef, dashboardlayoutRef, error: createError, msg, raw: createRaw } = await createDash(itemToAdd, stateLayout);
        if(createError) {
          setLoading(false);
          return { error: createError, msg };
        }
        const [addedDoc, layoutDoc] = await Promise
          .all([addedDashboardRef.get(), dashboardlayoutRef.get()]);

        dispatch({ type: 'addDashboard', dashboard: { ...addedDoc.data(), id: addedDoc.id }, dashboardLayout: layoutDoc.data() });
        return { addedDoc: { ...addedDoc.data(), id: addedDoc.id }, error: createError, msg, raw: createRaw };

      case 'editDashboard':
        setLoading();
        const { dashId, sharedWith, editInfos } = action;
        const { editedDashRef, error: errorEdit, msg: msgEdit, raw: editRaw } = await editDash(dashId, sharedWith, editInfos);

        if(errorEdit) {
          setLoading(false);
        } else { 
          const editedDoc = await editedDashRef.get(); 
          dispatch({ type: 'editDashboard', dashboard: { ...editedDoc.data(), id: editedDoc.id } });
        }
        return { error:errorEdit, msg: msgEdit, raw: editRaw };

      case 'toggleTrashDashboard':
        setLoading();
        const { dashDelId, dashSharedWith, isDeleted } = action;
        const {
          deletedDashRef, error: errorDelete, msg: msgDelete, raw: deleteRaw,
        } = await trashDash(dashDelId, dashSharedWith, isDeleted);

        if(errorDelete) {
          setLoading(false);
        } else { 
          const deletedDoc = await deletedDashRef.get(); 
          dispatch({ type: 'toggleTrashDashboard', dashboard: { ...deletedDoc.data(), id: deletedDoc.id } });
        }
        return { error: errorDelete, msg: msgDelete, raw: deleteRaw };

      case 'removeDashboard':
        setLoading();
          const { dashboard } = action;
          const { error: rmvError, msg: msgRemove, raw: rmvRaw } = await removeDash(currentUser.uid, dashboard);
          if(rmvError) { 
            setLoading(false);
          } else { 
            const lyt = await getDashLayout(currentUser.uid);
            dispatch({ type: 'removeDashboard', dashId: dashboard.id, dashboardLayout: lyt });
          }
          return { msg: msgRemove, error: rmvError, raw: rmvRaw };
  
      case 'copyToMe':
        setLoading();
        const { dashId: dId,sharedfromUserId,  files, depara } = action;
        const rescp = await copyDashboardAndFiles(dId, sharedfromUserId,  files, depara);
        if (rescp.error) { 
          setLoading(false);
        } else  {
          const [clonneddoc, layoutDoc] = await Promise
          .all([rescp.clonnedDashRef.get(), getRefDashboardLayout(currentUser.uid).get()]);
          dispatch({ type: 'addDashboard', dashboard: { ...clonneddoc.data(), id: clonneddoc.id }, dashboardLayout: layoutDoc.data() });
        }
        return { msg: rescp.msg, error: rescp.error, raw: rescp.raw };

      case 'dashboardAsTemplate': {
        setLoading();
        const { id, dashboardName, database, templateMap, filters, group } = action;
        const res = await dashboardAsTemplate(id, dashboardName, database, templateMap, filters, group);
        if(res.error) { 
          setLoading(false);
        } else {
          // TODO: mudar backend (df/template) para retornar id do dashboard criado.
          const { dashboards, sharedDashboards, dashboardLayout, groups, error: getAllError, msg: getAllMsg, raw: initRaw } = await getAll(currentUser.uid);
          if(getAllError) {
            setLoading(false);
            return { error: true, msg: getAllMsg, raw: initRaw };
          }
          dispatch({
            type: 'init',
            dashboards,
            sharedDashboards,
            dashboardLayout,
            groups,
          });
        }
        
        return { error: res.error, msg: res.msg, raw: res.raw };
      }
      case 'cloneDashboard': {
        setLoading();
        const res = await cloneDashboard(action.dashId, action.sharedOwnerId);
        if(res.error) { 
          setLoading(false);
        } else {
          const getAllRes = await getAll(currentUser.uid);
          if(getAllRes.error) {
            setLoading(false);
            return { error: true, msg: getAllRes.msg, raw: getAllRes.initRaw };
          }
          dispatch({
            type: 'init',
            dashboards: getAllRes.dashboards,
            sharedDashboards: getAllRes.sharedDashboards,
            dashboardLayout: getAllRes.dashboardLayout,
            groups: getAllRes.groups,
          });
        }
        return { error: res.error, msg: res.msg, raw: res.raw, databases: res.dbs };
      }

      case 'genRequiredFromDashboard': {  
        setLoading(true);
        const { reqColumns, reqVariablesDescription, error, msg } = await genRequiredFromDashboard(action.dashId, action.userId);
        setLoading(false);
        return { error, msg,  reqColumns, reqVariablesDescription, };
      }

      case 'getDashInfos':
        setLoading();
        const { dashId: dshId } = action;
        const r = await getDashInfos(dshId);
        setLoading(false);
        return { error: r.error, msg: r.msg, raw: r.raw };

      case 'shareDashboard':
        setLoading();
        const {  usersList, selectedUsers, removedUsers, currentUserFiles } = action;
        let dashboardDoc = null;
        const { msg: shareMsg, error: shareError, raw: shareRaw, res } = await shareDashboard(
          action.dashboard, usersList, selectedUsers, removedUsers, currentUserFiles,
        );
        // atualizar doc q foi compartilhado
        if(shareError) {
          setLoading(false);
        } else { 
          const sharedDash = await getRefDashboardItem(currentUser.uid, action.dashboard.id).get();
          dashboardDoc = { ...sharedDash.data(), id: sharedDash.id }
          dispatch({ type: 'editDashboard', dashboard: dashboardDoc });
        }

        return { msg: shareMsg, error: shareError, raw: shareRaw, dashboardDoc, res };

        case 'bookMarkDashboard': { 
          // setLoading();
          const { dashId, bookMark, isShared } = action;
          const result = await bookMarkDashboard(dashId, bookMark, isShared);
          if(!result.error) dispatch({ type: 'bookMark', dashId, bookMark, isShared });
          // setLoading(false)
          return result;
        }

        case 'genPublicKpis': {
          setLoading(true);
          const res = await genPublicKpis(action.dashDoc, action.revokeLink, action.snapId);
          if (!res.error) {
            dispatch({
              type: 'attDashDocPublic',
              dashDoc: action.dashDoc,
              publicUrl: res.publicUrl,
              publicTimestamp: res.localTimestamp,
            });
          } else {
            setLoading(false);
          }
          return res;
        }

      default:
        dispatch(action);
        return { error: false, msg: '', raw: '' };
    }
  }, [currentUser.uid, 
      copyDashboardAndFiles,
      createDash,
      editDash,
      getDashInfos,
      saveLayout,
      getAll,
      dashboardAsTemplate,
      cloneDashboard,
      shareDashboard,
      bookMarkDashboard,
      genRequiredFromDashboard
    ]);
 
  const dashboardAPI = useMemo(() => ({
    init: async () => middleware({ type: 'init' }),
    saveLayout: async (layout) => middleware({ type: 'saveLayout', layout }),
    createDashboard: async (itemToAdd, stateLayout) => middleware({ type: 'createDashboard', itemToAdd, stateLayout }),
    removeDashboard: async (dashboard) => middleware({ type: 'removeDashboard', dashboard }),
    setLoading: async (l = true) => middleware({ type: 'setLoading', loading: l}),
    editDashboard: async (dashId, sharedWith, editInfos) => middleware({
      type: 'editDashboard', dashId, sharedWith, editInfos,
    }),
    toggleTrashDashboard: async (dashDelId, dashSharedWith, isDeleted) => middleware({
      type: 'toggleTrashDashboard', dashDelId, dashSharedWith, isDeleted,
    }),
    copyDashboardAndFiles: async (dashId, sharedfromUserId,  files, depara) => middleware({ type: 'copyToMe', dashId, sharedfromUserId,  files, depara }),
    dashboardAsTemplate: async (id, dashboardName, database, templateMap, filters, group) => middleware({ type: 'dashboardAsTemplate', id, dashboardName, database, templateMap, filters, group }),
    cloneDashboard: async (dashId, sharedOwnerId) => middleware({ type: 'cloneDashboard', dashId, sharedOwnerId }),
    shareDashboard: async (dashboard, usersList, selectedUsers, removedUsers, currentUserFiles) => middleware({
      type: 'shareDashboard',  dashboard, usersList, selectedUsers, removedUsers, currentUserFiles,
    }),
    getDashInfos: async (dashId) => middleware({ type: 'getDashInfos', dashId}),
    bookMarkDashboard: async(dashId, bookMark, isShared) => middleware({ type: 'bookMarkDashboard', dashId, bookMark, isShared }),
    genRequiredFromDashboard: async(dashId, userId) => middleware({ type: 'genRequiredFromDashboard', dashId, userId }),
    genPublicKpis: async (dashDoc, revokeLink, snapId) => middleware({
      type: 'genPublicKpis', dashDoc, revokeLink, snapId,
    }),
  }), [middleware]);

  useEffect(() => {
    const init = async () => middleware({ type: 'init' });
    init();
  }, [middleware]);

  return [state, dashboardAPI];
}

export default useGridDashboard;

