import styled from 'styled-components';

export const Container = styled.div`
  font-size: 16px;
  min-height: 100px;
  padding: 5px;
`;

export const FirstRow = styled.span`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const ContainerRow = styled.div`
  flex-direction: row;
  display: flex;
  padding: 0px 15px 15px;
  border-left: 5px solid ${({ theme }) => theme.containerLine};
  background-color: ${({ theme }) => theme.container};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.containerOdd};
    border-left-color: ${({ theme }) => theme.containerLineOdd};
  }

  & .errorMessage {
    position: absolute;
  }
`;
