import styled from 'styled-components';
import { NavLink as NVLink } from 'react-router-dom';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

const getNumberOfDigits = (number) => `${number}`.length;

export const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.headerBackground};
  display: flex;
  padding: 0 5%;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;

  @media ${devices.tablet} {
    padding: 0 20px;
  }

  @media ${devices.phoneS} {
    height: 56px;
  }
`;

export const DrawerNavLink = styled(NVLink)`
  color: ${({ theme }) => theme.toolbarFont};
  text-decoration: none;
  padding-left: 1rem;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.containerHover};;
  }

  & svg {
    margin-right: 1rem;
    fill: ${({ theme }) => theme.toolbarFont};
    width: 25px;
    height: 25px;
    pointer-events: none;
  }

  &.header-active-link {
    color: ${({ theme }) => theme.primary};
    & svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

export const SeparatorLine = styled.hr`
  margin: 1.5rem auto 1rem auto;
  width: 80%;
  border: 0.8px solid #ddd;
`;

export const NavLink = styled(NVLink)`
  color: ${({ theme }) => theme.toolbarFont};
  text-decoration: none;
  padding: 0 5px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  &.header-active-link {
    color: ${({ theme }) => theme.primary};
    border-bottom-color: ${({ theme }) => theme.primary};
  }

  @media ${devices.phoneL} {
    display: none;
  }
`;

export const LeftElementHeader = styled.div`
  display: flex;
  ${gap('15px', 'row')}
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  button {
    display: none !important;
  }

  @media ${devices.phoneL} {
    button {
      display: inline-flex !important;
    }
  } ;
`;

export const Brand = styled.div`
  display: flex;
  margin-left: -15px;

  svg {
    height: 2rem;
    width: 8rem;
  };
`;

export const RightElementHeader = styled.div`
  display: flex;
  ${gap('15px', 'row')}
  align-items: center;
  justify-content: space-around;
`;

export const CompanyLogoOverlay = styled.div`
  display: none;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: left;
  background-color: #00000080;
  padding: 0 25%;
`;

export const HeaderAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 40px;
  background: ${({ theme }) => theme.grey}33;
  border-radius: 28px;
  position: relative;
  overflow: hidden;

  &:hover ${CompanyLogoOverlay} {
    display: flex;
  }

  & button {
    z-index: 1;
  }

  & div:hover + div {
    display: none !important;
  }

  img {
    margin: 0 auto;
    max-height: 38px;
    max-width: 68px;
  }

  span {
    position: absolute;
    bottom: -5px;
    right: -5px;
  }

  @media ${devices.phoneS} {
    width: 120px;
    height: 40px;
    border-radius: 20px;

    img {
      max-height: 38px;
      max-width: 68px;
    }

    span {
      bottom: -5px;
      right: -8px;
    }
  } ;
`;

export const NotificationElement = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};
  top: 3px;
  right: 3px;
  font-size: ${(props) => (
    getNumberOfDigits(props.newMessages) < 3 ? '10px' : '8px'
  )};
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  border-radius: 50%;
`;
