/* eslint-disable no-param-reassign */
import { legendPositionOptions } from '../../options';

export default function (newConfig, diff, value) {
  const styleConfig = { ...newConfig };
  switch (diff) {
    case 'ShowTitleControl':
      styleConfig.ShowTitleControl = { checked: value };
      break;
    case 'ShowZeroValuesControl':
      styleConfig.ShowZeroValuesControl = { checked: value };
      break;
    case 'CustomSortControl':
      styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, checked: value };
      if (value) {
        styleConfig.SortValuesControl = 'Não ordenado';
        // styleConfig.SortValuesControlLine = { options: styleConfig.SortValuesControlLine.options, value: 'Não ordenado' };
      }
      break;
    case 'CustomSortControlUpt':
      styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, ...value };
      break;
    case 'SortValuesControl':
      if (value === 'Customizado') {
        styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, checked: true };
      } else {
        styleConfig.CustomSortControl = { ...styleConfig.CustomSortControl, checked: false };
      }
      styleConfig.SortValuesControl = value;
      // styleConfig.SortValuesControlLine = { options: styleConfig.SortValuesControlLine.options, value };

      break;
    case 'SortValuesControlLine':
      styleConfig.SortValuesControlLine = { options: styleConfig.SortValuesControlLine.options, value };
      break;
    case 'GroupModeControl':
      styleConfig.GroupModeControl = value;
      break;
    case 'LayoutControl':
      // usado em Bar.
      if (value === 'horizontal') {
        styleConfig.LabelFormatControl = 'Central';
      }
      styleConfig.LayoutControl = value;
      if (styleConfig.MarkersList) {
        styleConfig.MarkersList = styleConfig.MarkersList.map((line) => ({
          ...line,
          axis: value === 'horizontal' ? 'x' : 'y',
        }));
      }
      break;
    case 'ReverseControl':
      styleConfig.ReverseControl.checked = value;
      break;
    case 'MinValueControl':
      if (typeof value === 'boolean') {
        styleConfig.MinValueControl = {
          ...styleConfig.MinValueControl,
          checked: value,
        };
      } else {
        styleConfig.MinValueControl = { ...styleConfig.MinValueControl, value };
      }
      break;
    case 'MaxValueControl':
      if (typeof value === 'boolean') {
        styleConfig.MaxValueControl = {
          ...styleConfig.MaxValueControl,
          checked: value,
        };
      } else {
        styleConfig.MaxValueControl = { ...styleConfig.MaxValueControl, value };
      }
      break;
    case 'PaddingControl':
      styleConfig.PaddingControl = { value };
      break;
    case 'InnerPaddingControl':
      styleConfig.InnerPaddingControl = { value };
      break;
    case 'LabelTextColorControl':
      if (value === false) {
        styleConfig.LabelTextColorControl = {
          from: 'color',
          modifiers: [['darker', 1.6]],
        };
      } else {
        styleConfig.LabelTextColorControl = value === true ? 'black' : value;
      }
      break;
    case 'LabelTextColorControl2':
      if (value === 'custom') styleConfig.LabelTextColorControl = '#000';
      else {
        styleConfig.LabelTextColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.LabelTextColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'LabelTextOpacityControl':
      styleConfig.LabelTextColorControl = { from: 'color', modifiers: [[styleConfig.LabelTextColorControl.modifiers[0][0], value]] };
      break;
    case 'SliceTextColorControl2':
      if (value === 'custom') styleConfig.SliceTextColorControl = '#000';
      else {
        styleConfig.SliceTextColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.SliceTextColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'SliceTextOpacityControl':
      styleConfig.SliceTextColorControl = { from: 'color', modifiers: [[styleConfig.SliceTextColorControl.modifiers[0][0], value]] };
      break;
    case 'PaddingAngleControl':
      styleConfig.PaddingAngleControl = { value };
      break;
    case 'InteractiveControl':
      styleConfig.InteractiveControl = value;
      break;
    case 'InteractiveSeparadorNumerico':
      styleConfig.InteractiveSeparadorNumerico = value;
      break;
    case 'InteractiveSeparadorNumericoCasas':
      styleConfig.InteractiveSeparadorNumericoCasas = +value;
      break;
    case 'InteractiveCurrencyControl':
      styleConfig.InteractiveCurrencyControl = value;
      break;
    case 'InteractiveGroupingControl':
      styleConfig.InteractiveGroupingControl = value;
      break;
    case 'InteractivePrefixControl':
      styleConfig.InteractivePrefixControl = value;
      break;
    case 'InteractiveSufixControl':
      styleConfig.InteractiveSufixControl = value;
      break;
    case 'BorderRadiusControl':
      styleConfig.BorderRadiusControl = { value };
      break;
    case 'InnerRadiusControl':
      styleConfig.InnerRadiusControl = { value };
      break;
    case 'BodyRadiusControl':
      styleConfig.BodyRadiusControl = { value };
      break;
    case 'CategorySpacingControl':
      styleConfig.CategorySpacingControl = { value };
      break;
    case 'CornerRadiusControl':
      styleConfig.CornerRadiusControl = { value };
      break;
    case 'BorderWidthControl':
      styleConfig.BorderWidthControl = { value };
      break;
    case 'BorderColorControl':
      styleConfig.BorderColorControl = value;
      break;
    case 'BorderColorControl2':
      if (value === 'custom') styleConfig.BorderColorControl = '#000';
      else {
        styleConfig.BorderColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.BorderColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'BorderOpacityControl':
      styleConfig.BorderColorControl = {
        from: 'color',
        modifiers: [[styleConfig.BorderColorControl.modifiers?.[0]?.[0] || 'darker', value]],
      };
      break;
    case 'DotPointsColorControl2':
      if (value === 'custom') styleConfig.DotPointsColorControl = '#000';
      else {
        styleConfig.DotPointsColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.DotPointsColorControl?.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'DotPointsOpacityControl':
      styleConfig.DotPointsColorControl = {
        from: 'color',
        modifiers: [[styleConfig.DotPointsColorControl?.modifiers?.[0]?.[0] || 'darker', value]],
      };
      break;
    case 'GradientFirstColor':
      styleConfig.GradientPickerControl = { ...styleConfig.GradientPickerControl, color1: value };
      break;
    case 'GradientSecondColor':
      styleConfig.GradientPickerControl = { ...styleConfig.GradientPickerControl, color2: value };
      break;
    case 'LegendPlacementControl':
      styleConfig.LegendPlacementControl = { value };
      break;
    case 'LegendPlacementControl2':
      styleConfig.LegendPlacementControl = { value: legendPositionOptions(value) };
      break;
    case 'LegendPlacementControlRM':
      styleConfig.LegendPlacementControl = {
        value: legendPositionOptions(value, { isMapOrRadar: true }),
      };
      break;
    case 'LegendPlacementControlW':
      styleConfig.LegendPlacementControl = {
        value: legendPositionOptions(value, { isWaffle: true }),
      };
      break;
    case 'LegendTranslateX':
      styleConfig.LegendPlacementControl.value.translateX = parseInt(value, 10);
      break;
    case 'LegendTranslateY':
      styleConfig.LegendPlacementControl.value.translateY = parseInt(value, 10);
      break;
    case 'EnableLabelControl':
      styleConfig.EnableLabelControl.checked = value;
      break;
    case 'LabelRotationControl':
      styleConfig.LabelRotationControl = value;
      break;
    case 'EnableSeparator':
      styleConfig.EnableSeparator.checked = value;
      break;
    case 'RadialLabelControl':
      styleConfig.RadialLabelControl.checked = value;
      break;
    case 'InteractivityControl':
      styleConfig.InteractivityControl.checked = value;
      break;
    case 'LegendControl':
      // PARA ATIVAR/DESATIVAR LEGENDA
      styleConfig.LegendControl.checked = value;
      break;
    case 'LabelsSeparadorNumerico':
      styleConfig.LabelsSeparadorNumerico = value;
      break;
    case 'separadorNumericoCasas':
      styleConfig.separadorNumericoCasas = +value; // 0, 1, 2, 3, 4 casas dps da virgula ou ponto.
      break;
    case 'LabelCurrencyControl':
      styleConfig.LabelCurrencyControl = value;
      break;
    case 'LabelFontFamilyControl':
      styleConfig.LabelFontFamilyControl = { value };
      break;
    case 'LabelFontSizeControl':
      styleConfig.LabelFontSizeControl = { value };
      break;
    case 'LabelGroupingControl':
      styleConfig.LabelGroupingControl = value;
      break;
    case 'LegendsControl':
      // MODIFICA AS PROPRIEDADES DA LEGENDA
      styleConfig.LegendPlacementControl = value;
      // styleConfig.LegendsControl[value.index] = value.value;
      break;
    case 'LegendsFontSizeControl':
      styleConfig.LegendsFontSizeControl = { value };
      break;
    case 'LegendsFontFamilyControl':
      styleConfig.LegendsFontFamilyControl = { value };
      break;
    case 'LegendsSpacingControl':
      styleConfig.LegendsSpacingControl = { value };
      break;
    case 'LabelsFontSizeControl':
      styleConfig.LabelsFontSizeControl = { value };
      break;
    case 'LabelsFontFamilyControl':
      styleConfig.LabelsFontFamilyControl = { value };
      break;
    case 'LabelTranslateY':
      styleConfig.LabelTranslateY = value;
      break;
    case 'LabelTranslateX':
      styleConfig.LabelTranslateX = value;
      break;
    case 'LabelFormatControl':
      styleConfig.LabelFormatControl = value;
      break;
      // Line cases
    case 'MarginTop':
      styleConfig.Margin = { ...styleConfig.Margin, top: +value };
      break;
    case 'MarginRight':
      styleConfig.Margin = { ...styleConfig.Margin, right: +value };
      break;
    case 'MarginBottom':
      styleConfig.Margin = { ...styleConfig.Margin, bottom: +value };
      break;
    case 'MarginLeft':
      styleConfig.Margin = { ...styleConfig.Margin, left: +value };
      break;
    case 'AreaConfig':
      if (typeof value === 'boolean') {
        styleConfig.AreaConfig = { ...styleConfig.AreaConfig, checked: value };
      } else {
        styleConfig.AreaConfig = {
          ...styleConfig.AreaConfig,
          opacity: +value / 100,
        };
      }
      break;
    case 'AreaControl':
      if (typeof value === 'boolean') {
        styleConfig.AreaControl = { ...styleConfig.AreaControl, checked: value };
      } else {
        styleConfig.AreaControl = {
          ...styleConfig.AreaControl,
          // opacity: +value / 100,
          opacity: +value,
        };
      }
      break;
    case 'EnablePoints':
      if (typeof value === 'boolean') {
        styleConfig.EnablePointsControl = {
          ...styleConfig.EnablePointsControl,
          checked: value,
        };
      } else {
        styleConfig.EnablePointsControl = {
          ...styleConfig.EnablePointsControl,
          pointsSize: +value,
        };
      }
      break;
    case 'EnablePointsBorder':
      styleConfig.EnablePointsControl = {
        ...styleConfig.EnablePointsControl,
        border: +value,
      };
      break;
    case 'PointsLabelColor':
      styleConfig.DotsLabel = {
        ...styleConfig.DotsLabel,
        color: value,
      };
      break;
    case 'PointsLabelFontFamily':
      styleConfig.DotsLabel = {
        ...styleConfig.DotsLabel,
        fontFamily: value,
      };
      break;
    case 'PointsLabelFontSize':
      styleConfig.DotsLabel = {
        ...styleConfig.DotsLabel,
        fontSize: +value,
      };
      break;
    case 'ColorTheme':
      styleConfig.ColorTheme = { scheme: value };
      break;
    case 'PointsColorControl':
      styleConfig.PointsColorControl = value;
      break;
    case 'PointsColorControlColorPicker':
      styleConfig.PointsColorControl = value;
      break;
    case 'PointsColorControl2':
      if (value === 'custom') styleConfig.PointsColorControl = '#000';
      else {
        styleConfig.PointsColorControl = {
          from: 'color',
          modifiers: [[value, styleConfig.PointsColorControl.modifiers?.[0]?.[1] || '1']],
        };
      }
      break;
    case 'PointsOpacityControl':
      styleConfig.PointsColorControl = { from: 'color', modifiers: [[styleConfig.PointsColorControl.modifiers[0][0], value]] };
      break;
    case 'EnableAxisTop':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, axisTop: value };
      break;
    case 'EnableAxisBottom':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, axisBot: value };
      break;
    case 'AxisXTickSize':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, tickSize: +value };
      break;
    case 'AxisXTickPadding':
      styleConfig.EnableAxisX = {
        ...styleConfig.EnableAxisX,
        tickPadding: +value,
      };
      break;
    case 'AxisXLegendOffset':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, legendOffset: value };
      break;
    case 'AxisXTickRotation':
      styleConfig.EnableAxisX = {
        ...styleConfig.EnableAxisX,
        tickRotation: +value,
      };
      break;
    case 'AxisXLegend':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, legend: value };
      break;
    case 'EnableAxisLeft':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, axisLeft: value };
      break;
    case 'EnableAxisRight':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, axisRight: value };
      break;
    case 'AxisYTickSize':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, tickSize: +value };
      break;
    case 'AxisYTickPadding':
      styleConfig.EnableAxisY = {
        ...styleConfig.EnableAxisY,
        tickPadding: +value,
      };
      break;
    case 'AxisYTickRotation':
      styleConfig.EnableAxisY = {
        ...styleConfig.EnableAxisY,
        tickRotation: +value,
      };
      break;
    case 'AxisYLegend':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, legend: value };
      break;
    case 'AxisYLegendOffset':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, legendOffset: value };
      break;
    case 'AxisGlobalFontSize':
      styleConfig.AxisGlobal = { ...styleConfig.AxisGlobal, fontSize: +value };
      break;
    case 'AxisGlobalLegendFontSize':
      styleConfig.AxisGlobal = {
        ...styleConfig.AxisGlobal,
        legendFontSize: +value,
      };
      break;
    case 'AxisGlobalFontFamily':
      styleConfig.AxisGlobal = { ...styleConfig.AxisGlobal, fontFamily: value };
      break;
    case 'AxisGlobalFontColor':
      styleConfig.AxisGlobal = { ...styleConfig.AxisGlobal, color: value };
      break;
    case 'GlobalValueFontSize':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, fontSize: value };
      break;
    case 'GlobalValueFontFamily':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, fontFamily: value };
      break;
    case 'GlobalValueFontColor':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, color: value };
      break;
    case 'GlobalValueBackColor':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, background: value };
      break;
    case 'GlobalValueBold':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, bold: value };
      break;
    case 'GlobalValueItalic':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, italic: value };
      break;
    case 'GlobalValueUnderline':
      styleConfig.GlobalValue = { ...styleConfig.GlobalValue, underline: value };
      break;
    case 'MinValueControl2':
      styleConfig.MinValueControl = { ...styleConfig.MinValueControl, checked: value };
      break;
    case 'MaxValueControl2':
      styleConfig.MaxValueControl = { ...styleConfig.MaxValueControl, checked: value };
      break;
    case 'MinValue':
      styleConfig.MinValueControl = { ...styleConfig.MinValueControl, value };
      break;
    case 'MaxValue':
      styleConfig.MaxValueControl = { ...styleConfig.MaxValueControl, value };
      break;
    case 'CurveControl':
      styleConfig.CurveControl = value;
      break;
    case 'DotControl':
      styleConfig.DotControl = value;
      break;
    case 'DotSizeControl':
      styleConfig.DotSizeControl = { value };
      break;
    case 'DotLabelControl':
      styleConfig.DotLabelControl = value;
      break;
    case 'DotLabelYOffsetControl':
      styleConfig.DotLabelYOffsetControl = { value };
      break;
    case 'FillOpacityControl':
      styleConfig.FillOpacityControl = { value };
      break;
    case 'GridLevelControl':
      styleConfig.GridLevelControl = { value };
      break;
    case 'GridShapeControl':
      styleConfig.GridShapeControl = value;
      break;
    case 'GridLabelOffsetControl':
      styleConfig.GridLabelOffsetControl = { value };
      break;
    case 'ProjectionScaleControl':
      styleConfig.ProjectionScaleControl = { value };
      break;
      //
    case 'separadorNumericoY':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, separadorNumerico: value };
      break;
    case 'separadorNumericoCasasY':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, separadorNumericoCasasY: +value };
      break;
    case 'DecimalsY':
      styleConfig.EnableAxisY = { ...styleConfig.EnableAxisY, Decimals: value };
      break;
    //
    case 'separadorNumericoX':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, separadorNumerico: value };
      break;
    case 'separadorNumericoCasasX':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, separadorNumericoCasasX: +value };
      break;
    case 'DecimalsX':
      styleConfig.EnableAxisX = { ...styleConfig.EnableAxisX, Decimals: value };
      break;
    case 'GlobalValueTitle':
      styleConfig.GlobalValueTitle = value;
      break;
    case 'GroupingSelectControl':
      styleConfig.GroupingSelectControl = value;
      break;
    case 'TitleControl':
      styleConfig.TitleControl = value;
      break;
    case 'DataFormatType':
      styleConfig.DataFormat = { ...styleConfig.DataFormat, type: value };
      break;
    case 'DataFormatTarget':
      styleConfig.DataFormat = { ...styleConfig.DataFormat, target: value };
      break;
    case 'ColorByControl':
      styleConfig.ColorByControl = value;
      break;
    case 'Colors':
      styleConfig.Colors[value.target] = value.value;
      break;
    case 'PalletsControl':
      styleConfig.PalletsControl = value;
      break;
    case 'TitleFontSize':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, fontSize: value };
      break;
    case 'TitleFontFamily':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, fontFamily: value };
      break;
    case 'TitleFontColor':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, color: value };
      break;
    case 'TitleBold':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, bold: value };
      break;
    case 'TitleItalic':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, italic: value };
      break;
    case 'TitleUnderline':
      styleConfig.StyleTitleControl = { ...styleConfig.StyleTitleControl, underline: value };
      break;
      // case 'DescriptionControl':
      //   newConfig.DescriptionControl = value;
      //   break;
    case 'AddMarkerLineControl':
      if (!styleConfig.MarkersList) {
        styleConfig.MarkersList = [];
      }
      styleConfig.MarkersList.push({
        showLine: true,
        axis: styleConfig?.LayoutControl === 'horizontal' ? 'x' : 'y',
        value: 0,
        lineStyle: {
          stroke: '#000',
          strokeWidth: 2,
          strokeDasharray: '0, 0',
          strokeStyle: 'normal',
        },
        legend: '',
        legendOrientation: 'horizontal',
        legendPosition: 'top',
        textStyle: {
          fill: '#000',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 12,
          fontFamily: 'arial',
        },
      });
      break;
    case 'RemoveMarkerLineControl':
      styleConfig.MarkersList.splice(value, 1);
      break;
    case 'MarkerAttrControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target][value.attr] = value.val;
      }
      break;
    case 'MarkerTextStyleControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          [value.attr]: value.val,
        };
      }
      break;
    case 'MarkerLineStyleControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          [value.attr]: value.val,
        };
      }
      break;
    case 'MarkerBoldControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          fontWeight: value.val ? 'bold' : 'normal',
        };
      }
      break;
    case 'MarkerItalicControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          fontStyle: value.val ? 'italic' : 'normal',
        };
      }
      break;
    case 'MarkerTextColorControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].textStyle = {
          ...styleConfig.MarkersList[value.target].textStyle,
          fill: value.val,
        };
      }
      break;
    case 'MarkerLineColorControl':
      if (styleConfig.MarkersList[value.target]) {
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          stroke: value.val,
        };
      }
      break;
    case 'MarkerLineFormatControl':
      if (styleConfig.MarkersList[value.target]) {
        const setMarkerLineStyle = (markerStyle, size) => {
          switch (markerStyle) {
            case 'dashed':
              return `${size * 2}, ${size}`;
            case 'dotted':
              return `${size}, ${size}`;
            default:
              return '0, 0';
          }
        };
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          strokeDasharray: setMarkerLineStyle(
            value.val,
            styleConfig.MarkersList[value.target].lineStyle.strokeWidth,
          ),
          strokeStyle: value.val,
        };
      }
      break;
    case 'MarkerLineSizeControl':
      if (styleConfig.MarkersList[value.target]) {
        const setMarkerLineStyle = (markerStyle, size) => {
          switch (markerStyle) {
            case 'dashed':
              return `${size * 2}, ${size}`;
            case 'dotted':
              return `${size}, ${size}`;
            default:
              return '0, 0';
          }
        };
        styleConfig.MarkersList[value.target].lineStyle = {
          ...styleConfig.MarkersList[value.target].lineStyle,
          strokeDasharray: setMarkerLineStyle(
            styleConfig.MarkersList[value.target].lineStyle.strokeStyle,
            value.val,
          ),
          strokeWidth: value.val,
        };
      }
      break;
    default:
      styleConfig[diff] = value;
      break;
  }
  return { ...styleConfig };
}
