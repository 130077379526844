import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import DashboardCard from '../DashboardCard';
import { MainContainer, StyledResponsiveReactGridLayout } from './styled/DashboardGrid.styled';

import urlHash from '../../utils/functions/urlHash';// '../../utils/functions/urlHash';
import { verifyTimestamp } from '../../utils/functions/lab';

const rglCols = {
  xxs: 1,
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
};

const breakpoints = {
  xxs: 0,
  xs: 260,
  sm: 680,
  md: 960,
  lg: 1220,
};

const DashboardGrid = ({
  dashboardList,
  dbLayout,
  editPainel,
  layouts,
  setLayouts,
  isFiltering,
  currentBreak,
  setCurrentBreakPoint,
  user,
  removeDashboard,
  shareDashboard,
  handleEditModal,
  bookMarkDashboard,
  handleCopyDashboard,
  handleUseAsTemplate,
  handleCloneDash,
  dashboardTrashToggle,
  tourContext = { tourOpen: false },
}) => {
  const history = useHistory();
  const [dragging, setDragging] = useState(false);

  const handleLayoutChange = (newLayouts) => {
    // Filtrou (edição de layout estará sempre desativada nesse momento)
    if (!editPainel && isFiltering) {
      const currentCards = dashboardList.map((card) => card.id);
      const aux = dbLayout[currentBreak]?.filter((p) => currentCards.includes(p.i)) || [];
      setLayouts((p) => ({
        ...p,
        ...dbLayout,
        [currentBreak]: aux,
      }));
    } else if (!editPainel && !isFiltering) {
      // esse aqui para n perder as posições originais dos card qnd n filtra.
      setLayouts({ ...dbLayout });
    } else {
      // para qnd está editando o layout.
      setLayouts(newLayouts);
    }
  };

  const onBreakpointChange = (newBreak, newCols) => {
    setCurrentBreakPoint(newBreak);
  };

  const handleOpenDash = (dash) => {
    if (editPainel) return;
    history.push(`/home/${urlHash(`${dash.id}/${dash.owner}`)}`);
    if (tourContext.tourOpen) tourContext.nextStep();
  };

  if (tourContext.tourOpen) tourContext.refreshTour();
  return (
    <MainContainer editPainel={!!editPainel} className="outerGrid">
      <StyledResponsiveReactGridLayout
        isResizable={false}
        isDraggable={!!editPainel}
        margin={[8, 8]}
        compactType="vertical"
        preventCollision={false}
        useCSSTransforms={false}
        measureBeforeMount={false}
        editPainel={!!editPainel}
        rowHeight={180}
        breakpoints={breakpoints}
        cols={rglCols}
        onDragStart={() => setDragging(true)}
        onDragStop={() => setDragging(false)}
        dragging={dragging}
        layouts={layouts}
        // layouts={_stateLayout}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={(_current, newLayouts) => handleLayoutChange(newLayouts)}
      >
        {dashboardList && dashboardList.map((dashboard) => (
          <div key={dashboard.id}>
            <DashboardCard
              dashInfo={dashboard}
              gridEditMode={editPainel}
              isUserOwner={dashboard.owner === user.id}
              openDashboard={handleOpenDash}
              shareFunc={shareDashboard}
              templateFunc={handleUseAsTemplate}
              cloneDashFunc={handleCloneDash}
              editFunc={handleEditModal}
              deleteFunc={removeDashboard}
              copyFunc={handleCopyDashboard}
              trashFunc={dashboardTrashToggle}
              bookMarkFunc={bookMarkDashboard}
              newsDash={verifyTimestamp(dashboard.viewAt || 0) < verifyTimestamp(dashboard.lastUpdateUser)}
              lastUpdate={dashboard.lastUpdateUser}
              tourContext={tourContext}
            />
          </div>
        ))}
      </StyledResponsiveReactGridLayout>
    </MainContainer>
  );
};

DashboardGrid.propTypes = {
  /**
   * Dashboards List.
   */
  dashboardList: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * A object (from State) containing the dashboards positions in the grid.
   * filter change this layout.
   *
   */
  layouts: PropTypes.shape({ }),
  /**
   * Function to change the layouts (from state)
   */
  setLayouts: PropTypes.func,
  /**
   *  A object (from BD) containing the dashboards positions in the grid.
   *  Only save to db changes this.
   */
  dbLayout: PropTypes.shape({ }),
  /**
   * Boolean which controll when user can change the grid layout.
   */
  editPainel: PropTypes.bool,

  /**
   * Boolean which tell to grid if the user is using some filter on dashboards.
   */
  isFiltering: PropTypes.bool,
  /**
   * The current breakpoint the grid is using. (state control, not react-grid-layout control)
   */
  currentBreak: PropTypes.string,
  /**
   * function to set which breakpoint is being used (state control, not react-grid-layout control)
   */
  setCurrentBreakPoint: PropTypes.func,
  /**
   * Object with the current user data.
   */
  user: PropTypes.shape({
    id: PropTypes.string,
  }),

  /**
   * Function to remove dashboard, recive the dashboard item as parameter.
   */
  removeDashboard: PropTypes.func,

  /**
   * Function to share the dashboard, recive the dashboard item as parameter.
   */
  shareDashboard: PropTypes.func,

  /**
   * Function to edit the dashboard infos (group name, headerColor, mainColor and name).
   * will recive the dashboard item as parameter.
   */
  handleEditModal: PropTypes.func,

  /**
   * Function to BookMark the dashboard
   * will receive the dashboard item as parameter
   */
  bookMarkDashboard: PropTypes.func,

  /**
   * Function to copy the shared dashboard and used files.
   * will receive the dashboard item as parameter
   */
  handleCopyDashboard: PropTypes.func,

  /**
   * Function that clones the dashboard
   */
  handleCloneDash: PropTypes.func,

  /**
   * Function to use the selected dashboard as template to create another dashboard.
   * will receive the dashboard item as parameter.
   */
  handleUseAsTemplate: PropTypes.func,

  /**
   * Function to toggle the boolean isDeleted from a dashboard
   * will receive the dashboard item as parameter.
   */
  dashboardTrashToggle: PropTypes.func,
};

DashboardGrid.defaultProps = {
  user: {},
  layouts: {},
  dbLayout: {},
  dashboardList: [],
  editPainel: false,
  currentBreak: 'lg',
  isFiltering: false,
  setCurrentBreakPoint: () => {},
  setLayouts: () => {},
  removeDashboard: () => {},
  shareDashboard: () => {},
  handleEditModal: () => {},
  bookMarkDashboard: () => {},
  handleCopyDashboard: () => {},
  handleCloneDash: () => {},
  handleUseAsTemplate: () => {},
  dashboardTrashToggle: () => {},
};

export default DashboardGrid;
