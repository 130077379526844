/* eslint-disable no-nested-ternary */
import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import useDeviceType from '../../../hooks/useDeviceType';

import Tooltip from '../../../core/Tooltip';
import Popover from '../../../core/Popover';

import { expDateOrder } from '../../../utils/formatCharts/orderDatetime';
import compare from '../../../../utils/functions/sorting';
import ControlFilter from '../../../../components/Popovers/ControlFilter';
import IconButton from '../../../core/IconButton';
import Button from '../../../core/Button';
import List from '../../../core/List';
import ListItem from '../../../core/ListItem';
import TextArea from '../../../core/TextArea';
import CardKpi from '../CardKpi';

import {
  StyledGridKpiCard,
  Comment,
  // FilterHeader,
  MenuCard,
} from './styled/GridKpisCard.styled';

import {
  Edit,
  Clone,
  MoreVert,
  PNG,
  Info,
  Report,
  AddComment,
  CommentExclamation,
  Filter,
  Fullscreen,
  Trash,
  Control,
  Warning,
} from '../../../icons';

const GridKpisCard = ({
  item,
  // isSuper,
  isOwner,
  gridKpiState,
  editCard,
  cloneCard,
  deleteCard,
  editPainel,
  openCardInfo,
  donwloadReport,
  downloadImgCard,
  createKpiComment,
  openModifiersModal,
  modifiersQtd,
  openKpiViewer,
  showToPrint,
  //
  getControlValues,
  handleDynamicKpi,
  // ...props
  cardSizes,
  globalFilters,
  dependencyFile,
  getKpiWithoutDependencyMessage,
}) => {
  const device = useDeviceType();
  const kpiRef = useRef();
  const [showControlFilter, setShowControlFilter] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showCommentContainer, setShowCommentContainer] = useState(false);
  const [isEditComment, setIsEditComment] = useState(false);
  const [comment, setComment] = useState(item.comment || '');

  const [controlSelected, setControlSelected] = useState();
  const [controlOptions, setControlOptions] = useState();
  const [controlFileError, setControlFileError] = useState();
  const [filteredItem, setFilteredItem] = useState(item);
  const [kpiLoading, setKpiLoading] = useState(false);

  useEffect(() => {
    setFilteredItem(item);
    setControlSelected(null);
  }, [item]);

  const meta = (item?.meta) || {};
  const { collection, kpiItemList } = gridKpiState;

  const closeMenu = () => setMenuIsOpen(false);
  const toggleMenu = () => setMenuIsOpen((p) => !p);

  const closeControlFilter = () => setShowControlFilter(false);

  /** @async Load dataframe values from control variable */
  const loadControlValues = async () => {
    if (!editPainel && !controlOptions && meta?.control?.[0]?.column && meta.database) {
      const res = await getControlValues(
        item.database, meta.control[0].column, meta.control[0].map,
      );
      if (res.fileError) {
        setControlFileError(res.fileError);
      } else {
        res.opts.sort((a, b) => (compare(
          expDateOrder(a.label), expDateOrder(b.label),
        )));
        setControlOptions([
          ...res.opts,
        ]);
      }
    }
  };

  const toggleControlFilter = () => setShowControlFilter((p) => {
    if (!p) loadControlValues();
    return !p;
  });

  const getReport = async () => { await donwloadReport(item.id, meta?.database); };

  const handleCardImgDonwload = () => downloadImgCard(kpiRef.current, item.name);

  const handleOpenCardInfo = () => openCardInfo(item);

  const handleOpenFilterValues = () => openModifiersModal(item);

  const handleDelete = () => deleteCard(item);

  const handleEdit = () => editCard(item);

  const handleClone = () => cloneCard(item);

  const closeCommentContainer = (resetComment) => {
    setShowCommentContainer(false);
    setIsEditComment(false);
    if (resetComment) setComment(item.comment);
  };

  const toggleCommentContainer = () => setShowCommentContainer((p) => !p);
  const showCommentEditor = () => {
    if (isOwner && collection === 'kpis') setIsEditComment(true);
  };
  const showAddComment = () => {
    setShowCommentContainer((p) => !p);
    setIsEditComment(true);
  };

  const onSubmitComment = async () => {
    setShowCommentContainer(false);
    setIsEditComment(false);
    await createKpiComment(comment, item.id);
  };

  const checkComment = () => !!item?.comment?.trim();

  const handleOpenKpiViewer = () => {
    openKpiViewer(item.id);
  };

  const kpiQtd = useMemo(() => (
    kpiItemList?.filter((kpi) => !['label', 'Value'].includes(kpi.type)).length || 1
  ), [kpiItemList]);

  const genControllerFilter = (values) => {
    if (values && values?.length !== controlOptions?.length) {
      return {
        ftype: 'values',
        inverse: false,
        column: meta.control[0].column,
        selector: meta.control[0].map,
        values,
      };
    } return null;
  };

  const genGlobalFilter = () => (
    Object.keys(globalFilters).reduce((aux, fId) => {
      if (globalFilters[fId]) {
        aux.push({
          ftype: 'values',
          inverse: false,
          column: globalFilters[fId].column,
          selector: globalFilters[fId].selector,
          values: globalFilters[fId].values,
        });
      } return aux;
    }, [])
  );

  const handleTempFilters = async (controlValues) => {
    setKpiLoading(true);
    const control = genControllerFilter(controlValues);
    const global = genGlobalFilter();
    let tempFilters = [];

    if (global.length > 0) {
      tempFilters = tempFilters.concat(global);
    }
    if (control) tempFilters.push(control);

    if (tempFilters.length > 0) {
      const tempMeta = {
        ...meta,
        filters: [
          ...tempFilters,
          ...meta.filters,
        ],
      };

      const tempData = await handleDynamicKpi(tempMeta, item.database);

      if (tempData) {
        setFilteredItem((it) => ({
          ...it,
          data: tempData,
        }));
      }
    } else {
      setFilteredItem(item);
    }
    setKpiLoading(false);
  };

  /**
   * Filters kpi data, based on selected control values
   * @param {array} selected Selected values
   */
  const handleControl = (selected) => {
    setControlSelected(selected);
    handleTempFilters(selected);
  };

  const handleGlobalFilters = () => {
    handleTempFilters(controlSelected);
  };

  useEffect(() => {
    if (globalFilters) {
      handleGlobalFilters();
    }
  }, [globalFilters]);

  return (
    <StyledGridKpiCard>
      <CardKpi
        ref={kpiRef}
        key={item.id + modifiersQtd}
        item={filteredItem}
        editPainel={editPainel}
        id={item.id}
        dashSize={kpiQtd}
        showToPrint={showToPrint}
        isDataLoading={kpiLoading}
      />
      {!editPainel && (
        <>
          <MenuCard
            className="menuCard"
            stayVisible={menuIsOpen || showCommentContainer || showControlFilter}
            cardHeight={cardSizes.h}
          >
            {/* ========== Control Filter ========== */}
            {collection === 'kpis' && meta?.control?.[0]?.column?.length > 0 && (
              <Popover
                open={showControlFilter}
                closePopover={closeControlFilter}
              >
                <Popover.Action>
                  <Tooltip text="Controle" direction="left">
                    <IconButton
                      color={
                        controlSelected && controlSelected?.length !== controlOptions?.length ? ('secondary')
                          : (device !== 'desktop' ? 'primary' : 'white')
                      }
                      shape="circle"
                      variant="pattern"
                      size={cardSizes.w > 5 ? 'medium' : 'small'}
                      style={{ boxShadow: 'none' }}
                      onClick={toggleControlFilter}
                    >
                      <Control />
                    </IconButton>
                  </Tooltip>
                </Popover.Action>
                <Popover.Content stayOpened offset={[-20, 2]}>
                  <ControlFilter
                    close={closeControlFilter}
                    controlOptions={controlOptions}
                    controlFilterValues={controlSelected}
                    handleFilter={handleControl}
                    controlVar={meta?.control?.[0]?.column || ''}
                    errorMsg={controlFileError}
                  />
                </Popover.Content>
              </Popover>
            )}

            {/* ========== Value Filter ========== */}
            {isOwner && item.type !== 'label' && collection === 'kpis' && device !== 'phone' && cardSizes.h > 6 && (
              <Tooltip text="Filtrar valores" direction="left">
                <IconButton
                  color={modifiersQtd > 0 ? 'secondary' : (device !== 'desktop' ? 'primary' : 'white')}
                  shape="circle"
                  variant="pattern"
                  size="medium"
                  style={{ boxShadow: 'none' }}
                  onClick={handleOpenFilterValues}
                >
                  <Filter />
                </IconButton>
              </Tooltip>
            )}

            {/* ========== Comment ========== */}
            {(checkComment() || isOwner) && device !== 'phone' && item.type !== 'label' && (
              <Popover
                open={showCommentContainer}
                closePopover={closeCommentContainer}
              >
                <Popover.Action>
                  {checkComment() ? (
                    <Tooltip text="Ver comentário" direction="left">
                      <IconButton
                        color="secondary"
                        shape="circle"
                        variant="pattern"
                        size="medium"
                        style={{ boxShadow: 'none' }}
                        onClick={toggleCommentContainer}
                      >
                        <CommentExclamation />
                      </IconButton>
                    </Tooltip>
                  ) : isOwner && collection === 'kpis' && (
                    <Tooltip text="Adicionar comentário" direction="left">
                      <IconButton
                        color={device !== 'desktop' ? 'primary' : 'white'}
                        shape="circle"
                        variant="pattern"
                        size={cardSizes.w > 5 ? 'medium' : 'small'}
                        style={{ boxShadow: 'none' }}
                        onClick={showAddComment}
                      >
                        <AddComment />
                      </IconButton>
                    </Tooltip>
                  )}
                </Popover.Action>
                <Popover.Content
                  stayOpened
                  offset={[-20, 2]}
                  style={{ width: '250px', padding: '1rem 1rem', whiteSpace: 'normal' }}
                >
                  <span style={{ color: '#696969', fontSize: '0.85rem', fontWeight: 'bold' }}>Comentário</span>
                  {!isEditComment ? (
                    <Comment
                      onClick={showCommentEditor}
                      isOwner={isOwner}
                      story={collection === 'kpis'}
                    >
                      {item.comment}
                    </Comment>
                  ) : (
                    <div>
                      <TextArea
                        value={comment}
                        label=""
                        onChange={(e) => setComment(e.target.value)}
                        style={{ height: '100px' }}
                      />
                      <div style={{
                        display: 'flex', justifyContent: 'center', gap: '5px', paddingTop: '5px',
                      }}
                      >
                        <Button
                          color="primary"
                          variant="outlined"
                          size="medium"
                          shape="rounded"
                          onClick={() => closeCommentContainer(true)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="medium"
                          shape="rounded"
                          onClick={onSubmitComment}
                        >
                          Salvar
                        </Button>
                      </div>
                    </div>
                  )}
                </Popover.Content>
              </Popover>
            )}

            {/* ========== Check dependencies ========== */}
            {(!dependencyFile?.allowed && item.type !== 'label' && device !== 'phone') && (
              <Tooltip text="Sem acesso ao arquivo de origem" direction="left">
                <IconButton
                  color="error"
                  shape="circle"
                  variant="pattern"
                  size={cardSizes.w > 5 ? 'medium' : 'small'}
                  className="kpi-options"
                  style={{ boxShadow: 'none' }}
                  onClick={() => getKpiWithoutDependencyMessage(dependencyFile, item)}
                >
                  <Warning aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
                </IconButton>
              </Tooltip>
            )}

            {/* ========== Menu options ========== */}
            <Popover
              open={menuIsOpen}
              closePopover={closeMenu}
            >
              <Popover.Action>
                <Tooltip text="Mais opções" direction="left">
                  <IconButton
                    color={device !== 'desktop' ? 'primary' : 'white'}
                    shape="circle"
                    variant="pattern"
                    size={cardSizes.w > 5 ? 'medium' : 'small'}
                    className="kpi-options"
                    style={{ boxShadow: 'none', transform: 'rotate(90deg)' }}
                    onClick={toggleMenu}
                  >
                    <MoreVert aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
                  </IconButton>
                </Tooltip>
              </Popover.Action>
              <Popover.Content offset={[-20, 2]}>
                <List>
                  {isOwner && item.type !== 'label' && collection === 'kpis' && device !== 'phone' && cardSizes.h <= 6 && (
                    <ListItem noOutline onClick={handleOpenFilterValues}>
                      <Filter />
                      Filtrar valores
                    </ListItem>
                  )}
                  {isOwner && item.type !== 'label' && collection === 'kpis' && (
                    <ListItem noOutline onClick={handleOpenCardInfo}>
                      <Info fill="none" />
                      Informações de KPI
                    </ListItem>
                  )}

                  {item.type !== 'label' && isOwner && collection === 'kpis' && (
                    <ListItem noOutline onClick={getReport}>
                      <Report fill="none" />
                      Relatório
                    </ListItem>
                  )}

                  {item.type !== 'label' && (
                    <ListItem
                      noOutline
                      onClick={handleCardImgDonwload}
                    >
                      <PNG />
                      Salvar PNG
                    </ListItem>
                  )}
                  {isOwner && collection === 'kpis' && device !== 'phone' && (
                    <>
                      <ListItem noOutline onClick={handleDelete}>
                        <Trash />
                        Apagar
                      </ListItem>

                      <ListItem noOutline onClick={handleEdit}>
                        <Edit />
                        Editar
                      </ListItem>

                      <ListItem noOutline onClick={handleClone}>
                        <Clone />
                        Clonar
                      </ListItem>
                    </>
                  )}
                  {item.type !== 'label' && cardSizes.h <= 6 && (
                    <ListItem noOutline onClick={handleOpenKpiViewer}>
                      <Fullscreen />
                      Expandir
                    </ListItem>
                  )}
                </List>
              </Popover.Content>
            </Popover>

            {/* ========== Kpi Viewer ========== */}
            {item.type !== 'label' && cardSizes.h > 6 && (
              <div className="menuItemBottom">
                <Tooltip text="Expandir" direction="left">
                  <IconButton
                    color={device !== 'desktop' ? 'primary' : 'white'}
                    shape="circle"
                    variant="pattern"
                    size="medium"
                    style={{ boxShadow: 'none' }}
                    onClick={handleOpenKpiViewer}
                  >
                    <Fullscreen />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </MenuCard>
        </>
      )}
    </StyledGridKpiCard>
  );
};

GridKpisCard.propTypes = {
  createKpiComment: PropTypes.func,
  downloadImgCard: PropTypes.func,
  donwloadReport: PropTypes.func,
  openCardInfo: PropTypes.func,
  deleteCard: PropTypes.func,
  cloneCard: PropTypes.func,
  editCard: PropTypes.func,
  // isSuper: PropTypes.bool,
  isOwner: PropTypes.bool,
  item: PropTypes.shape({
    database: PropTypes.string,
    id: PropTypes.string,
    comment: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    meta: PropTypes.shape({}),
  }),
  gridKpiState: PropTypes.shape({
    collection: PropTypes.string,
    kpiItemList: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  editPainel: PropTypes.bool,
  openModifiersModal: PropTypes.func,
  modifiersQtd: PropTypes.number,
  openKpiViewer: PropTypes.func,
  showToPrint: PropTypes.bool,
  getControlValues: PropTypes.func,
  handleDynamicKpi: PropTypes.func,
  cardSizes: PropTypes.shape({
    h: PropTypes.number,
    w: PropTypes.number,
  }),
  globalFilters: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.shape({})),
  }).isRequired,
  dependencyFile: PropTypes.shape({
    allowed: PropTypes.bool,
  }),
  getKpiWithoutDependencyMessage: PropTypes.func,
};

GridKpisCard.defaultProps = {
  item: {},
  // isSuper: false,
  isOwner: false,
  gridKpiState: {},
  editCard: () => {},
  cloneCard: () => {},
  deleteCard: () => {},
  openCardInfo: () => {},
  donwloadReport: () => {},
  downloadImgCard: () => {},
  createKpiComment: () => {},
  editPainel: false,
  openModifiersModal: () => {},
  modifiersQtd: 0,
  openKpiViewer: () => {},
  showToPrint: false,
  getControlValues: () => {},
  handleDynamicKpi: () => {},
  cardSizes: { h: 7, w: 4 },
  dependencyFile: {},
  getKpiWithoutDependencyMessage: () => {},
};

export default GridKpisCard;
