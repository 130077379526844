import React, {
  useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../../context/AuthProvider';

import {
  LabelCreateToolbarStyled,
  LeftLabelToolbar,
  OuterLabelCreate,
  GridContainer,
  ScrollContainer,
} from './styled/LabelCreate.styled';

import handleStyleConfig from './handleLabelConfig';
import Controllers from '../../juristec-ui/kpis/insight/controllers';
import Insight from '../../juristec-ui/kpis/insight';

import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Loader from '../../juristec-ui/core/Loader';
import Tooltip from '../../juristec-ui/core/Tooltip';
import UnsplashPicker from '../../juristec-ui/core/UnsplashPicker';

import {
  ArrowLeft,
} from '../../juristec-ui/icons';

import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import useGridKPI from '../../hooks/useGridKpi';

import { labelCreateInitialState } from '../../options';
import { verifyFileImgType, verifyFileSize } from '../../juristec-ui/utils/validators/fileValidators';

export default function LabelCreate({
  goBack,
  dashboardKey,
  kpiKey,
}) {
  const { currentUser, user } = useContext(AuthContext);
  const { setModalConfig, toggleModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);

  const [{
    dashboardDoc,
    kpisLayout,
    isLoading,
  }, gridKpiAPI] = useGridKPI(currentUser.uid, dashboardKey, currentUser, user);

  const [styleConfig, setStyleConfig] = useState(labelCreateInitialState);
  const [previewImg, setPreviewImg] = useState('');

  const handleImgPick = (url) => {
    setStyleConfig((prev) => ({ ...prev, image: url }));
    // setImgFile(url);
    setPreviewImg(url);
  };

  // Function to check if the image satisfies the specified conditions
  const checkFileIsImg = (file) => {
    const errorMsg = verifyFileImgType(file) || verifyFileSize(file, 1000000);
    if (errorMsg.length > 0) {
      setAlertConfig({ type: 'error', text: errorMsg });
      return false;
    }
    return true;
  };

  const handleUpload = (e) => {
    URL.revokeObjectURL(previewImg);
    const file = e.target.files[0];
    if (file && checkFileIsImg(file)) {
      setStyleConfig((prev) => ({ ...prev, image: file }));
      // setImgFile(file);
      setPreviewImg(URL.createObjectURL(file));
    }
  };

  const removeImg = () => {
    setStyleConfig((prev) => ({ ...prev, image: '' }));
    // setImgFile(null);
    URL.revokeObjectURL(previewImg);
    setPreviewImg('');
  };

  useEffect(() => {
    if (kpiKey !== 'new') {
      gridKpiAPI.getKpiInfo(kpiKey).then((kpi) => {
        if (kpi) {
          const captitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
          setStyleConfig({
            ...labelCreateInitialState,
            ...kpi.style,
            fontFamily: { value: kpi.style.fontFamily, label: captitalize(kpi.style.fontFamily) },
            name: kpi.name,
            link: kpi.link,
            kpiKey,
          });
        } else {
          goBack();
        }
      })
        .catch((err) => {
          console.log('errr', err);
        });
    }
    gridKpiAPI.loadLayoutAndDash();
  }, [gridKpiAPI, goBack, kpiKey]);

  async function labelToFirestore(labelKey) {
    const LabelObject = {
      name: styleConfig.name,
      link: styleConfig.link || '',
      style: {
        bgColor: styleConfig.bgColor,
        fontColor: styleConfig.fontColor,
        fontSize: styleConfig.fontSize,
        bold: styleConfig.bold,
        underline: styleConfig.underline,
        fontFamily: styleConfig.fontFamily.value,
        italic: styleConfig.italic,
        colorOpacityText: styleConfig.colorOpacityText,
        opacityText: styleConfig.opacityText,
        opacityImage: styleConfig.opacityImage,
        imageFit: styleConfig.imageFit,
        textPosition: styleConfig.textPosition,
      },
      status: 'active',
      type: 'label',
    };
    const result = await gridKpiAPI.saveInsightCard(kpisLayout, labelKey, LabelObject, styleConfig.image);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
      });
      return;
    }

    URL.revokeObjectURL(styleConfig.image);
    goBack();
  }

  const openUnsplashPicker = () => {
    setModalConfig({
      title: 'Selecionar Fotos',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={handleImgPick}
          defaultSearch="Insights"
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  const handleStyleChanges = (value, diff) => {
    setStyleConfig(handleStyleConfig(styleConfig, diff, value));
  };

  return (
    <>
      {isLoading && <Loader /> }
      <LabelCreateToolbarStyled>
        <LeftLabelToolbar>
          <Tooltip text="Voltar" direction="right">
            <IconButton
              variant="contained"
              color="secondary"
              size="medium"
              shape="rounded"
              onClick={() => goBack()}
            >
              <ArrowLeft />
            </IconButton>
          </Tooltip>
          <div>
            <span>
              {dashboardDoc?.name && `Inserindo Insight em: ${dashboardDoc.name}`}
              {!dashboardDoc && kpiKey !== 'new' ? 'Edição de Insight' : ''}
            </span>
          </div>
        </LeftLabelToolbar>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          shape="rounded"
          disabled={!styleConfig?.name?.trim() && !styleConfig?.image}
          onClick={() => labelToFirestore(kpiKey)}
        >
          Salvar
        </Button>
      </LabelCreateToolbarStyled>
      <OuterLabelCreate>
        <GridContainer>
          <div className="sideBar">
            <ScrollContainer>
              <div style={{ borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <Controllers
                  handle={handleStyleChanges}
                  config={styleConfig}
                />
              </div>
            </ScrollContainer>
          </div>
          <div className="main" style={{ borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', overflow: 'hidden' }}>
            <Insight
              item={{ style: styleConfig, name: styleConfig.name }}
              isEdit
              handleImageUpload={handleUpload}
              openUnsplashPicker={openUnsplashPicker}
              removeImg={removeImg}
              previewImg={previewImg}
            />
          </div>
        </GridContainer>
      </OuterLabelCreate>
    </>
  );
}

LabelCreate.propTypes = {
  dashboardKey: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  kpiKey: PropTypes.string,
};

LabelCreate.defaultProps = {
  kpiKey: 'new',
};
