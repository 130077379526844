export const error = {
  generic: 'Algo deu errado!',
  instanceProvider: {
    getInstances: 'Erro ao tentar carregar as instâncias',
    getInstanceUsers: 'Erro ao tentar carregar informações de usuários da instância',
    createInstance: 'Erro ao tentar criar nova instância',
    editInstance: 'Erro ao tentar editar instância',
    blockInstance: 'Erro ao tentar bloquear/desbloquear instância',
    deleteInstance: 'Erro ao tentar apagar a instância',
    createOnboardingReport: 'Erro ao tentar criar o relatório de onboarding',
    // instance users
    blockUser: 'Erro ao tentar bloquear/desbloquear usuário',
    deleteUser: 'Erro ao tentar apagar o usuário',
    addUser: 'Erro ao tentar ciar usuário',
    editUser: 'Erro ao tentar editar o usuário',
    transferUser: 'Erro ao tentar transferir os dados do usuário',
    getReports: 'Erro ao tentar listar os relatórios',
    getReportStatus: 'Erro ao tentar carregar as informações do relatório',
    deleteReport: 'Erro ao tentar remover um relatório',
    manualReportUpdate: 'Erro ao tentar atualizar um relatório',
    requestApiCredentials: 'Erro ao tentar requisitar as credenciais',
    addAntecipeiReport: 'Erro ao tentar criar o relatório Antecipei',
    addBennerReport: 'Erro ao tentar criar o relatório Benner',
  },
  filesProvider: {
    getFilesList: 'Erro ao tentar carregar os arquivos',
    getFile: 'Erro ao tentar carregar o arquivo',
    getCurrentCompanyStorage: 'Erro ao tentar carregar informações de armazenamento',
    downloadFile: 'Erro ao tentar fazer download do arquivo',
    convertFileToMail: 'Erro ao tentar reconfigurar o arquivo para e-mail',
    convertFileApiGed: 'Erro ao tentar reconfigurar o arquivo para utilizar a API GED',
    getSample: 'Erro ao tentar visualizar o arquivo', // ?
    deleteFile: 'Erro ao tentar apagar o arquivo',
    deleteGlobalFile: 'Erro ao tentar apagar arquivo',
    uploadFile: 'Erro ao tentar fazer upload do arquivo',
    uploadFileByLink: 'Erro ao tentar fazer upload do arquivo pelo link',
    uploadBySql: 'Erro ao tentar conectar com a base SQL',
    uploadBennerReport: 'Erro ao tentar gerar o relatório Benner',
    reopen: 'Erro ao tentar reabrir arquivo',
    getUnique: 'Erro ao tentar pegar os valores únicos do arquivo',
    baseUpdate: 'Erro ao tentar atualizar o arquivo',
    optimize: 'Erro ao tentar validar o arquivo',
    filePermissions: 'Erro ao tentar alterar as permissões do arquivo',
    transferUser: 'Erro ao tentar transferir o arquivo',
    selfRemoval: 'Erro ao tentar se auto remover do compartilhamnto do arquivo',
    getColumns: 'Erro ao tentar pegar os nomes das colunas do arquivo',
    editColumns: 'Erro ao tentar alterar as colunas do arquivo',
    renameColumns: 'Erro ao tentar renomear as colunas do arquivo',
  },
  notificationsProvider: {
    getNotifications: 'Erro ao tentar carregar as notificações',
    setViewNotification: 'Erro ao tentar atualizar notificação',
    setViewedAtAllVisible: 'Erro ao tentar atualizar notificações',
    getNotificationsCount: 'Erro ao tentar carregar quantidade contador de notificações',
  },
  policyProvider: {
    getActualPolicyId: 'Erro ao tentar receber o id da política atual',
  },
  fetchGeneric: 'Erro na comunicação com o servidor',
  useUsers: {
    getUsers: 'Erro ao tentar carregar as informações dos usuários',
    editUser: 'Erro ao tentar editar as informações do usuário',
    toggleBlock: 'Erro ao tentar bloquear/desbloquear usuário',
    deleteUser: 'Erro ao tentar apagar o usuário',
    addUser: 'Erro ao tentar criar novo usuário',
    resetPW: 'Erro ao tentar redefinir senha do usuário',
  },
  useGridKpi: {
    getAllInfos: 'Erro ao tentar carregar as informações do dashboard',
    saveLayout: 'Erro ao tentar salvar o layout',
    getReport: 'Erro ao tentar baixar o relatório',
    cloneCard: 'Erro ao tentar clonar card',
    removeCard: 'Erro ao tentar remover card',
    newSnap: 'Não foi possível criar um novo story',
    editSnap: 'Não foi possível o story',
    removeSnap: 'Não foi possível apagar o story',
    createKpiComment: 'Erro ao salvar o seu comentário',
    createKpiValueFilters: 'Erro ao salvar os filtros',
    createCard: 'Erro ao tentar criar kpi',
    createInsight: 'Erro ao tentar criar um insigth',
    // comments
    loadComments: 'Erro ao tentar carregar os comentários',
    loadMoreComments: 'Erro ao tentar carregar mais comentários',
    addComment: 'Erro ao tentar criar novo comentário',
    editComment: 'Erro ao tentar editar comentário',
    archiveComment: 'Erro ao tentar arquivar comentário',
    //
    setCardStyles: 'Erro ao tentar atualizar os estilos dos cards',
    setGlobalFilterSelectors: 'Erro ao tentar atualizar o filtro global',
    // template
    createTemplate: 'Erro ao tentar criar novo template',
    editTemplate: 'Erro ao tentar editar o template',
    deleteTemplate: 'Erro ao tentar editar o template',
  },
  useGridDash: {
    generic: 'Algo deu errado!',
    fetchGeneric: 'Erro na comunicação com o servidor',
    dashNotFound: 'Dashboard não encontrado',
    copyDash: 'Erro ao copiar o dashboard',
    kpiListNoFound: 'Lista de kpis não encontrado',
    layoutNotFound: 'Layout dos dashboards não foi encontrado',
    bookMarkDashboard: 'Erro ao tentar favoritar dashboard',
    genRequiredFromDashboard: 'Erro ao tentar acessar informações do dashboard',
  },
  useKpiData: {
    genKpiData: 'Erro ao gerar os dados do kpi',
  },
  instance: {
    edit: 'Aconteceu algum erro enquanto tentávamos editar a instância',
    create: 'Aconteceu algum erro enquanto tentávamos criar a instância',
  },

  optmize: {
    dateFormat: 'Data com formato diferente do selecionado',
    missingDateFormat: 'Não foi selecionado o formato para o tipo Data',
    stringToFloat: 'Campo com formato de texto não pode ser convertido para número real',
    stringToInt: 'Campo com formato de texto não pode ser convertido para número inteiro',
  },
  templateProvider: {
    getTemplate: 'Erro ao tentar carregar os templates',
    createTemplate: 'Erro ao tentar criar template',
  },
};

export const comfirm = {
  generic: 'Algo deu errado!',
};
