/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthProvider';

import IconButton from '../../IconButton';
import Button from '../../Button';
import { StyledNoFile, StyledOuterNoFile } from './styled/EmptyList.styled';
import { NoFileDashboard, Add } from '../../../icons';

export default function DashboardsListEmpty({ toggle, typeMsg }) {
  const { user } = useContext(AuthContext);
  return (
    <StyledOuterNoFile>
      <StyledNoFile>
        <div className="content">
          <p className="firstphrase">
            {typeMsg !== 'empty' ? (
              typeMsg === 'emptyTrash' ? (
                <b>Sua lixeira está vazia!</b>
              ) : (
                <b>Não achamos o que você procura!</b>
              )
            ) : (
              <span>
                <b>Nenhum dashboard criado!</b>
                {' '}
                <br />
                {' '}
                {user?.role !== 'guest' ? (
                  <>
                    Para criar um dashboard clique no botão
                    {' '}
                    <IconButton
                      onClick={toggle}
                      color="secondary"
                    >
                      <Add />

                    </IconButton>
                    {' '}
                    no canto superior direito.
                  </>
                ) : (
                  <>
                    Você não possui permissão para criar um dashboard, por enquanto!
                  </>
                )}
              </span>
            )}
          </p>
          <NoFileDashboard />
          {typeMsg === 'empty' ? (
            <>
              <p>
                {user?.role !== 'guest' ? (
                  <>
                    Caso você ainda não possua nenhuma base instalada, faça upload de um arquivo
                    {' '}
                    <b>.csv</b>
                    {' '}
                    ou
                    {' '}
                    <b>.xlsx</b>
                    {' '}
                    na página
                    {' '}
                    <NavLink
                      to="/arquivos"
                      className="navlink"
                    >
                      Arquivos
                    </NavLink>
                    {' '}
                    para ter acesso a todos os recursos do legalmetrics.
                  </>
                ) : null}
              </p>
            </>
          ) : (
            typeMsg === 'emptyTrash' ? (
              <>
                <div className="gap-fix">
                  Para voltar clique no botão
                  {' '}
                  <Button
                    onClick={toggle}
                    color="secondary"
                    variant="contained"
                    size="small"
                  >
                    Retornar
                  </Button>
                  {' '}
                  no canto superior direito.
                </div>
              </>
            ) : (
              <>
                <p>
                  Você não possui nenhum dashboard com qualquer um desses parâmetros:
                  {' '}
                  <b>Nome, Grupo ou Usuário</b>
                </p>
              </>
            )
          )}

        </div>
      </StyledNoFile>
    </StyledOuterNoFile>
  );
}

DashboardsListEmpty.propTypes = {
  toggle: PropTypes.func,
  typeMsg: PropTypes.string,
};

DashboardsListEmpty.defaultProps = {
  typeMsg: 'empty',
  toggle: () => {},
};
