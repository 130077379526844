import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { AuthContext } from '../AuthProvider';

import {
  getBatch, getRefNotification, getRefNotificationCount, getRefNotifications,
} from '../../utils/firestore';

// errors labels
import firestoreErrors from '../../utils/firestoreErrors';
import { error as errorLabels } from '../../label';

const initialState = {
  isLoading: false,
  notifications: [],
  notificationsCount: 0,
};

const reduce = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        isLoading: false,
        lastElement: action.lastElement,
        notifications: action.notifications,
        notificationsCount: action.notificationsCount,
      };
    }

    case 'setNotifications': {
      return {
        ...state,
        isLoading: false,
        lastElement: action.lastElement,
        notifications: [...state.notifications, ...action.notifications],
      };
    }

    case 'setViewedAtAllVisible': {
      return {
        ...state,
        notifications: state.notifications.map((n) => {
          const tmp = n;
          tmp.viewed[action.userId] = true;
          return tmp;
        }),
        notificationsCount: 0,
      };
    }

    case 'setNotificationsCunt': {
      return {
        ...state,
        notificationsCount: action.count,
        isLoading: false,
      };
    }

    case 'setLoading': {
      return {
        ...state,
        isLoading: action.loading,
      };
    }

    default:
      return state;
  }
};

export const NotificationsContext = createContext(null);
const maxPerFetch = 5;

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const setLoading = useCallback((l) => dispatch({ type: 'setLoading', loading: l }), [dispatch]);

  const { user } = useContext(AuthContext);

  const getNotificationsCount = useCallback(async () => {
    try {
      const countRef = getRefNotificationCount(user.company, user.id);
      const tmp = await countRef.get();
      return {
        error: false,
        count: tmp.data().new || 0,
        msg: '',
      };
    } catch (er) {
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.getNotificationsCount,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const getNotifications = useCallback(async (offSet) => {
    try {
      let ref = getRefNotifications(user.company)
        .orderBy('timestamp', 'desc')
        .where('sharedWith', 'array-contains', user.id)
        .limit(maxPerFetch);

      if (offSet) {
        ref = ref.startAfter(offSet);
      }

      ref = await ref.get();
      const notifications = ref.docs.map((d) => ({ id: d.id, ...d.data() }));

      const lastElement = ref.docs.length >= maxPerFetch ? ref.docs[ref.docs.length - 1] : null;

      return {
        error: false,
        msg: '',
        notifications,
        lastElement,
      };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.getNotifications,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const setViewNotification = useCallback(async (id) => {
    try {
      const ref = getRefNotification(user.company, id);
      await ref.set({
        viewed: {
          [user.id]: true,
        },
      }, { merge: true });

      return { error: false, msg: '' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.setViewNotification,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const setViewedAtAllVisible = useCallback(async (notifications = []) => {
    try {
      const notViewed = notifications.map((n) => {
        if (!n.viewed[user.id]) return n.id;
        return null;
      }).filter((f) => f);

      if (!notViewed.length) return { error: false, msg: 'all viewed' };

      const batch = getBatch();

      notViewed.forEach((id) => {
        const ref = getRefNotification(user.company, id);
        batch.set(ref, {
          viewed: {
            [user.id]: true,
          },
        }, { merge: true });
      });

      const countRef = getRefNotificationCount(user.company, user.id);
      batch.set(countRef, { new: 0 }, { merge: true });

      await batch.commit();
      return { error: false, msg: 'ok' };
    } catch (er) {
      console.log(er);
      return {
        error: true,
        msg: firestoreErrors(er.code) || errorLabels.notificationsProvider.setViewedAtAllVisible,
        raw: `Erro do sistema: ${er.toString()}`,
      };
    }
  }, [user]);

  const middleware = useCallback(async (action) => {
    switch (action.type) {
      case 'init': {
        setLoading(true);
        const [notificationsRes, notificationsCountRes] = await Promise.all([
          getNotifications(), getNotificationsCount()]);

        let notifications = [];
        let notificationsCount = 0;
        if (!notificationsRes.error) {
          notifications = notificationsRes.notifications;
        }
        if (!notificationsCountRes.error) {
          notificationsCount = notificationsCountRes.count;
        }

        dispatch({
          type: 'init',
          notifications,
          notificationsCount,
          lastElement: notificationsRes.lastElement,
        });

        return {
          error: true,
          msg: notificationsRes.msg,
          notificationsCount,
          notifications,
        };
      }

      case 'getNotifications': {
        setLoading(true);
        const res = await getNotifications(action.offSet);
        if (res.error) {
          setLoading(false);
        } else {
          dispatch({ type: 'setNotifications', notifications: res.notifications, lastElement: res.lastElement });
        }
        return res;
      }

      case 'setViewNotification': {
        const res = await setViewNotification(action.notificationId);
        return res;
      }

      case 'setViewedAtAllVisible': {
        const res = await setViewedAtAllVisible(action.notifications);
        if (!res.error) {
          dispatch({ type: 'setViewedAtAllVisible', userId: user.id });
        }
        return res;
      }

      case 'getNotificationsCount': {
        setLoading(true);
        const res = await getNotificationsCount();
        if (!res.error) {
          dispatch({ type: 'setNotificationsCunt', count: res.count });
        }
        return res;
      }

      default: {
        dispatch(action);
        return {
          error: false, msg: 'DEFAULT', raw: {}, default: true,
        };
      }
    }
  }, [
    getNotifications,
    setViewNotification,
    getNotificationsCount,
    setViewedAtAllVisible,
    user,
    setLoading,
  ]);

  const notificationsAPI = useMemo(() => ({
    init: () => middleware({ type: 'init' }),
    getNotifications: (offSet) => middleware({ type: 'getNotifications', offSet }),
    setViewNotification: (notificationId) => middleware({ type: 'setViewNotification', notificationId }),
    setViewedAtAllVisible: (notifications) => middleware({ type: 'setViewedAtAllVisible', notifications }),
  }), [middleware]);

  return (
    <NotificationsContext.Provider value={{ state, notificationsAPI }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
