export default function handleLoginErrors(error) {
  switch (error.code) {
    case 'auth/account-exists-with-different-credential':
    case 'auth/invalid-email':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return {
        text: 'Não foi possível realizar o seu acesso!',
        message: 'Verifique se as credenciais fornecidas estão corretas e tente novamente!',
      };

    case 'auth/invalid-credential':
      return {
        text: 'Problema de conexão com o servidor',
        message: 'Tente novamente mais tarde!',
      };

    case 'auth/user-disabled':
      return {
        text: 'Conta Bloqueada',
        message: 'Sua conta foi inativada por um administrador do sistema. Por favor, verifique com o responsável pela sua conta',
      };

    case 'auth/popup-closed-by-user':
      return {
        text: 'Algo deu errado',
        message: 'Operação cancelada pelo usuário.',
      };

    case 'auth/user-cancelled':
      return {
        text: 'Sem permissão',
        message: 'Usuário não deu permissão para o aplicativo Metrics',
      };

    case 'auth/network-request-failed':
      return {
        text: 'Conexão falhou',
        message: 'Problemas de conexão com o provedor de autenticidade.',
      };

    case 'auth/popup-blocked':
      return {
        text: 'Popup Bloqueado pelo Browser',
        message: '',
      };

    case 'auth/operation-not-supported-in-this-environment':
      return {
        text: 'Operação não suportada',
        message: '',
      };

    case 'auth/unauthorized-domain':
      return {
        text: 'Domínio não autorizado',
        message: '',
      };

    case 'auth/operation-not-allowed':
      return {
        text: 'Sistema de Login bloqueado',
        message: 'Entre em contato com o administrador do sistema',
      };

    case 'auth/too-many-requests':
      return {
        text: 'Muitas tentativas erradas',
        message: 'Houve muitas tentativas erradas de login. Seu acesso será temporariamente bloqueado! Caso tenha esquecido a sua senha, clique em "Trocar a senha" ou tente novamente mais tarde!',
      };

    case 'auth/invalid-verification-code':
      return {
        text: 'Código errado!',
        message: 'Informe corretamente o código de verificação enviado por SMS',
      };
    case 'auth/second-factor-already-in-use':
      return {
        text: 'Número em uso',
        message: 'Informe um número de telefone não cadastrado',
      };

    default:
      return {
        text: 'Algo deu errado',
        message: `Problema desconhecido: ${error.message}`,
      };
  }
}
