import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/Select';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import Checkbox from '../../../juristec-ui/core/Checkbox';

import { MainContainer, InfoText } from './styled/TransferFileOwner.styled';

/** Transfers all allowed data from one user to another */
const TransferFileOwner = ({
  file, usersOptions, close, submit,
}) => {
  const [selected, setSelected] = useState({ value: '', label: '' });
  const [selfRemove, setSelfRemove] = useState(false);

  const handleSubmit = () => {
    submit(selected, selfRemove);
  };

  return (
    <MainContainer>
      <InfoText>
        Selecione o usuário para o qual você deseja transferir o arquivo
        {' '}
        <b>
          {file.filename}
        </b>
      </InfoText>
      <Select
        selectLabel="Novo proprietário"
        value={selected}
        onChange={setSelected}
        options={usersOptions}
        fullWidth
        atModal
      />
      <Checkbox
        text="Não manter o meu usuário compartilhado neste arquivo"
        color="primary"
        variant="outlined"
        checked={selfRemove}
        handleCheckboxChange={setSelfRemove}
        style={{ marginTop: '10px' }}
      />
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={!selected.value}
        >
          Transferir
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

TransferFileOwner.propTypes = {
  file: PropTypes.shape({
    filename: PropTypes.string,
  }),
  usersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  close: PropTypes.func,
  submit: PropTypes.func,
};

TransferFileOwner.defaultProps = {
  file: {},
  usersOptions: [],
  close: () => {},
  submit: () => {},
};

export default TransferFileOwner;
