import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const KpiContainer = styled.div`
  max-width: calc(100% - 116px);
  max-height: calc(100% - 116px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
  ${({ allowDrag }) => allowDrag && css`
    cursor: grab;
  `};
`;

export const KpiWrapper = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const KpiCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  ${({
    size, cardStyles, kpiStyles, type,
  }) => css`
    height: ${size.rHeight}px;
    width: ${size.rWidth}px;
    background-color: ${cardStyles?.backgroundColor || '#ffffff'};  
    border: ${cardStyles?.borderStyle ? `${cardStyles.borderStyle.size}px ${cardStyles.borderStyle.color} ${cardStyles.borderStyle.line}` : '1px #9a9a9a solid'};
    border-radius: ${cardStyles?.borderStyle?.rounded || '4'}px;
    overflow: ${type === 'label' || type === 'Table' ? 'hidden' : 'initial'};

    ${KpiWrapper} {
      height: ${`calc(${size.rHeight}px - ${kpiStyles?.ShowTitleControl?.checked ? kpiStyles?.StyleTitleControl?.fontSize || 0 : 0}pt)`};
      ${type === 'Table' ? css`
        & > div:first-child {
          overflow-y: auto;
          height: 100%;
        }
      ` : ''}
    }
  `}
`;

export const KpiTitle = styled.div`
  border: 0px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${(props) => props.style && css`
    span {
      font-size: ${props.style.fontSize}px;
      color: ${props.style.color};
      font-family: '${props.style.fontFamily}', Arial;
      ${props.style.bold && 'font-weight: bold'};
      ${props.style.underline && 'text-decoration: underline'};
      ${props.style.italic && 'font-style: italic'};
    }
  `}
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  z-index: 1050;
  & .fix-icon svg{
    height: auto;
    width: auto;
  }
  &.top-right {
    top: 10px;
    right: 10px;
  }
  &.middle-left {
    top: 0;
    bottom: 0;
    left: 5px;
    margin: 20% 0;
    & svg {
      transform: rotate(180deg);
    }
  }
  &.middle-right {
    top: 0;
    bottom: 0;
    right: 5px;
    margin: 20% 0;
  }
  &.bottom-center {
    bottom: 10px;
    right: 0;
    left: 0;
    gap: 5px;
  }
  &.bottom-left {
    bottom: 10px;
    left: 10px;
  }
`;

export const BtnText = styled.span`
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  padding: 7px 0;
`;

export const CommentArea = styled.p`
  font-size: 1rem;
  text-align: justify;
  white-space: break-spaces;
  margin: 2px 0;
  padding: 10px;
`;
