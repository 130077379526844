import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';

const NewInstance = ({
  closeModal,
  submitNewInstance,
  isEdit,
  toEditInstance,
  submitEditInstance,
}) => {
  // Configs da instancia
  const [companyName, setCompanyName] = useState({ value: '', error: true, errorMsg: '' });
  const [companyUrl, setCompanyUrl] = useState({ value: '', error: false, errorMsg: '' });
  const [selectedPlan, setSelectedPlan] = useState({ value: '', label: '' });
  const [publicLink, setPublicLink] = useState(true);
  const [multifactor, setMultifactor] = useState(false);
  const [hasPush, setHasPush] = useState(false);
  const [externalIdentifier, setExternalIdentifier] = useState({ value: '', error: false, errorMsg: '' });
  const [createL1Report, setCreateL1Report] = useState(false);
  const [operationalStatus, setOperationalStatus] = useState({ value: '', label: '' });

  // var q são guardadas mas n tem input/select para modificá-las.
  const support = useRef(null);
  const templates = useRef([]);
  const image = useRef({});
  const [dashboardLimit, setDashboardLimit] = useState(100000);

  // Configs de plano
  const [volumeData, setVolumeData] = useState(0);
  const [scientistLimit, setScientistLimit] = useState(0);
  const [adminLimit, setAdminLimit] = useState(0);
  const [guestLimit, setGuestLimit] = useState(0);
  const [snapsLimit, setSnapsLimit] = useState(0);

  // Configs do push
  const [freeMonitoring, setFreeMonitoringLimit] = useState(0);
  const [monitoringPrice, setMonitoringPrice] = useState(0);
  const [newProcessPrice, setNewProcessPrice] = useState(0);
  const [oldProcessPrice, setOldProcessPrice] = useState(0);

  // Emails
  const [scientistsEmails, setScientistsEmails] = useState([]);
  const [adminEmails, setAdminEmails] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);

  // Configs da instancia a ser editada
  useEffect(() => {
    if (isEdit) {
      setCompanyName((old) => ({ ...old, value: toEditInstance.name, error: false }));
      setCompanyUrl((old) => ({ ...old, value: toEditInstance.companyurl }));
      setSelectedPlan({ value: toEditInstance.plan, label: toEditInstance.plan });
      setExternalIdentifier((old) => ({ ...old, value: toEditInstance.external_identifier }));
      setOperationalStatus({
        value: toEditInstance.operationalStatus,
        label: toEditInstance.operationalStatus,
      });
      setDashboardLimit(toEditInstance.dashboards);
      setVolumeData(toEditInstance.volumeData);
      setScientistLimit(toEditInstance.scientists);
      setAdminLimit(toEditInstance.superS);
      setGuestLimit(toEditInstance.guests);
      setSnapsLimit(toEditInstance.snapshots);
      setPublicLink(toEditInstance.publicLink);
      setCreateL1Report(toEditInstance.l1reportActive);
      setMultifactor(toEditInstance.multifactor);
      setHasPush(toEditInstance.push);
      setFreeMonitoringLimit(toEditInstance.monitoring || 0);
      setMonitoringPrice(toEditInstance.newMonitoring || 0);
      setNewProcessPrice(toEditInstance.newProcess || 0);
      setOldProcessPrice(toEditInstance.oldProcess || 0);

      image.current = toEditInstance.photoUrl;
      templates.current = toEditInstance.templates;
      support.current = toEditInstance.support;
    }
  }, [isEdit, toEditInstance]);

  /**
   * Funcao que checa se o primeiro passo pode avancar ou nao
   * @returns booleano que, se verdadeiro, nao possui erros e pode avancar
   */
  const checkFirstForm = () => (
    !companyName.error && selectedPlan?.value.length > 0 && adminLimit > 0
  );

  /**
   * Primeiro passo, configs da instancia
   */
  const firstStep = {
    label: 'Instância',
    validation: checkFirstForm,
    content: (
      <FirstStep
        companyNameState={[companyName, setCompanyName]}
        companyUrlState={[companyUrl, setCompanyUrl]}
        planState={[selectedPlan, setSelectedPlan]}
        externalIdentifierState={[externalIdentifier, setExternalIdentifier]}
        createL1ReportState={[createL1Report, setCreateL1Report]}
        operationalStatusState={[operationalStatus, setOperationalStatus]}
        dashboardsState={[dashboardLimit, setDashboardLimit]}
        publicLinkState={[publicLink, setPublicLink]}
        multifactorState={[multifactor, setMultifactor]}
        hasPushState={[hasPush, setHasPush]}
        volumeDataState={[volumeData, setVolumeData]}
        scientistLimitState={[scientistLimit, setScientistLimit]}
        adminLimitState={[adminLimit, setAdminLimit]}
        guestLimitState={[guestLimit, setGuestLimit]}
        snapsLimitState={[snapsLimit, setSnapsLimit]}
        freeMonitoringState={[freeMonitoring, setFreeMonitoringLimit]}
        monitoringPriceState={[monitoringPrice, setMonitoringPrice]}
        newProcessPriceState={[newProcessPrice, setNewProcessPrice]}
        oldProcessPriceState={[oldProcessPrice, setOldProcessPrice]}
      />
    ),
  };

  /**
   * Funcao que checa se o segundo passo pode avancar ou nao
   * @returns booleano que, se verdadeiro, nao possui erros e pode avancar
   */
  const checkSecondForm = () => adminEmails.length > 0;

  /**
   * Segundo passo, emails da instancia
   */
  const secondStep = {
    label: 'Emails',
    validation: checkSecondForm,
    content: (
      <SecondStep
        scientistsEmailsState={[scientistsEmails, setScientistsEmails]}
        adminEmailsState={[adminEmails, setAdminEmails]}
        sendEmailState={[sendEmail, setSendEmail]}
      />
    ),
  };

  /**
   * Lida com o envio dos novos dados para o backend,
   * para criar uma nova instancia ou atualizar uma ja existente
   */
  const handleSubmit = async () => {
    const instanceConfigs = {
      name: companyName.value,
      companyurl: companyUrl.error ? '' : companyUrl.value,
      plan: selectedPlan?.value,
      external_identifier: externalIdentifier.value,
      l1reportActive: createL1Report,
      operationalStatus: operationalStatus.value,
      disabled: false,
      sendmail: sendEmail,
      templates: templates.current,
      support: support.current,
      publiclink: publicLink,
      multifactor,
      //
      guests: +guestLimit,
      scientists: +scientistLimit,
      supers: +adminLimit,
      //
      snapshots: +snapsLimit,
      volumedata: +volumeData,
      dashboards: +dashboardLimit,
      //
      push: hasPush,
      monitoring: freeMonitoring,
      newMonitoring: monitoringPrice,
      newProcess: newProcessPrice,
      oldProcess: oldProcessPrice,
    };

    if (isEdit) {
      instanceConfigs.company_id = toEditInstance.id;
      instanceConfigs.disabled = toEditInstance.disabled;
      submitEditInstance(instanceConfigs);
    } else {
      const emailsSuper = adminEmails;
      const emailsScientist = scientistsEmails;
      submitNewInstance(instanceConfigs, emailsSuper, emailsScientist, image.current);
    }
  };

  return (
    <>
      <StepProgress
        steps={isEdit ? [firstStep] : [firstStep, secondStep]}
        hide={closeModal}
        removeMarkers
        complete={handleSubmit}
        markersWidth="medium"
        disableUntilValid
      />
    </>
  );
};

NewInstance.propTypes = {
  isEdit: PropTypes.bool,
  toEditInstance: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    companyurl: PropTypes.string,
    plan: PropTypes.string,
    external_identifier: PropTypes.string,
    l1reportActive: PropTypes.bool,
    operationalStatus: PropTypes.string,

    templates: PropTypes.arrayOf(PropTypes.shape({})),
    photoUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    support: PropTypes.any,

    volumeData: PropTypes.number,
    dashboards: PropTypes.number,
    snapshots: PropTypes.number,

    guests: PropTypes.number,
    scientists: PropTypes.number,
    superS: PropTypes.number,
    publicLink: PropTypes.bool,
    multifactor: PropTypes.bool,
    push: PropTypes.bool,
    disabled: PropTypes.bool,
    monitoring: PropTypes.number,
    newMonitoring: PropTypes.number,
    newProcess: PropTypes.number,
    oldProcess: PropTypes.number,
  }),

  closeModal: PropTypes.func,
  submitEditInstance: PropTypes.func,
  submitNewInstance: PropTypes.func,
};

NewInstance.defaultProps = {
  isEdit: false,
  toEditInstance: {},
  closeModal: () => {},
  submitEditInstance: () => {},
  submitNewInstance: () => {},
};

export default NewInstance;
