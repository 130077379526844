import React from "react";

export default function Underline() {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.3 3.3H24.75V14.85H28.05V3.3C28.05 1.48005 26.57 0 24.75 0H3.3C1.48005 0 0 1.48005 0 3.3V23.1C0 24.92 1.48005 26.4 3.3 26.4H16.5V23.1H3.3V3.3Z" />
            <path d="M9.89971 13.1999L4.94971 19.7999H23.0997L16.4997 9.8999L11.5497 16.4999L9.89971 13.1999Z" />
            <path d="M28.0498 18.1499H24.7498V23.0999H19.7998V26.3999H24.7498V31.3499H28.0498V26.3999H32.9998V23.0999H28.0498V18.1499Z" />
        </svg>
    );
}


