import React, {
  useEffect, useState, useRef, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import Chart from '../../juristec-ui/kpis/charts';
import InsightCard from '../../juristec-ui/kpis/insight';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import Tooltip from '../../juristec-ui/core/Tooltip';

import checkDataSize from '../../juristec-ui/kpis/utils/chartTools/kpiMsg';
import filterByValues from '../../juristec-ui/kpis/utils/formatCharts/filterByValues';
import groupByValues from '../../juristec-ui/kpis/utils/formatCharts/groupByValues';

import {
  Add, AdjustScreen, ArrowRight, Close, CommentExclamation, FillScreen, Finder, Minus,
} from '../../juristec-ui/icons';

import {
  MainContainer,
  KpiWrapper,
  KpiContainer,
  ActionButtons,
  KpiTitle,
  KpiCard,
  BtnText,
  CommentArea,
} from './styled/KpiViewer.styled';

/**
 * Exibe individualmente cada card do kpi
 */
const KpiViewer = ({
  close, kpiList, kpiId, layout, getKpiSize, cardStyles,
}) => {
  const containerRef = useRef();
  const isDragging = useRef(false);
  const cardPos = useRef({
    top: 0, left: 0, x: 0, y: 0,
  });

  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight, width: window.innerWidth,
  });
  const [showComment, setShowComment] = useState(false);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [fillScreen, setFillScreen] = useState(false);
  const [kpiInfo, setKpiInfo] = useState();

  const getKpi = useCallback((id) => kpiList.find((k) => k.id === id), []);

  useEffect(() => {
    setKpiInfo({ kpi: getKpi(kpiId), size: getKpiSize(kpiId) });
  }, [kpiList, kpiId]);

  // Adiciona um observador ao tamanho da janela
  useEffect(() => {
    function handleResize() {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Funcao que lida com os tamanhos dos cards do kpi,
   * recalculando para que seja exibido da melhor forma na tela
   * @returns {{ rWidth: Number, rHeight: Number }} um objeto com as novas medidas do card
   */
  const resizeKpi = () => {
    // Tamanho para kpi tabela
    if (kpiInfo?.kpi?.type === 'Table') {
      return {
        rWidth: windowDimensions.width - 120,
        rHeight: Math.min(kpiInfo?.size?.h, windowDimensions.height - 120),
      };
    }
    // Tamanho para kpi de valor ou insigth
    if (kpiInfo?.kpi?.type === 'label' || kpiInfo?.kpi?.type === 'Value') {
      return {
        rWidth: Math.min(kpiInfo?.size?.w, windowDimensions.width - 120),
        rHeight: Math.min(kpiInfo?.size?.h, windowDimensions.height - 120),
      };
    }

    // Preencher tela
    if (fillScreen) {
      return {
        rWidth: (windowDimensions.width - 120) * zoomFactor,
        rHeight: (windowDimensions.height - 120) * zoomFactor,
      };
    }

    // Ajuste proporcional
    const hDiff = windowDimensions.height - kpiInfo?.size?.h;
    const wDiff = windowDimensions.width - kpiInfo?.size?.w;
    if (hDiff < wDiff) {
      const rWidth = (kpiInfo?.size?.w + hDiff) - 120;
      const rHeight = windowDimensions.height - 120;
      return rWidth > 0 && rHeight > 0 ? {
        rWidth: rWidth * zoomFactor,
        rHeight: rHeight * zoomFactor,
      } : {
        rWidth: kpiInfo?.size?.w * zoomFactor,
        rHeight: kpiInfo?.size?.h * zoomFactor,
      };
    }
    const rWidth = windowDimensions.width - 120;
    const rHeight = (kpiInfo?.size?.h + wDiff) - 120;
    return rWidth > 0 && rHeight > 0 ? {
      rWidth: rWidth * zoomFactor,
      rHeight: rHeight * zoomFactor,
    } : {
      rWidth: kpiInfo?.size?.w * zoomFactor,
      rHeight: kpiInfo?.size?.h * zoomFactor,
    };
  };

  /**
   * Lida com o pressionamento do mouse no card, iniciando o arrasto
   * @param {*} e Evento
   */
  const mouseDownHandle = (e) => {
    if (e.button !== 0 || ['Table', 'label', 'Value'].includes(kpiInfo?.kpi?.type)) return;
    e.preventDefault();
    cardPos.current = {
      top: containerRef.current.scrollTop,
      left: containerRef.current.scrollLeft,
      x: e.clientX,
      y: e.clientY,
    };
    isDragging.current = true;
    containerRef.current.style.cursor = 'grabbing';
  };

  /**
   * Funcao que lida com o arrasto do card,
   * apenas quando o botao esquerdo do mouse estiver pressionado
   * @param {*} e Evento
   */
  const mouseMoveHandle = (e) => {
    if (isDragging.current) {
      containerRef.current.scrollTop = cardPos.current.top - (e.clientY - cardPos.current.y) * 2;
      containerRef.current.scrollLeft = cardPos.current.left - (e.clientX - cardPos.current.x) * 2;
    }
  };

  /**
   * Funcao para interromper o arrasto do card
   */
  const stopDragging = () => {
    if (isDragging.current) {
      isDragging.current = false;
      containerRef.current.style.cursor = 'grab';
    }
  };

  /**
   * Retorna o zoom para o valor inicial de 100%
   */
  const resetZoom = () => {
    setZoomFactor(1);
  };

  /**
   * Lida com o fechamendo do componente, retornando algumas preferencias para os valores padroes
   */
  const handleClose = () => {
    setFillScreen(false);
    resetZoom();
    close();
  };

  /**
   * Aumenta o zoom, 10% a mais em cada chamada, maximo de 200%
   */
  const increaseZoom = () => {
    const newZoomFactor = Number((zoomFactor + 0.1).toFixed(2));
    setZoomFactor(newZoomFactor > 2 ? 2 : newZoomFactor);
  };

  /**
   * Reduz o zoom, 10% a menos em cada chamada, minimo de 10%
   */
  const decreaseZoom = () => {
    const newZoomFactor = Number((zoomFactor - 0.1).toFixed(2));
    // minimo 10% do tamanho original
    setZoomFactor(newZoomFactor < 0.1 ? 0.1 : newZoomFactor);
  };

  /**
   * Troca para o proximo kpi, seguindo a ordem do layout e em relacao ao kpi atual
   */
  const nextKpi = () => {
    const kpiLayoutIndex = layout?.findIndex((l) => l.i === kpiInfo?.kpi?.id);
    const kId = layout[kpiLayoutIndex < layout.length - 1 ? kpiLayoutIndex + 1 : 0].i;
    setKpiInfo({
      kpi: getKpi(kId),
      size: getKpiSize(kId),
    });
    setFillScreen(false);
    resetZoom();
  };

  /**
   * Troca para o kpi anterior, seguindo a ordem do layout e em relacao ao kpi atual
   */
  const prevKpi = () => {
    const kpiLayoutIndex = layout?.findIndex((l) => l.i === kpiInfo?.kpi?.id);
    const kId = layout[kpiLayoutIndex > 0 ? kpiLayoutIndex - 1 : layout.length - 1].i;
    setKpiInfo({
      kpi: getKpi(kId),
      size: getKpiSize(kId),
    });
    setFillScreen(false);
    resetZoom();
  };

  /**
   * Fecha o popover de comentarios
   */
  const closeComment = () => {
    setShowComment(false);
  };

  /**
   * Abre o popover de comentarios
   */
  const openComment = () => {
    setShowComment(true);
  };

  /**
   * Troca o estilo de exibicao do card
   */
  const toggleAdjust = () => {
    setFillScreen((o) => !o);
  };

  // Adiciona a troca de kpi para as setas do teclado
  useEffect(() => {
    const handleKey = (e) => {
      if (e.key === 'ArrowRight') {
        nextKpi();
      } else if (e.key === 'ArrowLeft') {
        prevKpi();
      }
    };

    window.addEventListener('keydown', handleKey);
    return () => window.removeEventListener('keydown', handleKey);
  }, [kpiInfo?.kpi]);

  function modifyData(filterList, groupList, data, lineName) {
    let newData = data;
    if (filterList?.length > 0) {
      newData = filterByValues(filterList, newData, lineName);
    }
    if (groupList?.length > 0) {
      newData = groupByValues(groupList, newData, lineName);
    }
    return newData;
  }
  const data = (kpiInfo?.kpi?.data) ? (
    modifyData(
      kpiInfo?.kpi?.meta?.filterValues,
      kpiInfo?.kpi?.meta?.groupValues,
      kpiInfo?.kpi.data,
      kpiInfo?.kpi?.meta?.lines?.[0]?.column,
    )
  ) : (
    { index: [], data: [], columns: [] }
  );

  const kpiQtd = useMemo(() => (
    kpiList?.filter((kpi) => !['label', 'Value'].includes(kpi.type)).length || 1
  ), [kpiList]);

  return (
    <MainContainer>
      {/* Fechar e comentarios */}
      <ActionButtons className="top-right">
        {kpiInfo?.kpi?.comment?.trim()?.length > 0 && (
          <Popover
            open={showComment}
            closePopover={closeComment}
            style={{
              borderTopRightRadius: '0',
              zIndex: 1050,
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
            }}
            offset={[-30, 2]}
          >
            <Popover.Action>
              <Tooltip text="Comentário" atModal direction="bottom-end">
                <IconButton
                  size="large"
                  color="white"
                  style={{ boxShadow: 'none', padding: '5px' }}
                  onClick={openComment}
                >
                  <CommentExclamation />
                </IconButton>
              </Tooltip>
            </Popover.Action>
            <Popover.Content>
              <CommentArea>
                {kpiInfo?.kpi?.comment}
              </CommentArea>
            </Popover.Content>
          </Popover>
        )}
        <Tooltip text="Fechar" atModal direction="bottom-end">
          <IconButton
            size="large"
            color="white"
            style={{ boxShadow: 'none', padding: '5px' }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </ActionButtons>

      {/* Kpi anterior */}
      <ActionButtons className="middle-left">
        <Tooltip text="Kpi anterior" atModal direction="right">
          <IconButton
            size="large"
            color="white"
            style={{ boxShadow: 'none', padding: '5px' }}
            onClick={prevKpi}
          >
            <ArrowRight />
          </IconButton>
        </Tooltip>
      </ActionButtons>

      {/* Proximo kpi */}
      <ActionButtons className="middle-right">
        <Tooltip text="Próximo kpi" atModal direction="left">
          <IconButton
            size="large"
            color="white"
            style={{ boxShadow: 'none', padding: '5px' }}
            onClick={nextKpi}
          >
            <ArrowRight />
          </IconButton>
        </Tooltip>
      </ActionButtons>

      {/* Controles de zoom */}
      {!['Table', 'label', 'Value'].includes(kpiInfo?.kpi?.type) && (
        <>
          <ActionButtons className="bottom-center">
            <Tooltip text="Reduzir" atModal direction="top">
              <IconButton
                size="large"
                color="white"
                style={{ boxShadow: 'none', padding: '5px' }}
                onClick={decreaseZoom}
              >
                <Minus />
              </IconButton>
            </Tooltip>
            <Tooltip text="Redefinir zoom" atModal direction="top">
              <IconButton
                size="medium"
                color="white"
                shape="rounded"
                style={{ boxShadow: 'none', padding: '5px', gap: '5px' }}
                onClick={resetZoom}
              >
                <Finder />
                <BtnText>{`${(zoomFactor * 100).toFixed(0)}%`}</BtnText>
              </IconButton>
            </Tooltip>
            <Tooltip text="Aumentar" atModal direction="top">
              <IconButton
                size="large"
                color="white"
                style={{ boxShadow: 'none', padding: '5px' }}
                onClick={increaseZoom}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </ActionButtons>
          {/* Controle de tipo de ajuste */}
          <ActionButtons className="bottom-left">
            <Tooltip text={fillScreen ? 'Ajuste proporcional' : 'Preencher tela'} atModal direction="top-end">
              <IconButton
                size="large"
                color="white"
                className="fix-icon"
                style={{ boxShadow: 'none', padding: '5px' }}
                onClick={toggleAdjust}
              >
                {fillScreen ? <AdjustScreen /> : <FillScreen />}
              </IconButton>
            </Tooltip>
          </ActionButtons>
        </>
      )}

      {/* Kpi */}
      <KpiContainer
        ref={containerRef}
        onMouseDown={mouseDownHandle}
        onMouseMove={mouseMoveHandle}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
        isDragging={isDragging.current}
        allowDrag={!['Table', 'label', 'Value'].includes(kpiInfo?.kpi?.type)}
      >
        <KpiCard
          size={resizeKpi()}
          cardStyles={cardStyles}
          kpiStyles={kpiInfo?.kpi?.style}
          type={kpiInfo?.kpi?.type}
        >
          {kpiInfo?.kpi?.type === 'label' ? (
            <InsightCard
              item={kpiInfo?.kpi}
            />
          ) : (
            <>
              {kpiInfo?.kpi?.style?.ShowTitleControl?.checked ? (
                <KpiTitle style={kpiInfo?.kpi?.style.StyleTitleControl}>
                  <span>{kpiInfo?.kpi?.name}</span>
                </KpiTitle>
              ) : <></>}
              <KpiWrapper>
                {checkDataSize(data, kpiInfo?.kpi?.type, true, kpiQtd) || (
                  <Chart
                    key={kpiInfo?.kpi?.id}
                    type={kpiInfo?.kpi?.type}
                    data={data}
                    valueFunction={kpiInfo?.kpi?.meta?.values?.[0]?.map}
                    styleConfig={kpiInfo?.kpi?.type === 'Map' ? { ...kpiInfo?.kpi?.style, zoom: zoomFactor } : kpiInfo?.kpi?.style}
                    handleStyleConfig={() => {}}
                    isEdit={false}
                  />
                )}
              </KpiWrapper>
            </>
          )}
        </KpiCard>
      </KpiContainer>
    </MainContainer>
  );
};

KpiViewer.propTypes = {
  /**
   * Funcao para fechar a exibicao do kpi
   */
  close: PropTypes.func,
  /**
   * Array com os dados dos kpis
   */
  kpiList: PropTypes.arrayOf(PropTypes.any),
  /**
   * Id do kpi selecionado
   */
  kpiId: PropTypes.string.isRequired,
  /**
   * Lista das posicoes dos kpis no dashboard
   */
  layout: PropTypes.arrayOf(PropTypes.any),
  /**
   * Funcao que obtem o tamanho do kpi na tela
   */
  getKpiSize: PropTypes.func,
  /**
   * Estilos do kpi selecionado
   */
  cardStyles: PropTypes.objectOf(PropTypes.any),
};

KpiViewer.defaultProps = {
  kpiList: [],
  close: () => {},
  layout: undefined,
  getKpiSize: () => {},
  cardStyles: {},
  // setKpi: () => {},
};

export default KpiViewer;
