/* eslint-disable no-param-reassign */
import styled, { css } from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

const getContrastYIQ = (hexcolor) => {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  user-select: none;
  @media ${devices.phoneS} {
    flex-direction: column-reverse;
  }
`;

export const NewDashboardContainer = styled(Column)`
  padding: 1rem;
  box-sizing: border-box;
  @media ${devices.phoneS} {
    width: 100%;
  }
  & > span {
    align-self: end;
  }
`;

export const ImageSquare = styled.div`
  background-color: ${({ theme }) => theme.white};
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 1rem 1rem;
  width: 100%;

  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `};
`;

export const FormLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const InputWrapper = styled.div`
  margin: 0.8rem 0;
  width: 100%;
  & span {
    position: absolute;
  }
`;

export const ImageTip = styled.span`
  color: ${({ theme }) => theme.grey};
  font-size: 12px;
  margin-bottom: 0.3rem;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  & .under {
  }
  & .over {
    z-index: 1;
  }
`;

export const ColorPickerCircle = styled.div`
  border-radius: 8px;
  width: 35px;
  height: 35px;
  cursor: pointer;

  background-color: ${({ $color }) => $color};
  box-shadow: 0px 1px 2px gray;
  &:hover {
    box-shadow: 0px 1px 5px gray;
  }
`;

export const ImageOverlay = styled.div`
  align-items: flex-end;
  position: absolute;
  display: flex;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 1rem;
  display: flex;
  height: 100%;
  width: 100%;
  align-self: center;
  max-width: 500px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 180px;
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.shadow};

  ${({ bgSrc, bgColor }) => (bgSrc.length === 0 ? css`
    background: linear-gradient(to top, ${bgColor} 90%, transparent 0);
    color: ${getContrastYIQ(bgColor)};
  ` : css`
    &:after {
      opacity: 0.35;
      background-image: url(${bgSrc});
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
  `)}
  transition: transform .3s;
  &:hover,
  &:focus {
    transform: ${({ stopAnimation }) => (stopAnimation ? 'none' : 'scale3d(1.03, 1.03, 1)')};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 36px;
  border-radius: 1rem 1rem 0 0;
  cursor: default;
  ${({ bgColor }) => css`
    background-color: ${bgColor};
    & h5 {
      margin: 0.5rem 0;
      font-size: 14px;
      color: ${getContrastYIQ(bgColor)};
    }
  `};
`;

export const CardMain = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 144px;

  ${({ bgColor }) => css`
    & span {
      color: ${getContrastYIQ(bgColor)};
    }
  `};
`;

export const BackgroundOptions = styled.div`
  background-color: #000000aa;
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  & button {
    box-shadow: none !important;
    &.fix-icon {
      padding: 0;
      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;
