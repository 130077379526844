import styled, { css } from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ControlContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.orientation || 'column'};
  align-items: ${(props) => (props.orientation === 'row' ? 'center' : 'start')};
  justify-content: space-between;
  height: ${(props) => (props.fullHeight ? 'auto' : '50px')};
  user-select: none;
  
  ${(props) => props.disabled && css`
    pointer-events: none;
    opacity: 0.3;
  `}
  
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(50% - 5px)')};
  &.fullwidth-inline {
    flex-direction: column;
    align-items: start;
  }

  & .inputTxt {
    padding: 5px 0;
    padding-right: ${(props) => (props.isNumber ? '28px' : '0')};
  }

  & .visible-label, .inputTxt {
    font-size: 0.9rem;
  }

  & .select_header > div {
    padding: 5px 0;
    margin: 0;
  }
  & .select_wrapper {
    min-height: auto;
  }

  & .choicebox-btn {
    min-width: auto;
    width: 100%;
    box-shadow: none;
    white-space: nowrap;
    border-width: 1px;
    border-right-width: 0;
    height: 100%;
    & svg {
      height: 18px;
    }
  }
  & .choicebox-btn:first-child {
    border-radius: 8px 0 0 8px;
  }
  & .choicebox-btn:last-child {
    border-radius: 0 8px 8px 0;
    border-right-width: 1px;
  }

  & .marker-badge {
    font-size: 12px;
    height: 22px;
    width: 49%;
    cursor: pointer;
  }

  & .marker-badge-active {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.white};
  }

  & .marker-add-badge {
    padding: 0;
    & svg {
      fill: ${({ theme }) => theme.secondary};
    }
    &:hover svg{
      fill: ${({ theme }) => theme.secondaryHover};
    }
  }
`;

export const LabelController = styled.label`
  font-size:0.85rem;
  color:${({ theme }) => theme.grey};
  font-weight: bold;
`;

export const NumberControls = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2px;
  bottom: 2px;
  & button {
    height: 14px;
    width: 20px;
    padding: 0;
    border: none;
    background-color: ${({ theme }) => theme.inputNumberControl};
    &:hover {
      background-color: ${({ theme }) => theme.inputNumberControlHover};
    }
  }
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.grey};
  }
  & .number-increase {
    transform: rotate(180deg);
  }
`;

export const OrderControls = styled(FlexRow)`
  align-self: center;
  gap: 4px;
  ${(props) => props.disableFirst && css`
    & div:first-child {
      cursor: normal;
      pointer-events: none;
      & svg {
        fill: ${({ theme }) => theme.grey};
      }
    }
  `}
  ${(props) => props.disableLast && css`
    & div:last-child {
      cursor: normal;
      pointer-events: none;
      & svg {
        fill: ${({ theme }) => theme.grey};
      }
    }
  `}
`;

export const OrderBtn = styled.button`
  height: 14px;
  width: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  & svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.primary};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.primaryHover};
  }
  &.order-decrease {
    transform: rotate(180deg);
  }
`;
