export default {
  TitleControl: '',
  ShowTitleControl: { checked: true },
  SortValuesControl: 'Valor Crescente',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  RowsControl: 15,
  ColumnsControl: 10,
  Margin: {
    top: 20,
    right: 60,
    bottom: 20,
    left: 60,
  },
  FillDirection: 'top',
  DataFormat: { type: 'absolute' },
  PaddingControl: { value: 1 },
  /* Style */
  BorderWidthControl: { value: 0 },
  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  ColorTheme: { scheme: 'nivo' },
  Colors: {},
  PalletsControl: 'default',
  /* Legend */
  LegendControl: { checked: true },
  LegendsFontSizeControl: { value: 12 },
  LegendsSpacingControl: { value: 10 },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendPlacementControl: {
    value: {
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      translateX: 0,
      translateY: 0,
    },
  },
  /* Tooltip */
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  InteractiveDecimals: 'auto',
};
