import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import Loading from '../../../juristec-ui/core/MiniLoading';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';

import {
  MainContainer,
  GridContainer,
  LeftSide,
  RightSide,
  ColumnBadge,
  EmptyMsg,
  LoadingContainer,
  InfoTooltip,
  FullMsg,
} from './styled/GlobalFiltersColumns.styled';

import { dateOptions } from '../../../options';

import {
  Close, DateIcon, Letters, Number,
} from '../../../juristec-ui/icons';

const getSelectorLabel = (selector) => (
  dateOptions.find((d) => d.value === selector).label || ''
);

const op = {
  category: <Letters />,
  float64: <Number />,
  'datetime64[ns]': <DateIcon />,
};

const EMPTYSELECTION = { label: '', value: '' };

const GlobalFiltersColumns = ({
  usedFiles,
  getFileColumns,
  hide,
  globalFiltersState,
  showToolbar,
  submit,
}) => {
  const selectedFileColumns = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(EMPTYSELECTION);
  const [selectedColumn, setSelectedColumn] = useState(EMPTYSELECTION);
  const [columnsOpts, setColumnsOpts] = useState([]);
  const [filteredDateOpts, setFilteredDateOpts] = useState(dateOptions);
  const [selectedFormat, setSelectedFormat] = useState(EMPTYSELECTION);
  const [globalFilterSelectors, setGlobalFilterSelectors] = globalFiltersState;
  const [tempFilters, setTempFilters] = useState({});
  const [filteredFiles, setFilteredFiles] = useState([]);

  useEffect(() => {
    if (usedFiles?.length > 0) {
      const fFiles = usedFiles.filter((uf) => uf.allowed);
      setSelectedFile(fFiles[0] || EMPTYSELECTION);
      setFilteredFiles(fFiles);
      setTempFilters(globalFilterSelectors);
      setIsLoading(fFiles.length > 0);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedFile.value) {
      setSelectedColumn(EMPTYSELECTION);
      setSelectedFormat(EMPTYSELECTION);
      if (selectedFile.columns) {
        setColumnsOpts(selectedFile.columns);
        selectedFileColumns.current = selectedFile.columns;
        setIsLoading(false);
      } else {
        (async () => {
          setIsLoading(true);
          const opts = await getFileColumns(selectedFile.value);
          selectedFileColumns.current = opts;
          setColumnsOpts(opts);
          setIsLoading(false);
        })();
      }
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFileColumns.current) {
      setColumnsOpts(selectedFileColumns.current.filter((col) => (
        !Object.keys(tempFilters).includes(`${selectedFile.value}${col.value}`)
      )));
    }
  }, [tempFilters, selectedFile, selectedFileColumns?.current]);

  useEffect(() => {
    if (selectedFileColumns.current && selectedColumn.type === 'datetime64[ns]') {
      setFilteredDateOpts(dateOptions.filter((o) => (
        !Object.keys(tempFilters).includes(`${selectedFile.value}${selectedColumn.value}${o.value}`)
      )));
    }
    setSelectedFormat(EMPTYSELECTION);
  }, [selectedColumn, selectedFile, selectedFileColumns?.current]);

  const addTempFilter = () => {
    const dateSelector = selectedColumn.type === 'datetime64[ns]' ? selectedFormat.value : '';
    setTempFilters((gfilters) => ({
      ...gfilters,
      [`${selectedFile.value}${selectedColumn.value}${dateSelector}`]: {
        database: selectedFile.value,
        filename: selectedFile.label,
        column: selectedColumn.value,
        type: selectedColumn.type,
        selector: dateSelector,
        isOpen: false,
      },
    }));
    setSelectedColumn(EMPTYSELECTION);
    setSelectedFormat(EMPTYSELECTION);
  };

  const removeTempFilter = (e, fId) => {
    e.preventDefault();
    e.stopPropagation();
    setTempFilters((gfilters) => {
      const { [fId]: _, ...auxObj } = gfilters;
      return auxObj;
    });
  };

  const saveFilters = () => {
    submit(Object.keys(tempFilters).map((key) => ({
      key,
      column: tempFilters[key].column,
      database: tempFilters[key].database,
      selector: tempFilters[key].selector,
      type: tempFilters[key].type,
      fType: 'values',
      inverse: false,
    })));
    setGlobalFilterSelectors(tempFilters);
    showToolbar(Object.keys(tempFilters).length > 0);
    hide();
  };

  const disableBtn = () => (
    Object.keys(tempFilters).length >= 10
    || selectedColumn.value === ''
    || (selectedColumn.type === 'datetime64[ns]' && selectedFormat.value === '')
  );

  return (
    <MainContainer>
      <GridContainer>
        {isLoading && (
          <LoadingContainer>
            <Loading fill="primary" />
          </LoadingContainer>
        )}
        <LeftSide>
          <span>
            Selecione as variáveis que você quer utilizar como filtros globais:
          </span>
          <Select
            selectLabel="Arquivo"
            options={filteredFiles}
            value={selectedFile}
            onChange={setSelectedFile}
            atModal
            fullWidth
          />
          <Select
            selectLabel="Coluna"
            options={columnsOpts}
            value={selectedColumn}
            onChange={setSelectedColumn}
            atModal
            fullWidth
          />
          {selectedColumn.type === 'datetime64[ns]' && (
            <Select
              selectLabel="Formato"
              options={filteredDateOpts}
              value={selectedFormat}
              onChange={setSelectedFormat}
              atModal
              fullWidth
            />
          )}
          <Button
            color="secondary"
            onClick={addTempFilter}
            disabled={disableBtn()}
          >
            Adicionar variável
          </Button>
          {Object.keys(tempFilters).length >= 10 && (
            <FullMsg>*Limite de variáveis atingido!</FullMsg>
          )}
        </LeftSide>
        <RightSide>
          {Object.keys(tempFilters).length > 0 ? (
            Object.keys(tempFilters).map((tfKey) => (
              <Tooltip
                key={tfKey}
                text={(
                  <InfoTooltip>
                    <span>{`Arquivo: ${tempFilters[tfKey].filename}`}</span>
                    {tempFilters[tfKey].selector && (
                      <span>{`Formato: ${getSelectorLabel(tempFilters[tfKey].selector)}`}</span>
                    )}
                  </InfoTooltip>
                )}
                direction="top"
                atModal
              >
                <ColumnBadge varType={tempFilters[tfKey].type}>
                  {op[tempFilters[tfKey].type]}
                  <h5>{tempFilters[tfKey].column}</h5>
                  <IconButton
                    size="small"
                    variant="contained"
                    color="secondary"
                    style={{ boxShadow: 'none', margin: '0 2px 0 auto' }}
                    onClick={(e) => removeTempFilter(e, tfKey)}
                  >
                    <Close />
                  </IconButton>
                </ColumnBadge>
              </Tooltip>
            ))
          ) : (
            <EmptyMsg>
              Nenhuma variável foi adicionada.
            </EmptyMsg>
          )}
        </RightSide>
      </GridContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button style={{ margin: '5px' }} size="large" onClick={saveFilters}>
          Salvar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

GlobalFiltersColumns.propTypes = {
  usedFiles: PropTypes.arrayOf(PropTypes.any),
  getFileColumns: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  globalFiltersState: PropTypes.arrayOf(PropTypes.any).isRequired,
  showToolbar: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

GlobalFiltersColumns.defaultProps = {
  usedFiles: [],
};

export default GlobalFiltersColumns;
