/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import InputNumberLine from '../../../../juristec-ui/core/InputNumberLine';
import Select from '../../../../juristec-ui/core/Select';
import Toggle from '../../../../juristec-ui/core/Toggle';

import { planOpt, operationalStatusOpt } from '../../../../options';
import getPlan from '../../../../juristec-ui/utils/plans';

import { trimString, compactString } from '../../../../juristec-ui/utils/functions/formatString';

import {
  verifyInput,
  verifyLink,
} from '../../../../juristec-ui/utils/validators/inputTextValidators';

import {
  MainContainer,
  ColumnContainer,
  InputWrapper,
  VerticalSeparator,
  CategoryLabel,
} from './styled/NewInstance.styled';

const FirstStep = ({
  companyNameState,
  companyUrlState,
  planState,
  externalIdentifierState,
  createL1ReportState,
  operationalStatusState,
  dashboardsState,
  publicLinkState,
  multifactorState,
  hasPushState,
  volumeDataState,
  scientistLimitState,
  adminLimitState,
  guestLimitState,
  snapsLimitState,
  freeMonitoringState,
  monitoringPriceState,
  newProcessPriceState,
  oldProcessPriceState,
}) => {
  // Estados que contem o que sera enviado (declarados no componente pai)
  const [companyName, setCompanyName] = companyNameState;
  const [companyUrl, setCompanyUrl] = companyUrlState;
  const [selectedPlan, setSelectedPlan] = planState;
  const [externalIdentifier, setExternalIdentifier] = externalIdentifierState;
  const [createL1Report, setCreateL1Report] = createL1ReportState;
  const [operationalStatus, setOperationalStatus] = operationalStatusState;
  const [dashboardLimit, setDashboardLimit] = dashboardsState;
  const [publicLink, setPublicLink] = publicLinkState;
  const [multifactor, setMultifactor] = multifactorState;
  const [hasPush, setHasPush] = hasPushState;
  const [volumeData, setVolumeData] = volumeDataState;
  const [scientistLimit, setScientistLimit] = scientistLimitState;
  const [adminLimit, setAdminLimit] = adminLimitState;
  const [guestLimit, setGuestLimit] = guestLimitState;
  const [snapsLimit, setSnapsLimit] = snapsLimitState;
  const [freeMonitoring, setFreeMonitoringLimit] = freeMonitoringState;
  const [monitoringPrice, setMonitoringPrice] = monitoringPriceState;
  const [newProcessPrice, setNewProcessPrice] = newProcessPriceState;
  const [oldProcessPrice, setOldProcessPrice] = oldProcessPriceState;

  /**
   * Altera outros estados com as configuracoes padroes do plano selecionado
   * @param {string} selectVal Valor que representa o plano selecionado
   */
  const setDefaultPlanConfig = (selectVal) => {
    const tmpPlan = getPlan(selectVal);
    setVolumeData(tmpPlan.diskSize);
    setDashboardLimit(tmpPlan.dashboards.value);
    setScientistLimit(tmpPlan.scientists);
    setAdminLimit(tmpPlan.superS);
    setGuestLimit(tmpPlan.guests);
    setSnapsLimit(tmpPlan.snapshots);
    setFreeMonitoringLimit(tmpPlan.monitoring || 0);
    setMonitoringPrice(tmpPlan.newMonitoring || 0);
    setNewProcessPrice(tmpPlan.newProcess || 0);
    setOldProcessPrice(tmpPlan.oldProcess || 0);
  };

  // Marca um plano e status operacional inicial, quando os mesmos estiverem vazios
  useEffect(() => {
    if (!selectedPlan.value) {
      setSelectedPlan(planOpt[0]);
      setDefaultPlanConfig(planOpt[0].value);
    }
    if (!operationalStatus.value) {
      setOperationalStatus(operationalStatusOpt[1]);
    }
  }, []);

  /**
   * Lida com a selecao de plano no select, alterando outros estados necessarios
   * @param {object} select Objeto com a opcao escolhida
   */
  const handlePlanSelect = (select) => {
    setSelectedPlan(select);
    if (select.value === 'push') {
      setHasPush(true);
    }
    setDefaultPlanConfig(select.value);
  };

  const handleOperationalStatus = (select) => {
    setOperationalStatus(select);
  };

  /**
   * Lida com o input de nome da empresa, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const companyNameHandle = (e) => {
    const val = e.target.value;
    const msg = verifyInput(val, true, 5);
    setCompanyName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  /**
   * Lida com o input de link da empresa, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const handleCompanyUrl = (e) => {
    const val = e.target.value;
    const msg = verifyLink(val);
    setCompanyUrl({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };
  /**
   * Lida com o input de identificador externo, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const handleExternalIdentifier = (e) => {
    const val = e.target.value;
    setExternalIdentifier({
      value: val,
      error: false,
      errorMsg: '',
    });
  };

  /**
   * Formata o valor do input, a partir do estado informado
   * @param {function} setter Estado a ser atualizado
   * @param {boolean} compact Informa se a formatacao deve remover espacos em branco ou nao
   */
  const handleBlurTrim = (setter, compact = false) => {
    setter((old) => ({
      ...old,
      value: compact ? compactString(old.value) : trimString(old.value),
    }));
  };

  /**
   * Formata o input de nome da empresa quando ele perde o foco
   */
  const companyNameTrim = () => { handleBlurTrim(setCompanyName); };

  /**
   * Formata o input de link da empresa quando ele perde o foco
   */
  const CompanyUrlTrim = () => { handleBlurTrim(setCompanyUrl, true); };

  /**
   * Ativa ou desativa a permissao de compartilhar link publico dos dashboards
   */
  const togglePublicLink = () => { setPublicLink((p) => !p); };

  /**
   * Ativa ou desativa a ativação da autenticação de multifator para os usuários da instância
   */
  const toggleMultifactor = () => { setMultifactor((p) => !p); };

  /**
   * Ativa ou desativa o serviço do push na instancia
   */
  const togglePush = () => { setHasPush((p) => !p); };

  /**
   * Ativa ou desativa a opção de criação de relatório do Legal One pelo modelo antigo (Mail ou API GED)
   */
  const toggleL1Report = () => { setCreateL1Report((p) => !p); };

  return (
    <MainContainer>
      {/* Formulario a esquerda */}
      <ColumnContainer>
        <InputWrapper>
          <InputTextLine
            label="Nome da empresa"
            onChange={companyNameHandle}
            onBlur={companyNameTrim}
            value={companyName.value}
            error={companyName.errorMsg.length > 0}
            errorMessage={companyName.errorMsg}
          />
        </InputWrapper>
        <InputWrapper>
          <InputTextLine
            label="Url da empresa"
            onChange={handleCompanyUrl}
            onBlur={CompanyUrlTrim}
            value={companyUrl.value}
            error={companyUrl.errorMsg.length > 0}
            errorMessage={companyUrl.errorMsg}
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            selectLabel="Plano"
            placeholder="Selecione um plano"
            placement="start"
            onChange={handlePlanSelect}
            formatOptionLabel={false}
            options={planOpt}
            value={selectedPlan}
            fullWidth
            fullHeight
            atModal
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            selectLabel="Situação Operacional"
            placeholder="Selecione a situação"
            placement="start"
            onChange={handleOperationalStatus}
            formatOptionLabel={false}
            options={operationalStatusOpt}
            value={operationalStatus}
            fullWidth
            fullHeight
            atModal
          />
        </InputWrapper>
        <InputWrapper>
          <InputTextLine
            label="Identificador externo"
            onChange={handleExternalIdentifier}
            value={externalIdentifier.value}
            error={externalIdentifier.error}
            errorMessage={externalIdentifier.errorMsg}
          />
        </InputWrapper>
        <>
          <InputWrapper className="toggle bottom">
            <span className="custom-label">Habilitar capturas</span>
            <Toggle
              checked={hasPush}
              handleChange={togglePush}
              firstColor="primary"
              size="small"
              disabled={selectedPlan.value === 'push'}
            />
          </InputWrapper>
          { process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
            <InputWrapper className="toggle">
              <span className="custom-label">Permitir criação de relatório Legal One</span>
              <Toggle
                checked={createL1Report}
                handleChange={toggleL1Report}
                firstColor="primary"
                size="small"
              />
            </InputWrapper>
          )}
          <InputWrapper className="toggle">
            <span className="custom-label">Permitir link público</span>
            <Toggle
              checked={publicLink}
              handleChange={togglePublicLink}
              firstColor="primary"
              size="small"
            />
          </InputWrapper>
          <InputWrapper className="toggle">
            <span className="custom-label">Multifator obrigatório</span>
            <Toggle
              checked={multifactor}
              handleChange={toggleMultifactor}
              firstColor="primary"
              size="small"
            />
          </InputWrapper>
        </>
      </ColumnContainer>

      <VerticalSeparator />

      {/* Formulario a direita */}
      <ColumnContainer style={{ padding: '5px 5px 5px 0' }}>
        <CategoryLabel>Configurações do plano</CategoryLabel>
        <InputWrapper className="inline">
          <span className="custom-label">Espaço em disco</span>
          <InputNumberLine
            value={volumeData}
            onChange={(e) => setVolumeData(e.target.value)}
            label=""
            setValue={setVolumeData}
            min={1}
            numContainerStyle={{ width: '50%' }}
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Nº de cientistas</span>
          <InputNumberLine
            value={scientistLimit}
            onChange={(e) => setScientistLimit(e.target.value)}
            label=""
            setValue={setScientistLimit}
            min={0}
            numContainerStyle={{ width: '50%' }}
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Nº de proprietários</span>
          <InputNumberLine
            value={adminLimit}
            onChange={(e) => setAdminLimit(e.target.value)}
            label=""
            setValue={setAdminLimit}
            min={1}
            numContainerStyle={{ width: '50%' }}
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Nº de convidados</span>
          <InputNumberLine
            value={guestLimit}
            onChange={(e) => setGuestLimit(e.target.value)}
            label=""
            setValue={setGuestLimit}
            min={0}
            numContainerStyle={{ width: '50%' }}
          />
        </InputWrapper>
        <InputWrapper className="inline">
          <span className="custom-label">Nº de snapshots (stories)</span>
          <InputNumberLine
            value={snapsLimit}
            onChange={(e) => setSnapsLimit(e.target.value)}
            label=""
            setValue={setSnapsLimit}
            min={0}
            numContainerStyle={{ width: '50%' }}
          />
        </InputWrapper>
        {hasPush && (
          <>
            <CategoryLabel>Configurações das capturas</CategoryLabel>
            <InputWrapper className="inline">
              <span className="custom-label">Nº de monitoramentos</span>
              <InputNumberLine
                value={freeMonitoring}
                onChange={(e) => setFreeMonitoringLimit(e.target.value)}
                label=""
                setValue={setFreeMonitoringLimit}
                min={0}
                numContainerStyle={{ width: '50%' }}
              />
            </InputWrapper>
            <InputWrapper className="inline">
              <span className="custom-label">Valor monitoramento adicional</span>
              <InputNumberLine
                value={monitoringPrice}
                onChange={(e) => setMonitoringPrice(e.target.value)}
                label=""
                setValue={setMonitoringPrice}
                min={0}
                precision={2}
                step={1.00}
                isMoney
                numContainerStyle={{ width: '50%' }}
              />
            </InputWrapper>
            <InputWrapper className="inline">
              <span className="custom-label">Valor por processo novo</span>
              <InputNumberLine
                value={newProcessPrice}
                onChange={(e) => setNewProcessPrice(e.target.value)}
                label=""
                setValue={setNewProcessPrice}
                min={0}
                precision={2}
                step={0.1}
                isMoney
                numContainerStyle={{ width: '50%' }}
              />
            </InputWrapper>
            <InputWrapper className="inline">
              <span className="custom-label">Valor por processo legado</span>
              <InputNumberLine
                value={oldProcessPrice}
                onChange={(e) => setOldProcessPrice(e.target.value)}
                label=""
                setValue={setOldProcessPrice}
                min={0}
                precision={2}
                step={0.1}
                isMoney
                numContainerStyle={{ width: '50%' }}
              />
            </InputWrapper>
          </>
        )}
      </ColumnContainer>
    </MainContainer>
  );
};

FirstStep.propTypes = {
  companyNameState: PropTypes.arrayOf(PropTypes.any).isRequired,
  companyUrlState: PropTypes.arrayOf(PropTypes.any).isRequired,
  planState: PropTypes.arrayOf(PropTypes.any).isRequired,
  externalIdentifierState: PropTypes.arrayOf(PropTypes.any).isRequired,
  createL1ReportState: PropTypes.arrayOf(PropTypes.any).isRequired,
  operationalStatusState: PropTypes.arrayOf(PropTypes.shape).isRequired,
  dashboardsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  publicLinkState: PropTypes.arrayOf(PropTypes.any).isRequired,
  multifactorState: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasPushState: PropTypes.arrayOf(PropTypes.any).isRequired,
  volumeDataState: PropTypes.arrayOf(PropTypes.any).isRequired,
  scientistLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  guestLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  snapsLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  freeMonitoringState: PropTypes.arrayOf(PropTypes.any).isRequired,
  monitoringPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
  newProcessPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
  oldProcessPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default FirstStep;
