export default {
  TitleControl: '',
  ShowTitleControl: { checked: true },
  SortValuesControl: 'Não ordenado',
  CustomSortControl: {
    checked: false, kpiCategories: [],
  },
  ShowZeroValuesControl: { checked: true },
  Margin: {
    top: 50,
    right: 110,
    bottom: 50,
    left: 60,
  },
  MarginTop: 50,
  MarginRight: 110,
  MarginBottom: 50,
  MarginLeft: 60,
  EndAngleControl: 270,
  StartAngleControl: 0,
  BorderWidthControl: { value: 0 },
  PaddingAngleControl: { value: 0 },
  InnerRadiusControl: { value: 0.3 },
  DataFormat: { type: 'absolute', target: 'global' },

  BorderColorControl: { from: 'color', modifiers: [['darker', 1.6]] },
  LabelsColorControl: '#333333',
  LinesFormatControl: 'linear',
  EnableTrackControl: true,
  TrackColorControl: '#000000',
  TrackOpacityControl: 0.2,
  EnableGridR: true,
  EnableGridC: true,

  MinValueControl: { checked: true, value: 0 }, // true = auto | false = number
  MaxValueControl: { checked: true, value: 0 },
  PaddingControl: { value: 0.1 },
  InnerPaddingControl: { value: 0 },

  EnableAxisStart: true,
  EnableAxisEnd: false,
  AxisRadialTickSize: 5,
  AxisRadialTickPadding: 5,
  AxisRadialTickRotation: 0,

  EnableAxisOuter: true,
  EnableAxisInner: false,
  AxisCircularTickSize: 5,
  AxisCircularTickPadding: 5,
  AxisCircularTickRotation: 0,

  AxisGlobalRadialFontSize: 12,
  AxisGlobalRadialFontFamily: 'arial',
  AxisGlobalRadialFontColor: '#222',

  EnableSeparator: { checked: true },
  LegendControl: { checked: true },
  LegendsFontFamilyControl: { value: 'sans-serif' },
  LegendsFontSizeControl: { value: 12 },
  LegendPlacementControl: {
    value: {
      // PARA USAR NO SELECT DE POSICAO...
      selectedPosition: 'right',
      direction: 'column',
      anchor: 'right',
      label: 'Direita',
      // anchor: 'bottom',
      translateX: 110,
      translateY: 0,
      // translateX: -92,
      // translateY: 45,
    },
  },
  LegendsSpacingControl: { value: 10 },
  // COLORS
  // ColorByControl: 'id',
  ColorTheme: { scheme: 'nivo' },
  Colors: {},
  PalletsControl: 'default', // || 'custom' // variável para poder selecionar palletas do nivo ou poder setar cada cor pessoalmente...
  //
  LabelTranslateX: 0,
  LabelTranslateY: 0,
  LabelGroupingControl: '',
  LabelCurrencyControl: 'Nenhum',
  LabelRotationControl: 0,
  separadorNumericoCasas: 0,
  LabelsSeparadorNumerico: true,
  EnableLabelControl: { checked: true },
  RadialLabelControl: { checked: true },
  LabelSkipWidthControl: { value: 0 },
  LabelSkipHeightControl: { value: 0 },
  LabelsFontSizeControl: { value: 15 },
  LabelsFontFamilyControl: { value: 'sans-serif' },
  LabelTextColorControl: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },

  // TOOLTIP / INTERACTIVE
  InteractiveControl: true,
  InteractiveSeparadorNumerico: true,
  InteractiveCurrencyControl: 'Nenhum',
  InteractiveGroupingControl: '',
  Animate: false,
  MotionConfig: 'default',

  CurrencyControl: 'Nenhum',
  LineShowTotalControl: true,
  ColumnShowTotalControl: true,
  StyleTitleControl: {
    fontSize: 20,
    color: '#000000',
    fontFamily: 'arial',
    bold: false,
    italic: false,
    underline: false,
  },
  legendClick: [],
  GradientPickerControl: {
    color1: '#4711B2',
    color2: '#FF9626',
  },
};
